import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Rest_park} from "../services/rest_park";


interface IParkState {
    dataPark: []
}
const initialState: IParkState = {
    dataPark: []
}

export const getPark = createAsyncThunk(
    'getPark',
    async () => {
        return await Rest_park.getPark()
    }
)
const parkSlice = createSlice({
    name: 'park',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPark.fulfilled, (state, action) => {
            state.dataPark = action.payload
        })
    },
});
export default parkSlice.reducer;