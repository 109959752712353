import React, { useState } from 'react'

import { useTranslation } from 'react-i18next';
import { AutoComplete, Button, Flex, Input } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import style from './style.module.scss';
import { pathsDepot } from './constants';
import { columns } from '../contacts/columns';
import { useStoreDispatch } from '../../store';
import { setFilters } from '../../store/releases';
import Container from '../../components/Container';
import { filtersReleases } from '../../store/filters';
import { TableReleases } from './components/TableReleases';
import { useBreadcrumb, useMenu, useParamsHistory } from '../../hooks';
import { RouteNames } from '../../router/names';

export const Releases = () => {
  useBreadcrumb(['Депо', 'Релизы']);
  useMenu({openKey: RouteNames.DEPOT, activeKeys: [RouteNames.DEPOT, RouteNames.RELEASES]});
  const {history} = useParamsHistory();
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [activeButtons, setActiveButtons] = useState<any>({});
  const [searchColumn, setSearchColumn] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (val: string) => {
    setSearchValue(val);
    dispatch(filtersReleases({ text: val, data: searchColumn })).then(
      (res) => {
        dispatch(setFilters(res.payload?.rows));
      },
    );
  };

  const handleButtonClickSearch = (option: any) => {
    setActiveButtons((prevActiveButtons: any) => {
      setSearchColumn((prev) =>
        prevActiveButtons[option.key]
          ? prev.filter((item: string) => item !== option.dataIndex)
          : [...prev, option.dataIndex],
      );
      return {
        ...prevActiveButtons,
        [option.key]: !prevActiveButtons[option.key],
      };
    });
  };

  return (
    <Container>
    <div className={style.container}>
         <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('Релизы')}</div>
        </Flex>
        <Flex gap={16}>
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
            onClick={() => history.push(pathsDepot.create)}
          />
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            options={columns}
            defaultValue={searchValue}
            popupMatchSelectWidth={false}
            onSearch={handleSearch}
            dropdownRender={() => (
              <>
                <div
                  className={'text-card-12'}
                  style={{ padding: '10px 10px' }}
                >
                  {t('Выбор колонок по которым будет производиться поиск')}
                </div>
                <Flex
                  gap={5}
                  style={{ width: 300, flexWrap: 'wrap', padding: 10 }}
                >
                  {columns.map(
                    (option) =>
                      option.title !== '' &&
                      option.dataIndex !== 'date_entry' &&
                      option.dataIndex !== 'arrival_date' &&
                      option.dataIndex !== 'departure_date' &&
                      option.dataIndex !== 'booking_before' && (
                        <Button
                          size={'small'}
                          key={option.key}
                          type="primary"
                          className={
                            activeButtons[Number(option.key)]
                              ? 'active-search-btn'
                              : ''
                          }
                          onClick={() => handleButtonClickSearch(option)}
                        >
                          {option.title}
                        </Button>
                      ),
                  )}
                </Flex>
              </>
            )}
          >
            <Input placeholder={t('Введите текст')} />
          </AutoComplete>
        </Flex>
      </Flex>
      <TableReleases paths={pathsDepot} />
    </div>
    </Container>
  )
}
