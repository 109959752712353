import * as CryptoJS from 'crypto-js'
import {NotificationPlacement} from "antd/es/notification/interface";
import moment from "moment/moment";

export class Utils {
    static getStorage(key: string,) {
        try {
            return localStorage.getItem(key)
        } catch (e) { console.error(e)}
    }

    static getStorageRoles() {
        try {
            return localStorage.getItem('_inr')
        } catch (e) { console.error(e)}
    }

    static async setStorage(key: string,payload: any) {
        try {
            return localStorage.setItem(key, payload)
        } catch (e) { console.error(e)}
    }

    static setStorageRoles(payload: string) {
        try {
            return localStorage.setItem('_inr', payload)
        } catch (e) {console.error(e)}
    }

    static yandexCity(payload: string): Promise<any> {

        return new Promise((resolve, reject) => {
            let url = "https://geocode-maps.yandex.ru/1.x/?apikey=abe34967-a3b9-4325-968a-aaae6b200547&geocode=" + payload + "&format=json";
            fetch(url)
                .then(response => response.text())
                .then(result => {
                    resolve(JSON.parse(result))
                    localStorage.setItem('vd', result)
                })
                .catch(error => console.error("error", error));
        })
    }

    static requisites(inn: string): Promise<any> {
        return  new Promise((resolve, reject) => {
            let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
            let token = "0b93ed269718d155cd1f34f003fd0dcae136d5fb ";
            let options: any = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: inn})
            }

            fetch(url, options)
                .then(response => response.text())
                .then(result =>{ resolve(result)
                    localStorage.setItem('vd',result)})
                .catch(error => console.error("error", error));
        })
    }

    static requisitesBank(inn: string): Promise<any> {
        return  new Promise((resolve, reject) => {
            let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/bank";
            let token = "0b93ed269718d155cd1f34f003fd0dcae136d5fb ";
            let options: any = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: inn})
            }

            fetch(url, options)
                .then(response => response.text())
                .then(result =>{ resolve(result)
                    localStorage.setItem('bn',result)})
                .catch(error => console.error("error", error));
        })
    }

    static decrypt(hex: any) {
        const bytes  = CryptoJS.AES.decrypt(hex, '02aab0f70eca03ff2e2397cb91076262');
        return  JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    static async encrypt(hex: string) {
        return CryptoJS.AES.encrypt(hex, '02aab0f70eca03ff2e2397cb91076262').toString();
    }

    static getUserInfo() {
        try {
            return this.decrypt(this.getStorage('_in'))
        } catch (e) { console.error(e)}
    }

    static ErrorOpenNotification = (
        placement: NotificationPlacement,
        duration?: number | null ,
        message?: string,
        description?: string | null,
        api?: any) => {
        api.error({
            message: message,
            description: description,
            duration,
            placement,
        });
    };
    static InfoOpenNotification = (
        placement: NotificationPlacement,
        message?: string,
        duration?: number | null ,
        api?: any) => {
        api.info({
            message: message,
            placement,
            duration: duration,
        });
    };

    static openNotification = (placement: NotificationPlacement,message: string,api: any) => {
        api.info({
            message: message,
            placement,
        });
    };

    static localeCity(){
        return [
                { value: 'ru', label: 'ru' },
                { value: 'en', label: 'en' },
                { value: 'de', label: 'de' },
            ]
    }

    static role (){
        return  this.getUserInfo().group_id > 0 ?
            {id: this.getUserInfo().group_id,type: 'group'} :
            {id: this.getUserInfo().role_id,type: 'role'}
    }

    static isValidContainerNumber = (value: string): boolean => {
        const regex = /^[A-Z]{4}\d{7}$/;
        return regex.test(value);
    };

    static isValidDate = (value: string): boolean => {
        return moment(value, ['DD.MM.YYYY HH:mm:ss', 'DD.MM.YYYY', moment.ISO_8601], true).isValid();
    };

    static convertToEnglishUppercase(text: string): string {
        const keyboardMap: Record<string, string> = {
            'а': 'F', 'б': ',', 'в': 'D', 'г': 'U', 'д': 'L', 'е': 'T', 'ё': '~', 'ж': ':',
            'з': 'P', 'и': 'B', 'й': 'Q', 'к': 'R', 'л': 'K', 'м': 'V', 'н': 'Y', 'о': 'J',
            'п': 'G', 'р': 'H', 'с': 'C', 'т': 'N', 'у': 'E', 'ф': 'A', 'х': '{', 'ц': 'W',
            'ч': 'X', 'ш': 'I', 'щ': 'O', 'ъ': '}', 'ы': 'S', 'ь': 'M', 'э': '"', 'ю': '>',
            'я': 'Z', 'А': 'F', 'Б': '<', 'В': 'D', 'Г': 'U', 'Д': 'L', 'Е': 'T', 'Ё': '~',
            'Ж': ':', 'З': 'P', 'И': 'B', 'Й': 'Q', 'К': 'R', 'Л': 'K', 'М': 'V', 'Н': 'Y',
            'О': 'J', 'П': 'G', 'Р': 'H', 'С': 'C', 'Т': 'N', 'У': 'E', 'Ф': 'A', 'Х': '{',
            'Ц': 'W', 'Ч': 'X', 'Ш': 'I', 'Щ': 'O', 'Ъ': '}', 'Ы': 'S', 'Ь': 'M', 'Э': '"',
            'Ю': '>', 'Я': 'Z'
        };

        return text.split('').map(char => {
            const upperChar = char.toUpperCase();
            return keyboardMap[char] || upperChar;
        }).join('');
    }

    static defaultTable = ()=>{
        return  Array.from({ length: 20 }, (_, index) => ({
            id: index + 1,
            avatar: null
        }));
    }
}
