// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ref-responsible {
  float: right;
  font-size: 12px;
  color: #a3a9b1;
  cursor: pointer;
}

.ant-space-item {
  width: 100%;
}

.ant-modal .ant-modal-content {
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/contractors/style.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".ref-responsible {\n  float: right;\n  font-size: 12px;\n  color: #a3a9b1;\n  cursor: pointer;\n}\n\n.ant-space-item {\n  width: 100%;\n}\n\n.ant-modal .ant-modal-content {\n  height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
