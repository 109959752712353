import React, { useMemo, useRef } from 'react';

import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

import TabDoc from './tabs/view/TabDoc';
import style from '../style.module.scss';
import TabDeal from './tabs/view/TabDeal';
import Responsible from './list/Responsible';
import ITabs from '../../../../components/ui/Tabs';
import { useParamsHistory } from '../../../../hooks';
import { TAB_DEAL, TAB_DOCUMENT } from '../constants';
import { ITab } from '../../../../components/ui/Tabs/Tabs';
import ScrollList from '../../../../components/ui/ScrollList';
import CopyButton from '../../../../components/ui/CopyButton/CopyButton';
import { CopyBufferIconDefault, EditViewIconDefault } from '../../../../assets/image/svg';
import TooltipButton from '../../../../components/ui/TooltipButton/TooltipButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { IDeal, IViewDeal } from '../../../../store/sales';

const ViewDeal: React.FC<IViewDeal> = ({ onClose, onEdit, isOpen }) => {
  const { t } = useTranslation();
  const refResponsible = useRef<HTMLDivElement>(null);
  const { location } = useParamsHistory();
  const { pathname } = location;
  const deal = useSelector<RootState>(
    (state) => state.sales.dealOneData,  ) as IDeal;

  const items: ITab[] = useMemo(
    () => [
      {
        key: TAB_DEAL,
        label: 'Сделка',
        forceRender: true,
        content: <TabDeal />,
      },
      {
        key: TAB_DOCUMENT,
        label: 'Документы',
        forceRender: true,
        content: <TabDoc />,
      },
    ],
    [isOpen],
  );
  return (
    <div className={style['tabs-form']}>
      <Flex justify={'space-between'} align="center">
        <Flex vertical style={{ width: '100%' }} justify={'space-between'}>
          <Flex
            justify={'space-between'}
            align={'center'}
            style={{ marginBottom: 24 }}
          >
            <h2 className={style.title}>{t('Сделка')} {deal.id_deal}</h2>
            <Flex gap={2} align={'center'}>
              <TooltipButton
                propsTooltip={{title: t('Редактировать'), placement: 'topLeft'}}
                propsButton={{
                  hidden: !!deal?.delete,
                  icon: <EditViewIconDefault />,
                  type: 'text',
                  className: style.icon,
                  style: { padding: 0, height: 'auto' },
                  onClick: onEdit,
                }}
              />
              <CopyButton
                className={style.icon}
                text={pathname}
                textSuccess={t('Ссылка скопирована')}
                icon={CopyBufferIconDefault}
              />
            </Flex>
          </Flex>
          <ITabs className={style['tabs-form__tab']} tabs={items} />
          <div className={style['box-list']}>
            {!!deal?.responsible?.length && (
              <ScrollList
                setLastItemReached={(v) => v}
                height={
                  deal?.responsible?.length >= 3
                    ? 200
                    : (deal?.responsible?.length || 1) * 80
                }
                totalTableActivity={1}
                data={deal?.responsible}
                tableRef={refResponsible}
                paginationActivity={() => {}}
                isTable={false}
                endMessage={null}
              >
                {deal?.responsible.map((cont, i) => (
                  <Responsible
                    key={i}
                    title={cont.name}
                    avatar={cont.avatar}
                    description={
                      cont?.id === deal?.responsible_id
                        ? t('Ответственный')
                        : ''
                    }
                  />
                ))}
              </ScrollList>
            )}
          </div>
        </Flex>
      </Flex>
      <Flex
        gap={8}
        justify="space-between"
        style={{ margin: '32px 0 10px auto', maxWidth: '500px' }}
      >
        <Button onClick={onClose} type="primary" style={{ width: '174px' }}>
          {t('Закрыть')}
        </Button>
      </Flex>
    </div>
  );
};

export default ViewDeal;
