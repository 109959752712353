import { MenuProps } from 'antd';
import { IColumnType } from './Table';
import { TableRowCell } from './TableRowCell';
import TableRowItem from './TableRowItem';

interface Props<T> {
  t: any;
  data: T[];
  columns: IColumnType<T>[];
  hiddenColumn: any;
  onRow?: (record: any, rowIndex: number) => void;
  subMenu?: {
    label: string;
    getSubMenu: ({item, rows, data, onClose} : {item: any, rows: Set<any>, data: T[], onClose: () => void}) => MenuProps['items'];
  };
  editRow?: (item: any) => void;
  deleteRow?: (row: any) => void;
  onClickLink?: ((data: any) => void) | undefined;
  setAllSelected?: (value: ((prevState: boolean) => boolean) | boolean) => void;
  selectedRows?: any;
  handleRowSelect: (id: number, checked: boolean) => void;
  handleCopyAll?: () => void;
  handleDeleteCopyAll?: () => void | any[];
}

export function TableRow<T>({
  t,
  data,
  columns,
  hiddenColumn,
  onRow,
  editRow,
  deleteRow,
  onClickLink,
  selectedRows,
  handleRowSelect,
  handleCopyAll,
  handleDeleteCopyAll,
  subMenu
}: Props<T>): JSX.Element {

  return (
    <>
      {data?.length
        ? data?.map((item: any, itemIndex) => (
            <TableRowItem
              key={`table-body-${itemIndex}`}
              onRow={onRow}
              item={item}
              itemIndex={itemIndex}
              isChecked={selectedRows.has(item.id)}
              handleRowSelect={handleRowSelect}
            >
              {columns.map(
                (column, columnIndex) =>
                  !hiddenColumn.includes(columnIndex) && (
                    <TableRowCell
                      t={t}
                      key={`table-row-cell-${columnIndex}`}
                      item={item}
                      column={column}
                      data={data}
                      selectedRows={selectedRows}
                      onClickLink={onClickLink}
                      itemIndex={columnIndex}
                      hiddenColumn={hiddenColumn}
                      onRow={onRow}
                      editRow={editRow}
                      deleteRow={deleteRow}
                      handleCopyAll={handleCopyAll}
                      handleDeleteCopyAll={handleDeleteCopyAll}
                      subMenu={subMenu}
                    />
                  ),
              )}
            </TableRowItem>
          ))
        : null}
    </>
  );
}
