import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  App,
  Button,
  Flex,
  Form,
  FormListFieldData,
  Input,
  Modal,
  Select,
  Tooltip,
  Typography,
} from 'antd';

import Icon, { InfoCircleOutlined } from '@ant-design/icons';

import style from '../../style.module.scss';
import { IContainer, ITab } from '../../types';
import { nameColumnsKtk } from '../../constants';
import { RouteNames } from '../../../../router/names';
import { getEmpty, getSizeKtk, mapperKtk } from '../utils';
import { IContainers } from '../../../../types/containers';
import { findContainers } from '../../../../store/containers';
import IconButton from '../../../../components/ui/IconButton';
import { openInNewTab } from '../../../../helpers/link-helper';
import { RootState, useStoreDispatch } from '../../../../store';
import { FormInputNumber } from '../../../../components/ui/Form';
import { handleDownloadXlsx } from '../../../../helpers/xlsx-helper';
import { Checkbox } from '../../../../components/ui/Checkbox/Checkbox';
import TooltipButton from '../../../../components/ui/TooltipButton/TooltipButton';
import {
  resetContainer,
  setIsContainerDrawer,
} from '../../../../store/releases';
import { errorImportIcon } from '../../../../assets/image/svg';

export const TableTab: React.FC = () => {
  const form = Form.useFormInstance();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  const reasons = [
    { id: 0, reason: '' },
    { id: 1, reason: t('Отмена закупа') },
    { id: 2, reason: t('Отмена продажи') },
    { id: 3, reason: t('Замена КТК') },
    { id: 4, reason: t('Другое') },
  ];
  const id: number = Form.useWatch('id', {
    form,
    preserve: true,
  });
  const no_number: boolean = Form.useWatch('no_number', {
    form,
    preserve: true,
  });
  const type: string = Form.useWatch('type', {
    form,
    preserve: true,
  });
  const terminal: string = Form.useWatch('terminal', {
    form,
    preserve: true,
  });
  const terminal_id: number = Form.useWatch('terminal_id', {
    form,
    preserve: true,
  });
  const containers_count: number = Form.useWatch('containers_count', {
    form,
    preserve: true,
  });
  const release_id: string = Form.useWatch('release_id', {
    form,
    preserve: true,
  });
  const containers = Form.useWatch('containers', {
    form,
    preserve: true,
  }) as IContainer[];
  const dataTypeContainers = useSelector(
    (state: RootState) => state.types.dataTypeContainers,
  );
  const newContainer = useSelector(
    (state: RootState) => state.releases.newContainer as IContainers,
  );
  const isContainerDrawer = useSelector(
    (state: RootState) => state.releases.isContainerDrawer as boolean,
  );
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [isDelete, setIsDelete] = React.useState<boolean>(false);
  const [reason, setReason] = React.useState(reasons[0]);
  const [numbersKtk, setNumbersKtk] = React.useState<string[]>([]);
  const [containerNumbers, setContainerNumbers] = React.useState('');
  const [isOpenContainerNumbers, setIsOpenContainerNumbers] =
    React.useState(false);
  const [indexCurrent, setIndexCurrent] = useState<number>(0);

  useEffect(() => {
    if (newContainer) {
      const fields = form.getFieldsValue();
      const { containers } = fields;
      const { num_container, id, type_container } = newContainer;
      const result: IContainer[] = [...containers];
      result[indexCurrent] = {
        ...containers[indexCurrent],
        num_container,
        container_id: id,
        type_container,
        size: getSizeKtk(type_container),
      };
      form.setFieldsValue({ containers: result, containers_count: result.length });
      dispatch(resetContainer(null));
    }
  }, [isContainerDrawer, newContainer]);

  const filterTerminalKTK = (ktk: IContainer[]) => {
    return (
      ktk?.filter((field) =>
        terminal_id && field?.['num_container']
          ? field?.['child_terminal_id'] === terminal_id
          : true,
      ) || []
    );
  };

  const closeModalContainersNumbers = () => {
    setIsOpenContainerNumbers(false);
    setContainerNumbers('');
    setNumbersKtk([]);
  };

  const handlerUpload = () => {
    const rows = containers.map(
      ({ num_container, size, type_container, comment, count }) => ({
        size,
        type_container,
        num_container,
        comment,
        count,
      }),
    );
    handleDownloadXlsx({
      nameFile: `${t('ктк релиза')} ${release_id ?? ''}`,
      nameColumns: nameColumnsKtk,
      nameList: `${t('Лист')} 1`,
      rows,
    });
  };

  const handlerReceivingData = (newContainers: string[]) => {
    const result = newContainers.filter(
      (num_container) =>
        containers.findIndex(
          (cont) =>
            cont.num_container?.toLocaleLowerCase() ===
            num_container?.toLocaleLowerCase(),
        ) === -1,
    );

    dispatch(findContainers({ data: result, key: 'num_container' }))
      .then((res) => {
        const resultContainers = res?.payload?.length
          ? filterTerminalKTK(mapperKtk(res.payload))
          : [];
        const result = [...resultContainers, ...containers];
        form.setFieldsValue({
          containers: result,
        });
        message.success(
          `${t('Из')} ${newContainers?.length} ${t('добавлены')} ${
            resultContainers.length
          } ктк`,
          3,
        );
      })
      .catch((err) => {
        message.error(`${t('Произошла ошибка')}, добавлено 0 ктк`, 3);
        console.error(t('Произошла ошибка'), err);
      });
  };

  const removeField = () => {
    const result: IContainer[] = [...containers];
    result[indexCurrent] = {
      ...containers[indexCurrent],
      reason: reason.reason,
      delete: true,
      edit: false,
    };
    form.setFieldsValue({ containers: result,  containers_count: result.length });
    setIndexCurrent(0);
    closeConfirmation();
  };
  const disabledField = () => {
    const result: IContainer[] = [...containers];
    result[indexCurrent] = {
      ...containers[indexCurrent],
      reason: reason.reason,
      delete: false,
      edit: false,
    };
    form.setFieldsValue({ containers: result, containers_count: result.length });
    setIndexCurrent(0);
    closeConfirmation();
  };

  const createContainer = (index: any) => {
    setIndexCurrent(index);
    dispatch(setIsContainerDrawer(true));
  };

  const closeConfirmation = () => {
    setConfirmation(false);
    setIsDelete(false);
    setReason(reasons[0]);
  };

  const deletedContainer = (index: any) => {
    setIndexCurrent(index);
    setIsDelete(true);
    setReason(reasons[0]);
    setConfirmation(true);
  };

  const submitContainer = (index: number) => {
    setIndexCurrent(index);
    setIsDelete(false);
    setConfirmation(true);
  };

  // HACK: порядок для маски нужен
  const changeHandlerAreaNumbers = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const input = e.target;
    const replaceValue = String(input.value).replace(/\n|\s/g, '');
    input.value = replaceValue.match(/.{0,11}/g)?.join('\n') || '';
    input.value = input.value.slice(0, -1).toUpperCase();
    setNumbersKtk(
      input.value.split('\n').map((num_container) => num_container),
    );
    setContainerNumbers(input.value);
  };
  const keyUpHandlerAreaNumbers = (
    evt: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    const specialCharRegex = new RegExp('[a-zA-Z0-9]');
    if (!specialCharRegex.test(evt.key)) {
      evt.preventDefault();
      return false;
    }
  };

  const submitInputKtk = () => {
    handlerReceivingData(numbersKtk);
    closeModalContainersNumbers();
  };

  const footerAddNumberContainer = (
    <div className={style.modal__footer}>
      <div className={style['modal__buttons']}>
        <TooltipButton
          propsTooltip={{
            title: t('Загрузить'),
          }}
          propsButton={{
            type: 'primary',
            disabled: !numbersKtk?.length,
            onClick: submitInputKtk,
          }}
        >
          {t('Загрузить')}
        </TooltipButton>
        <Button
          onClick={closeModalContainersNumbers}
          type={'text'}
          style={{ color: '#E14453' }}
        >
          {t('Отменить')}
        </Button>
      </div>
    </div>
  );

  const contentDelete = (
    <Flex vertical gap={20}>
      <Flex gap={10} align="center">
        <Icon component={errorImportIcon} />
        <Typography.Text className={style['modal__title']}>
          Выберите причину удаления
        </Typography.Text>
      </Flex>
      <Flex vertical gap={10} style={{ paddingLeft: '20px' }}>
        <Checkbox
          isChoice={reason.id === 1}
          onChange={(e: boolean) => {
            if (e) {
              setReason(reasons[1]);
            }
          }}
        >
          {t('Отмена закупа')}
        </Checkbox>
        <Checkbox
          isChoice={reason.id === 2}
          onChange={(e: boolean) => {
            if (e) {
              setReason(reasons[2]);
            }
          }}
        >
          {t('Отмена продажи')}
        </Checkbox>
        <Checkbox
          isChoice={reason.id === 3}
          onChange={(e: boolean) => {
            if (e) {
              setReason(reasons[3]);
            }
          }}
        >
          {t('Замена КТК')}
        </Checkbox>
        <Checkbox
          isChoice={reason.id === 4}
          onChange={(e: boolean) => {
            if (e) {
              setReason(reasons[4]);
            }
          }}
        >
          {t('Другое')}
        </Checkbox>
        {reason.id === 4 && (
          <Input
            placeholder={t('Введите причину')}
            onChange={(e) => {
              setReason((prev) => ({
                ...prev,
                reason: e.target.value || t('Другое'),
              }));
            }}
          />
        )}
      </Flex>
    </Flex>
  );
  const footerConfirmContainer = ({
    success,
    cancel,
  }: {
    success: () => void;
    cancel: () => void;
  }) => (
    <div className={style.modal__footer} style={{ paddingLeft: '20px' }}>
      <div className={style['modal__buttons']}>
        <TooltipButton
          propsTooltip={{
            title: t(isDelete ? 'Сохранить' : 'Да'),
          }}
          propsButton={{
            type: isDelete ? 'primary' : 'default',
            onClick: success,
          }}
        >
          {t(isDelete ? 'Сохранить' : 'Да')}
        </TooltipButton>
        <TooltipButton
          propsTooltip={{
            title: t(isDelete ? 'Отменить' : 'Нет'),
          }}
          propsButton={{
            type: isDelete ? 'text' : 'default',
            style: isDelete ? { color: '#E14453' } : {},
            onClick: cancel,
          }}
        >
          {t(isDelete ? 'Отменить' : 'Нет')}
        </TooltipButton>
      </div>
    </div>
  );

  return (
    <Flex vertical gap={10}>
      <Flex gap={5} style={{ marginBottom: '5px' }}>
        <Typography.Text>{t('Терминал')}:</Typography.Text>
        <Typography.Text type={terminal ? 'success' : 'danger'}>
          {terminal || 'не выбран.'}
        </Typography.Text>
      </Flex>
      <div className={style.buttons__title}>
        <Button
          type="link"
          onClick={() => setIsOpenContainerNumbers(true)}
          style={{ marginRight: 'auto', paddingLeft: 0 }}
          disabled={no_number}
        >
          {t('Заполнить контейнеры автоматически')}
        </Button>
        <IconButton
          iType="excel"
          className={style['button__excel']}
          onClick={handlerUpload}
          style={{
            opacity: !containers?.length ? 0.5 : 1,
          }}
          disabled={!containers?.length}
          title={t('Выгрузить в Excel')}
        />
      </div>

      <Form.List name="containers">
        {(fields: FormListFieldData[], operation, meta: { errors: any }) => (
          <div className={style['block-ktk']}>
            <div
              className={style['overlap-group']}
              style={{ marginBottom: '10px' }}
            >
              <div className={style['table']}>
                <div className={style['table__container']}>
                  <div className={style['table__header']}>
                    <div className={style['menu-icon']}>
                      <div className={style['label-card-16']} />
                    </div>
                    {nameColumnsKtk.map((title) => (
                      <div
                        key={title}
                        className={classnames(
                          style['table__cell'],
                          style['label-card-16'],
                        )}
                      >
                        {t(title)}
                      </div>
                    ))}
                    <div className={style['menu-icon']}>
                      <div className={style['label-card-16']} />
                    </div>
                  </div>

                  <div className={style['table__body']}>
                    {fields.map(({ ...field }) => (
                      <div
                        key={field.key}
                        className={style['table__row']}
                        data-row="row"
                      >
                        <Flex className={style['table__menu']} align="center">
                          <div
                            className={classnames(
                              style['text-card-14'],
                              style['menu-icon'],
                            )}
                          >
                            {containers?.[field.name]?.['delete'] ? (
                              <IconButton
                                className={style['menu-icon__button-error']}
                                iType="delete"
                                title={t(
                                  containers?.[field.name]?.['reason'] ||
                                    'Удален',
                                )}
                                style={{ cursor: 'default' }}
                                placement="topRight"
                              />
                            ) : (
                              <>
                                <IconButton
                                  className={style['menu-icon__button-error']}
                                  iType="close"
                                  title={t('Удалить')}
                                  placement="topRight"
                                  disabled={!containers?.[field.name]?.['edit']}
                                  onClick={() =>
                                    id && containers?.[field.name]?.['id']
                                      ? deletedContainer(field.name)
                                      : operation.remove(field.name)
                                  }
                                />
                                <IconButton
                                  className={style['menu-icon__button-success']}
                                  iType="check"
                                  title={
                                    !containers?.[field.name]?.['edit']
                                      ? t('Выдан')
                                      : t('Выдать')
                                  }
                                  placement="topRight"
                                  disabled={
                                    !containers?.[field.name]?.[
                                      'num_container'
                                    ] ||
                                    !id ||
                                    !containers?.[field.name]?.['edit'] ||
                                    !containers?.[field.name]?.['id']
                                  }
                                  onClick={() => submitContainer(field.name)}
                                />
                              </>
                            )}
                          </div>
                        </Flex>
                        <div className={classnames(style['table__cell'])}>
                          <Form.Item name={[field.name, 'size']} noStyle />
                          <Typography.Text
                            className={classnames(
                              style['text-card-14'],
                              style['pl-10'],
                            )}
                            ellipsis
                          >
                            {containers?.[field.name]?.['size']}
                          </Typography.Text>
                        </div>
                        <div
                          className={classnames(
                            style['table__cell'],
                            style['text-card-14'],
                          )}
                        >
                          {!containers?.[field.name]?.['num_container'] ||
                          !containers?.[field.name]?.['edit'] ? (
                            <Form.Item name={[field.name, 'type_container_id']}>
                              {
                                <Select
                                  disabled={!containers?.[field.name]?.['edit']}
                                  placeholder={t('Добавьте тип')}
                                  style={{ width: '100%' }}
                                  options={dataTypeContainers}
                                  onSelect={(val) => {
                                    containers[field.name].size =
                                      getSizeKtk(val);
                                    containers[field.name].type_container_id =
                                      val;
                                    containers[field.name].type_container = val;
                                    form.setFieldsValue({ containers });
                                  }}
                                />
                              }
                            </Form.Item>
                          ) : (
                            <Typography.Text
                              ellipsis={{
                                tooltip: {
                                  placement: 'leftBottom',
                                  title:
                                    containers?.[field.name]?.type_container ||
                                    '',
                                },
                              }}
                            >
                              {containers?.[field.name]?.['type_container'] ||
                                t('Добавьте тип')}
                            </Typography.Text>
                          )}
                        </div>

                        <div className={classnames(style['table__cell'])}>
                          <Typography.Link
                            className={classnames(style['text-card-14'], {
                              [style['default-link']]:
                                containers?.[field.name]?.['container_id'],
                            })}
                            ellipsis
                            onClick={() =>
                              openInNewTab(
                                `${RouteNames.CONTAINERS_VIEW}${
                                  containers?.[field.name]?.['container_id']
                                }`,
                              )
                            }
                          >
                            {containers?.[field.name]?.['num_container']}
                          </Typography.Link>
                        </div>
                        <div className={classnames(style['table__cell'])}>
                          <Tooltip
                            placement={'top'}
                            title={containers?.[field.name]?.['comment']}
                          >
                            <Form.Item name={[field.name, 'comment']}>
                              <Input
                                allowClear
                                disabled={!containers?.[field.name]?.['edit']}
                              />
                            </Form.Item>
                          </Tooltip>
                        </div>
                        <div
                          className={classnames(
                            style['table__cell'],
                            style['text-card-14'],
                          )}
                        >
                          {!containers?.[field.name]?.['num_container'] ? (
                            <Form.Item name={[field.name, 'count']}>
                              {
                                <FormInputNumber
                                  min={1}
                                  disabled={!containers?.[field.name]?.['edit']}
                                />
                              }
                            </Form.Item>
                          ) : (
                            <Typography.Text
                              ellipsis={{
                                tooltip: {
                                  placement: 'leftBottom',
                                  title: containers?.[field.name]?.count || '',
                                },
                              }}
                            >
                              {containers?.[field.name]?.['count'] || ''}
                            </Typography.Text>
                          )}
                        </div>
                        <div
                          className={classnames(
                            style['text-card-14'],
                            style['menu-icon'],
                          )}
                        >
                          <IconButton
                            className={style['menu-icon__button-depo']}
                            iType="pluse"
                            title={t('Создать в Депо')}
                            placement="topRight"
                            disabled={
                              !!containers?.[field.name]?.num_container ||
                              !containers?.[field.name]?.['edit']
                            }
                            onClick={() => {
                              createContainer(field.name);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <Modal
              className={style.modal}
              open={confirmation}
              closeIcon={null}
              centered
              footer={() =>
                footerConfirmContainer(
                  isDelete
                    ? {
                        success: removeField,
                        cancel: closeConfirmation,
                      }
                    : {
                        success: disabledField,
                        cancel: closeConfirmation,
                      },
                )
              }
              onCancel={(e: any) => setConfirmation(false)}
            >
              <div className={style.modal}>
                <div className={classnames(style['modal__content'])}>
                  {isDelete ? (
                    contentDelete
                  ) : (
                    <Flex justify="center">
                      <Typography.Text className={style['modal__title']}>
                        {t('Подтвердить выдачу')}
                      </Typography.Text>
                    </Flex>
                  )}
                </div>
              </div>
            </Modal>
            <Button
              type="default"
              className={style['button__primary']}
              onClick={() => operation.add(getEmpty({ id, release_id, type }))}
              style={{ marginRight: 'auto' }}
              disabled={!no_number}
            >
              {t('Добавить контейнер')}
            </Button>
          </div>
        )}
      </Form.List>

      <Modal
        open={isOpenContainerNumbers}
        centered
        footer={footerAddNumberContainer}
        onCancel={closeModalContainersNumbers}
      >
        <div className={style.modal} style={{ maxWidth: 'none' }}>
          <div
            className={classnames(style['modal__content'])}
            style={{ padding: '30px' }}
          >
            <Input.TextArea
              value={containerNumbers}
              className={classnames(
                'all-custom-v-scroll',
                style['modal__textarea'],
              )}
              placeholder={t('Введите номера ктк')}
              autoSize={{ minRows: 15, maxRows: 18 }}
              onChange={changeHandlerAreaNumbers}
              onKeyUp={keyUpHandlerAreaNumbers}
              onKeyDown={keyUpHandlerAreaNumbers}
            />
          </div>
        </div>
      </Modal>
    </Flex>
  );
};
