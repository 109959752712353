import { ALL_TYPE_DEAL } from "../constants";
import socket from "../socket";
import { IComments, IContainer, IDeal } from "../store/sales";

export const GENERATE_ID_DEAL_SALES = 'generate-id-deal-sales';
export const GET_FILTERS_DEAL_SALES = 'get-filters-deal-sales';
export const GET_DEALS_SALES = 'get-deals-sales';
export const GET_DEALS_SALES_IDS = 'get-deals-sales-ids';
export const SET_DEAL_SALES = 'set-deal-sales';
export const FIND_ONE_DEAL_SALES = 'find-one-deal-sales';
export const DELETE_DEAL_SALES = 'delete-deal-sales';

export class Rest_deal{
    static getTypeDeal(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(ALL_TYPE_DEAL, null,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static generateIdDeal(payload: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(GENERATE_ID_DEAL_SALES, payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

    static getFiltersDeal(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(GET_FILTERS_DEAL_SALES, param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getContainerDealStatuses(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-dealContainer-statusAll', null,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getDeals(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(GET_DEALS_SALES, param, (response: any, req: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getDealsIds(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(GET_DEALS_SALES_IDS, param, (response: any, req: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static setDeals(payload: {deal: IDeal, containers: IContainer[], comments: IComments[]}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(SET_DEAL_SALES, payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static findOneDeal(id: number): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(FIND_ONE_DEAL_SALES, id, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static findAllCommentsDeal(id: number): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-all-comments-deal-sales', id, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static deleteDeal(payload: IDeal | IDeal[]): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(DELETE_DEAL_SALES, payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
}
