import axios from 'axios';

export const fetchAddAllFiles = async (files: any[]) => {
  const URL: any = process.env.REACT_APP_API_URL;
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return await axios.post(`${URL}/uploadAllFiles`, files, config);
};
