// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__5V8NI {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 5px 0;
}
.style_container__rate__oeC\\+m {
  color: #0055FF;
}
.style_container__description__VGXiA {
  border-radius: 6px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  color: #858FA3;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Rate/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,QAAA;EACA,cAAA;AACF;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAEJ","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 5px;\n  padding: 5px 0;\n  &__rate {\n    color: #0055FF;\n  }\n  &__description {\n    border-radius: 6px;\n    padding: 5px 10px;\n    font-size: 12px;\n    font-weight: 600;\n    color: #858FA3;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__5V8NI`,
	"container__rate": `style_container__rate__oeC+m`,
	"container__description": `style_container__description__VGXiA`
};
export default ___CSS_LOADER_EXPORT___;
