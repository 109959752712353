import { IContainer } from "../../../store/sales";

// для сделок
const SALES = 3; // id в продаже
// мапер для таблицы ктк в сделках
export const getterKtk = (container: IContainer): IContainer => ({
      id: container?.id,
      num_container: container.num_container,
      container_id: container?.id,
      type: container?.type_container,
      status: null,
      status_id: null,
      bet: container?.bet ?? null,
      price: null,
      departure_date: null,
      delete: false,
      deal: container?.deal ?? null,
      edit: false,
    });
export const mapperKtk = (containers: any[]): IContainer[] | [] => {
  if (Array.isArray(containers) && containers?.length) {
    return containers.map(getterKtk);
  }
  return [];
};
export const filterResultKtk = (containers: any[]): IContainer[] | [] => {
  if (Array.isArray(containers) && containers?.length) {
    return containers.filter((item) => item?.status_id !== SALES).map(getterKtk);
  }
  return [];
};