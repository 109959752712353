import socket from "../socket";

export const SEARCH_FILTERS_DEAL_SALES = 'search-filters-deal-sales';
export const SEARCH_FILTERS_DEAL_RENT = 'search-filters-deal-rent';

export class RestFilters {
    static filtersContainers(payload: {}): Promise<any> {
        return new Promise(async (resolve, reject) => {
            socket.emit('filters-containers', payload, (response: any) => {
                try {
                    resolve(response)
                } catch (e) {reject(e)}
            })
        })
    }
    static filtersTerminals(payload: {}): Promise<any> {
        return new Promise(async (resolve, reject) => {
            socket.emit('filters-terminals', payload, (response: any) => {
                try {
                    resolve(response)
                } catch (e) {reject(e)}
            })
        })
    }
    static filtersContractors (payload: {}): Promise<any> {
        return new Promise(async (resolve, reject) => {
            socket.emit('filters-contractors', payload, (response: any) => {
                try {
                    resolve(response)
                } catch (e) {reject(e)}
            })
        })
    }
    static filtersContacts (payload: {}): Promise<any> {
        return new Promise(async (resolve, reject) => {
            socket.emit('filters-contacts', payload, (response: any) => {
                try {
                    resolve(response)
                } catch (e) {reject(e)}
            })
        })
    }

    static filtersDealSales(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(SEARCH_FILTERS_DEAL_SALES, param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static filtersAgreements(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('search-filters-agreements', param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static filtersReleases(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('search-filters-releases', param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static filtersDealRent(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(SEARCH_FILTERS_DEAL_RENT, param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
}
