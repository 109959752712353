// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginForm_loginButton__GHtvD {
  width: "100%";
}

.LoginForm_color__XX3Wz {
  color: #0055FF;
  cursor: pointer;
  margin: 0;
}

.LoginForm_container__d3-dh {
  width: 400px;
}

.LoginForm_input__password__9I4Da .ant-input-suffix {
  translate: 4px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/LoginForm/LoginForm.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;AACD;;AAEA;EACC,cAAA;EACA,eAAA;EACA,SAAA;AACD;;AAEA;EACC,YAAA;AACD;;AAKG;EACC,gBAAA;AAFJ","sourcesContent":[".loginButton {\n\twidth: '100%';\n}\n\n.color {\n\tcolor: #0055FF;\n\tcursor: pointer;\n\tmargin: 0;\n}\n\n.container {\n\twidth: 400px;\n}\n\n.input {\n\t&__password {\n\t\t:global {\n\t\t\t.ant-input-suffix {\n\t\t\t\ttranslate: 4px 0;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginButton": `LoginForm_loginButton__GHtvD`,
	"color": `LoginForm_color__XX3Wz`,
	"container": `LoginForm_container__d3-dh`,
	"input__password": `LoginForm_input__password__9I4Da`
};
export default ___CSS_LOADER_EXPORT___;
