import socket from "../socket";


export class Rest_contacts {

    static getFiltersContact(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-filters-contact', payload, (response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static getSourceContact(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('source-contact',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static getFindExecutor(payload: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-executor',payload,(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static async getFindCity(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-city',payload,(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static setContacts(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('save-contact', payload,(response: any) => {
                try {
                    resolve(response)
                } catch (e) {reject(e)}
            })
        })
    }

    static updateContacts(payload: {}): Promise<any> {
        return new Promise( () => {
            socket.emit('update-contact', payload)
        })
    }

    static getContact(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-contact', payload, (response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }
    static findContactById(id: number): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-contact-by-id', id, (response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static async findContact(payload: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-contact',payload,(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static async deleteContact(payload: {}): Promise<any> {
        return new Promise(async (resolve, reject) => {
            socket.emit('delete-contact', payload, (response: any) => {
                try {
                    resolve(response)
                } catch (e) { reject(e) }
            })
        })
    }
}
