import Layouts from "./Layouts/Layouts";


const Container = (props: any) => {

    return (
        <Layouts>
            {props.children}
        </Layouts>
    );
};

export default Container;
