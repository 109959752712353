// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.carousel {
  justify-content: center;
  top: 9%;
  position: relative;
}

.image-wrapper {
  transition: transform 0.5s ease; /* Плавный переход */
}

.active {
  transform: translateX(0);
  min-width: 700px;
  max-height: 604px;
}

.box-arrow {
  align-self: center;
  text-align: -webkit-center;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/carousel.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,uBAAA;EACA,OAAA;EACA,kBAAA;AAEF;;AACA;EACE,+BAAA,EAAA,oBAAA;AAEF;;AACA;EACE,wBAAA;EACA,gBAAA;EACA,iBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,0BAAA;AAGF","sourcesContent":[".carousel {\n  justify-content: center;\n  top: 9%;\n  position: relative;\n}\n\n.image-wrapper {\n  transition: transform 0.5s ease; /* Плавный переход */\n}\n\n.active {\n  transform: translateX(0);\n  min-width: 700px;\n  max-height: 604px;\n}\n.box-arrow{\n  align-self: center;\n  text-align: -webkit-center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
