import { Flex, Typography } from 'antd';
import IRate from '../../../components/ui/Rate';
import { IColumnType } from '../../../components/ui/Table/Table';
import { IRelease } from '../../../store/releases';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../router/names';
import { IDeal } from '../../../store/rent';

export const columns: IColumnType<any>[] = [
  {
    dataIndex: 'id',
    checkbox: true,
    menu: true,
    type: 'string',
    key: '0',
  },
  {
    title: 'ID',
    dataIndex: 'id_deal',
    link: true,
    width: '20%',
    type: 'string',
    key: '1',
  },
  {
    title: 'Релизы',
    dataIndex: 'releases',
    width: '20%',
    type: 'string',
    key: '2',
    render: (col, item) =>
      item?.releases?.length ? (
        <Flex vertical>
          {item?.releases.map((rel: IRelease, index: number) => (
            <Typography.Paragraph
              key={index}
              ellipsis={{
                rows: 2,
                tooltip: { placement: 'top', title: rel?.release_id },
              }}
              style={{ maxWidth: '250px', margin: 0, color: '#0148D7' }}
            >
              <Link
                style={{ color: 'currentcolor' }}
                to={RouteNames.RELEASES_VIEW + rel?.id}
              >
                {rel?.release_id}
              </Link>
            </Typography.Paragraph>
          ))}
        </Flex>
      ) : null,
  },
  {
    title: 'Локация релиза',
    dataIndex: 'location_release',
    width: '25%',
    key: '3',
    type: 'string',
    render: (_, item) =>
      item?.location_release ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: { placement: 'left', title: item.location_release },
          }}
          style={{ maxWidth: '200px', margin: 0 }}
        >
          {item.location_release}
        </Typography.Paragraph>
      ) : null,
  },
  {
    title: 'Связанные сделки',
    dataIndex: 'related_deals',
    width: '20%',
    type: 'string',
    key: '4',
    render: (col, item) =>
      item?.related_deals?.length ? (
        <Flex vertical>
          {item?.related_deals.map((rel: IDeal, index: number) => (
            <Typography.Paragraph
              key={index}
              ellipsis={{
                rows: 2,
                tooltip: { placement: 'top', title: rel?.id_deal },
              }}
              style={{ maxWidth: '250px', margin: 0, color: '#0148D7' }}
            >
              <Link
                style={{ color: 'currentcolor' }}
                to={RouteNames.SALES_DEAL_VIEW + rel?.id}
              >
                {rel?.id_deal}
              </Link>
            </Typography.Paragraph>
          ))}
        </Flex>
      ) : null,
  },
  {
    title: 'Клиент',
    dataIndex: 'company',
    width: '20%',
    type: 'string',
    key: '5',
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    width: '20%',
    type: 'string',
    key: '6',
  },
  {
    title: 'Ответственный',
    dataIndex: 'responsible_name',
    width: '200px',
    key: '7',
    type: 'string',
  },
  {
    title: 'Кол-во КТК',
    dataIndex: 'containers_count',
    width: '20%',
    key: '8',
    type: 'string',
  },
  {
    title: '',
    dataIndex: 'hidden',
    width: '20%',
    key: '9',
  },
];
