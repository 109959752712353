import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  Flex,
  Form,
  MenuProps,
  Modal,
  Tag,
  Typography,
} from 'antd';
import classnames from 'classnames';
import { Utils } from '../../../utils';
import style from '../style.module.scss';
import { IContainer, IForm, IRelease, IStatus } from '../types';
import { messagesModal } from '../../../constants';
import { RootState, useStoreDispatch } from '../../../store';
import { ITab, ITabs } from '../../../components/ui/Tabs/Tabs';
import { setRelease, setSaveFiles } from '../../../store/releases';
import ModalConfirm from '../../../components/ui/ModalConfirm/ModalConfirm';
import TooltipButton from '../../../components/ui/TooltipButton/TooltipButton';
import { BaseTab, TableTab } from './edit';
import { BASE_TAB, NEW, releaseStatus, TABLE_TAB } from '../constants';
import Icon from '@ant-design/icons';
import { moreIcon } from '../../../assets/image/svg';
import UploaderDocs from '../../../components/ui/UploaderDocs';
import { openInNewTab } from '../../../helpers/link-helper';
import { RouteNames } from '../../../router/names';
import { useParamsHistory } from '../../../hooks';
import socket from '../../../socket';
import { SET_RELEASE } from '../../../services/rest_releases';
import { fetchAddAllFiles } from '../../../components/ui/UploaderDocs/apiFiles';
import { EDIT, VIEW } from '../../../helpers/string-helpers';


export const FormRelease: React.FC<IForm> = ({
  typeForm,
  onHistory,
  onClose,
  onDeal,
  onEdit,
  isOpen,
  title = 'Создание релиза',
}) => {
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const [activeKey, setActiveKey] = useState(() => BASE_TAB);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [isModalFiles, setIsModalFiles] = useState<boolean>(false);
  const [isErrorsBase, setIsErrorsBase] = useState<boolean | undefined>(false);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const initialValue: any = useSelector<RootState>(
    (state) => state.releases.release,
  ) as IRelease;
  const saveFiles: any = useSelector<RootState>(
    (state) => state.releases.saveFiles,
  );

  const defaultValues: IRelease = {
    release_id: '',
    release_generate_id: '',
    user_release_id: '',
    inn: '',
    company: '',
    containers_count: undefined,
    containers: [],
    date_action_start: null,
    date_action_start_string: '',
    date_action_end: null,
    date_action_end_string: '',
    terminal: '',
    terminal_id: null,
    contractor: '',
    responsible_id: null,
    status: undefined,
    responsible: '',
    history_ids: [],
    history: [],
    transporter: [
      {
        fio: '',
        phone: '',
        gos_number: '',
        series_passport: '',
        number_passport: '',
      },
    ],
    no_number: undefined,
    is_transporter: undefined,
    file_ids: [],
  };



  const isDisabled = !!initialValue?.id;

  const handlerSaveFiles = (files: any[]) => {
    dispatch(setSaveFiles(files));
  };

  const openModalFile = () => {
    setConfirmation(false);
    setIsModalFiles(true);
  };
  const closeModalFile = () => {
    setIsModalFiles(false);
  };
  const openModalHistory = () => {
    setIsModalFiles(false);
    if (onHistory) {
      onHistory();
    }
  };

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: BASE_TAB,
        label: 'Основное',
        forceRender: true,
        isError: isErrorsBase,
        disabledText: '',
        disabled: false,
        content: <BaseTab />,
      },
      {
        key: TABLE_TAB,
        label: 'Таблица',
        forceRender: true,
        isError: false,
        content: <TableTab />,
      },
    ],
    [isErrorsBase],
  );

  const onSubmit = async (values: IRelease) => {
    try {
      if (saveFiles?.length) {
        const res = await fetchAddAllFiles(saveFiles);
        if (res.data?.length) {
          values.file_ids.push(...res.data);
        }
      }
      const submit = {
        ...initialValue,
        ...values,
        saveFiles,
      };
      dispatch(setSaveFiles([]));
      setIsSave(true);
      dispatch(setRelease(submit))
        .then(() => {
          onClose();
        })
        .catch(console.error)
        .finally(() => {
          setIsSave(false);
          socket.off(SET_RELEASE);
        });
    } catch (err) {
      console.error('submit', err);
    }
  };
  const onUpdate = (changedValues: IRelease, allValues: any) => {
    setIsErrorsBase(false);
    return;
  };
  const containers = Form.useWatch('containers', {
    form,
    preserve: true,
  }) as IContainer[];
  const file_ids = Form.useWatch('file_ids', {
    form,
    preserve: true,
  });
  const is_transporter = Form.useWatch('is_transporter', {
    form,
    preserve: true,
  });
  const status = Form.useWatch('status', {
    form,
    preserve: true,
  }) as IStatus;

  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    if (!outOfDate && errorFields) {
      const checkValues = [
        'release_id',
        'date_action_start',
        'date_action_end',
        'containers_count',
        is_transporter ? 'transporter' : 'inn',
      ];
      for (const errors of errorFields) {
        const title = errors?.name?.[0];
        if (checkValues.includes(title)) {
          setIsErrorsBase(true);
          break;
        }
      }
    }

    return;
  };

  const createDeal = (url: string) => {
    openInNewTab(pathname);
    history.push(url, { deal: { containers: initialValue?.containers || [] } });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    form.resetFields();
  };

  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

  const items: MenuProps['items'] = [
    {
      key: 1,
      label: t('Связанные документы'),
      disabled: true || !isDisabled,
    },
    {
      key: 2,
      label: t('История изменений'),
      disabled: !onHistory || !isDisabled,
      onClick: openModalHistory,
    },
    {
      key: 3,
      label: t('Создать сделку на основание релиза'),
      disabled: !isDisabled,
      children: [
        {
          key: t('Продажа'),
          label: (
            <Typography.Text
              data-id="link"
              onClick={() =>
                onDeal(
                  containers
                    ?.filter((item) => item?.num_container)
                    ?.map((item) => ({ ...item, edit: false })),
                )
              }
            >
              {t('Продажа')}
            </Typography.Text>
          ),
        },
        {
          disabled: true,
          key: t('Аренда'),
          label: (
            <Typography.Text
              disabled
              data-id="link"
              onClick={() => createDeal(RouteNames.RENT_DEAL_CREATE)}
            >
              {t('Аренда')}
            </Typography.Text>
          ),
        },
      ],
    },
    {
      key: 4,
      label: t('Выгрузить шаблон релиза PDF (РФ)'),
      disabled: true,
    },
    {
      key: 5,
      label: t('Выгрузить шаблон релиза PDF (EN)'),
      disabled: true,
    },
    {
      key: 6,
      label: t('Просмотр'),
      onClick: onEdit,
    },
    {
      key: 7,
      label: isDisabled ? t('Просмотр вложений') : t('Загрузить вложение'),
      onClick: openModalFile,
    },
  ];


  useEffect(() => {
    if (isDisabled && typeForm === EDIT) {
      form.setFieldsValue({ ...defaultValues, ...initialValue });
    }
    if (typeForm === VIEW) {
      const {
        email,
        id: responsible_id,
        fio: responsible,
      } = Utils.getUserInfo();
      const [start] = typeof email === 'string' ? email.split('@') : ['SM'];
      const user_release_id = start.toUpperCase();
      form.setFieldsValue({
        ...defaultValues,
        responsible_id,
        responsible,
        user_release_id,
        status: NEW,
      });
    }
  }, [isDisabled, isOpen, form, dispatch, initialValue]);

  return (
    <Form
      className={style['form']}
      noValidate
      autoComplete='off'
      layout="vertical"
      form={form}
      initialValues={isDisabled ? { ...defaultValues, ...initialValue } : defaultValues}
      scrollToFirstError
      onFinish={onSubmit}
      onFinishFailed={onFinishError}
      onValuesChange={onUpdate}
    >
      <Flex vertical gap={24}>
        <Flex justify="space-between" align="center">
          <Flex gap={10} align="center" style={{ marginTop: 15 }}>
            <Typography.Text className={style['title']}>
              {isDisabled ? 'Редактирование' : t(title)}
            </Typography.Text>
            {isDisabled && (
              <Typography.Text
                className={classnames(style['title'], style['title_label'])}
              >
                {initialValue.release_id}
              </Typography.Text>
            )}
          </Flex>
          <Dropdown
            overlayClassName={style['menu-drop']}
            placement="bottomRight"
            menu={{
              items,
            }}
            trigger={['click']}
          >
            <Typography.Link className="" onClick={(e) => e.preventDefault()}>
              <Icon component={moreIcon} />
            </Typography.Link>
          </Dropdown>
        </Flex>
        <div className={style['relative']}>
          <ITabs
            className={style['tabs-form__tab']}
            isErrorTabs
            activeKey={activeKey}
            tabs={tabs}
            onChange={changeClickTab}
          />
          <div className={style['relative__block']}>
            <Typography.Text>{t('Статус')}</Typography.Text>
            {!!status && (
              <Tag style={releaseStatus[status].style}>
                {t(releaseStatus?.[status].title)}
              </Tag>
            )}
          </div>
        </div>
      </Flex>

      <Form.Item>
        <Flex
          gap={8}
          justify={'space-between'}
          style={{ margin: '32px auto 10px auto', maxWidth: '500px' }}
        >
          <TooltipButton
            propsTooltip={{
              title: isDisabled
                ? t('Сохранить изменения')
                : t('Сохранить'),
            }}
            propsButton={{
              type: 'primary',
              htmlType: 'submit',
              style: { maxWidth: '290px', minWidth: '290px' },
              disabled: isSave,
            }}
          >
            {isDisabled ? t('Сохранить изменения') : t('Добавить')}
          </TooltipButton>
          <Button
            onClick={() => setConfirmation(true)}
            type={'text'}
            style={{ maxWidth: '134px', color: '#E14453' }}
            disabled={isSave}
          >
            {t('Отменить')}
          </Button>
        </Flex>
      </Form.Item>

      <Modal
        open={isModalFiles}
        title={
          <Typography.Text className={style.label}>
            {t('Файлы')}
          </Typography.Text>
        }
        closeIcon
        centered
        footer={null}
        onCancel={closeModalFile}
      >
        <div className={style.modal}>
          <div className={classnames(style['modal__content'])}>
            <div
              className={classnames(style['file-box'], 'all-custom-v-scroll')}
            >
              <UploaderDocs
                isOpen
                ids={file_ids}
                setDataIds={(files) => {
                  form.setFieldValue('file_ids', files);
                }}
                handlerSaveFiles={handlerSaveFiles}
              />
            </div>
          </div>
        </div>
      </Modal>

      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleClose}
      />
    </Form>
  );
};
