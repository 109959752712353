import React, { useEffect, useState } from 'react';
import { TableRowItem as TableRow } from './styled';
import { addPropsToChildren } from '../../../helpers/children-helpers';

interface IRow {
  item: any;
  itemIndex: number;
  isChecked: boolean;
  onRow?: (record: any, rowIndex: number) => void;
  handleRowSelect: (id: number, checked: boolean) => void;
  children: any;
}

const TableRowItem: React.FC<IRow> = ({isChecked, itemIndex, item,  handleRowSelect, onRow, children}) => {
  const [selectedRow, setSelectedRow] = useState(false);

  const handleSelect = (checked: boolean) => {
    if (handleRowSelect) {
      handleRowSelect(item.id, checked);
      setSelectedRow(checked);
    }
  }

  useEffect(() => {
    if (isChecked !== selectedRow) {
      setSelectedRow(isChecked)
    }
  }, [isChecked])
  return (
    <TableRow
              key={`table-body-${itemIndex}`}
              onDoubleClick={(e: any) => {
                if (e.target?.type === 'checkbox') {
                  return;
                }
                if (onRow) {
                  e.stopPropagation();
                  onRow(item, itemIndex);
                }
              }}
            >
              {addPropsToChildren(children, { isChecked: selectedRow, onCheck: handleSelect })}
            </TableRow>
  )
}

export default TableRowItem;
