import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Divider, Button } from 'antd';
import { purchaseColumns, subleaseColumns, ktkColumns } from './columns';
import EditableTable from '../table/Table';
import Icon from "@ant-design/icons";
import { ReloadOutlined } from "@ant-design/icons";
import { successIcon } from '../../../../../assets/image/svg';
import { errorImportIcon } from '../../../../../assets/image/svg';

import style from '../style.module.scss';

interface ApprovalTabProps {
  initialValue: any; 
  isAgreed: boolean;
  toggleApproval: () => void;
  returnToRevision: () => void;
}

const ApprovalTab: React.FC<ApprovalTabProps> = ({ initialValue, isAgreed, toggleApproval, returnToRevision  }) => {
  const [form] = Form.useForm();

  const defaultValues = {
    name: 'Рога и копыта',
    legal_entity: 'ООО Юр. лицо',
    client: 'ООО "Марта"',
    contract_number: '11111',
    release_location: 'Екатеринбург',
    return_location: 'Самара',
    type: '20DC',
    quality: 'NEW',
    count_containers: '20',
    rate: '1200',
    currency: 'RUB',
    days: '30',
    SNP: '11111',
    // request_author: 'Иванов Иван Иванович',
    // supply_department: 'Васильев Василий Васильевич',
    // director: 'Николаев Николай Николаевич',
    // operational_department: 'Дмитриев Дмитрий Дмитриевич',
    purchase: [
      {
        supplier: "1",
        from: "11",
        quantity: "111",
        type: "40HC",
        condition: "CW",
        price: "1111",
        paymentOption: "Безналичный",
      },
      {
        supplier: "2",
        from: "22",
        quantity: "222",
        type: "20HC",
        condition: "NEW",
        price: "2222",
        paymentOption: "Наличный",
      },
      {
        supplier: "1",
        from: "11",
        quantity: "111",
        type: "40HC",
        condition: "CW",
        price: "1111",
        paymentOption: "Безналичный",
      },
      {
        supplier: "1",
        from: "11",
        quantity: "111",
        type: "40HC",
        condition: "CW",
        price: "1111",
        paymentOption: "Безналичный",
      },
      {
        supplier: "2",
        from: "22",
        quantity: "222",
        type: "20HC",
        condition: "NEW",
        price: "2222",
        paymentOption: "Наличный",
      },
      {
        supplier: "1",
        from: "11",
        quantity: "111",
        type: "40HC",
        condition: "CW",
        price: "1111",
        paymentOption: "Безналичный",
      },
    ],
    sublease: [
      {
        supplier: "1",
        from: "11",
        to: "111",
        type: "20HC",
        quantity: "1111",
        condition: "CW",
        rate: "11111",
        days: "111111",
        snp: "1111111",
      },
      {
        supplier: "1",
        from: "11",
        to: "111",
        type: "20HC",
        quantity: "1111",
        condition: "CW",
        rate: "11111",
        days: "111111",
        snp: "1111111",
      },
    ],
    ktk: [
      {
        number: "1",
        from: "11",
        quantity: "111",
        type: "40HC",
        condition: "NEW",
        remainingCost: "1111",
        terminal: "11111",
      },
    ],
  };

  const [formData, setFormData] = useState(defaultValues);

  useEffect(() => {
    if (initialValue) {
      setFormData({ ...defaultValues, ...initialValue });
    }
  }, [initialValue]);

  return (
    <Form layout="vertical"  form={form} className={style.requestTab}>
      <Form.Item label="Название">
        <Input value={formData.name} disabled className={style.inputName} style={{color: '#0E1117', backgroundColor: '#0e111700'}}/>
      </Form.Item>
      <Row gutter={24}>
        <Col span={9} className={style.approvalInputs}>
          <Form.Item label="Наше юридическое лицо">
            <Input value={formData.legal_entity} disabled />
          </Form.Item>

          <Form.Item label="Клиент">
            <Input value={formData.client} disabled />
          </Form.Item>

          <Form.Item label="№ Договора">
            <Input value={formData.contract_number} disabled />
          </Form.Item>

          <Form.Item label="Локация релиза">
            <Input value={formData.release_location} disabled />
          </Form.Item>

          <Row gutter={16} align="middle">
            <Col span={12}>
              <Form.Item label="Локация возврата">
                <Input value={formData.return_location} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Ставка">
                <Input value={formData.rate} disabled />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Тип КТК">
            <Input value={formData.type} disabled />
          </Form.Item>

          <Form.Item label="Состояние">
            <Input value={formData.quality} disabled />
          </Form.Item>

          <Form.Item label="Количество КТК">
            <Input value={formData.count_containers} disabled />
          </Form.Item>

          <Form.Item label="Валюта">
            <Input value={formData.currency} disabled />
          </Form.Item>

          <Form.Item label="Количество дней">
            <Input value={formData.days} disabled />
          </Form.Item>

          <Form.Item label="СНП">
            <Input value={formData.SNP} disabled />
          </Form.Item>
        </Col>
        <Col span={15} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: '10px'}}>
          <div className={style.ApprovalTableContainer}>
            <div className={style.ApprovalTable}>
              <Divider className={style.RequestTablesDivider}>Закуп</Divider>
              <Form.List name="purchase" initialValue={defaultValues.purchase}>
                {(fields, operation) => (
                  <EditableTable
                    columns={purchaseColumns}
                    fields={fields}
                    rowCount={fields.length}
                    columnWidths="15% 11% 9% 13% 15% 17% 20%"
                    isEditable={false}
                  />
                )}
              </Form.List>
            </div>

            <div className={style.ApprovalTable}>
              <Divider className={style.RequestTablesDivider}>Субаренда/Эвакуация</Divider>
              <Form.List name="sublease" initialValue={defaultValues.sublease}>
                {(fields, operation) => (
                  <EditableTable
                    columns={subleaseColumns}
                    fields={fields}
                    rowCount={fields.length}
                    columnWidths="15% 11% 8% 13% 11% 16% 11% 7% 8%"
                    isEditable={false}
                  />
                )}
              </Form.List>
            </div>

            <div className={style.ApprovalTable}>
              <Divider className={style.RequestTablesDivider}>КТК МК</Divider>
              <Form.List name="ktk" initialValue={defaultValues.ktk}>
                {(fields, operation) => (
                  <EditableTable
                    columns={ktkColumns}
                    fields={fields}
                    rowCount={fields.length}
                    columnWidths="14% 11% 11% 13% 15% 19% 17%"
                    isEditable={false}
                  />
                )}
              </Form.List>
            </div>
          </div>
          <div className={style["additional"]}>
            <div onClick={toggleApproval} style={{ cursor: 'pointer' }}>
              {isAgreed ? (
                <div className={style["agreed"]}>
                  <div className={style["icon-container"]}>
                    <Icon component={successIcon} className={style["success-icon"]} />
                  </div>
                  Согласовано
                </div>
              ) : (
                <div className={style["not-agreed"]}>
                  <div className={style["error-icon-container"]}>
                    <Icon component={errorImportIcon} className={style["error-icon"]} />
                  </div>
                  Не согласовано
                </div>
              )}
            </div>

            <div className={style.Return}>
              <div className="returnContainer">
                <p>Вернуть на доработку</p>
                <Button
                  className="reset"
                  icon={<ReloadOutlined />}
                  style={{ marginRight: "16px" }}
                  onClick={returnToRevision}
                >
                </Button>
              </div>
              <Form.Item
                name="reason"
                label="Причина"
                rules={[{ required: true, message: "Обязательное поле!", whitespace: true }]}
              >
                <Input.TextArea placeholder="Введите причину" />
              </Form.Item>
            </div>
          </div>
        </Col>
      </Row>

    </Form>
  );
};

export default ApprovalTab;
