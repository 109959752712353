const messagesModal = {
  warning: {
    title: 'Предупреждение',
    subtitle: 'Вы уверены, что хотите отменить?',
  },
  delete: {
    title: 'Предупреждение',
    subtitle: 'Вы уверены, что хотите удалить?',
  },
};

const ALL_TYPE_DEAL = 'all-type-deal';
const ALL_TYPE_DEAL_SALES = 'all-type-deal-sales';
const ALL_TYPE_DEAL_RENT = 'all-type-deal-rent';

const COMPANY = 'company';
const CONTACTS = 'contacts';
const INTEGRATION = 'integration';
const DEALS = 'deals';
const LEADS = 'leads';
const AGREEMENTS = 'agreements';

const crmNames: { [key: string]: string } = {
  [COMPANY]: 'Компании',
  [CONTACTS]: 'Контакты',
  [INTEGRATION]: 'Интеграции',
  [DEALS]: 'Сделки',
  [LEADS]: 'Лиды',
  [AGREEMENTS]: 'Договора',
};

export {
  messagesModal,
  COMPANY,
  CONTACTS,
  INTEGRATION,
  DEALS,
  LEADS,
  AGREEMENTS,
  crmNames,
  ALL_TYPE_DEAL_SALES,
  ALL_TYPE_DEAL_RENT,
  ALL_TYPE_DEAL,
};
