import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AutoComplete, Button, Flex, Input, Space, Typography, Modal, Divider, Form } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { RouteNames } from '../../../router/names';
import Container from '../../../components/Container';
import { filtersDealSales } from '../../../store/filters';
import { RootState, useStoreDispatch } from '../../../store';
import { useBreadcrumb, useCsvExport, useMenu, useParamsHistory } from '../../../hooks';
import {
  Table,
} from '../../../components/ui/Table/Table';
import {
  IDeal,
  setCount,
  setFiltersDeals,
  setTableDeals,
} from '../../../store/rent';
import { columns } from './columns';
import FormModalRentRequest from './components/formModalRentRequest';

const RentRequests = () => {
    const [activeButtons, setActiveButtons] = useState<any>({});
    const [searchColumn, setSearchColumn] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const { history, location } = useParamsHistory();
    const { pathname } = location;
    const dispatch = useStoreDispatch();
    const { t } = useTranslation();
    const dealData = useSelector<RootState>(
      (state) => state.rent.dealData,
    ) as IDeal[];
    const loading = useSelector<RootState>(
      (state) => state.rent.loading,
    ) as boolean;
    const page = useSelector<RootState>((state) => state.rent.page) as number;
    const count = useSelector<RootState>((state) => state.rent.count) as number;
    const current = useSelector<RootState>(
      (state) => state.rent.currentPage,
    ) as number;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [typeForm, setTypeForm] = useState<string | null>(null);
    const [total, setTotal] = useState<number>(0);
    const [form] = Form.useForm();
    const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

    useBreadcrumb(['Аренда', 'Запросы аренды']);
    useMenu({openKey: RouteNames.RENT, activeKeys: [RouteNames.RENT, RouteNames.RENT_REQUESTS]});

    const onClose = () => {
      setIsModalOpen(false);
      form.resetFields();
      history.replace(RouteNames.RENT_REQUESTS);
    };

    const handleButtonClickSearch = (option: any) => {
      setActiveButtons((prevActiveButtons: any) => {
        setSearchColumn((prev) =>
          prevActiveButtons[option.key]
            ? prev.filter((item: string) => item !== option.dataIndex)
            : [...prev, option.dataIndex],
        );
        return {
          ...prevActiveButtons,
          [option.key]: !prevActiveButtons[option.key],
        };
      });
    };

    const handleSearch = (val: string) => {
      setSearchValue(val);
      dispatch(filtersDealSales({ text: val, data: searchColumn })).then(
        (res) => {
          dispatch(setFiltersDeals(res.payload?.rows));
        },
      );
    };


    const onClickAddRequest = () => {
      setIsModalOpen(true);
      setTypeForm('create')
      //history.push(RouteNames.RENT_REQUESTS_CREATE, {table: data, total});
    }

    const handleCancelModal = () => {
      setIsModalOpen(false);
      form.resetFields();
      const data = location.state?.data;
      history.push(RouteNames.RENT_REQUESTS, {table: data, total});
    }

    useEffect(() => {
      if (location?.state?.table) {
        dispatch(setTableDeals(location.state.table));
        dispatch(setCount(location.state.count));
      }
    }, [dispatch, page, current, pathname]);

    return (
      <Container>
        <Flex
          gap={20}
          justify={'space-between'}
          align={'flex-start'}
          style={{ width: '100%' }}
          className={'container'}
        >
          <Flex align={'center'} gap={10}>
            <div className={'drawer-title'}>{t('Запросы аренды')}</div>
          </Flex>
          <Flex gap={16}>
            <Button
              icon={<PlusOutlined />}
              style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
              onClick={onClickAddRequest}
            />
            <AutoComplete
              allowClear
              style={{ width: 300 }}
              options={columns}
              defaultValue={searchValue}
              popupMatchSelectWidth={false}
              onSearch={handleSearch}
              dropdownRender={() => (
                <>
                  <div
                    className={'text-card-12'}
                    style={{ padding: '10px 10px' }}
                  >
                    {t('Выбор колонок по которым будет производиться поиск')}
                  </div>
                  <Flex
                    gap={5}
                    style={{ width: 300, flexWrap: 'wrap', padding: 10 }}
                  >
                    {columns.map(
                      (option) =>
                        option.title !== '' &&
                        option.dataIndex !== 'date_entry' &&
                        option.dataIndex !== 'arrival_date' &&
                        option.dataIndex !== 'departure_date' &&
                        option.dataIndex !== 'booking_before' && (
                          <Button
                            size={'small'}
                            key={option.key}
                            type="primary"
                            className={
                              activeButtons[Number(option.key)]
                                ? 'active-search-btn'
                                : ''
                            }
                            onClick={() => handleButtonClickSearch(option)}
                          >
                            {option.title}
                          </Button>
                        ),
                    )}
                  </Flex>
                </>
              )}
            >
              <Input placeholder={t('Введите текст')} />
            </AutoComplete>
          </Flex>
        </Flex>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          columns={columns}
          dataSource={dealData}
          loading={loading}
          height={'calc(-162px + 100vh)'}
          pagination={{
            pageSize: page,
            total: count,
            current: current,
            showSizeChanger: true,
          }}
        />
      </Space>
      <Modal
            maskClosable={false}
            title= {typeForm === 'create'? 'Запрос на аренду':'Редактировать запрос на аренду'}
            open={isModalOpen}
            onCancel={handleCancelModal}
            width={1117}
            footer={null}>
          <Divider style={{marginTop: 0, marginBottom: 8}}/>
          <Flex vertical className={'modal-content'}>
            <FormModalRentRequest
                initialValue={null}
                onClose={onClose}
            />
          </Flex>
        </Modal>
      </Container>
    );
  };

  export default RentRequests;
















