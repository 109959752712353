import {Card, Flex, Row} from "antd";
import {FC} from 'react';
import './style.scss';
import ForgetLogin from '../../components/ForgetLogin/ForgetLogin';
import Button from '../../components/ui/Button/Button';
import {RouteNames} from '../../router/names';
import {useHistory} from 'react-router-dom';

const { Meta } = Card;

const ForgetPassword: FC = () => {

    const router = useHistory()
    const clickRegistration = () => {
        router.push(RouteNames.REGISTRATION)
    }
    return (
        <>
            <div style={{position: 'absolute', right: 20, top: 30}}>
                <p style={{display: 'inline', margin: 10, fontWeight: 600, fontFamily: 'Manrope'}}>Нет аккаунта?</p>
                <Button onClick={clickRegistration} type='primary' style={{width: 200}}>Зарегистрироваться</Button>
            </div>
            <Row justify="space-around" align="middle" className="h100">
                <Flex vertical align='center' gap={30} className="container-login">
                    <Card
                        style={{
                            borderRadius: 12,
                            width: 600,
                            height: 306,
                            paddingTop: '4%',
                            paddingLeft: 100,
                            paddingRight: 100,
                            boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)"
                        }}>
                        <ForgetLogin/>
                    </Card>
                    <div>
                        <p style={{display: 'inline', margin: 10, fontWeight: 600, fontFamily: 'Manrope'}}>Нет
                            аккаунта?</p>
                        <p onClick={clickRegistration} style={{
                            display: 'inline',
                            color: 'black',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>Зарегистрироваться</p>
                    </div>
                </Flex>
            </Row>
        </>
    );
};

export default ForgetPassword;
