import React, { useCallback, useMemo, useState } from 'react';

import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, DatePicker, Divider, Flex, Menu, MenuProps, Popover, Select, Switch } from "antd";

import Icon, { PlusCircleOutlined } from "@ant-design/icons";

import { IColumnType } from "./Table";
import { RootState } from "../../../store";
import { Checkbox as Checkboxs } from '../Checkbox/Checkbox';
import { AddHiddenColumn, TableHeaderCell, TableHeaderCellColor } from "./styled";
import { burgerBlueIcon, RepairIcon, sortIconDown, sortIconUp } from "../../../assets/image/svg";

interface Props<T> {
    columns: IColumnType<T>[],
    content?: React.JSX.Element,
    sortData?: (column: IColumnType<T>) => void,
    hiddenColumn?: number[],
    order?: ((sort: string) => void) | undefined,
    filtersFetch?: ((text: (string | number), column: IColumnType<any>) => void) | undefined,
    filters?: ((column: string[] | null) => void) | undefined,
    onSelectAll: (checked: boolean) => void;
    allSelected: boolean;
}

type MenuItem = Required<MenuProps>['items'][number];

export function TableHeader<T>({
                                   columns,
                                   content,
                                   sortData,
                                   hiddenColumn = [],
                                   order,
                                   filtersFetch,
                                   filters,
                                   onSelectAll,
                                   allSelected
                               }: Props<T>): JSX.Element {
    const { t } = useTranslation();
    const findCheck = columns.filter((el: IColumnType<T>) => el.checkbox !== undefined);
    const [openPopovers, setOpenPopovers] = useState<{ [key: number]: boolean }>({});
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [toggle, setToggle] = useState<boolean>(true);
    const dataFilters: any = useSelector((state: RootState) => state.filters.dataFilters);

    // Состояние фильтров для каждой колонки
    const [columnFilters, setColumnFilters] = useState<{ [key: number]: string[] | null }>({});

    const items: MenuItem[] = [
        {
            label: t('Сначала новые'),
            key: 'DESC',
            icon: <Icon component={sortIconUp}/>,
        },
        {
            label: t('Сначала старые'),
            key: 'ASC',
            icon: <Icon component={sortIconDown}/>,
        },
    ]

    const optionsFilter = useMemo(() => {
        return dataFilters;  // Убираем фильтрацию, оставляем все контейнеры
    }, [dataFilters]);

    const searchFilter = useCallback((row: string, column: any) => {
        if (row.length > 0 && filtersFetch) {
            filtersFetch(row, !toggle ? column.subTitle : column.dataIndex);
        }
    }, [filtersFetch,toggle]);

    // Обработка изменения фильтров для каждой колонки
    const handleChange = (option: string[], checked?: boolean, columnIndex?: any) => {
        setColumnFilters((prev: any) => {
            const updatedFilters = { ...prev, [columnIndex]: option[0] === 'checked' ? [checked] : option };
            if (filters) {
                const allFilters: any = columns.reduce((acc, column, index) => {
                    if (!index) return acc;
                    const columnFilters = updatedFilters[index];
                    if (columnFilters && columnFilters.length > 0) {
                        acc[column.dataIndex] = columnFilters;
                    }
                    return acc;
                }, {} as { [key: string]: string[] });
                filters([allFilters]);
            }
            return updatedFilters;
        });
    };

    const updateFilters = async (columnIndex: number) => {
        const column = columns[columnIndex];
        if (startDate && endDate) {
            const allFilters: any = {
                [column.dataIndex]: [startDate, endDate]
            };
            if (filters) {
                filters([allFilters]);
            }
        }
    };

    const handleDateChange = (type: 'start' | 'end', date: any, columnIndex: number) => {
        const newStartDate = type === 'start' ? date : startDate;
        const newEndDate = type === 'end' ? date : endDate;

        // Обновляем состояние
        setStartDate(newStartDate);
        setEndDate(newEndDate);

    };

    const handleOrder: MenuProps['onClick'] = (row) => {
        if (order) {
            order(row.key)
        }
    };

    const closeFilter = () =>{
        if (filters) {
            filters([]);
        }
        setStartDate(null);
        setEndDate(null);
    }


    // Функция фильтрации для конкретной колонки
    const filter = useCallback((column: IColumnType<T>, columnIndex: number) => (
        <Flex style={{ width: '100%' }} vertical justify="center">
            {column.type === 'string' ? (
                <Flex vertical gap={7}>
                        <Select
                            allowClear
                            mode="multiple"
                            value={columnFilters[columnIndex] || []}
                            onSearch={(value) => searchFilter(value, column)}
                            style={{ width: 200 }}
                            placeholder={t(column.title ?? '')}
                            notFoundContent={null}
                            defaultActiveFirstOption={false}
                            onChange={(option) => handleChange(option, false, columnIndex)}
                            options={Array.from(
                                new Set(
                                    (optionsFilter || []).map((d: any) =>
                                        d[column.dataIndex]).filter((val: any) => val !== undefined && val !== null && val !== '')
                                )
                            ).map((uniqueValue) => ({
                                value: uniqueValue,
                                label: uniqueValue,
                            }))}
                        />
                 {/*         <div>
                        {typeof column.subTitle !== 'undefined' &&
                            <Switch checkedChildren="ФИО" unCheckedChildren="Тип" defaultChecked
                                    checked={toggle}
                                    onClick={() => setToggle(prevToggle => !prevToggle)} />
                        }
                    </div>*/}
                </Flex>
            ) : column.type === 'date' ? (
                <Flex gap={7} vertical>
                    <Flex gap={7}>
                        <DatePicker format={'DD.MM.YYYY'} onChange={(date,dateString) =>
                            handleDateChange('start', date, columnIndex)} style={{width: 120}} value={startDate}/>
                        <DatePicker format={'DD.MM.YYYY'} onChange={(date,dateString) =>
                            handleDateChange('end', date, columnIndex)} style={{width: 120}} value={endDate}/>
                    </Flex>
                    <Flex justify={"flex-end"} gap={7}>
                        <Button size={"small"} onClick={closeFilter} >{t('Сбросить')}</Button>
                        <Button type="primary" size={"small"} onClick={() =>updateFilters(columnIndex)}>ОК</Button>
                    </Flex>
                    <Divider style={{margin: 0}}/>
                </Flex>
            ) : column.type === 'boolean' ? (
                <Checkbox onChange={(e) =>
                    handleChange(['checked'], e.target.checked, columnIndex)}>В ремонте</Checkbox>
            ) : column.type === '' && null}
            <Flex>
                <Menu mode="inline" style={{ borderInlineEnd: 0 }} defaultSelectedKeys={['1']} onClick={handleOrder} items={items} />
            </Flex>
        </Flex>
    ), [dataFilters, items, searchFilter, columnFilters]);  // Добавляем columnFilters в зависимости

    const handleOpenChange = useCallback((index: number, visible: boolean) => {
        setOpenPopovers(prevState => ({...prevState, [index]: visible}));
    }, []);

    const handleSelectAll = (e: any) => {
        onSelectAll(e);
    };

    const onClickRow = () =>{
        setStartDate(null);
        setEndDate(null);
        setToggle(true);
    }

    return (
        <tr>
            {columns.map((column, columnIndex) => (
                !hiddenColumn.includes(columnIndex) && (
                    column.dataIndex !== 'hidden' ? (
                        <TableHeaderCell key={`table-head-cell-${columnIndex}`}
                                         style={{width: column.width}}>
                            <Flex gap={10} align={"center"}>
                                {findCheck.length > 0 && column.checkbox !== undefined &&
                                    <Checkboxs
                                        isChoice={allSelected}
                                        onChange={handleSelectAll}
                                    />}
                                {!!columnIndex && (<Flex gap={3}>
                                    <TableHeaderCellColor>
                                        <Flex gap={4}>
                                            {column.dataIndex === 'repair' ?
                                                <Icon component={RepairIcon}/>
                                                :
                                                t(column.title ?? '')
                                            }
                                            <Popover placement="bottom"
                                                     content={filter(column, columnIndex)}  // Передаем индекс колонки
                                                     open={openPopovers[columnIndex]}
                                                     onOpenChange={(visible) => handleOpenChange(columnIndex, visible)}
                                                     trigger="click">
                                                <Icon component={burgerBlueIcon}  onClick={onClickRow}/>
                                            </Popover>
                                        </Flex>
                                    </TableHeaderCellColor>

                                </Flex>)}
                            </Flex>
                        </TableHeaderCell>
                    ) : (
                        <TableHeaderCell key={`table-head-cell-${columnIndex}`} style={{width: column.width}}>
                            <AddHiddenColumn>
                                <Popover placement="bottomRight" content={content}>
                                    <PlusCircleOutlined/>
                                </Popover>
                            </AddHiddenColumn>
                        </TableHeaderCell>
                    )
                )
            ))}
        </tr>
    );
}
