import { useSelector } from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import { privateRoutes, publicRoutes } from '../router';
import { RootState } from '../store';
// import { useParamsHistory } from '../hooks';
// import { RouteNames } from '../router/names';

const AppRouter = () => {
    // const history = useHistory();
    const isAuth = useSelector((state: RootState) => state.users.auth);
    const routes = isAuth ? privateRoutes : publicRoutes;
    // const { location } = useParamsHistory();
    // const arPathname = location.pathname.split('/') ?? [];
    // const hasContainers = arPathname.some(item => item === "containers");
    // const hasView = arPathname.some(item => item === "view");
    // let idView: string | undefined = arPathname.at(-1);

    // useEffect(() => {
    //     if (hasContainers && hasView ) {
    //         let containerRoute: string;
    //         containerRoute = RouteNames.CONTAINERS_ONE.replace(':id', String(idView));
    //         history.push(containerRoute);
    //     }
    // }, [hasContainers, history, RouteNames.CONTAINERS_ONE]);

    return (
        <Switch>
            {routes.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                />
            ))}
        </Switch>
    );
};

export default AppRouter;
