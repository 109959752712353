import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CompanyRequest } from '../services/rest_companies';
import {
  ITypeCompany,
  ITypeContact,
  ITypeContainers,
  ITypeEmail,
  ITypeMessenger,
  ITypePhone,
  ITypeQuality,
  ITypeSite,
  ITypePayment,
  ITypeDeal,
} from '../types';

interface ITypeState {
  dataTypePhone: Array<ITypePhone>;
  dataTypeMessenger: Array<ITypeMessenger>;
  dataTypeEmail: Array<ITypeEmail>;
  dataTypeContact: Array<ITypeContact>;
  dataTypeCompany: Array<ITypeCompany>;
  dataTypeContainers: Array<ITypeContainers>;
  dataTypeQuality: Array<ITypeQuality>;
  dataTypeSite: Array<ITypeSite>;
  dataTypePayment: Array<ITypePayment>;
  dataTypeDepartment: [];
  dataTypeDeal: ITypeDeal[];
}

const initialState: ITypeState = {
  dataTypePhone: [],
  dataTypeMessenger: [],
  dataTypeEmail: [],
  dataTypeContact: [],
  dataTypeCompany: [],
  dataTypeContainers: [],
  dataTypeQuality: [],
  dataTypePayment: [],
  dataTypeSite: [],
  dataTypeDepartment: [],
  dataTypeDeal: [],
};
export const typePayment = createAsyncThunk('typePayment', async () => {
  return await CompanyRequest.getTypePayment();
});
export const typePhone = createAsyncThunk('typePhone', async () => {
  return await CompanyRequest.getTypePhone();
});
export const typeMessenger = createAsyncThunk('typeMessenger', async () => {
  return await CompanyRequest.getTypeMessenger();
});
export const typeEmail = createAsyncThunk('typeEmail', async () => {
  return await CompanyRequest.getTypeEmail();
});
export const typeSite = createAsyncThunk('typeSite', async () => {
  return await CompanyRequest.getTypeSite();
});
export const typeContact = createAsyncThunk('typeContact', async () => {
  return await CompanyRequest.getTypeContact();
});
export const typeCompany = createAsyncThunk('typeCompany', async () => {
  return await CompanyRequest.getTypeCompany();
});
export const typeContainers = createAsyncThunk('typeContainers', async () => {
  return await CompanyRequest.getTypeContainers();
});
export const typeQuality = createAsyncThunk('typeQuality', async () => {
  return await CompanyRequest.getTypeQuality();
});

const typeSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setTypeContainers(state, action) {
      state.dataTypeContainers = action.payload;
    },
    setTypeQuality(state, action) {
      state.dataTypeQuality = action.payload;
    },
    setTypePhone(state, action) {
      state.dataTypePhone = action.payload;
    },
    setTypeMessenger(state, action) {
      state.dataTypeMessenger = action.payload;
    },
    setTypeEmail(state, action) {
      state.dataTypeEmail = action.payload;
    },
    setTypeCompany(state, action) {
      state.dataTypeCompany = action.payload;
    },
    setTypePayment(state, action) {
      state.dataTypePayment = action.payload;
    },
    setTypeContact(state, action) {
      state.dataTypeContact = action.payload;
    },
    setTypeDeal(state, action) {
      state.dataTypeDeal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(typePhone.fulfilled, (state, action) => {
      state.dataTypePhone = action.payload;
    });
    builder.addCase(typeMessenger.fulfilled, (state, action) => {
      state.dataTypeMessenger = action.payload;
    });
    builder.addCase(typeEmail.fulfilled, (state, action) => {
      state.dataTypeEmail = action.payload;
    });
    builder.addCase(typeContact.fulfilled, (state, action) => {
      state.dataTypeContact = action.payload;
    });
    builder.addCase(typeCompany.fulfilled, (state, action) => {
      state.dataTypeCompany = action.payload;
    });
    builder.addCase(typeContainers.fulfilled, (state, action) => {
      state.dataTypeContainers = action.payload;
    });
    builder.addCase(typeQuality.fulfilled, (state, action) => {
      state.dataTypeQuality = action.payload;
    });
    builder.addCase(typeSite.fulfilled, (state, action) => {
      state.dataTypeSite = action.payload;
    });
    builder.addCase(typePayment.fulfilled, (state, action) => {
      state.dataTypePayment = action.payload;
    });
  },
});
export default typeSlice.reducer;
export const {
  setTypeContainers,
  setTypeQuality,
  setTypePhone,
  setTypeMessenger,
  setTypeEmail,
  setTypeCompany,
  setTypePayment,
  setTypeContact,
  setTypeDeal
} = typeSlice.actions;
