import { IAgreement } from "../pages/agreements/types";
import socket from "../socket";

export class Rest_agreement {
    static getFiltersAgreements(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-filters-agreements', param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getAgreementTypes(payload?: number[]): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('type-agreement', payload,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getAgreements(payload?: number[]): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-agreements', payload,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static setAgreement(payload: IAgreement): Promise<any>{
      return new Promise(async (resolve, reject) => {
          socket.emit('set-agreement', payload,  (response: any) => {
              try {
                  resolve(response);
              } catch (e) {
                  reject(e);
              }
          })
      })
  }
    static findOneAgreement(id: number): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-one-agreement', id,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

    static deleteAgreement(payload: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('delete-agreement', payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

  }