import { createSlice } from '@reduxjs/toolkit';
// import { Rest_deal } from '../services/rest_deal';

interface IResponsible {
  avatar: string;
  name: string;
  id: number;
  isResponsible: boolean
}

export interface IContainers {
  id?: number;
  bet: string;
  num_container: string;
  price: string;
  status: string;
  status_id: number;
  type: string;
  edit?: boolean;
}
export interface IComments {
  comment: string;
  id_deal: string;
  user_id: number;
  user?: {avatar: string, fio: string, id: number, name: string}
  edit?: boolean;
  createdAt?: string;
}

export interface IDeal {
  id?: number;
  id_deal?: string;
  account_num: string;
  type_deal?: string;
  type_deal_id?: number;
  name_deal?: string;
  company?: string;
  company_id?: number;
  containers_ids: number[];
  phone?: string;
  my_company?: string;
  my_company_id?: number;
  contact_id?: number;
  contact?: any;
  bet?: string;
  currency?: string;
  currency_id?: number;
  nds?: string;
  containers: IContainers[];
  comments: IComments[];
  comment?: string;
  comment_last?: string;
  responsible: IResponsible[];
  responsible_id?: number;
  responsible_name?: string;
  docs: number[];
  route: string[];
  delete?: boolean;
}
interface IDealState {
  saveFiles?: any[];
  count: number;
  currentPage: number;
  page: number;
  loading: boolean;
  loadingOneDeal: boolean;
  locations: any[];
  idDeal: string;
  isEditDeal: boolean;
  containerDealStatuses: {id: number, value: string}[];
  dealType: {id: number, value: string}[];
  isEditDealContainers: boolean;
  responsible: IResponsible[];
  docs: number[];
  dealData: IDeal[];
  dealOneData: IDeal | {};
  containers: IContainers[];
  comments: IComments[];
}

const initialState: IDealState = {
  saveFiles: [],
  containerDealStatuses: [],
  dealType: [],
  loading: false,
  loadingOneDeal: false,
  count: 0,
  currentPage: 0,
  page: 20,
  locations: [],
  idDeal: '',
  isEditDeal: false,
  isEditDealContainers: false,
  responsible: [],
  docs: [],
  dealData: [],
  dealOneData: {},
  containers: [],
  comments: [],
};

const rentSlice = createSlice({
  name: 'rent',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setLocations(state, action) {
      state.locations = action.payload;
    },
    setResponsible(state, action) {
      state.responsible = action.payload;
    },
    setEditDeal(state, action) {
      state.isEditDeal = action.payload;
    },
    setOneDeal(state, action) {
      state.dealOneData = action.payload;
    },
    setTableDeals(state, action) {
      state.dealData = action.payload;
    },
    setFiltersDeals(state, action) {
      state.dealData = action.payload || [];
    },
    setDocsDeal(state, action) {
      state.docs = action.payload;
    },
    setIsEditDealContainers(state, action) {
      state.isEditDealContainers = action.payload;
    },
    editDealContainerList(state, action) {
      state.containers = action.payload;
    },
    setSaveFiles(state, action) {
      state.saveFiles = action.payload;
    },
  },
  extraReducers: (builder) => {

  },
});
export default rentSlice.reducer;
export const {
  setResponsible,
  setEditDeal,
  setDocsDeal,
  setIsEditDealContainers,
  setOneDeal,
  editDealContainerList,
  setLocations,
  setCount,
  setPage,
  setCurrentPage,
  setFiltersDeals,
  setLoading,
  setTableDeals,
  setSaveFiles
} = rentSlice.actions;