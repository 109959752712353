import React from 'react';
import classnames from 'classnames';
import { IRelease } from '../../../store/releases';
import { Column } from './history/Column';
import { Breadcrumb, Collapse, Flex, Typography } from 'antd';
import { getDateString } from '../../../helpers/date-helpers';
import { useTranslation } from 'react-i18next';
import style from '../style.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { defaultValues } from '../../../store/releases';

export const HistoryReleases: React.FC = () => {
  const { t } = useTranslation();
  const initialValue = useSelector<RootState>(
    (state) => state.releases.release,
  ) as IRelease;
  const items = initialValue?.history?.map((item, i, arr) => ({
    key: item.createdAt,
    label: (
      <Flex justify="space-between">
        <Typography.Text>{item.responsible}</Typography.Text>
        <Typography.Text>
          {getDateString({ date: item.createdAt, format: 'DD/MM/YYYY HH:mm' })}
        </Typography.Text>
      </Flex>
    ),
    children: (
      <Column prevData={i ? arr[i - 1].data : defaultValues} data={item.data} />
    ),
  }));
  const breadcrumb = initialValue?.release_id
    ? [{ title: t('История изменений') }, { title: initialValue.release_id }]
    : [{ title: t('История изменений') }];
  return (
    <div className={style['history']}>
      <Flex gap={10} align="center">
        <Breadcrumb
          style={{ fontSize: '16px' }}
          separator={
            <div className={style['separator']}>
              <span className={style['separator__circle']} />
            </div>
          }
          items={breadcrumb}
        />
      </Flex>
      <div className={classnames(style['history__content'])}>
        <Collapse collapsible="icon" items={items} />
      </div>
    </div>
  );
};
