import React from 'react';
import { Divider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface IRouteList {
  array: string[];
  width?: string | number;
}

const RouteList: React.FC<IRouteList> = ({ array, width }) => {
  const { t } = useTranslation();
  return Array.isArray(array) ? (
    <div>
      {array
        ?.map((item: string, i: number) => (
          <span key={`${i}array`}>
            {!!i && <Divider style={{ marginTop: 0, marginBottom: 8 }} />}
            <Typography.Paragraph
              ellipsis={{ rows: 2, tooltip: { placement: 'leftBottom', title: item || t('нет маршрута') } }}
              style={{ margin: 0, maxWidth: width || '200px' }}
            >
              <strong>{i ? t('Куда') : t('Откуда')}: </strong>{item || t('нет маршрута')}
            </Typography.Paragraph>
          </span>
        ))}
    </div>
  ) : null;
}

export default RouteList;
