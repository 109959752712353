import React, { useState, useEffect } from 'react';
import { Upload, App, Button, UploadFile, Typography, Flex } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import axios from 'axios';
import FullScreen from '../FullScreen/FullScreen';
import Carousel from '../FullScreen/Carousel';
import { UploadProps } from 'antd/lib/upload';

const URL: any = process.env.REACT_APP_API_URL;

interface UploaderProps {
  ids_photo?: [];
  setDataId?: (value: ((prevState: any[]) => any[]) | any[]) => void;
  type?: string;
  setDataPhoto?: (value: any) => void;
  onChangePhoto?: any;
  onChangeRemovePhoto?: any;
}

const Uploader: React.FC<UploaderProps> = ({
  ids_photo,
  setDataId,
  type,
  setDataPhoto,
  onChangePhoto,
  onChangeRemovePhoto,
}) => {
  const { message } = App.useApp();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  useEffect(() => {
    if (setDataId) {
      setDataId([]);
    }
    setFileList([]);
    if (setDataPhoto) {
      setDataPhoto([]);
    }
    if (ids_photo) {
      const fetchImages = async () => {
        try {
          const { data } = await axios.post(URL + '/images', ids_photo);
          if (type === 'view') {
            if (setDataPhoto) {
              setDataPhoto(
                data.map((img: any) => ({
                  uid: img.id,
                  name: img.name,
                  status: 'done',
                  url: img.image,
                })),
              );
            }
          }
          setFileList(
            data.map((img: any) => ({
              uid: img.id,
              name: img.name,
              status: 'done',
              url: img.image,
            })),
          );
          if (setDataId) {
            setDataId(data.map((img: any) => img.id));
          }
        } catch (error) {
          message.error('Не удалось загрузить изображения');
        }
      };
      fetchImages();
    }
  }, [ids_photo]);

  const handleUpload: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
    onError,
    onProgress,
  }: any) => {
    try {
      onChangePhoto(file.name);
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64 = reader.result as string;
        const response = await axios.post(
          URL + '/upload',
          { image: base64, name: file.name },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        if (setDataId) {
          setDataId((prev) => {
            if (Array.isArray(prev)) {
              return [...prev, response.data];
            } else {
              return [];
            }
          });
        }
        message.success('Изображение сохранено');
        onSuccess(response.data, file);
      };
      reader.onerror = (error) => {
        message.error('Ошибка при конвертации в base64');
        onError(error);
      };
    } catch (error) {
      message.error('Ошибка при загрузке файла');
      onError(error);
    }
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handlePreview: UploadProps['onPreview'] = async (file: any) => {
    setPreviewVisible(true);
  };

  const handleRemove: UploadProps['onRemove'] = async (file: UploadFile) => {
    if (setDataId) {
      if (onChangeRemovePhoto) {
        onChangeRemovePhoto(file.name);
      }
      setDataId((prevDataId) => {
        const newDataId = prevDataId.filter((item) => item !== file.uid);
        return newDataId.length > 0 ? newDataId : [];
      });
    }
  };

  const onCloseModal = () => setPreviewVisible(false);

  return (
    <>
      {type !== 'view' && (
        <Upload
          customRequest={handleUpload}
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          onPreview={handlePreview}
          onRemove={handleRemove}
        >
          {fileList.length < 30 && <Button icon={<CameraOutlined />} />}
        </Upload>
      )}
      <FullScreen isOpen={previewVisible}>
        <Carousel images={fileList} onClick={onCloseModal} />
      </FullScreen>
    </>
  );
};

export default Uploader;
