import React from 'react';

import { Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import style from '../../style.module.scss';
import { columnsKtk, nameColumnsKtk } from '../../constants';
import IconButton from '../../../../components/ui/IconButton';
import { handleDownloadXlsx } from '../../../../helpers/xlsx-helper';
import { ViewTable } from '../../../../components/ui/List/ViewTable/ViewTable';
import { ITab } from '../../../../store/releases';

export const TableTab: React.FC<ITab> = ({ initialValue }) => {
  const { t } = useTranslation();

  const handlerUpload = () => {
    const rows =
      initialValue?.containers?.map(
        ({ num_container, size, type_container, comment, count }) => ({
          size,
          type_container,
          num_container,
          comment,
          count,
        }),
      ) || [];
    handleDownloadXlsx({
      nameFile: `${t('ктк релиза')} ${initialValue?.release_id ?? ''}`,
      nameColumns: nameColumnsKtk,
      nameList: `${t('Лист')} 1`,
      rows,
    });
  };

  return (
    <Flex vertical gap={10}>
      <Flex justify="space-between">
        <Flex gap={5} style={{ marginBottom: '5px' }}>
          <Typography.Text>{t('Терминал')}:</Typography.Text>
          <Typography.Text type={initialValue?.terminal ? 'success' : 'danger'}>
            {' '}
            {initialValue?.terminal || 'не выбран.'}
          </Typography.Text>
        </Flex>
        <IconButton
          className={style['button__excel']}
          iType="excel"
          onClick={handlerUpload}
          style={{
            opacity: !initialValue?.containers?.length ? 0.5 : 1,
          }}
          disabled={!initialValue?.containers?.length}
          title={t('Выгрузить в Excel')}
        />
      </Flex>

      <ViewTable columns={columnsKtk} data={initialValue?.containers} />
      <Flex justify="end">
        <Flex gap={5} style={{ marginBottom: '5px' }}>
          <Typography.Text>{t('Кол-во КТК')}:</Typography.Text>
          <Typography.Text
            type={initialValue?.containers_count ? 'success' : 'danger'}
          >
            {initialValue?.containers_count|| 0}
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
