// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer-container {
  width: 48px;
  background: #FFFFFF;
  position: absolute;
  z-index: 1000;
  max-width: 100vw;
  transition: all 0.3s;
  right: 0;
  bottom: 0;
  border-left: 1px solid #D8D8D8;
  top: 58px;
  padding: 8px 0;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/drawer-right.scss"],"names":[],"mappings":"AAAA;EACG,WAAA;EACD,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,oBAAA;EACA,QAAA;EACA,SAAA;EACA,8BAAA;EACA,SAAA;EACA,cAAA;AACF","sourcesContent":[".drawer-container{\n   width: 48px;\n  background: #FFFFFF;\n  position: absolute;\n  z-index: 1000;\n  max-width: 100vw;\n  transition: all 0.3s;\n  right: 0;\n  bottom: 0;\n  border-left: 1px solid #D8D8D8;\n  top: 58px;\n  padding: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
