// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-ul {
  list-style-type: none;
}

.list-li {
  cursor: pointer;
  padding: 8px;
}
.list-li:hover {
  background-color: #F2F6FF;
  padding: 8px;
  border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/List/style.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AACA;EACE,eAAA;EACA,YAAA;AAEF;AADE;EACC,yBAAA;EACC,YAAA;EACA,kBAAA;AAGJ","sourcesContent":[".list-ul{\n  list-style-type: none;\n}\n.list-li{\n  cursor: pointer;\n  padding: 8px;\n  &:hover {\n   background-color: #F2F6FF;\n    padding: 8px;\n    border-radius: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
