import {io} from "socket.io-client";

const URL: any = process.env.REACT_APP_API_URL;

const socket = io(  URL,
    {
        // reconnectionDelayMax: 10000,
        transports: ['websocket', 'polling'],
        forceNew: true,
        upgrade: false,
        auth: {token: 'Bearer ffgfg'}
    });


export default socket;
