import {IColumnType} from "../../components/ui/Table/Table";

export const columns: IColumnType<any>[] = [
    {
        dataIndex: 'id',
        checkbox: true,
        menu: true,
        type: 'string',
        key: '0',
      },
    {
        title: 'Название',
        dataIndex: 'name_company',
        subTitle: 'type_company',
        avatar: 'avatar',
        width: '350px',
        type: 'string',
        key: '1',
    },
    {
        title: 'Задачи',
        dataIndex: 'tasks',
        subTitle: 'tasks_name',
        width: '200px',
        type: '',
        key: '2',
    },
    {
        title: 'Ответственный',
        dataIndex: 'users.fio',
        width: '200px',
        type: 'string',
        key: '3',
    },
    {
        title: 'Дата создания',
        date: true,
        dataIndex: 'createdAt',
        width: '200px',
        type: 'date',
        key: '4',
    },
    {
        title: 'Источник',
        dataIndex: 'type_source',
        width: '100%',
        type: 'string',
        key: '5',
    },
    {
        title: '',
        dataIndex: 'hidden',
        width: '20%',
        key: '6',
    },
];
