import React from 'react';
import {Button, Col, Divider, Dropdown, Flex, MenuProps, Row, Typography} from "antd";
import Icon, {FullscreenOutlined} from "@ant-design/icons";
import {blackCloseIcon, moreIcon} from "../../../assets/image/svg";
import {useTranslation} from "react-i18next";
import moment from "moment";
import TableHistory from "./tableHistory";
import {IActivityContainer} from "../../../types/containers";
import * as XLSX from "xlsx";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

const {Title, Link} = Typography;

interface FormModalHistoryProps {
    dataRowContainer?: any,
    closeModalHistory?: any,
    activityContainer?: IActivityContainer[] | undefined
}



const FormModalHistory = ({
                              dataRowContainer,
                              closeModalHistory,
                          }: FormModalHistoryProps) => {
    const {t, i18n} = useTranslation();
    const dataHistory = useSelector((state: RootState) => state.containers.dataHistory);

    const handleDownload = (type: string) => {
        const rows = dataHistory.map((item: any) => ({
            date: moment(item.date).format('DD.MM.YYYY H:mm'),
            pair: item.pair[1] + '-' + item.pair[0],
            user_name: item.user_name,
        }));
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(rows);

        const colLengths = Object.keys(rows[0]).map((k) => k.toString().length)
        for (const d of rows) {
            Object.values(d).forEach((element, index) => {
                if (element !== undefined) {
                    const length = element.toString().length
                    if (colLengths[index] < length) {
                        colLengths[index] = length
                    }
                }
            })
        }
        worksheet["!cols"] = colLengths.map((l,index) => {
            if (index === 3 || index === 6 || index === 7 || index === 8 || index === 9
                || index === 10 || index === 11) {
                return {
                    wch: 7,
                }
            }else {
                return {
                    wch: 20,
                }
            }
        })
        XLSX.utils.book_append_sheet(workbook, worksheet, "История");

        XLSX.utils.sheet_add_aoa(worksheet, [
            ["Дата изменения", "Изменение", "Сотрудник"],
        ]);
        if (type === 'xlsx') {
            XLSX.writeFile(workbook, `История контейнера ${dataRowContainer?.num_container}.xlsx`, {compression: true});
        }else {
            XLSX.writeFile(workbook, `История контейнера ${dataRowContainer?.num_container}.csv`, {compression: true});
        }
    }
    const items: MenuProps['items'] = [
        {
            label: <div onClick={() =>handleDownload('xlsx')}>в Excel</div>,
            key: '0',
        },
        {
            label: <div onClick={() =>handleDownload('csv')}>в CSV</div>,
            key: '1',
        }
    ];
    return (
        <>
            <Row className={'modal-container'}>
                <Flex align={'center'} vertical={false} style={{width: '100%'}}>
                    <Col span={7}>
                        <Title level={4} style={{marginBottom: 0}}>{dataRowContainer?.num_container}</Title>
                    </Col>
                    <Col span={15} className={'text-base'}>
                        <Flex gap={12}>
                            <div>{dataRowContainer?.type_container}</div>
                            <div>|</div>
                            <div>Собственник: {dataRowContainer?.contractors?.name_company}</div>
                            <div className={'line'}>|</div>
                            <div>Терминал: {dataRowContainer?.child_terminal?.name}</div>
                        </Flex>
                    </Col>
                    <Col span={2}>
                        <Flex gap={10} justify={"flex-end"} style={{cursor: "pointer"}}>
                            <Icon component={moreIcon}/>
                            <Icon component={blackCloseIcon} onClick={closeModalHistory}/>
                        </Flex>
                    </Col>
                </Flex>
            </Row>
            <Divider style={{marginBottom: 0, marginTop: 8}}/>
            <Flex className={'container-modal'} vertical gap={20}>
                <Flex justify={"space-between"} align={"center"}>
                    <Col span={12} className={'label-card-24-500'}>{t("HistoryChanges")}</Col>
                    <Col span={12} className={'label-card-16 gutter-row'}
                         style={{textAlignLast: 'end', right: 48}}>{moment().format('DD.MM.YYYY  H:mm')}</Col>
                </Flex>
                <Flex justify={"space-between"}>
                    <Dropdown menu={{items, selectable: true, defaultSelectedKeys: ['3'],}}
                              placement="bottomLeft" trigger={['click']}>
                        <Button type="primary" ghost>{t('UploadTo')}</Button>
                    </Dropdown>
                    <FullscreenOutlined style={{ fontSize: '20px', position: 'relative',
                        right: 17,top: 18}}/>
                </Flex>
                <Flex>
                    <TableHistory dataHistory={dataHistory}/>
                </Flex>
            </Flex>
        </>
    );
};

export default FormModalHistory;
