import React, { useState, useMemo } from 'react';
import {
  Form,
  Button,
  Flex,
  message
} from 'antd';
import { BASE_TAB, APPROVAL_TAB, SIGNING_TAB, RELEASE_TAB, CHAT_TAB, } from '../constants';
import RequestTab from './tabs/RequestTab';
import ApprovalTab from './tabs/ApprovalTab';
import SigningTab from './tabs/SigningTab';
import ReleaseTab from './tabs/ReleaseTab';
import ChatTab from './tabs/ChatTab';
import ModalConfirm from '../../../../components/ui/ModalConfirm/ModalConfirm';
import { messagesModal } from '../../../../constants';
import { ITab, ITabs } from '../../../../components/ui/Tabs/Tabs';
import styles from './style.module.scss';

const { title: warningTitle, subtitle } = messagesModal.warning;

interface Props {
  onFinishAddTerminal?: (data: any, open: boolean) => void | undefined;
  setIsModalOpenForm?: (value?: ((prevState: boolean) => boolean) | boolean) => void;
  form?: any;
  initialValue: any;
  onClose: () => void;
}

const FormModalRentRequest: React.FC<Props> = ({
  onFinishAddTerminal,
  setIsModalOpenForm,
  form,
  initialValue,
  onClose
}) => {
  const [locations, setLocations] = useState([{ id: 1 }]);
  const [isErrorsBase, setIsErrorsBase] = useState<boolean | undefined>(false);
  const [activeKey, setActiveKey] = useState(() => BASE_TAB);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);

  const toggleApproval = () => setIsAgreed((prev) => !prev);
  const returnToRevision = () => setIsAgreed(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

  const handleFinish = (formData: any, action: string) => {
    console.log('Submitted Data:', formData);
    console.log('Action:', action);
    message.success('Заявка успешно обработана!');
  };

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: BASE_TAB,
        label: 'Запрос аренды',
        forceRender: true,
        isError: isErrorsBase,
        disabledText: '',
        disabled: false,
        content: <RequestTab 
          initialValue={initialValue}
          typeForm="create" 
          dataRowContainer={{ }}
          onFinish={handleFinish} 
          isAgreed={isAgreed}
        />,
      },
      {
        key: APPROVAL_TAB,
        label: 'Согласование',
        forceRender: true,
        isError: false,
        content: <ApprovalTab 
          initialValue={initialValue} 
          isAgreed={isAgreed}
          toggleApproval={toggleApproval}
          returnToRevision={returnToRevision}
        />,
      },
      {
        key: SIGNING_TAB,
        label: 'Подписание заявки с клиентом',
        forceRender: true,
        isError: false,
        content: <SigningTab initialValue={initialValue} />,
      },
      {
        key: RELEASE_TAB,
        label: 'Релиз',
        forceRender: true,
        isError: false,
        content: <ReleaseTab initialValue={initialValue} />,
      },
      {
        key: CHAT_TAB,
        label: 'Чат',
        forceRender: true,
        isError: false,
        content: <ChatTab initialValue={initialValue} />,
      },
    ],
    [isErrorsBase, isAgreed],
  );

  return (
    <>
      <Form autoComplete="off" form={form} layout="vertical"className={styles.formModalRequest}>
        <div className={styles.formModalRentRequest}>
          <ITabs
            className={styles['tabs-form__tab']}
            isErrorTabs
            activeKey={activeKey}
            tabs={tabs}
            onChange={changeClickTab}
          />
        </div>
        <Flex gap={8} style={{marginTop: 10}} className={styles.buttonContainer}>
          <Button type="primary" style={{width: '57%', height: '35px'}}>Сохранить</Button>
          <Button type={'text'} onClick={() => setConfirmation(true)}
            style={{width: '30%', color: '#E14453'}}>Отменить</Button>
        </Flex>
      </Form>
      <ModalConfirm title={warningTitle} subtitle={subtitle} isOpen={confirmation} closeModal={() => setConfirmation(false)} actionAfterConsent={isModalOpen ? handleCancel : onClose} />
    </>
  );
};

export default FormModalRentRequest;





