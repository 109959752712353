import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  AutoComplete,
  Button,
  Flex,
  Form,
  Input,
  MenuProps,
  App,
  Modal,
  notification,
  Space,
  Typography,
} from 'antd';

import Icon, { PlusOutlined } from '@ant-design/icons';
import {delay} from 'lodash';
import socket from '../../socket';
import { columns } from './columns';
import { Utils } from '../../utils';
import FormContainer from './formContainer';
import { TerminalService } from './service';
import FormModalImport from './formModalImport';
import { RouteNames } from '../../router/names';
import Container from '../../components/Container';
import FormModalContainer from './formModalContainer';
import { blackCloseIcon } from '../../assets/image/svg';
import { useStoreDispatch } from '../../store';
import { useBreadcrumb, useCsvExport, useMenu, useParamsHistory } from '../../hooks';
import { CREATE, EDIT, getTypeForm, VIEW } from '../../helpers/string-helpers';
import {
  IColumnType,
  IPagination,
  Table,
} from '../../components/ui/Table/Table';
import {
  deleteContainer,
  findContainerOne,
  getActivityContainer,
  getCommentContainer,
  getContainers,
  getEdi,
  getFiltersContainers,
  searchContainer,
  setContainer,
} from '../../store/containers';

import './style.scss';
import '../../assets/scss/terminal.scss';
import { filtersContainers } from '../../store/filters';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';

const TABLE_CONTAINER = 'tableContainer';
const ROW_CONTAINER = 'rowContainer';
const TOTAL_CONTAINER = 'totalContainer';
const DATA_CONTAINER = 'dataContainer';

const Containers: React.FC = () => {
  const {message} = App.useApp();
  const { history, location } = useParamsHistory();
  const { pathname, search } = location;
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isModalOpenContainer, setIsModalOpenContainer] = useState<boolean>(false);
  const [isModalOpenImport, setIsModalOpenImport] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [openDrawerContainer, setOpenDrawerContainer] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(1);
  const [page, setPage] = useState<number>(10);
  const [currentActivity, setCurrentActivity] = useState<number>(1);
  const [pageActivity, setPageActivity] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [totalTableActivity, setTotalTableActivity] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const [dataRowContainer, setDataRowContainer] = useState<any>();
  const [nameTerminal, setNameTerminal] = useState<string | null>(null);
  const searchParams = new URLSearchParams(location.search);
  const [typeForm, setTypeForm] = useState<string>('create');
  const [loadings, setLoadings] = useState<boolean>(false);
  const [dataComment, setDataComment] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeButtons, setActiveButtons] = useState<any>({});
  const [searchColumn, setSearchColumn] = useState<any[]>([]);
  const [activityContainer, setActivityContainer] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  useBreadcrumb(['Депо', 'Контейнеры']);
  useMenu({openKey: RouteNames.DEPOT, activeKeys: [RouteNames.DEPOT, RouteNames.CONTAINERS]});

  // проверка url
  useEffect(() => {
    const reg = /\d+/g;
    const [, current] = pathname.split(RouteNames.CONTAINERS);
    const path = getTypeForm(current);

    if (search) {
      return;
    }

    switch (path) {
      case CREATE:
        const isCreate = pathname.split('/')?.at(-1) === path;
        if (isCreate) {
          setTypeForm(CREATE);
          setOpenDrawerContainer(true);
          form.resetFields();
          return;
        }
        return history.replace(RouteNames.CONTAINERS_CREATE);
      case EDIT:
        const idEdit = pathname.split(`${EDIT}/`).at(-1) ?? '';
        const isEdit = reg.test(idEdit);
        if (isEdit) {
          if (location?.state?.[ROW_CONTAINER]) {
            if (location.state[ROW_CONTAINER]?.delete) {
              history.replace(`${RouteNames.CONTAINERS_VIEW}${location.state[ROW_CONTAINER].id}`);
              return;
            }
            setDataRowContainer(location?.state[ROW_CONTAINER]);
            setOpenDrawerContainer(true);
            setTypeForm(EDIT);
            return;
          }
          dispatch(findContainerOne(idEdit)).then((res) => {
            const container = res?.payload;

            if (container) {
              if (container?.delete) {
                history.replace(`${RouteNames.CONTAINERS_VIEW}${container.id}`);
                return;
              }
              setDataRowContainer(container);
              setOpenDrawerContainer(true);
              setTypeForm(EDIT);
            } else {
              history.push(RouteNames.CONTAINERS);
            }
          });
          return;
        }
        return history.push(RouteNames.CONTAINERS);
      case VIEW:
        const idView = pathname.split(`${VIEW}/`).at(-1) ?? '';
        const isView = reg.test(idView);

        if (isView) {
          const row = location?.state?.[ROW_CONTAINER];
          const getArray = [
            dispatch(
              getActivityContainer({
                id: +idView,
                current: (currentActivity - 1) * pageActivity,
                page: pageActivity,
              }),
            ),
            dispatch(getCommentContainer(+idView)),
          ];

          Promise.all(
            row ? getArray : [...getArray, dispatch(findContainerOne(idView))],
          ).then(([activityData, commentData, containerData]) => {
            const activity = activityData?.payload.rows;
            const comment = commentData?.payload;
            const container = row ? row : containerData?.payload;

            if (container) {
              setDataRowContainer(container);
              setActivityContainer(activity);
              setDataComment(comment);
              setIsModalOpenContainer(true);
            } else {
              history.push(RouteNames.CONTAINERS);
            }
          });
          return;
        }
        return history.push(RouteNames.CONTAINERS);
      default:
        history.push(RouteNames.CONTAINERS);
    }
  }, [pathname]);

  const subMenu = {
    label: 'Создать сделку',
    getSubMenu: ({item, rows, data}: {item: any, rows: Set<any>, data: any, onClose: () => void}): MenuProps['items'] => {
      const containers: any[] = rows?.size && data?.length ? data.filter((item: any) => rows.has(item?.id)) : [];
      if (item && rows && !rows.has(item?.id)) {
        containers.push(item);
      }
      return [
        {key: 'Продажа', label: <Typography.Text data-id="link" onClick={() => history.push(RouteNames.SALES_DEAL_CREATE, {sales: {deal: {containers}}})}>{t('Продажа')}</Typography.Text>},
        {key: 'Аренда', label: <Typography.Text data-id="link" onClick={() => history.push(RouteNames.RENT_DEAL_CREATE, {rent: {deal: {containers}}})}>{t('Аренда')}</Typography.Text>},
      ];
    }
  }

  const { generateCSV, copyToClipboard } = useCsvExport({
    fields: columns
      .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
      .map((item: any) => item.dataIndex), // Создаем массив из dataIndex
  });

  const handleSearch = async (inputValue: string) => {
    setLoading(true);
    setSelectedOption(inputValue);
    const commonParams = [
      { page: page },
      { current: (current - 1) * page, page: page },
    ];

    const executeSearch = async (params: any) => {
      try {
        const response = await dispatch(searchContainer(params));
        setData([]);
        setData(response.payload);
      } finally {
        setLoading(false);
      }
    };

    // Если inputValue пусто, выполняем запрос с пустым массивом
    if (inputValue === '') {
      setSelectedOption(null);
      setSearchColumn([]);
      await executeSearch([{ page: page }, { current: current }]);
      return;
    }

    // Если searchColumn пуст, выполняем поиск по всем колонкам, кроме исключенных
    if (searchColumn.length === 0) {
      const searchParams = columns
        .filter(
          (item: any) =>
            item.dataIndex !== 'date_entry' &&
            item.dataIndex !== 'arrival_date' &&
            item.dataIndex !== 'departure_date' &&
            item.dataIndex !== 'booking_before' &&
            item.dataIndex !== 'hidden',
        )
        .map((item: any) => ({ [item.dataIndex]: inputValue }));

      const finalSearchParams = [...searchParams, ...commonParams];

      await executeSearch(finalSearchParams);
      return;
    }

    // Если есть колонки для поиска и inputValue длиннее 1 символа
    if (searchColumn.length > 0 && inputValue.length > 1) {
      const searchParams = searchColumn.map((item) => ({ [item]: inputValue }));

      const finalSearchParams = [...searchParams, ...commonParams];

      await executeSearch(finalSearchParams);
    } else {
      setLoading(false);
    }
  };

  const handleButtonClickSearch = (option: any) => {
    setActiveButtons((prevActiveButtons: any) => ({
      ...prevActiveButtons,
      [option.key]: !prevActiveButtons[option.key],
    }));
    setSearchColumn((prevData: any) => [option.dataIndex, ...prevData]);
  };

  const fetchData = (
    current: number,
    page: number,
    clear: string,
    order: string,
  ) => {
    if (location?.state?.[TABLE_CONTAINER]) {
      setLoading(true);
      setData(location.state[TABLE_CONTAINER]);
      setTotal(location.state?.[TOTAL_CONTAINER] ?? 0);
      delay(() => {
        setLoading(false);
      }, 1000);
      return;
    }
    setLoading(true);
    dispatch(
      getContainers({
        current: (current - 1) * page,
        page: page,
        child: clear === 'fetch' ? Number(searchParams.get('child')) : 0,
        city: clear === 'fetch' ? Number(searchParams.get('city')) : 0,
        stock: clear === 'fetch' ? searchParams.get('stock') : null,
        order,
      }),
    )
      .then((response) => {
        if (response.payload.success === 1) {
          setData(response.payload.data);
          setTotal(response.payload.count);
          setLoading(false);
        } else {
          message.error(response.payload.message, 3);
          setLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };

  const onChangePagination = (page: IPagination<any>) => {
    if (page.current != null && page.pageSize != null) {
      setCurrent(page.current);
      setPage(page.pageSize);
      fetchData(page.current, page.pageSize, '', 'DESC');
    }
  };

  useEffect(() => {
    setNameTerminal(searchParams.get('name'));
    fetchData(current, page, 'fetch', 'DESC');

    socket.on('res-update-new-booking', handleNewBooking);
    socket.on('res-set-container', handleNewContainer);
    socket.on('res-set-container-import', resContainerImport);
    socket.on('res-delete-container', resHandleDeleteRow);
    socket.on('res-update-repair', resUpdateRepair);
    socket.on('res-update-date-booking', handleDateBooking);

    return () => {
      socket.off('res-update-new-booking', handleNewBooking);
      socket.off('res-set-container', handleNewContainer);
      socket.off('res-set-container-import', resContainerImport);
      socket.off('res-delete-container', resHandleDeleteRow);
      socket.off('res-update-repair', resUpdateRepair);
      socket.off('res-update-date-booking', handleDateBooking);
    };
  }, [current, page]);

  const resUpdateRepair = (response: any) => {
    setActivityContainer(response.data.rows);
    setData((prevData: any) => {
      return prevData.map((row: any) => {
        // Найти соответствующий элемент в response.data
        const matchedElement = response.data.rows.find(
          (el: any) => row.id === el.container_id,
        );
        if (matchedElement) {
          // Обновить поле repair, если найдено совпадение
          return {
            ...row, // Скопировать остальные свойства строки
            repair: matchedElement.repair, // Обновить значение repair
          };
        }
        return row; // Если совпадение не найдено, вернуть оригинальную строку
      });
    });
  };

  const resHandleDeleteRow = (response: any) => {
    setData((prevData) => {
      // Проверьте, что response является массивом, если это необходимо
      if (!Array.isArray([response])) {
        console.error('Invalid response data:', [response]);
        return prevData;
      }

      // Предположим, что response это объект с обновленными данными
      const updatedItem = response;
      return prevData.map((item) =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item,
      );
    });
  };

  const resContainerImport = (response: any) => {
    setData((prevData) => {
      const updatedData = [...prevData];

      response.data.forEach((newItem: any) => {
        const index = updatedData.findIndex((item) => item.id === newItem.id);
        if (index !== -1) {
          // Обновляем существующий объект
          updatedData[index] = newItem;
        } else {
          // Добавляем новый объект
          updatedData.unshift(newItem);
        }
      });

      return updatedData;
    });
    setIsModalOpenImport(false);
    //  setLoadings(false);
  };

  const handleNewContainer = (response: any) => {
    if (JSON.parse(response).success === 0) {
      Utils.InfoOpenNotification(
        'topRight',
        JSON.parse(response).message,
        0,
        api,
      );
    } else {
      if (typeForm === 'create') {
        setData((prevData: any) => [JSON.parse(response).data[0], ...prevData]);
      } else {
        setData((prevData) => {
          return prevData.map((item) =>
            item.id === JSON.parse(response).data[0].id
              ? { ...JSON.parse(response).data[0] }
              : item,
          );
        });
      }
      setOpenDrawerContainer(false);
      if (Utils.getUserInfo().id === JSON.parse(response).user_id) {
        Utils.openNotification(
          'topLeft',
          typeForm === 'create' ? 'Добавлено' : 'Обновлено',
          api,
        );
      }
      form.resetFields();
    }
  };

  const handleNewBooking = (response: any) => {
    dispatch(
      getActivityContainer({
        id: response[0].id,
        current: (currentActivity - 1) * pageActivity,
        page: pageActivity,
      }),
    ).then((response) => {
      setTotalTableActivity(response.payload.count);
      setActivityContainer(response.payload.rows);
    });
    setData((prevData: any) => {
      return prevData.map((row: any) =>
        row.id === response[0].id
          ? {
              ...row,
              ['status_terminal.name']: response[0]['status_terminal.name'],
              booking_before:
                response[0].booking_before !== null
                  ? moment(response[0].booking_before).format('DD.MM.YYYY')
                  : null,
            }
          : row,
      );
    });
  };

  const handleDateBooking = (response: any) => {
    setActivityContainer(response.rows);
    setData((prevData: any) => {
      return prevData.map((row: any) => {
        // Найти соответствующий элемент в response.data
        const matchedElement = response.rows.find(
          (el: any) => row.id === el.container_id,
        );
        if (matchedElement) {
          return {
            ...row,
            repair: matchedElement.repair,
          };
        }
        return row; // Если совпадение не найдено, вернуть оригинальную строку
      });
    });
  };

  const handleShowDrawerContainer = () => {
    setOpenDrawerContainer(true);
    setDataRowContainer([]);
    form.resetFields();
  };

  const onCloseFormContainer = () => {
    setOpenDrawerContainer(false);
    history.replace(RouteNames.CONTAINERS);
  };

  const closeModalContainers = () => {
    history.replace(RouteNames.CONTAINERS);
    setIsModalOpenContainer(false);
  };

  const onClickLink = (row: any) => {
    const { id } = row?.[0];
    if (id) {
      history.push(`${RouteNames.CONTAINERS_VIEW}${id}`, {
        [TABLE_CONTAINER]: [row?.[0]],
        [DATA_CONTAINER]: data,
        [TOTAL_CONTAINER]: total,
        [ROW_CONTAINER]: row[0],
      });
    }
  };

  const onCloseFilter = () => {
    history.push(RouteNames.CONTAINERS);
    fetchData(current, page, 'clear', 'DESC');
    setNameTerminal(null);
  };

  const handleShowEditForm = (payload: any) => {
    if (payload?.id) {
      history.push(`${RouteNames[payload?.deal ? 'CONTAINERS_VIEW' : 'CONTAINERS_EDIT']}${payload.id}`, {
        [ROW_CONTAINER]: payload,
        [TABLE_CONTAINER]: [payload],
        [DATA_CONTAINER]: data,
        [TOTAL_CONTAINER]: total,
      });
    }
  };

  const onFinish = (data: any) => {
    dispatch(setContainer(data))
      .then(() => {
        history.replace(RouteNames.CONTAINERS);
      })
      .catch((err) => message.error('Ошибка сохранения'));
  };

  const showCardContainer = (row: any) => {
    if (row?.id) {
      setIsModalOpenContainer(true);
      history.push(`${RouteNames.CONTAINERS_VIEW}${row.id}`, {
        [TABLE_CONTAINER]: [row],
        [ROW_CONTAINER]: row,
        [DATA_CONTAINER]: data,
        [TOTAL_CONTAINER]: total,
      });
    }
  };

  const handleShowModal = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChangeImport = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    TerminalService.handleFile(event, columns).then((response) => {
      if (response.length > 0) {
        setIsModalOpenImport(true);
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      }
    });
  };

  const closeModalImport = () => {
    setIsModalOpenImport(false);
  };

  const filtersFetch = (text: string | number, column: IColumnType<any>) => {
    dispatch(filtersContainers({ text: text, column: column }));
  };

  const filtersColumn = (value: []) => {
    if (value.length > 0) {
      dispatch(
        getFiltersContainers({
          data: value,
          current: (current - 1) * page,
          page: page,
        }),
      ).then((response) => {
        setData(response.payload.rows);
        setTotal(response.payload.count);
      });
    } else {
      fetchData(current, page, '', 'DESC');
    }
  };

  const handleOrder = (order: string) => {
    fetchData(current, page, '', order);
  };

  const handleCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    const rowsData = generateCSV(arSelectedRows, data);
    copyToClipboard(rowsData);
  };

  const handleDeleteRow = (row: any) => {
    dispatch(
      deleteContainer({
        current: (current - 1) * page,
        page: page,
        id: row.id,
        delete: row.delete !== true,
      }),
    );
  };

  const handleDeleteCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    arSelectedRows.forEach((item: any) => {
      // Оптимизированная фильтрация
      const deleteData = data.find(
        (el) => el.id === item && el.delete !== true,
      );

      if (deleteData) {
        dispatch(
          deleteContainer({
            current: (current - 1) * page,
            page: page,
            id: item,
            delete: deleteData.delete !== true,
          }),
        );
      }
    });
  };

  const handleEdi = async () => {
    dispatch(getEdi());
  };

  return (
    <Container className="page-container">
      {contextHolder}
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('ContainerTable')}</div>
          <div className={'drawer-title terminal'}>{nameTerminal}</div>
          {nameTerminal !== null && (
            <Icon component={blackCloseIcon} onClick={onCloseFilter} />
          )}
        </Flex>
        <Flex gap={16}>
          <input
            style={{ display: 'none' }}
            ref={inputRef}
            type="file"
            id="fileSelect"
            accept=".xlsx, .xls, .csv"
            onChange={handleFileChangeImport}
          />
          <Button onClick={handleShowModal}>{t('Импорт')}</Button>
          <Button onClick={handleEdi}>{t('Импорт из Edi')}</Button>

          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
            onClick={handleShowDrawerContainer}
          />
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            options={columns}
            onSearch={(text) => handleSearch(text)}
            popupMatchSelectWidth={false}
            value={selectedOption}
            dropdownRender={() => (
              <>
                <div
                  className={'text-card-12'}
                  style={{ padding: '10px 10px' }}
                >
                  Выбор колонок по которым будет производиться поиск
                </div>
                <Flex
                  gap={5}
                  style={{ width: 300, flexWrap: 'wrap', padding: 10 }}
                >
                  {columns.map(
                    (option) =>
                      option.title !== '' &&
                      option.dataIndex !== 'date_entry' &&
                      option.dataIndex !== 'arrival_date' &&
                      option.dataIndex !== 'departure_date' &&
                      option.dataIndex !== 'booking_before' && (
                        <Button
                          size={'small'}
                          key={option.key}
                          type="primary"
                          className={
                            activeButtons[Number(option.key)]
                              ? 'active-search-btn'
                              : ''
                          }
                          onClick={() => handleButtonClickSearch(option)}
                        >
                          {option.title}
                        </Button>
                      ),
                  )}
                </Flex>
              </>
            )}
          >
            <Input placeholder={t('Введите текст')} />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          subMenu={subMenu}
          onClickLink={onClickLink}
          editRow={handleShowEditForm}
          dataSource={data}
          columns={columns}
          loading={loading}
          order={handleOrder}
          filtersFetch={filtersFetch}
          filters={filtersColumn}
          deleteRow={handleDeleteRow}
          height={'calc(-162px + 100vh)'}
          pagination={{
            pageSize: page,
            total: Number(total),
            current: current,
            showSizeChanger: true,
          }}
          style={{ overflow: 'auto hidden' }}
          onChangePagination={onChangePagination}
          handleCopyAll={handleCopyAll}
          handleDeleteCopyAll={handleDeleteCopyAll}
          onRow={(record, rowIndex) => {
            showCardContainer(record);
          }}
        />
      </Space>
      <CustomDrawer open={openDrawerContainer} onClose={onCloseFormContainer}>
        <FormContainer
          typeForm={typeForm}
          form={form}
          dataRowContainer={dataRowContainer}
          onClose={onCloseFormContainer}
          onFinishForm={onFinish}
          title={
            typeForm === CREATE
              ? 'Добавить контейнер'
              : 'Редактировать контейнер'
          }
        />
      </CustomDrawer>
      <Modal
        className='page-container'
        closable={false}
        footer={null}
        width={'80%'}
        open={isModalOpenContainer}
      >
        <FormModalContainer
          totalTableActivity={totalTableActivity}
          setActivityContainer={setActivityContainer}
          currentActivity={currentActivity}
          pageActivity={pageActivity}
          activityContainer={activityContainer}
          dataComment={dataComment}
          dataRowContainer={dataRowContainer}
          closeModalContainers={closeModalContainers}
          isDisabled={!!dataRowContainer?.deal || !!dataRowContainer?.release || !!dataRowContainer?.delete}
        />
      </Modal>
      <Modal
        className='page-container'
        closable={false}
        footer={null}
        width={'94%'}
        open={isModalOpenImport}
      >
        <FormModalImport
          closeModalImport={closeModalImport}
          loadings={loadings}
          setLoadings={setLoadings}
        />
      </Modal>
    </Container>
  );
};

export default Containers;
