import React from 'react';
import {Avatar, Col, Divider, Flex, Form, Row, Typography} from "antd";
import Icon, {CloseOutlined, UserOutlined} from "@ant-design/icons";
import {EditIcon, EmailIcon, flagRuIcon} from "../../assets/image/svg";
import Button from "../../components/ui/Button/Button";
import CopyButton from '../../components/ui/CopyButton/CopyButton';
import {Icons} from "./Icons";
import './style.scss'


const { Text,Title } = Typography;

const FormCardContractors = (props: any) => {
    const internal_company: any = props?.dataContractors.internal_company !== null ? props?.dataContractors.internal_company : '';
    const address: any = props?.dataContractors.address !== null ? props?.dataContractors.address : '';
    return (
        <>
            {/* <CloseOutlined onClick={props.onClose} className={'close'}/> */}
            <Form layout="vertical" form={props.formCard} autoComplete="off">
                <Flex justify={'space-between'} className={'drawer-header'}>
                    <Flex vertical style={{width: '100%'}}>
                        <Flex gap={4} justify={"space-between"} align={'center'} style={{marginBottom: 12}}>
                            <div className={'drawer-title'} >
                                {props?.dataContractors?.name_company}
                            </div>
                                <Icon component={EditIcon} onClick={() =>props.handleShowEditForm(props?.dataContractors)}/>
                        </Flex>
                        <Flex gap={4} vertical>
                            <Text>Внутреннее название компании</Text>
                            <Text>{props?.dataContractors?.internal_company}</Text>
                        </Flex>
                        <Divider style={{marginTop: 15, marginBottom: 15}}/>
                        <Flex gap={4} vertical>
                            <Text>Тип компании</Text>
                            <Text>{props?.dataContractors?.type_company}</Text>
                        </Flex>
                        <Divider style={{marginTop: 15, marginBottom: 15}}/>
                        <Flex gap={4} vertical>
                            <Text>Источник</Text>
                            <Text>{props?.dataContractors?.type_source}</Text>
                        </Flex>
                        <Divider style={{marginTop: 15, marginBottom: 15}}/>
                        <Flex gap={4} vertical>
                            <Flex gap={4} vertical>
                                <Text>Контакты</Text>
                                <Row>
                                    <Col className="gutter-row" span={10}>
                                        <Text>{props?.dataContractors?.type_phone_first}</Text>
                                    </Col>
                                    {props?.dataContractors?.phone_first && (
                                        <>
                                            <Col className="gutter-row" span={12}>
                                                <Flex gap={4}>
                                                    <Icon component={flagRuIcon}/>
                                                    <Text>{props?.dataContractors?.phone_first}
                                                    </Text>
                                                </Flex>
                                            </Col>
                                            <Col className="gutter-row" span={2}>
                                                <CopyButton text={props?.dataContractors?.phone_first} />
                                            </Col>
                                        </>
                                    )}
                                </Row>
                                {props?.dataContractors?.last_phone?.map((item: any, index: number) => {
                                    return (
                                        <Row key={index}>
                                            <Col className="gutter-row" span={10}>
                                                <Text>{item.add_type_phone}</Text>
                                            </Col>
                                            {item?.add_phone && (
                                            <>
                                                <Col className="gutter-row" span={12}>
                                                    <Flex gap={4}>
                                                        <Icon component={flagRuIcon}/>
                                                        <Text>{item?.add_phone}</Text>
                                                    </Flex>
                                                </Col>
                                                <Col className="gutter-row" span={2}>
                                                <CopyButton text={item?.add_phone} />
                                                </Col>
                                            </>)}
                                        </Row>
                                    )
                                })
                                }
                            </Flex>
                            <Flex gap={4} vertical>
                                {props?.dataContractors?.email_first !== null &&
                                    <Row>
                                        <Col className="gutter-row" span={10}>
                                            <Text>{props?.dataContractors?.type_email_first}</Text>
                                        </Col>
                                        <Col className="gutter-row" span={12}>
                                            <Flex gap={4} align={"center"}>
                                                <Button icon={<EmailIcon/>} type={'icon'}/>
                                                <Text>{props?.dataContractors?.email_first}</Text>
                                            </Flex>
                                        </Col>
                                        <Col className="gutter-row" span={2}>
                                            <CopyButton text={props?.dataContractors?.email_first} />
                                        </Col>
                                    </Row>
                                }
                                {props?.dataContractors?.last_email?.map((item: any, index: number) => {
                                    return (
                                        <Row key={index}>
                                            <Col className="gutter-row" span={10}>
                                                <Text>{item.add_type_email}</Text>
                                            </Col>
                                            <Col className="gutter-row" span={12}>
                                                <Flex gap={4}>
                                                    <Button icon={<EmailIcon/>} type={'icon'}/>
                                                    <Text>{item.add_email}</Text>
                                                </Flex>
                                            </Col>
                                            <Col className="gutter-row" span={2}>
                                                <CopyButton text={item.add_email} />
                                            </Col>
                                        </Row>
                                    )
                                })
                                }
                            </Flex>
                            <Flex gap={4} vertical>
                                {props?.dataContractors?.messenger_first !== null &&
                                    <Row>
                                        <Col className="gutter-row" span={10}>
                                            <Text>{props?.dataContractors?.type_messenger_first}</Text>
                                        </Col>
                                        <Col className="gutter-row" span={12}>
                                            <Flex gap={4}>
                                                <Icons.arrIcon type={props?.dataContractors?.type_messenger_first}/>
                                                <Text>{props?.dataContractors?.messenger_first}</Text>
                                            </Flex>
                                        </Col>
                                        <Col className="gutter-row" span={2}>
                                            <CopyButton text={props?.dataContractors?.messenger_first} />
                                        </Col>
                                    </Row>
                                }
                                {props?.dataContractors?.last_messenger?.map((item: any, index: number) => {
                                    return (
                                        <Row key={index}>
                                            <Col className="gutter-row" span={10}>
                                                <Text>{item.add_messenger}</Text>
                                            </Col>
                                            <Col className="gutter-row" span={12}>
                                                <Flex gap={4}>
                                                    <Icons.arrIcon type={item.add_type_messenger}/>
                                                    <Text>{item.add_type_messenger}</Text>
                                                </Flex>
                                            </Col>
                                            <Col className="gutter-row" span={2}>
                                                <CopyButton text={item.add_type_messenger} />
                                            </Col>
                                        </Row>
                                    )
                                })
                                }
                            </Flex>
                        </Flex>
                        <Divider style={{marginTop: 15, marginBottom: 15}}/>
                        <Flex gap={4} vertical>
                            <Text>Контакт</Text>
                            <Text>
                                {props?.dataContractors?.contact_name !== null && props?.dataContractors?.contact_name +' '}
                                {props?.dataContractors?.contact_phone  !== null && props?.dataContractors?.contact_phone +' '}
                                {props?.dataContractors?.contact_email  !== null &&  props?.dataContractors?.contact_email}
                            </Text>
                        </Flex>
                        <Divider style={{marginTop: 15, marginBottom: 15}}/>
                        <Flex gap={4} vertical>
                            <Text>Реквизиты</Text>
                            <Text>{internal_company + ' '+ address}
                            </Text>
                        </Flex>
                        <Divider/>
                        <Flex gap={6} vertical>
                            <Title level={4} style={{marginBottom: 0}}>Дополнительно</Title>
                            <Text style={{marginTop: 0}}>Ответственный</Text>
                            <Flex justify={'space-between'} align={'center'} style={{paddingRight: 7}}>
                                <Flex gap={6} align={'center'}>
                                    <Avatar size={28} icon={<UserOutlined/>}/>
                                    <Text style={{marginTop: 0, color: '#05F'}}>
                                        {props?.dataContractors?.['users.fio']}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex vertical style={{marginTop: 10,marginBottom: 20}}>
                            <Title level={4} style={{marginBottom: 6}}>Комментарий</Title>
                            <Form.Item name="comment">
                                <div>
                                    {props?.dataContractors?.comment}
                                </div>
                            </Form.Item>
                        </Flex>
                    </Flex>
                </Flex>
            </Form>
        </>
    );
};

export default FormCardContractors;
