import { useEffect, useState } from 'react';

import { Flex, Tabs, TabsProps, Typography } from 'antd';

import Layouts from './Layouts/Layouts';
import { Utils } from '../utils';
import Contacts from '../pages/contacts/Contacts';
import { RouteNames } from '../router/names';
import Contractors from '../pages/contractors/Contractors';
import { useBreadcrumb, useMenu, useParamsHistory } from '../hooks';
import {
  COMPANY,
  CONTACTS,
  crmNames,
  DEALS,
  REQUESTS,
  INTEGRATION,
  LEADS,
  AGREEMENTS,
} from '../constants';

import '../assets/scss/srm-container.scss';
import { useTranslation } from 'react-i18next';
import AgreementsCRM from '../pages/agreements';

const { Title } = Typography;

const CrmContainers = () => {
  const { t } = useTranslation();
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const [activeKey, setActiveKey] = useState(() => COMPANY);
  const [name, setName] = useState('');
  const [activeTitle, setActiveTitle] = useState(() => crmNames[COMPANY]);
  useBreadcrumb(['CRM', activeTitle]);
  useMenu({activeKeys: [RouteNames.CRM]});

  const items: TabsProps['items'] = [
    { key: AGREEMENTS, label: t(crmNames[AGREEMENTS]), children: <AgreementsCRM /> },
    { key: DEALS, label: t(crmNames[DEALS]), children: 'Сделки' },
    { key: COMPANY, label: t(crmNames[COMPANY]), children: <Contractors /> },
    { key: CONTACTS, label: t(crmNames[CONTACTS]), children: <Contacts /> },
    { key: INTEGRATION, label: t(crmNames[INTEGRATION]), children: 'Интеграции' },
  ];

  const handleClickTab = (key: string) => {
    setActiveKey(key);
    history.replace(`${RouteNames.CRM}/${key}`);
  };

  useEffect(() => {
    const localUser = async () => {
      if (Utils.getStorage('_in') !== null) {
        const user = await Utils.decrypt(Utils.getStorage('_in'));
        setName(user.name === null ? '' : user.name);
      }
    };
    localUser();
  }, []);

  useEffect(() => {
    const isCrm = pathname.includes(RouteNames.CRM);
    const [, current] = pathname.split(isCrm ? RouteNames.CRM : '/');
    const [, path] = current?.split('/');
    switch (path) {
      case AGREEMENTS:
        setActiveKey(AGREEMENTS);
        setActiveTitle(crmNames[AGREEMENTS]);
        return;
      case DEALS:
        setActiveKey(DEALS);
        setActiveTitle(crmNames[DEALS]);
        return;
      case REQUESTS:
        setActiveKey(REQUESTS);
        setActiveTitle(crmNames[REQUESTS]);
        return;
      case CONTACTS:
        setActiveKey(CONTACTS);
        setActiveTitle(crmNames[CONTACTS]);
        return;
      case COMPANY:
        setActiveKey(COMPANY);
        setActiveTitle(crmNames[COMPANY]);
        return;
      case INTEGRATION:
        setActiveKey(INTEGRATION);
        setActiveTitle(crmNames[INTEGRATION]);
        return;
      default:
        history.push(RouteNames.CRM_COMPANY);
    }
  }, [pathname]);

  return (
    <Layouts>
      <Flex style={{ width: '100%' }} vertical className='crm'>
        <Title level={2} style={{ padding: '20px 18px' }}>
          {t('Доброе утро')} {name ?? ''}!
        </Title>
          <Tabs
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={handleClickTab}
            items={items}
            style={{ width: '100%' }}
            indicator={{ size: (origin: any) => origin - 100, align: 'center' }}
          />
       {/* </Spin>*/}
      </Flex>
    </Layouts>
  );
};

export default CrmContainers;
