// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForgetLogin_element__--C28 {
  display: block;
  margin: 3px;
  padding: 3px;
  border: 1px solid rgba(68, 83, 113, 0.1490196078);
  border-radius: 5px;
}

.ForgetLogin_button__2Kfge {
  border-radius: 5px;
  background-color: #0055FF;
  color: #fff;
  font-size: 14;
}

.ForgetLogin_p__8Kjdd {
  color: #0055FF;
}

::placeholder { /* Most modern browsers support this now. */
  color: #000000;
}

.ForgetLogin_email__QMSAN {
  position: absolute;
  left: 150;
}

.ForgetLogin_formEmail__8ACTA {
  position: relative;
}

.ForgetLogin_smallStep__Rbcd5 {
  margin: 5px 0;
}

.ForgetLogin_bigStep__l8TDk {
  margin: 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ForgetLogin/ForgetLogin.module.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;EACA,WAAA;EACA,YAAA;EACA,iDAAA;EACA,kBAAA;AACD;;AACA;EACC,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,aAAA;AAED;;AAAA;EACC,cAAA;AAGD;;AAAA,gBAAA,2CAAA;EACE,cAAA;AAGF;;AAAA;EACC,kBAAA;EACA,SAAA;AAGD;;AAAA;EACC,kBAAA;AAGD;;AAAA;EACC,aAAA;AAGD;;AAAA;EACC,cAAA;AAGD","sourcesContent":[".element {\n\tdisplay: block;\n\tmargin: 3px;\n\tpadding: 3px;\n\tborder: 1px solid #44537126;\n\tborder-radius: 5px;\n}\n.button {\n\tborder-radius: 5px;\n\tbackground-color: #0055FF;\n\tcolor: #fff;\n\tfont-size: 14;\n}\n.p {\n\tcolor: #0055FF;\n}\n\n::placeholder { /* Most modern browsers support this now. */\n  color: #000000;\n\t// padding-left: 7px;\n}\n.email {\n\tposition: absolute;\n\tleft: 150\n}\n\n.formEmail {\n\tposition: relative;\n}\n\n.smallStep {\n\tmargin: 5px 0;\n}\n\n.bigStep {\n\tmargin: 20px 0;\n}\n.class {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": `ForgetLogin_element__--C28`,
	"button": `ForgetLogin_button__2Kfge`,
	"p": `ForgetLogin_p__8Kjdd`,
	"email": `ForgetLogin_email__QMSAN`,
	"formEmail": `ForgetLogin_formEmail__8ACTA`,
	"smallStep": `ForgetLogin_smallStep__Rbcd5`,
	"bigStep": `ForgetLogin_bigStep__l8TDk`
};
export default ___CSS_LOADER_EXPORT___;
