import { CSSProperties, useEffect, useState } from 'react';
import { Checkbox as Check, CheckboxProps } from 'antd';
import classnames from 'classnames';
import checkboxStyle from './style.module.scss';

interface ICheckbox extends CheckboxProps {
  className?: string;
  stopChange?: boolean;
  isChoice?: boolean;
  onChange?: (e: any) => void;
  customChange?: (e: any) => void;
  type?: string;
  label?: string;
  labelType?: string;
  style?: CSSProperties;
  children?: any;
  [key: string]: any;
}

export const Checkbox = ({
  onChange,
  customChange,
  children,
  stopChange = false,
  isChoice = undefined,
  className = '',
  type = '',
  label = '',
  labelType = '',
  style = {},
  ...props
}: ICheckbox) => {
  const [checked, setChecked] = useState(() => isChoice);
  const handlerChecked = (e: any) => {
    setChecked(e.target.checked);
    if (onChange) {
      onChange(e.target.checked);
    }
    if (customChange) {
      customChange(e.target.checked);
    }
  };

  useEffect(() => {
    if (isChoice !== checked && isChoice !== undefined) {
      setChecked(isChoice);
      if (onChange) {
        onChange(isChoice);
      }
    }
  }, [isChoice]);
  return (
    <>
      {type ? (
        <label
          className={classnames(checkboxStyle.label, {
            [checkboxStyle[labelType]]: labelType,
          })}
        >
          <input
            style={style}
            type="checkbox"
            checked={isChoice}
            onChange={handlerChecked}
            className={classnames(checkboxStyle.element, {
              [checkboxStyle[type]]: type,
            })}
          />
          {label}
        </label>
      ) : (
        <Check
          {...props}
          className={classnames(checkboxStyle.checkbox, {
            [className]: className,
          })}
          checked={checked}
          onChange={handlerChecked}
        >
          {children}
        </Check>
      )}
    </>
  );
};
