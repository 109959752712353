import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import {
  AutoComplete,
  Avatar,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  Typography,
} from 'antd';

import ru from 'react-phone-input-2/lang/ru.json';
import { DeleteOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';

import { Utils } from '../../utils';
import FormRequisites from './formRequisites';
import { messagesModal } from '../../constants';
import { RootState, useStoreDispatch } from '../../store';
import DrawerComponent from '../../components/DrawerComponent';
import { findContact, findExecutor } from '../../store/contact';
import { regNoQuotesSymbol } from '../../helpers/input-helpers';
import { getContractorsRequisites } from '../../store/contractors';
import ModalConfirm from '../../components/ui/ModalConfirm/ModalConfirm';
import { SelectSearch } from '../../components/ui/SelectSearch/SelectSearch';

import 'react-phone-input-2/lib/style.css';

const { title: warningTitle, subtitle } = messagesModal.warning;

interface DataType {
  key: React.Key;
  name: string;
  meaning: string;
}

const { Title, Text } = Typography;

interface IFormEditContractors {
  title: string;
  dataContractors: any;
  setDataRequisites?: (value: any) => void;
  onFinishEdit?: (data: any, type: string) => Promise<void> | undefined;
  onCloseEdit?: () => void;
  form: FormInstance;
}

const FormEditContractors: React.FC<IFormEditContractors> = ({
  setDataRequisites,
  dataContractors,
  title,
  onFinishEdit,
  onCloseEdit,
  form,
}) => {
  const { t } = useTranslation();
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const dispatch = useStoreDispatch();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [dataContact, setContact] = useState<any>([]);
  const [dataCity, setCity] = useState<any>([]);
  const [finContact, setFinContact] = useState<any>([]);
  const [valueCity, setValueCity] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dataSourceContact = useSelector(
    (state: RootState) => state.contact.dataSourceContact,
  );
  const dataTypeCompany = useSelector(
    (state: RootState) => state.types.dataTypeCompany,
  );
  const dataTypePhone = useSelector(
    (state: RootState) => state.types.dataTypePhone,
  );
  const dataTypeEmail = useSelector(
    (state: RootState) => state.types.dataTypeEmail,
  );
  const dataTypeMessenger = useSelector(
    (state: RootState) => state.types.dataTypeMessenger,
  );
  const [typeCompany, setTypeCompany] = useState<any>(
    dataContractors?.type_company,
  );
  const [typeSource, setTypeSource] = useState<any>(
    dataContractors?.type_source,
  );

  const [arrPhones, setArrPhones] = useState<any>([]);
  const [arrCompanyInn, setArrCompanyInn] = useState<any>([]);
  const [dataInn, setDataInn] = useState<any>([]);
  const [reg, setReg] = useState<any>(dataContractors?.requisites?.requisites);
  const [dataExecutor, setDataExecutor] = useState<[]>([]);
  const [responsibleChange, setResponsibleChange] = useState<any>(() => null);
  const [arrResponsible, setArrResponsible] = useState<any>(null);
  const [responsible, setResponsible] = useState<string>(
    Utils.getUserInfo().fio,
  );
  const [dataBankRequisites, setDataBankRequisites] = useState<[]>([]);
  const [valueInn, setValueInn] = useState<string>('');
  const [valueContact, setValueContact] = useState<string>('');
  const [searchValue, setSearchValue] = React.useState(null);

  useEffect(() => {
    if (dataContractors?.id) {
      setDataBankRequisites(dataContractors?.requisites?.bank);
      const user = Utils.decrypt(Utils.getStorage('_in'));
      setArrResponsible(dataContractors?.users?.value ?? null);
      setResponsible(dataContractors?.users?.fio ?? '');
      setName(user.name);
      form.setFieldsValue({
        name_company: dataContractors?.name_company,
        internal_company: dataContractors?.internal_company,
        type_company: dataContractors?.type_company,
        type_source: dataContractors?.type_source,
        phone_first:
          !Array.isArray(dataContractors) &&
          dataContractors?.phone_first !== null
            ? dataContractors?.phone_first
            : '+7',
        type_phone_first: dataContractors?.type_phone_first,
        email_first: dataContractors?.email_first,
        type_email_first: dataContractors?.type_email_first,
        messenger_first: dataContractors?.messenger_first,
        type_messenger_first: dataContractors?.type_messenger_first,
        address: dataContractors?.address,
        comment: dataContractors?.comment,
        last_phone: dataContractors?.last_phone,
        last_email: dataContractors?.last_email,
        last_messenger: dataContractors.last_messenger,
        contact_id: dataContractors?.contact
          ? dataContractors?.contact?.last_name +
            ' ' +
            dataContractors?.contact?.name +
            ' ' +
            dataContractors?.contact?.first_name
          : null,
        inn:
          dataContractors?.requisites?.requisites !== undefined &&
          dataContractors?.requisites?.requisites !== null
            ? dataContractors?.requisites?.requisites[2]?.meaning
            : null,
      });
    } else {
      form.setFieldsValue({});
    }
  }, [form, dataContractors, reg]);

  const showDrawerInn = () => {
    dispatch(getContractorsRequisites(dataContractors.id)).then((res) => {
      if (res?.payload.length > 0) {
        if (res?.payload[0]?.requisites !== null) {
          setReg(res?.payload[0].requisites);
        }
      }
      setOpen(true);
    });
  };

  const onClose = () => {
    setArrPhones([]);
    setOpen(false);
  };

  const onCloseForm = () => {
    if (onCloseEdit) {
      onCloseEdit();
    }
    setReg([]);
  };

  const handleSearchContact = async (payload: any) => {
    if (payload.length > 2) {
      const result: any = await dispatch(findContact(payload));
      const jsResult = JSON.parse(result.payload);
      if (jsResult.success > 0) {
        setContact(jsResult.data);
      }
    }
  };

  const handleSelectContact = (val: string, record: any) => {
    setFinContact([record]);
    setValueContact(record.label);
  };

  const handleRequisites = (data: []) => {
    if (setDataRequisites) {
      setDataRequisites(data);
    }
  };

  const funStorage = async (data: any) => {
    return new Promise(async (resolve, reject) => {
      const arResult: DataType[] = [];
      data?.suggestions?.forEach((el: any) => {
        let address = el?.data?.address?.data;
        arResult.push({ key: 1, name: 'Компания', meaning: el?.value });
        arResult.push({
          key: 2,
          name: 'Полное наименование организации',
          meaning: el?.data?.name?.full_with_opf,
        });
        arResult.push({ key: 3, name: 'ИНН', meaning: el?.data?.inn });
        arResult.push({ key: 4, name: 'КПП', meaning: el?.data?.kpp });
        arResult.push({
          key: 5,
          name: 'ОГРН (Дата)',
          meaning:
            el?.data?.ogrn +
            ' (' +
            moment(el?.data?.ogrn_date).format('DD.MM.YYYY') +
            ')',
        });
        arResult.push({
          key: 6,
          name: 'Адрес',
          meaning: el?.data?.address.value,
        });
        arResult.push({
          key: 7,
          name: 'Дата государственной регистрации',
          meaning: moment(el?.data?.state?.registration_date).format(
            'DD.MM.YYYY',
          ),
        });
        arResult.push({ key: 8, name: 'ОКПО', meaning: el?.data?.okpo });
        arResult.push({ key: 9, name: 'ОКТМО', meaning: el?.data?.oktmo });
        arResult.push({
          key: 10,
          name: 'Ген. директор',
          meaning: el?.data?.management?.name,
        });
        arResult.push({
          key: 11,
          name: 'Короткий адрес',
          meaning:
            'г.' +
            address.city +
            ' ' +
            address?.street_with_type +
            ' ' +
            'д.' +
            address?.house,
        });
      });
      resolve(arResult);
    });
  };

  const handleSearchInn = async (val: any) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(val) || val === '' || val === '-') {
      const arResult = await Utils.requisites(val);
      if (JSON.parse(arResult).suggestions.length > 0) {
        const nameInn = JSON.parse(arResult)?.suggestions[0]?.value;
        const addressInn =
          JSON.parse(arResult)?.suggestions[0]?.data?.address?.value;
        setDataInn([
          {
            value: nameInn + ', ' + addressInn,
            label: nameInn + ' ' + addressInn,
          },
        ]);
        setArrCompanyInn(JSON.parse(arResult));
        await Utils.requisitesBank(val);
        await funStorage(JSON.parse(arResult)).then((res) => {
          setReg(res);
        });
      }
    }
  };

  const handleChangeInn = (value: string) => {
    // Проверка, состоит ли значение только из цифр
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value) || value === '' || value === '-') {
      setValueInn(value);
    } else {
      setValueInn('');
    }
  };

  const handleSelectInn = (value: string) => {
    setValueInn(value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchCity = async (payload: any) => {
    if (payload !== '') {
      const arResult: any = [];
      const arResultYandex = await Utils.yandexCity(payload);
      await arResultYandex.response?.GeoObjectCollection?.featureMember.map(
        (item: any, i: number) => {
          const text =
            item?.GeoObject?.metaDataProperty?.GeocoderMetaData?.AddressDetails
              ?.Country?.AddressLine;
          arResult.push({ value: text, label: text });
        },
      );
      setCity(arResult);
    }
  };

  const handleChangeCity = async (payload: any) => {
    setValueCity(payload);
  };

  const changePerson = (e: number, option: any) => {
    setResponsibleChange(option.children.props.children);
    setArrResponsible(option);
  };

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const result: any = await dispatch(
      findExecutor(event.target.value.toLocaleLowerCase()),
    );
    if (result.payload.length > 0) {
      setDataExecutor(result.payload);
    }
  };

  const addFormResponsible = () => {
    setResponsible(responsibleChange.name);
    setIsModalOpen(false);
    setResponsibleChange(null);
    setSearchValue(null);
  };

  const handleAddResponsible = (e: any, option: any) => {
    const resp = {
      avatar: option.avatar,
      name: option.children,
      id: option.value,
    };
    setResponsibleChange(resp);
    setArrResponsible(resp.id);
    setDataExecutor([]);
    setSearchValue(option.children);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (data: any) => {
    Object.assign(data, {
      type_company: typeCompany,
      type_source: typeSource,
      id: dataContractors?.id || null,
      user_id: Utils.getUserInfo().id,
      contact_id:
        finContact.length > 0
          ? finContact[0]?.id
          : dataContractors?.contact?.value,
      requisites: {
        requisites: data.inn !== undefined ? reg : null,
        bank: dataBankRequisites?.length > 0 ? dataBankRequisites : null,
        contractors_id: dataContractors.id,
      },
      requisites_id: dataContractors?.requisites?.id,
      responsible_id: arrResponsible ? arrResponsible : Utils.getUserInfo().id,
    });
    if (onFinishEdit) {
      onFinishEdit({ ...dataContractors, ...data }, 'update');
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Flex justify={'space-between'} className={'drawer-header'}>
          <Flex gap={20} justify={'center'} align={'center'}>
            <div className={'drawer-title'}>{title}</div>
          </Flex>
        </Flex>
        <Flex className={'content--drawer'} gap={10}>
          <Flex vertical gap={8} style={{ width: '100%' }}>
            <Form.Item
              label="О компании"
              name="name_company"
              rules={[
                {
                  required: true,
                  message: 'Введите название компании',
                  whitespace: true,
                },
                {
                  message: t('Нельзя использовать спец символы и кавычки'),
                  whitespace: true,
                  validator: (_, val) => {
                    if (regNoQuotesSymbol.test(val)) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
                {
                  message: t('Название компании должно быть с заглавной буквы'),
                  whitespace: true,
                  validator: (_, val) => {
                    if (/^[A-ZА-ЯЁ]/.test(val)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  },
                },
              ]}
            >
              <Input
                placeholder="Название компании"
                defaultValue={dataContractors?.name_company}
              />
            </Form.Item>
            <Form.Item
              name="internal_company"
              rules={[
                {
                  required: true,
                  message: 'Введите короткое название компании',
                  whitespace: true,
                },
              ]}
            >
              <Input
                placeholder="Внутреннее название компании"
                defaultValue={dataContractors?.internal_company}
              />
            </Form.Item>
            <Form.Item
              name="type_company"
              rules={[{ required: true, message: 'Выберите тип компании' }]}
            >
              <Select
                defaultValue={dataContractors?.type_company || null}
                showSearch
                placeholder="Тип компании"
                options={dataTypeCompany}
                onChange={(e) => setTypeCompany(e)}
              />
            </Form.Item>
            <Form.Item
              name="type_source"
              rules={[{ required: true, message: 'Выберите источник' }]}
            >
              <Select
                defaultValue={dataContractors?.type_source || null}
                showSearch
                placeholder="Источник"
                options={dataSourceContact}
                onChange={(e) => setTypeSource(e)}
              />
            </Form.Item>

            {/* Телефон*/}
            <Flex gap={12}>
              <Form.Item
                name="phone_first"
                style={{ width: '70%' }}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <PhoneInput
                  defaultMask={''}
                  enableSearch
                  searchPlaceholder={'Поиск'}
                  searchNotFound={'ничего не нашлось'}
                  country={'ru'}
                  localization={ru}
                />
              </Form.Item>
              <Form.Item name="type_phone_first" style={{ width: '30%' }}>
                <Select
                  defaultValue={dataContractors?.type_phone_first || null}
                  placeholder="Рабочий"
                  showSearch
                  options={dataTypePhone}
                />
              </Form.Item>
              <Flex gap={5}>
                <Form.List name="last_phone">
                  {(_fields, { add }) => (
                    <>
                      <DeleteOutlined
                        style={{
                          fontSize: '16px',
                          color: '#D91528',
                          cursor: 'pointer',
                        }}
                      />
                      <PlusOutlined
                        style={{
                          fontSize: '16px',
                          color: '#00B288',
                          cursor: 'pointer',
                        }}
                        onClick={() => add()}
                      />
                    </>
                  )}
                </Form.List>
              </Flex>
            </Flex>
            <Form.List name="last_phone">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Flex gap={12} key={key}>
                      <Col style={{ width: '70%' }}>
                        <Form.Item {...restField} name={[name, 'add_phone']}>
                          <PhoneInput
                            defaultMask={''}
                            enableSearch
                            searchPlaceholder={'Поиск'}
                            searchNotFound={'ничего не нашлось'}
                            country={'ru'}
                            localization={ru}
                          />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '30%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_type_phone']}
                        >
                          <Select
                            placeholder="Рабочий"
                            showSearch
                            options={dataTypePhone}
                            optionFilterProp="children"
                          />
                        </Form.Item>
                      </Col>
                      <Flex gap={5}>
                        <DeleteOutlined
                          onClick={() => remove(name)}
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() => add()}
                        />
                      </Flex>
                    </Flex>
                  ))}
                </>
              )}
            </Form.List>

            {/* email*/}
            <Flex gap={12}>
              <Form.Item
                name="email_first"
                style={{ width: '70%' }}
                rules={[
                  {
                    required: true,
                    message: 'Обязательное поле!',
                    whitespace: true,
                  },
                ]}
              >
                {/* <div> */}
                <Input
                  placeholder="e-mail"
                  defaultValue={dataContractors?.email_first}
                />
                {/* </div> */}
              </Form.Item>
              <Form.Item name="type_email_first" style={{ width: '30%' }}>
                <Select
                  defaultValue={dataContractors?.type_email_first || null}
                  placeholder="Рабочий"
                  showSearch
                  options={dataTypeEmail}
                />
              </Form.Item>
              <Flex gap={5}>
                <Form.List name="last_email">
                  {(_fields, { add }) => (
                    <>
                      <DeleteOutlined
                        style={{
                          fontSize: '16px',
                          color: '#D91528',
                          cursor: 'pointer',
                        }}
                      />
                      <PlusOutlined
                        style={{
                          fontSize: '16px',
                          color: '#00B288',
                          cursor: 'pointer',
                        }}
                        onClick={() => add()}
                      />
                    </>
                  )}
                </Form.List>
              </Flex>
            </Flex>
            <Form.List name="last_email">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Flex gap={12} key={key}>
                      <Col style={{ width: '70%' }}>
                        <Form.Item {...restField} name={[name, 'add_email']}>
                          <Input placeholder="e-mail" />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '30%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_type_email']}
                        >
                          <Select
                            placeholder="Рабочий"
                            showSearch
                            options={dataTypeEmail}
                            optionFilterProp="children"
                          />
                        </Form.Item>
                      </Col>
                      <Flex gap={5}>
                        <DeleteOutlined
                          onClick={() => remove(name)}
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() => add()}
                        />
                      </Flex>
                    </Flex>
                  ))}
                </>
              )}
            </Form.List>

            {/* Мессенджер*/}
            <Flex gap={12}>
              <Form.Item name="messenger_first" style={{ width: '70%' }}>
                <Input
                  placeholder={'Мессенджер'}
                  defaultValue={dataContractors?.messenger_first}
                />
              </Form.Item>
              <Form.Item name="type_messenger_first" style={{ width: '30%' }}>
                <Select
                  placeholder="Рабочий"
                  showSearch
                  options={dataTypeMessenger}
                  defaultValue={dataContractors?.type_messenger_first}
                />
              </Form.Item>
              <Flex gap={5}>
                <Flex gap={5}>
                  <Form.List name="last_messenger">
                    {(_fields, { add }) => (
                      <>
                        <DeleteOutlined
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() => add()}
                        />
                      </>
                    )}
                  </Form.List>
                </Flex>
              </Flex>
            </Flex>
            <Form.List name="last_messenger">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Flex gap={12} key={key}>
                      <Col style={{ width: '70%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_messenger']}
                        >
                          <Input placeholder="Мессенджер" />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '30%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_type_messenger']}
                        >
                          <Select
                            placeholder="Рабочий"
                            showSearch
                            options={dataTypeMessenger}
                          />
                        </Form.Item>
                      </Col>
                      <Flex gap={5}>
                        <DeleteOutlined
                          onClick={() => remove(name)}
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() => add()}
                        />
                      </Flex>
                    </Flex>
                  ))}
                </>
              )}
            </Form.List>

            <Divider style={{ marginBottom: 6, marginTop: 6 }} />
            <Form.Item label={'Контакт'} name="contact_id">
              <AutoComplete
                showSearch
                allowClear
                value={valueContact}
                placeholder="Имя, телефон, или e-mail"
                onSearch={handleSearchContact}
                onSelect={handleSelectContact}
                options={(dataContact || []).map((d: any) => ({
                  value: d.label,
                  label: d.label,
                  id: d.value,
                  phone: d.contact_phone,
                  email: d.contact_email,
                }))}
              />
            </Form.Item>
            <Divider style={{ marginBottom: 6, marginTop: 6 }} />
            <Form.Item label={'Реквизиты'} name="address">
              <Select
                allowClear
                showSearch
                defaultValue={dataContractors?.address || null}
                placeholder="Адрес"
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={handleSearchCity}
                onChange={handleChangeCity}
                notFoundContent={null}
                options={(dataCity || []).map((d: any) => ({
                  value: d?.value,
                  label: d?.label,
                }))}
              />
            </Form.Item>
            <Form.Item name="inn">
              <AutoComplete
                allowClear
                value={valueInn}
                showSearch
                placeholder="Заполнить ИНН"
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={handleSearchInn}
                onChange={handleChangeInn}
                onSelect={handleSelectInn}
                notFoundContent={null}
                options={(dataInn || []).map((d: any) => ({
                  value: d?.value,
                  label: d?.label,
                }))}
              />
            </Form.Item>
            <Flex vertical align={'flex-end'} style={{ width: '100%' }}>
              <Text
                onClick={showDrawerInn}
                style={{ color: '#05F', cursor: 'pointer' }}
              >
                Подробно
              </Text>
            </Flex>
            <Divider style={{ marginBottom: 6, marginTop: 6 }} />
            <Flex gap={6} vertical>
              <Title level={4} style={{ marginBottom: 0 }}>
                Дополнительно
              </Title>
              <Text style={{ marginTop: 0 }}>Ответственный</Text>
              <Flex
                justify={'space-between'}
                align={'center'}
                style={{ paddingRight: 7 }}
              >
                <Flex gap={6} align={'center'}>
                  <Avatar size={28} icon={<UserOutlined />} />
                  <Form.Item name={'user_fio'}>
                    {!!responsible && (
                      <Text style={{ marginTop: 0, color: '#05F' }}>
                        {responsible}
                      </Text>
                    )}
                  </Form.Item>
                </Flex>
                <Text
                  style={{ marginTop: 0, color: '#05F', cursor: 'pointer' }}
                  onClick={showModal}
                >
                  {responsible ? t('Сменить') : t('Добавить')}
                </Text>
              </Flex>
            </Flex>
            <Flex vertical style={{ marginTop: 6 }}>
              <Title level={4} style={{ marginBottom: 6 }}>
                Комментарий
              </Title>
              <Form.Item name="comment">
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 20 }} />
              </Form.Item>
            </Flex>
            <Flex gap={8} style={{ marginTop: 10 }}>
              <Button
                type={'primary'}
                htmlType="submit"
                style={{ width: '70%' }}
              >
                Сохранить изменения
              </Button>
              <Button
                onClick={() => setConfirmation(true)}
                type={'text'}
                style={{ width: '30%', color: '#E14453' }}
              >
                Отменить
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Form>

      <DrawerComponent
        open={open}
        title={'Организация'}
        form={
          <FormRequisites
            reg={reg}
            setDataBankRequisites={setDataBankRequisites}
            bank={dataBankRequisites}
            // handleRequisites={handleRequisites}
            dataContractors={dataContractors}
            onClose={onClose}
            title={'Организация'}
          />
        }
      />
      <Modal
        title="Сменить ответственного"
        open={isModalOpen}
        onCancel={handleCancelModal}
        keyboard={false}
        footer={null}
      >
        <Flex vertical style={{ height: '50vh', padding: 20 }}>
          <SelectSearch
            type={'page'}
            defaults={searchValue}
            dataSource={dataExecutor}
            onChangeSelect={handleAddResponsible}
            dropdownRender={onNameChange}
            style={{ width: '100%' }}
            placeholder={'Ответственный'}
            hiddenBtn={false}
          />
          <Flex
            gap={8}
            style={{ position: 'absolute', bottom: 28, width: '100%' }}
          >
            <Button
              type={'primary'}
              style={{ width: '56%' }}
              onClick={addFormResponsible}
            >
              Добавить
            </Button>
            <Button
              type={'text'}
              style={{ width: '30%', color: '#E14453' }}
              onClick={() => setConfirmation(true)}
            >
              Отменить
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <ModalConfirm
        title={warningTitle}
        subtitle={subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={isModalOpen ? handleCancel : onCloseEdit}
      />
    </>
  );
};

export default FormEditContractors;
