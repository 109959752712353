import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import {RootState, useStoreDispatch} from '../../../../../store';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Divider,
  Button,
  Checkbox,
  SelectProps,
  Flex,
  Tooltip,
} from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { searchTerminalCity } from '../../../../../store/terminals';
import { purchaseColumns, subleaseColumns, ktkColumns } from "./columns";
import { IContainer } from '../../../../../store/releases';
import { getSizeKtk } from '../../../../releases/components/utils';
import { Utils } from '../../../../../utils';
import { findCompany } from '../../../../../store/contractors';
import { findContact, findExecutor } from '../../../../../store/contact';
import { ICurrency } from '../../../../../types';
import { SelectSearch } from '../../../../../components/ui/SelectSearch/SelectSearch';
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import styles from '../style.module.scss';
import CancelReasonModal from './CancelReasonModal';
import EditableTable from '../table/Table';

interface RequestTabProps {
  // form?: any;
  initialValue: any;
  typeForm?: string;
  dataRowContainer?: any;
  onFinish: (requestData: any, action: string) => void;
  isAgreed: boolean;
}

interface Executor {
  id: number;
  fio: string;
  [key: string]: any;
}

const dataExecutor: Executor[] = [];

const RequestTab: React.FC<RequestTabProps> = ({
  typeForm,
  initialValue,
  dataRowContainer,
  onFinish,
  isAgreed
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const dataTypeContainers = useSelector(
    (state: RootState) => state.types.dataTypeContainers,
  );
  const containers = Form.useWatch('containers', {
    form,
    preserve: true,
  }) as IContainer[];
  const dataTypeQuality = useSelector(
    (state: RootState) => state.types.dataTypeQuality,
  );
  const dataStatusContainer = useSelector(
    (state: RootState) => state.containers.dataStatusContainer,
  );
  const dataCurrency = useSelector(
    (state: RootState) => state.stores.dataCurrency,
  );

  const dispatch = useStoreDispatch();
  const [isLeftColumnActive, setIsLeftColumnActive] = useState(true);
  const [returnReason, setReturnReason] = useState('');
  const [selectedCompanies, setSelectedCompanies] = useState<string[] | null>(null);
  const [companyData, setCompanyData] = useState<SelectProps['options']>([]);
  const [changeCompany, setChangeCompany] = useState<string[]>([]);
  const [city, setCity] = useState<[]>([]);
  const [cityData, setDataCity] = useState<any>([]);
  const [searchCity, setSearchCity] = useState<string | null>(null);
  const [icons, setIcons] = useState<{ [key: string]: JSX.Element }>({});
  const [responsible, setResponsible] = useState(Utils.getUserInfo().fio);
  const [dataExecutor, setDataExecutor] = useState<[]>([]);
  const [responsibleChange, setResponsibleChange] = useState('');
  const [arrResponsible, setArrResponsible] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectedRowsPurchase, setSelectedRowsPurchase] = useState<Set<number>>(new Set());
  const [selectedRowsSublease, setSelectedRowsSublease] = useState<Set<number>>(new Set());
  const [selectedRowsKtk, setSelectedRowsKtk] = useState<Set<number>>(new Set());
  const [columnName, setColumnName] = useState<string>("");
  const [arHistory, setArHistory] = useState<any[]>([
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
    [
        {id: 0, meaning: null},
        {id: 1, meaning: null},
    ],
  ]);

  const handleOpenCancelReasonModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleSaveReasons = (reasons: string[]) => {
        setSelectedReasons(reasons);
    };

  const handleColumnActiveClick = async () => {
    try {
      await form.validateFields(["name", "legal_entity", "client", "days"]);

      setIsLeftColumnActive(false);
      console.log("Передача снабжению: поля успешно прошли валидацию.");
    } catch (error) {
      console.error("Ошибка валидации формы для передачи снабжению:", error);
    }
  };

  const handleReturnClick = async () => {
    try {
      await form.validateFields(["reason"]);

      setIsLeftColumnActive(true);
      console.log("Возврат аренде: причина указана.");
    } catch (error) {
      console.error("Ошибка валидации поля 'Причина':", error);
    }
  };

  const selectedType = (type?: string) => {
    const result = (containers || [])
      .filter((cont) => cont?.type_container_id === type)
      .map((item) => ({
        ...item,
        size: getSizeKtk(type),
        type_container: type,
        type_container_id: type,
      }));
    form.setFieldsValue({
      containers: result,
    });
  };

  const selectQualityContainer = (val: string) => {
    if (typeForm === 'update') {
        const isFind = [dataRowContainer].some(
            (item: any) => item['quality'] === val,
        );
        if (!isFind) {
            arHistory[2].map((item: any, index: number) => {
                if (index === 0) {
                    item.meaning = dataRowContainer.quality;
                    item.status_id = dataStatusContainer.find(
                        (item: any) => item.name === 'Состояние',
                    )?.id;
                    item.user_id = Utils.getUserInfo().id;
                    item.container_id = dataRowContainer?.id;
                } else {
                    item.meaning = val;
                    item.status_id = dataStatusContainer.find(
                        (item: any) => item.name === 'Состояние',
                    )?.id;
                    item.user_id = Utils.getUserInfo().id;
                    item.container_id = dataRowContainer?.id;
                }
            });
        } else {
            arHistory[2].map((item: any, index: number) => {
                if (index === 0) {
                    item.meaning = null;
                } else {
                    item.meaning = null;
                }
            });
        }
    }
  };

  const handleCurrency = (val: string, record: any) => {
    if (typeForm === 'update') {
        const isFind = [dataRowContainer].some(
            (item: any) => item['currency'] === val,
        );
        if (!isFind) {
            arHistory[6].map((item: any, index: number) => {
                if (index === 0) {
                    item.meaning = dataRowContainer.currency;
                    item.status_id = dataStatusContainer.find(
                        (item: any) => item.name === 'Валюта',
                    )?.id;
                    item.user_id = Utils.getUserInfo().id;
                    item.container_id = dataRowContainer?.id;
                } else {
                    item.meaning = val;
                    item.status_id = dataStatusContainer.find(
                        (item: any) => item.name === 'Валюта',
                    )?.id;
                    item.user_id = Utils.getUserInfo().id;
                    item.container_id = dataRowContainer?.id;
                }
            });
        } else {
            arHistory[6].map((item: any, index: number) => {
                if (index === 0) {
                    item.meaning = null;
                } else {
                    item.meaning = null;
                }
            });
        }
    }
  };

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCity(event.target.value.toLocaleLowerCase())
    if (event.target.value === '') {
      setSearchCity(null)
    }
    dispatch(searchTerminalCity(event.target.value.toLocaleLowerCase())).then(response => {
      setCity(response.payload)
    })
  };

  const changePerson = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const result: any = await dispatch(findExecutor(event.target.value));
    if (result.payload.length > 0) {
        setDataExecutor(result.payload)
    }
  };

  // const onResponsibleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const result: any = await dispatch(findExecutor(event.target.value));
  //   if (result.payload.length > 0) {
  //     setDataExecutor(result.payload);
  //   }
  // };

  const handleSearchCompany = async (val: string) => {
    const result: any = await dispatch(findCompany(val.toLocaleLowerCase()));
    if (result.payload.length > 0) {
        setCompanyData(result.payload)
    }
  };

  const handleChangeCompany = (data: any) =>{
      setChangeCompany(data)
  }

  const onChangeReleaseLocation = (e: number, option: any) => {
    const value = option?.children || option?.label;
    form.setFieldsValue({ release_location: value });
  };

  const onChangeReturnLocation = (e: number, option: any) => {
      const value = option?.children || option?.label;
      form.setFieldsValue({ return_location: value });
  };

  const onChangeRequestAuthor = (e: number, option: any) => {
    let fio = "";

    if (option?.children?.props?.children) {
      fio = option.children.props.children;
    } else if (option?.label) {
      fio = option.label;
    }

    form.setFieldsValue({ request_author: fio });
  };

  const onChangeSupplyDepartment = (e: number, option: any) => {
    let fio = "";

    if (option?.children?.props?.children) {
      fio = option.children.props.children;
    } else if (option?.label) {
      fio = option.label;
    }
    form.setFieldsValue({ supply_department: fio });
  };

  const onChangeDirector = (e: number, option: any) => {
    let fio = "";

    if (option?.children?.props?.children) {
      fio = option.children.props.children;
    } else if (option?.label) {
      fio = option.label;
    }
    form.setFieldsValue({ director: fio });
  };

  const onChangeOperationalDepartment = (e: number, option: any) => {
    let fio = "";

    if (option?.children?.props?.children) {
      fio = option.children.props.children;
    } else if (option?.label) {
      fio = option.label;
    }
    form.setFieldsValue({ operational_department: fio });
  };

//ообрабатываем отмеченные строчки по каждой таблицк
  const handleRowSelectPurchase = (index: number, selected: boolean) => {
    const updated = new Set(selectedRowsPurchase);
    if (selected) {
      updated.add(index);
    } else {
      updated.delete(index);
    }
    setSelectedRowsPurchase(updated);
  };

  const handleRowSelectSublease = (index: number, selected: boolean) => {
    const updated = new Set(selectedRowsSublease);
    if (selected) {
      updated.add(index);
    } else {
      updated.delete(index);
    }
    setSelectedRowsSublease(updated);
  };

const handleRowSelectKtk = (index: number, selected: boolean) => {
  const updated = new Set(selectedRowsKtk);
  if (selected) {
    updated.add(index);
  } else {
    updated.delete(index);
  }
  setSelectedRowsKtk(updated);
};

const onFinishRequest = (requestData: any) => {
  const allData = form.getFieldsValue(true);
  const { containers, ...filteredData } = allData;
  const selectedPurchase = Array.from(selectedRowsPurchase)
        .map((index) => filteredData["purchase"][index])
        .filter((row) => Object.values(row).some((value) => value !== "" && value !== null));

    const selectedSublease = Array.from(selectedRowsSublease)
        .map((index) => filteredData["sublease"][index])
        .filter((row) => Object.values(row).some((value) => value !== "" && value !== null));

    const selectedKtk = Array.from(selectedRowsKtk)
        .map((index) => filteredData["ktk"][index])
        .filter((row) => Object.values(row).some((value) => value !== "" && value !== null));

    const requestDataToSubmit = {
        ...filteredData,
        ...(selectedPurchase.length >= 0 && { purchase: selectedPurchase }),
        ...(selectedSublease.length >= 0 && { sublease: selectedSublease }),
        ...(selectedKtk.length >= 0 && { ktk: selectedKtk }),
    };

    localStorage.setItem("requestData", JSON.stringify(requestDataToSubmit));

  Object.assign(
    requestData, {
      name: allData.name,
      legal_entity: allData.legal_entity,
      client: allData.client,
      contract_number: allData.contract_number,
      release_location: allData.release_location,
      return_location: allData.return_location,
      rate: allData.rate,
      type: allData.type,
      quality: allData.quality,
      count_containers: allData.count_containers,
      currency: allData.currency,
      days: allData.days,
      SNP: allData.SNP,
      request_author: allData.request_author,
      supply_department: allData.supply_department,
      director: allData.director,
      operational_department: allData.operational_department,
      return_rent_reason: allData.reason,
      purchase__table: selectedPurchase,
      sublease__table: selectedSublease,
      ktk__table: selectedKtk,
    },
  )
  if (typeof onFinish === "function") {
        onFinish(requestDataToSubmit, "save");
    } else {
        console.error("onFinish не определен");
    }

    console.log("Сформированные данные для отправки:", requestDataToSubmit);
}

  const handleCheckboxChange = (type: string) => {
    if (type === "Аренда") {
      setColumnName("Субаренда");
    } else if (type === "Передислокация") {
      setColumnName("Передислокация");
    }
  };

  const resetCheckboxes = () => {
    setColumnName("   ");
  };

  useEffect(() => {
    if (responsible) {
      form.setFieldsValue({ request_author: responsible });
    }
  }, [responsible, form]);

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        // initialValues={initialValue}
        initialValues={{
          ...initialValue,
          request_author: responsible,
        }}
        className={styles.requestTab}
        autoComplete="off"
        style={{
          pointerEvents: isAgreed ? 'none' : 'auto',
          opacity: isAgreed ? 0.5 : 1,
        }}
      >
        <Button
          type="primary"
          // htmlType='submit'
          className={styles.sendButton}
          onClick={onFinishRequest}
        >
          Отправить на согласование
        </Button>
        <Form.Item name="name" label="Название" rules={[{ required: false }]}>
          <div className={styles.inputWithCheckbox}>
            <Input placeholder="Введите название" style={{ flex: 1 }}  className={styles.inputName}/>
            <Checkbox />
          </div>
        </Form.Item>
        <Row gutter={24}>
          <Col span={9}  style={{ pointerEvents: isLeftColumnActive ? 'auto' : 'none' }}>
            <Form.Item name="legal_entity" label="Наше юридическое лицо" rules={[{ required: true, message: "Обязательное поле!", whitespace: true }]}>
              <Input placeholder="Введите название юридического лица" />
            </Form.Item>

            <Form.Item label="Клиент" name="client"
                rules={[{required: true, message: 'Обязательное поле!'}]}>
                <Select
                  value={selectedCompanies}
                  showSearch
                  placeholder={'Название компании'}
                  // style={props.style}
                  // mode="multiple"
                  filterOption={false}
                  onSearch={handleSearchCompany}
                  onChange={handleChangeCompany}
                  notFoundContent={null}
                  options={(companyData || []).map((d) => ({
                      value: d.value,
                      label: d.label,
                  }))}
                />
            </Form.Item>

            <Form.Item name="contract_number" label="№ Договора">
              <Input placeholder="Введите номер договора" />
            </Form.Item>

            <Form.Item
                name="release_location"
                label="Локация релиза"
                rules={[{ required: true, message: "Обязательное поле!" }]}
            >
                <SelectSearch
                    defaults={form.getFieldValue("release_location")}
                    dataSource={searchCity !== null ? city : cityData}
                    onChangeSelect={onChangeReleaseLocation}
                    dropdownRender={onNameChange}
                    placeholder={t("Введите локацию релиза")}
                    type={"page"}
                    style={{ width: 200 }}
                    hiddenBtn={false}
                />
            </Form.Item>

            <Row gutter={16} align="middle" style={{ position: 'relative' }}>
              <Col span={9}>
                <Form.Item
                    name="return_location"
                    label="Локация возврата"
                    rules={[{ required: true, message: "Обязательное поле!" }]}
                >
                  <SelectSearch
                    defaults={form.getFieldValue("return_location")}
                    dataSource={searchCity !== null ? city : cityData}
                    onChangeSelect={onChangeReturnLocation}
                    dropdownRender={onNameChange}
                    placeholder={t("Введите локацию возврата")}
                    type={"page"}
                    style={{ width: 200 }}
                    hiddenBtn={false}
                  />
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item name="rate" label="Ставка">
                  <Input placeholder="1200" />
                </Form.Item>
              </Col>
              <Col span={8} className={styles.checkboxesWrapper}>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleCheckboxChange("Аренда");
                    } else {
                      resetCheckboxes();
                    }
                  }}
                  checked={columnName === "Субаренда"}
                >
                  Аренда
                </Checkbox>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleCheckboxChange("Передислокация");
                    } else {
                      resetCheckboxes();
                    }
                  }}
                  checked={columnName === "Передислокация"}
                >
                  Передислокация
                </Checkbox>
              </Col>
            </Row>

            <Form.Item
                label={t('Тип КТК')}
                name="type"
                style={{ width: '100%' }}
              >
                {
                  <Select
                    allowClear
                    placeholder={t('Выберите тип')}
                    style={{ width: '100%' }}
                    options={dataTypeContainers}
                    onSelect={selectedType}
                  />
                }
              </Form.Item>

            <Form.Item name={'quality'}  label="Состояние">
              <Select
                  allowClear
                  options={dataTypeQuality}
                  onSelect={selectQualityContainer}
                  placeholder={'Состояние'}
                  style={{width: '100%'}}/>
            </Form.Item>

            <Form.Item name="count_containers" label="Количество КТК">
              <Input placeholder="Введите количество КТК" />
            </Form.Item>
              <Form.Item name={'currency'} label="Валюта">
                <Select style={{width: '100%'}} onChange={handleCurrency}
                        placeholder={'Валюта'} allowClear>
                    {dataCurrency?.map((item: ICurrency) => (
                        <Select.Option key={item.id} value={item.symbol}>
                            <Flex align={"center"}
                                  gap={5}>{icons[item.id]}{item.symbol} {item.value}</Flex>
                        </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item name="days" label="Количество дней*" rules={[{ required: true, message: "Обязательное поле!", whitespace: true }]}>
                <Input placeholder="Введите количество дней" />
              </Form.Item>

              <Form.Item name="SNP" label="СНП">
                <Input/>
              </Form.Item>

              <Form.Item name="request_author" label="Автор заявки">
                  <SelectSearch
                    defaults={responsible}
                    notFoundContent="Пользователь не найден."
                    dataSource={dataExecutor}
                    onChangeSelect={onChangeRequestAuthor}
                    dropdownRender={changePerson}
                    style={{ width: '100%' }}
                    placeholder="Ответственный"
                  />
              </Form.Item>

              <Form.Item name="supply_department " label="Сотрудник отдела снабжения">
                <SelectSearch
                  defaults={responsibleChange}
                  notFoundContent={'Пользователь не найден.'}
                  dataSource={dataExecutor}
                  onChangeSelect={onChangeSupplyDepartment}
                  dropdownRender={changePerson}
                  style={{width: '100%'}}
                  placeholder={''}
                />
               </Form.Item>

              <Form.Item name="director" label="Директор для согласования">
                <SelectSearch
                  defaults={responsibleChange}
                  notFoundContent={'Пользователь не найден.'}
                  dataSource={dataExecutor}
                  onChangeSelect={onChangeDirector}
                  dropdownRender={changePerson}
                  style={{width: '100%'}}
                  placeholder={''}
                />
              </Form.Item>

              <Form.Item name="operational_department" label="Сотрудник оперативного отдела">
                <SelectSearch
                  defaults={responsibleChange}
                  notFoundContent={'Пользователь не найден.'}
                  dataSource={dataExecutor}
                  onChangeSelect={onChangeOperationalDepartment}
                  dropdownRender={changePerson}
                  style={{width: '100%'}}
                  placeholder={''}
                />
              </Form.Item>

              <Row justify="space-between">
                <Col style={{display: 'flex'}}>
                  <Button
                    type="default"
                    onClick={handleColumnActiveClick}
                    style={{height: '36px'}}
                  >
                    Передать снабжению
                  </Button>
                  <Button
                    type="link"
                    danger
                    onClick={handleOpenCancelReasonModal}
                  >
                    Отменить запрос
                  </Button>
                  {selectedReasons.length > 0 && (
                    <Tooltip
                      title={
                        <div>
                          {selectedReasons.map((reason, index) => (
                            <div key={index}>{reason}</div>
                          ))}
                        </div>
                      }
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                    </Tooltip>
                  )}
                  <CancelReasonModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onSave={handleSaveReasons}
                  />
                </Col>
              </Row>
          </Col>

          <Col span={15} style={{ pointerEvents: isLeftColumnActive ? 'none' : 'auto', display: 'flex', flexDirection: 'column' }}>
          <Divider className={styles.RequestTablesDivider}>Закуп</Divider>
          <Form.List name="purchase" initialValue={Array(7).fill({})}>
            {(fields, operation) => (
                <EditableTable
                  fields={fields}
                  // operation={operation}
                  columns={purchaseColumns}
                  rowCount={fields.length}
                  onAddRow={() => operation.add({})}
                  columnWidths="15% 11% 9% 13% 15% 17% 20%"
                  selectedRows={selectedRowsPurchase}
                  onRowSelect={handleRowSelectPurchase}
                />
            )}
          </Form.List>

          <Divider className={styles.RequestTablesDivider}>{columnName}</Divider>
          <Form.List name="sublease" initialValue={Array(7).fill({})}>
            {(fields, operation) => (
              // <>
                <EditableTable
                  fields={fields}
                  // operation={operation}
                  columns={subleaseColumns}
                  rowCount={fields.length}
                  onAddRow={() => operation.add({})}
                  columnWidths="15% 11% 8% 13% 11% 16% 11% 7% 8%"
                  selectedRows={selectedRowsSublease}
                  onRowSelect={handleRowSelectSublease}
                />
            )}
          </Form.List>

          <Divider className={styles.RequestTablesDivider}>КТК МК</Divider>
          <Form.List name="ktk" initialValue={Array(7).fill({})}>
            {(fields, operation) => (
              // <>
                <EditableTable
                  fields={fields}
                  // operation={operation}
                  columns={ktkColumns}
                  rowCount={fields.length}
                  onAddRow={() => operation.add({})}
                  columnWidths="14% 11% 11% 13% 15% 19% 17%"
                  selectedRows={selectedRowsKtk}
                  onRowSelect={handleRowSelectKtk}
                />
            )}
          </Form.List>

            <div className={styles.Return}>
              <div className='returnContainer'>
                <p>Вернуть аренде</p>
                <Button
                  className='reset'
                  icon={<ReloadOutlined />}
                  style={{ marginRight: "16px" }}
                  onClick={handleReturnClick}
                  >
                </Button>
              </div>
              <Form.Item name="reason" label="Причина" rules={[{ required: true, message: "Обязательное поле!", whitespace: true }]}>
                <Input.TextArea
                  placeholder="Введите причину"
                  value={returnReason}
                  onChange={(e) => setReturnReason(e.target.value)}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>

      </Form>
    </>
  );
};

export default RequestTab;







