import React, {useEffect} from 'react';

interface FullScreenModalProps {
    isOpen: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
}


const FullScreen: React.FC<FullScreenModalProps> = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                if (onClose) {
                    onClose();
                }
            }
        };

        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, onClose]);

    if (!isOpen) {
        return null;
    }

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            overflow: 'auto',
            backgroundColor: '#4B4B4B',
            zIndex: 1000
        }}>
            {children}
        </div>
    );
}
export default FullScreen;
