import {Button, Flex, Form, Input, notification, Typography} from "antd";
import React, {createContext, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {RouteNames} from "../../router/names";
import {RootState, useStoreDispatch} from "../../store";
import {resetPassword} from "../../store/users";
import s from './ForgetLogin.module.scss';
import {EmailInputIcon} from '../../assets/image/svg';
import {Utils} from "../../utils";

const Context = createContext({name: 'Default'});

const ForgetLogin = () => {
    const {usersData}: any = useSelector((state: RootState) => state.users.usersData);
    const dispatch = useStoreDispatch()
    const router = useHistory()
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (data: any) => {
        setLoading(true)
        dispatch(resetPassword(data.email)).then(response => {
            if (JSON.parse(response.payload).success === 0) {
                Utils.ErrorOpenNotification('topRight', null,
                    JSON.parse(response.payload).message,
                    JSON.parse(response.payload).description, api);
                setLoading(false)
            } else {
                setLoading(false)
                router.push({
                    pathname: RouteNames.LOGIN,
                    state: { param: JSON.parse(response.payload) }
                });
            }
        })
    }

    const contextValue = useMemo(() => ({name: ''}), []);
    const clickLogin = () => {
        router.push(RouteNames.LOGIN)
    }
    return (
        <Context.Provider value={contextValue}>
            {contextHolder}
            <Flex align={'center'} justify={"center"} vertical>
                <h1>Забыли пароль?</h1>
                <Form onFinish={onFinish} layout="vertical" style={{width: '100%'}}>
                    <Form.Item
                        name="email"
                        className={s.smallStep}
                        rules={[{
                            type: 'email', message: 'Введенный адрес электронной почты неверен!'
                        },
                            {
                                required: true, message: 'Пожалуйста, введите свой адрес электронной почты!'
                            }]}
                    >
                        <Input placeholder="e-mail" prefix={<EmailInputIcon/>}/>
                    </Form.Item>
                    <Form.Item name="button" className={s.bigStep}>
                        <Flex align={'center'} vertical gap={20}>
                            <Button type="primary" block htmlType="submit" loading={loading}>
                                Отправить ссылку
                            </Button>
                            <p onClick={clickLogin} className={"ahref"}>или войдите</p>
                        </Flex>
                    </Form.Item>
                </Form>
            </Flex>
        </Context.Provider>
    );
};

export default ForgetLogin;
