// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_scrollableDiv__O3rrw {
  scrollbar-width: auto !important;
}
.style_scrollableDiv__O3rrw::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.style_scrollableDiv__O3rrw::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.style_scrollableDiv__O3rrw::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/ScrollList/style.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF;AAAE;EACE,UAAA;EACA,WAAA;AAEJ;AACE;EACE,mBAAA;AACJ;AAEE;EACE,gBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".scrollableDiv {\n  scrollbar-width: auto !important;\n  &::-webkit-scrollbar {\n    width: 6px;\n    height: 6px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: #f1f1f1;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: #888;\n    border-radius: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollableDiv": `style_scrollableDiv__O3rrw`
};
export default ___CSS_LOADER_EXPORT___;
