import React, { useEffect, useState } from 'react';
import {
  AutoComplete,
  Avatar,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Typography,
  Button,
} from 'antd';
import { DeleteOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import DrawerComponent from '../../components/DrawerComponent';
import { Utils } from '../../utils';
import FormRequisites from './formRequisites';
import './style.scss';
import { RootState, useStoreDispatch } from '../../store';
import { CloseOutlined } from '@ant-design/icons/lib';
import { findContact, findExecutor } from '../../store/contact';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { SelectSearch } from '../../components/ui/SelectSearch/SelectSearch';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { EmailIcon, WatsappIcon } from '../../assets/image/svg';
import ru from 'react-phone-input-2/lang/ru.json';
import ModalConfirm from '../../components/ui/ModalConfirm/ModalConfirm';
import { messagesModal } from '../../constants';
import { useTranslation } from 'react-i18next';
import { regNoQuotesSymbol } from '../../helpers/input-helpers';

const { title: warningTitle, subtitle } = messagesModal.warning;

const { Title } = Typography;
const { Text } = Typography;

interface DataType {
  key: React.Key;
  name: string;
  meaning: string;
}

const FormContractors = (props: any) => {
  const { t } = useTranslation();
  const dataSourceContact = useSelector(
    (state: RootState) => state.contact.dataSourceContact,
  );
  const dataTypeCompany = useSelector(
    (state: RootState) => state.types.dataTypeCompany,
  );
  const dataTypePhone = useSelector(
    (state: RootState) => state.types.dataTypePhone,
  );
  const dataTypeEmail = useSelector(
    (state: RootState) => state.types.dataTypeEmail,
  );
  const dataTypeMessenger = useSelector(
    (state: RootState) => state.types.dataTypeMessenger,
  );

  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const dispatch = useStoreDispatch();
  const [open, setOpen] = useState(false);
  const [dataExecutor, setDataExecutor] = useState<[]>([]);
  const [dataContact, setContact] = useState<any>([]);
  const [dataCity, setCity] = useState<any>([]);
  const [valueContact, setValueContact] = useState<any>({});
  const [valueCity, setValueCity] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [responsible, setResponsible] = useState(Utils.getUserInfo().fio);
  const [arrResponsible, setArrResponsible] = useState<any>([]);
  const [typeCompany, setTypeCompany] = useState<any>([]);
  const [typeSource, setTypeSource] = useState<any>([]);
  const [dataInn, setDataInn] = useState<any>([]);
  const [detailInn, setDetailInn] = useState<boolean>(false);
  const [reg, setReg] = useState<any>([]);
  const [valueInn, setValueInn] = useState<string>('');
  const [finContact, setFinContact] = useState<any>([]);
  const [dataBankRequisites, setDataBankRequisites] = useState<[]>([]);
  const [responsibleChange, setResponsibleChange] = useState<any>(() => null);
  const [searchValue, setSearchValue] = React.useState(null);

  const showDrawer = () => {
    if (detailInn) {
      setOpen(true);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSearchInn = async (val: any) => {
    // Проверка, состоит ли значение только из цифр
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(val) || val === '' || val === '-') {
      const arResult = await Utils.requisites(val);
      if (JSON.parse(arResult).suggestions.length > 0) {
        let address = JSON.parse(arResult)?.suggestions[0]?.data?.address?.data;
        const nameInn = JSON.parse(arResult)?.suggestions[0]?.value;
        const addressShort =
          'г.' +
          address.city +
          ' ' +
          address?.street_with_type +
          ' ' +
          'д.' +
          address?.house;
        setDataInn([
          {
            value: nameInn + ', ' + addressShort,
            label: nameInn + ' ' + addressShort,
          },
        ]);
        await Utils.requisitesBank(val);
        await funStorage(JSON.parse(arResult)).then((res) => {
          setReg(res);
          props.setDataRequisites(res);
          setDetailInn(true);
        });
      }
    } else {
      //  setValueInn('');
    }
  };

  const handleChangeInn = (value: string) => {
    // Проверка, состоит ли значение только из цифр
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value) || value === '' || value === '-') {
      setValueInn(value);
    } else {
      setValueInn('');
    }
  };

  const handleSelectInn = (value: string) => {
    setValueInn(value);
  };

  const funStorage = async (data: any) => {
    return new Promise(async (resolve) => {
      const arResult: DataType[] = [];
      data?.suggestions?.forEach((el: any) => {
        let address = el?.data?.address?.data;
        arResult.push({ key: 1, name: 'Компания', meaning: el?.value });
        arResult.push({
          key: 2,
          name: 'Полное наименование организации',
          meaning: el?.data?.name?.full_with_opf,
        });
        arResult.push({ key: 3, name: 'ИНН', meaning: el?.data?.inn });
        arResult.push({ key: 4, name: 'КПП', meaning: el?.data?.kpp });
        arResult.push({
          key: 5,
          name: 'ОГРН (Дата)',
          meaning:
            el?.data?.ogrn +
            ' (' +
            moment(el?.data?.ogrn_date).format('DD.MM.YYYY') +
            ')',
        });
        arResult.push({
          key: 6,
          name: 'Адрес',
          meaning: el?.data?.address.value,
        });
        arResult.push({
          key: 7,
          name: 'Дата государственной регистрации',
          meaning: moment(el?.data?.state?.registration_date).format(
            'DD.MM.YYYY',
          ),
        });
        arResult.push({ key: 8, name: 'ОКПО', meaning: el?.data?.okpo });
        arResult.push({ key: 9, name: 'ОКТМО', meaning: el?.data?.oktmo });
        arResult.push({
          key: 10,
          name: 'Ген. директор',
          meaning: el?.data?.management?.name,
        });
        arResult.push({
          key: 11,
          name: 'Короткий адрес',
          meaning:
            'г.' +
            address.city +
            ' ' +
            address?.street_with_type +
            ' ' +
            'д.' +
            address?.house,
        });
      });
      resolve(arResult);
    });
  };

  const handleSelectContact = (val: string, record: any) => {
    setFinContact([record]);
    setValueContact(record.label);
  };

  const handleSearchContact = async (value: string) => {
    if (value.length > 2) {
      const result: any = await dispatch(findContact(value));
      const jsResult = JSON.parse(result.payload);
      if (jsResult.success > 0) {
        setContact(jsResult.data);
      }
    }
  };

  const onFinishContractors = (data: any) => {
    Object.assign(data, {
      responsible_id:
        arrResponsible.value !== undefined
          ? arrResponsible.value
          : Utils.getUserInfo().id,
      type_company: typeCompany,
      type_source: typeSource,
      contact_id: finContact.length > 0 ? finContact[0]?.id : null,
      requisites: reg,
      bank: dataBankRequisites?.length > 0 ? dataBankRequisites : null,
    });
    props.onFinish(data);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const result: any = await dispatch(findExecutor(event.target.value));
    if (result.payload.length > 0) {
      setDataExecutor(result.payload);
    }
  };

  const changePerson = (e: number, option: any) => {
    setResponsibleChange(option.children.props.children);
    setArrResponsible(option);
  };

  //   const addFormResponsible = () => {
  //     setResponsible(responsibleChange);
  //     setIsModalOpen(false);
  //   };

  const addFormResponsible = () => {
    setResponsible(responsibleChange.name);
    setIsModalOpen(false);
    setResponsibleChange(null);
    setSearchValue(null);
  };

  const handleAddResponsible = (e: any, option: any) => {
    const resp = {
      avatar: option.avatar,
      name: option.children,
      id: option.value,
    };
    setResponsibleChange(resp);
    // setAccountableId(resp.id);
    setDataExecutor([]);
    setSearchValue(option.children);
  };

  const handleSearchCity = async (payload: any) => {
    if (payload !== '') {
      const arResult: any = [];
      const arResultYandex = await Utils.yandexCity(payload);
      await arResultYandex.response.GeoObjectCollection.featureMember.map(
        (item: any) => {
          const text =
            item?.GeoObject?.metaDataProperty?.GeocoderMetaData?.AddressDetails
              ?.Country?.AddressLine;
          if (text) {
            arResult.push({ value: text, label: text });
          }
        },
      );
      setCity(arResult);
    }
  };

  const handleChangeCity = async (payload: any) => {
    setValueCity(payload);
  };

  const handleContact = () => {
    setContact([]);
  };

  const handleRequisites = (data: []) => {
    if (props.setDataRequisites) {
      props.setDataRequisites(data);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={props.form}
        autoComplete="off"
        initialValues={{
          type_phone_first: dataTypePhone[0]?.label,
          type_email_first: dataTypeEmail[0]?.label,
          type_email: dataTypeEmail[0]?.label,
          type_messenger_first: dataTypeMessenger[0]?.label,
          type_messenger: dataTypeMessenger[0]?.label,
          contact_id: null,
        }}
        onFinish={onFinishContractors}
      >
        <Flex justify={'space-between'} className={'drawer-header'}>
          <Flex gap={20} justify={'center'} align={'center'}>
            <div className={'drawer-title'}>{props.title}</div>
          </Flex>
        </Flex>
        <Flex className={'content--drawer'} gap={10}>
          <Flex vertical gap={8} style={{ width: '100%' }}>
            <Form.Item
              label="О компании"
              name="name_company"
              rules={[
                {
                  required: true,
                  message: 'Введите название компании',
                  whitespace: true,
                },
                {
                  message: t('Нельзя использовать спец символы и кавычки'),
                  validator: (_, val) => {
                    if (regNoQuotesSymbol.test(val)) {
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input
                placeholder="Название компании"
                defaultValue={props?.dataContractors?.name_company}
              />
            </Form.Item>
            <Form.Item
              name="internal_company"
              rules={[
                {
                  required: true,
                  message: 'Введите короткое название компании',
                  whitespace: true,
                },
              ]}
            >
              <Input
                placeholder="Внутреннее название компании"
                defaultValue={props?.dataContractors?.internal_company}
              />
            </Form.Item>
            <Form.Item
              name="type_company"
              rules={[{ required: true, message: 'Выберите тип компании' }]}
            >
              <Select
                showSearch
                placeholder="Тип компании"
                options={dataTypeCompany}
                onChange={(e) => setTypeCompany(e)}
              />
            </Form.Item>
            <Form.Item
              name="type_source"
              rules={[{ required: true, message: 'Выберите источник' }]}
            >
              <Select
                showSearch
                placeholder="Источник"
                options={dataSourceContact}
                onChange={(e) => setTypeSource(e)}
              />
            </Form.Item>

            {/* Телефон*/}
            <Flex gap={12}>
              <Form.Item
                name="phone_first"
                style={{ width: '60%' }}
                rules={[{ required: true, message: 'Введите номер телефона' }]}
              >
                <PhoneInput
                  defaultMask={''}
                  enableSearch
                  searchPlaceholder={'Поиск'}
                  searchNotFound={'ничего не нашлось'}
                  country={'ru'}
                  localization={ru}
                />
              </Form.Item>
              <Form.Item name="type_phone_first" style={{ width: '40%' }}>
                <Select
                  defaultValue={props?.dataContacts?.type_phone_first}
                  placeholder="Рабочий"
                  showSearch
                  options={dataTypePhone}
                />
              </Form.Item>
              <Flex gap={5}>
                <Form.List name="last_phone">
                  {(_fields, { add }) => (
                    <>
                      <DeleteOutlined
                        style={{
                          fontSize: '16px',
                          color: '#D91528',
                          cursor: 'pointer',
                        }}
                      />
                      <PlusOutlined
                        style={{
                          fontSize: '16px',
                          color: '#00B288',
                          cursor: 'pointer',
                        }}
                        onClick={() => add()}
                      />
                    </>
                  )}
                </Form.List>
              </Flex>
            </Flex>
            <Form.List name="last_phone">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Flex gap={12} key={key}>
                      <Col style={{ width: '60%' }}>
                        <Form.Item {...restField} name={[name, 'add_phone']}>
                          <PhoneInput
                            defaultMask={''}
                            enableSearch
                            searchPlaceholder={'Поиск'}
                            searchNotFound={'ничего не нашлось'}
                            country={'ru'}
                            localization={ru}
                          />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '40%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_type_phone']}
                        >
                          <Select
                            placeholder="Рабочий"
                            showSearch
                            options={dataTypePhone}
                            optionFilterProp="children"
                          />
                        </Form.Item>
                      </Col>
                      <Flex gap={5}>
                        <DeleteOutlined
                          onClick={() => remove(name)}
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() => add()}
                        />
                      </Flex>
                    </Flex>
                  ))}
                </>
              )}
            </Form.List>

            {/* Емаил*/}
            <Flex gap={12}>
              <Form.Item
                name="email_first"
                style={{ width: '60%' }}
                rules={[
                  {
                    required: true,
                    message: 'Введите email',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  prefix={<EmailIcon />}
                  placeholder="e-mail"
                  defaultValue={props?.dataContacts?.email_first}
                />
              </Form.Item>
              <Form.Item name="type_email_first" style={{ width: '40%' }}>
                <Select
                  defaultValue={props?.dataContacts?.type_email_first}
                  placeholder="Рабочий"
                  showSearch
                  options={dataTypeEmail}
                />
              </Form.Item>
              <Flex gap={5}>
                <Form.List name="last_email">
                  {(_fields, { add }) => (
                    <>
                      <DeleteOutlined
                        style={{
                          fontSize: '16px',
                          color: '#D91528',
                          cursor: 'pointer',
                        }}
                      />
                      <PlusOutlined
                        style={{
                          fontSize: '16px',
                          color: '#00B288',
                          cursor: 'pointer',
                        }}
                        onClick={() => add()}
                      />
                    </>
                  )}
                </Form.List>
              </Flex>
            </Flex>
            <Form.List name="last_email">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Flex gap={12} key={key}>
                      <Col style={{ width: '60%' }}>
                        <Form.Item {...restField} name={[name, 'add_email']}>
                          <Input placeholder="e-mail" prefix={<EmailIcon />} />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '40%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_type_email']}
                        >
                          <Select
                            placeholder="Рабочий"
                            showSearch
                            options={dataTypeEmail}
                            optionFilterProp="children"
                          />
                        </Form.Item>
                      </Col>
                      <Flex gap={5}>
                        <DeleteOutlined
                          onClick={() => remove(name)}
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() => add()}
                        />
                      </Flex>
                    </Flex>
                  ))}
                </>
              )}
            </Form.List>

            {/* Мессенджер*/}
            <Flex gap={12}>
              <Form.Item name="messenger_first" style={{ width: '60%' }}>
                <Input
                  prefix={<WatsappIcon />}
                  placeholder={'Мессенджер'}
                  defaultValue={props?.dataContacts?.messenger_first}
                />
              </Form.Item>
              <Form.Item name="type_messenger_first" style={{ width: '40%' }}>
                <Select
                  placeholder="Рабочий"
                  showSearch
                  options={dataTypeMessenger}
                  defaultValue={props?.dataContacts?.type_messenger_first}
                />
              </Form.Item>
              <Flex gap={5}>
                <Flex gap={5}>
                  <Form.List name="last_messenger">
                    {(_fields, { add }) => (
                      <>
                        <DeleteOutlined
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() => add()}
                        />
                      </>
                    )}
                  </Form.List>
                </Flex>
              </Flex>
            </Flex>
            <Form.List name="last_messenger">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Flex gap={12} key={key}>
                      <Col style={{ width: '60%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_messenger']}
                        >
                          <Input
                            placeholder="Мессенджер"
                            prefix={<WatsappIcon />}
                          />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '40%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_type_messenger']}
                        >
                          <Select
                            placeholder="Рабочий"
                            showSearch
                            options={dataTypeMessenger}
                          />
                        </Form.Item>
                      </Col>
                      <Flex gap={5}>
                        <DeleteOutlined
                          onClick={() => remove(name)}
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() => add()}
                        />
                      </Flex>
                    </Flex>
                  ))}
                </>
              )}
            </Form.List>

            <Divider style={{ marginBottom: 6, marginTop: 6 }} />
            {/* Контакт*/}
            <Form.Item label={'Контакт'} name="contact_id">
              <AutoComplete
                showSearch
                allowClear
                value={valueContact}
                placeholder="Имя, телефон, или e-mail"
                onSearch={handleSearchContact}
                onSelect={handleSelectContact}
                onClick={handleContact}
                options={(dataContact || []).map((d: any) => ({
                  value: d?.label,
                  label: d?.label,
                  id: d?.value,
                }))}
              />
            </Form.Item>
            <Divider style={{ marginBottom: 6, marginTop: 6 }} />
            <Form.Item label={'Реквизиты'} name="address">
              <AutoComplete
                showSearch
                allowClear
                value={valueCity}
                placeholder="Адрес"
                onSearch={handleSearchCity}
                onChange={handleChangeCity}
                options={(dataCity || []).map((d: any) => ({
                  value: d?.value,
                  label: d?.label,
                }))}
              />
            </Form.Item>
            <Form.Item name={'inn_name'}>
              <div>
                <AutoComplete
                  allowClear
                  showSearch
                  value={valueInn}
                  placeholder="Заполнить ИНН"
                  onSearch={handleSearchInn}
                  onChange={handleChangeInn}
                  onSelect={handleSelectInn}
                  options={dataInn.map((d: any) => ({
                    value: d.value,
                    label: d.label,
                  }))}
                >
                  <Input />
                </AutoComplete>
              </div>
            </Form.Item>
            <Flex vertical align={'flex-end'} style={{ width: '100%' }}>
              <Text
                disabled={!detailInn}
                onClick={showDrawer}
                style={detailInn ? { color: '#05F', cursor: 'pointer' } : {}}
              >
                Подробно
              </Text>
            </Flex>
            <Divider style={{ marginBottom: 6, marginTop: 6 }} />
            <Flex gap={6} vertical>
              <Title level={4} style={{ marginBottom: 0 }}>
                Дополнительно
              </Title>
              <Text style={{ marginTop: 0 }}>Ответственный</Text>
              <Flex
                justify={'space-between'}
                align={'center'}
                style={{ paddingRight: 7 }}
              >
                <Flex gap={6} align={'center'}>
                  <Avatar size={28} icon={<UserOutlined />} />
                  <Text style={{ marginTop: 0, color: '#05F' }}>
                    {responsible}
                  </Text>
                </Flex>
                <Text
                  style={{ marginTop: 0, color: '#05F', cursor: 'pointer' }}
                  onClick={showModal}
                >
                  Сменить
                </Text>
              </Flex>
            </Flex>
            <Flex vertical style={{ marginTop: 6 }}>
              <Title level={4} style={{ marginBottom: 6 }}>
                Комментарий
              </Title>
              <Form.Item name="comment">
                <Input.TextArea
                  placeholder={'Введите комментарий'}
                  autoSize={{ minRows: 3, maxRows: 20 }}
                />
              </Form.Item>
            </Flex>
            <Flex gap={8} style={{ marginTop: 10 }}>
              <Button
                type={'primary'}
                htmlType="submit"
                style={{ width: '70%' }}
              >
                Добавить
              </Button>
              <Button
                onClick={() => setConfirmation(true)}
                type={'text'}
                style={{ width: '30%', color: '#E14453' }}
              >
                Отменить
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Form>
      {/* <DrawerComponent
        open={open}
        title={'Организация'}
        form={
          <FormRequisites
            reg={reg}
            dataContractors={props.dataContractors}
            handleRequisites={handleRequisites}
            setDataBankRequisites={setDataBankRequisites}
            onClose={onClose}
            title={'Организация'}
          />
        }
      /> */}
      <Modal
        title="Добавить ответственного"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" style={{ height: '50vh', padding: 20 }}>
          <SelectSearch
            type={'page'}
            defaults={searchValue}
            notFoundContent={'Пользователь не найден.'}
            dataSource={dataExecutor}
            // onChangeSelect={changePerson}
            onChangeSelect={handleAddResponsible}
            dropdownRender={onNameChange}
            style={{ width: '100%' }}
            placeholder={'Ответственный'}
          />
          <Flex
            gap={8}
            style={{ position: 'absolute', bottom: 28, width: '100%' }}
          >
            <Button
              type={'primary'}
              style={{ width: '56%' }}
              onClick={addFormResponsible}
            >
              Сменить
            </Button>
            <Button
              type={'text'}
              style={{ width: '30%', color: '#E14453' }}
              onClick={() => setConfirmation(true)}
            >
              Отменить
            </Button>
          </Flex>
        </Form>
      </Modal>
      <ModalConfirm
        title={warningTitle}
        subtitle={subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={isModalOpen ? handleCancel : props.onClose}
      />
    </>
  );
};

export default FormContractors;
