import { RouteNames } from "../../router/names";

const optionsBoolean = [
  {value: 1, label: 'Да'},
  {value: 0, label: 'Нет'},
];

const pathsCrm = {
  create: RouteNames.CRM_AGREEMENT_CREATE,
  edit: RouteNames.CRM_AGREEMENT_EDIT,
  view: RouteNames.CRM_AGREEMENT_VIEW,
  parent: RouteNames.CRM_AGREEMENT,
}

export {optionsBoolean, pathsCrm};