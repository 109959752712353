import {CSSProperties, FC, MouseEvent} from 'react';
import "./Button.scss"

export interface IButtonProps {
    style?: CSSProperties,
    size?: string,
    children?: string,
    onClick?: (e: MouseEvent) => void,
    icon?: JSX.Element,
    type?: string,
    [key: string]: any
}


const Button: FC<IButtonProps> = ({
    children,
    style,
    size,
    onClick,
    icon,
    type,
    ...props
}) => {
    return (

        <button {...props} className={'button '+ type } style={style} onClick={onClick}>
            {icon}{children}
        </button>
    );
};

export default Button;
