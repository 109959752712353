import dayjs from 'dayjs';

const disabledDateFromStartToEnd = (
  current: any,
  start: dayjs.ConfigType,
  end?: dayjs.ConfigType,
) => {
  const date = dayjs(start).subtract(1, 'day');
  if (current < dayjs(date)) {
    return true;
  }
  if (current > dayjs(end) && end) {
    return true;
  }
  return false;
};

const checksDatePeriod = (date: dayjs.Dayjs, day = 1) => {
  return dayjs(date).add(day, 'day') <= dayjs();
};
const checksDatePeriodComment = (date?: dayjs.Dayjs | string, day = 1) => {
  return !(dayjs(date).add(day, 'day') <= dayjs());
};
const disableDateAfter = (date?: dayjs.Dayjs) => {
  return dayjs(date) > dayjs();
};
const disableDateBefore = (dateStart: dayjs.Dayjs) => (date?: dayjs.Dayjs) => {
  return dayjs(date) < (dateStart ? dayjs(dateStart) : dayjs().add(-1, 'day'));
};

interface IGetData {
  date?: dayjs.Dayjs | string;
  formatDate?: string;
  format?: string;
}
const getDateString = ({date, formatDate, format = 'DD.MM.YYYY'}: IGetData) => {
  if (typeof date === 'string') {
    if (formatDate) {
      return date ? dayjs(date, formatDate)?.isValid() ? dayjs(date, formatDate).format(format) : '' : ''
    } return date ? dayjs(date)?.isValid() ? dayjs(date).format(format) : '' : ''
  } return date ? dayjs(date)?.isValid() ? dayjs(date).format(format) : '' : ''
};

export {
  checksDatePeriod,
  disabledDateFromStartToEnd,
  checksDatePeriodComment,
  disableDateAfter,
  disableDateBefore,
  getDateString,
};
