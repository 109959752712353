import {uniqBy} from 'lodash';

export const mapperSearchYandexLocation = ({ response }: any) => {
  return uniqBy(response?.GeoObjectCollection?.featureMember?.map(
    (item: any, i: number) => {
      const text =
        item?.GeoObject?.metaDataProperty?.GeocoderMetaData
          ?.AddressDetails?.Country?.AddressLine;
      return { value: text, label: text };
    }
  ), (item: { value: string, label: string }) => item?.value);
}