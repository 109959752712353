import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Rest_releases } from '../services/rest_releases';
import { IRelease } from '../pages/releases/types';
import { Rest_containers } from '../services/rest_containers';
import { IContainers } from '../types/containers';
import { Rest_terminal } from '../services/rest_terminal';

interface IReleasesState {
  releases: IRelease[];
  release?: IRelease;
  newContainer?: IContainers | null;
  loading: boolean;
  isContainerDrawer: boolean;
  count: number;
  page: number;
  currentPage: number;
  saveFiles: any[];
  terminals: any[];
}

export const defaultValues: IRelease = {
  release_id: '',
  release_generate_id: '',
  user_release_id: '',
  inn: '',
  company: '',
  containers_count: undefined,
  containers: [],
  date_action_start: null,
  date_action_start_string: '',
  date_action_end: null,
  date_action_end_string: '',
  terminal: '',
  terminal_id: null,
  contractor: '',
  responsible_id: null,
  status: undefined,
  responsible: '',
  history_ids: [],
  history: [],
  transporter: [
    {
      fio: '',
      phone: '',
      gos_number: '',
      series_passport: '',
      number_passport: '',
    },
  ],
  no_number: undefined,
  is_transporter: undefined,
  file_ids: [],
};

const initialState: IReleasesState = {
  releases: [],
  release: defaultValues,
  newContainer: undefined,
  saveFiles: [],
  loading: false,
  isContainerDrawer: false,
  count: 0,
  page: 20,
  currentPage: 0,
  terminals: [],
};

export const generateIdReleaseId = createAsyncThunk(
  'generateIdReleaseId',
  async (param?: any) => {
    return await Rest_releases.generateIdReleaseId(param);
  },
);
export const getFiltersReleases = createAsyncThunk(
  'getFiltersReleases',
  async (param?: any) => {
    return await Rest_releases.getFiltersReleases(param);
  },
);
export const getReleases = createAsyncThunk(
  'getReleases',
  async (param?: any) => {
    return await Rest_releases.getReleases(param);
  },
);

export const setRelease = createAsyncThunk(
  'setRelease',
  async (payload: any) => {
    return await Rest_releases.setRelease(payload);
  },
);
export const deleteRelease = createAsyncThunk(
  'deleteRelease',
  async (payload: any) => {
    return await Rest_releases.deleteRelease(payload);
  },
);
export const findOneRelease = createAsyncThunk(
  'findOneRelease',
  async (id: number) => {
    return await Rest_releases.findOneRelease(id);
  },
);
export const searchTerminalCityChild = createAsyncThunk(
  'searchTerminalCityChild',
  async (text: string) => {
    return await Rest_terminal.searchTerminalCityChild(text);
  },
);

export const setContainer = createAsyncThunk(
  'setContainer',
  async (payload: {}) => {
      return await Rest_containers.setContainer(payload)
  }
)

const releasesSlice = createSlice({
  name: 'releases',
  initialState,
  reducers: {
    resetState(state) {
     state = initialState;
    },
    resetContainer(state, action) {
      state.newContainer = action.payload;
    },
    setIsContainerDrawer(state, action) {
      state.isContainerDrawer = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setSaveFiles(state, action) {
      state.saveFiles = action.payload;
    },
    setTables(state, action) {
      state.releases = action.payload;
    },
    setOne(state, action) {
      const data = action.payload?.release ? {
        ...action.payload?.release,
        containers: action.payload.containers,
        history: action.payload.history,
      } : {};
      state.release = data;
    },
    setFilters(state, action) {
      state.releases = action.payload || [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReleases.fulfilled, (state, action) => {
      state.count = action.payload?.count;
      state.releases = action.payload?.rows;
      state.loading = false;
    });
    builder.addCase(getReleases.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getReleases.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getFiltersReleases.fulfilled, (state, action) => {
      state.count = action.payload?.count || 0;
      state.releases = action.payload?.rows || [];
      state.loading = false;
    });
    builder.addCase(getFiltersReleases.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFiltersReleases.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(findOneRelease.fulfilled, (state, action) => {
      const data = action.payload?.release ? {
        ...action.payload?.release,
        containers: action.payload.containers,
        history: action.payload.history,
      } : {};
      state.release = data;
      state.loading = false;
    });
    builder.addCase(findOneRelease.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(findOneRelease.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(searchTerminalCityChild.fulfilled, (state, action) => {
      state.terminals = action.payload;
    });
  },
});
export default releasesSlice.reducer;
export const {
  setLoading,
  setCount,
  setCurrentPage,
  setPage,
  setSaveFiles,
  setTables,
  setOne,
  setFilters,
  setIsContainerDrawer,
  resetContainer,
  resetState
} = releasesSlice.actions;
