import * as XLSX from "xlsx";

interface IRows {
  [key: string]: any;
}

interface IDownloadXlsx {
  type?: string;
  nameFile: string;
  nameList: string;
  nameColumns: string[];
  rows: IRows[];
}

const handleDownloadXlsx = ({nameFile, nameColumns, nameList, rows, type = 'xlsx'}: IDownloadXlsx) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(rows);

  // const colLengths = Object.keys(rows[0]).map((k) => k.toString().length)
  const colLengths = nameColumns.map((k) => k.toString().length)
  for (const d of rows) {
      Object.values(d).forEach((element, index) => {
          if (element !== undefined) {
              const length = element?.toString()?.length || 1;
              if (colLengths[index] < length) {
                  colLengths[index] = length
              }
          }
      })
  }
  worksheet["!cols"] = colLengths.map((l,index) => ({wch: l + 6}));

  XLSX.utils.book_append_sheet(workbook, worksheet, nameList);

  XLSX.utils.sheet_add_aoa(worksheet, [
    nameColumns,
  ]);
  if (type === 'xlsx') {
    XLSX.writeFile(workbook, `${nameFile}.xlsx`, {compression: true});
  }else {
    XLSX.writeFile(workbook, `${nameFile}.csv`, {compression: true});
  }
}

export {handleDownloadXlsx};
