import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import XHR from "i18next-http-backend";
import commonRu from "./locales/ru/common.json";
import commonEn from "./locales/en/common.json";

const resources = {
    en: {translation: commonEn},
    ru: {translation: commonRu},
};

const options = {
    order: ["querystring", "localStorage", "navigator"],
    caches: ["localStorage"],
    lookupQuerystring: "lng"
};
i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: options,
        resources,
        fallbackLng: "ru",
        supportedLngs: ["ru", "en"],
        interpolation: {
            escapeValue: false
        },
        debug: false
    });

export default i18n;
