import { Key, ReactNode, useEffect, useRef, useState } from 'react';

import { Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Icon from '@ant-design/icons';

import i18n from '../../i18n/i18n';
import { RootState } from '../../store';
import style from './style.module.scss';
import { useParamsHistory } from '../../hooks';
import { RouteNames } from '../../router/names';
import { depoIcon, financeIcon, logisticsIcon, rentIcon, saleIcon, userIcon } from '../../assets/image/svg';

const checkAddStringFromArray = (arrStr: string[], str: string) => {
  if (arrStr.includes(str)) {
    return arrStr;
  } else {
    return [...arrStr, str];
  }
};

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItem[] | null,
  style?: boolean | { display: string },
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    style,
    type,
  } as MenuItem;
}

const Menus = (props: any) => {
  const ref = useRef(null);
  const lang = useSelector((state: RootState) => state.stores.lang) as
    | 'ru'
    | 'en';
  const { t } = useTranslation();
  const { history, location } = useParamsHistory();
  const stateLocation = location?.state as { openKeys?: string[] | [] };
  const locationOpenKeys: string[] | [] = stateLocation?.openKeys || [];
  const { pathname } = location;
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>(() => locationOpenKeys);

  useEffect(() => {
    i18n.changeLanguage(lang.toLocaleLowerCase()).then((r) => r);
    const [_, current, sub] = pathname.split('/');

    switch (`/${current}`) {
      case RouteNames.CONTAINERS:
        setActiveKeys([RouteNames.DEPOT, RouteNames.CONTAINERS]);
        setOpenKeys((prev) => checkAddStringFromArray(prev, RouteNames.DEPOT));
        return;
      case RouteNames.TERMINALS:
        setActiveKeys([RouteNames.DEPOT, RouteNames.TERMINALS]);
        setOpenKeys((prev) => checkAddStringFromArray(prev, RouteNames.DEPOT));
        return;
      case RouteNames.RELEASES:
        setActiveKeys([RouteNames.DEPOT, RouteNames.RELEASES]);
        setOpenKeys((prev) => checkAddStringFromArray(prev, RouteNames.DEPOT));
        return;
      case RouteNames.DEPOT:
        setActiveKeys([RouteNames.DEPOT]);
        setOpenKeys((prev) => checkAddStringFromArray(prev, RouteNames.DEPOT));
        return;
      case RouteNames.SALES:
        setOpenKeys((prev) => checkAddStringFromArray(prev, RouteNames.SALES));
        switch (`${RouteNames.SALES}/${sub}`) {
          case RouteNames.SALES_DEAL:
            return setActiveKeys([RouteNames.SALES, RouteNames.SALES_DEAL]);
        }
        return;
      case RouteNames.RENT:
        setOpenKeys((prev) => checkAddStringFromArray(prev, RouteNames.RENT));
        switch (`${RouteNames.RENT}/${sub}`) {
          case RouteNames.RENT_DEAL:
            return setActiveKeys([RouteNames.RENT, RouteNames.RENT_DEAL]);
        }
        return;
      case '/':
      case RouteNames.CRM:
        setActiveKeys([RouteNames.CRM]);
        return;
      case '/logistics':
        setActiveKeys(['logistics']);
        return;
      case '/finance':
        setActiveKeys(['finance']);
        return;
    }
  }, [pathname]);

  const handleClickMenu = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const items: MenuProps['items'] = [
    getItem(
      <Link
        className={style.menu__link}
        to={RouteNames.CRM}
        onClick={(evt) => evt.preventDefault()}
      >
        CRM
      </Link>,
      RouteNames.CRM,
      <Icon component={userIcon} />,
      null,
      !props.isCompanys && { display: 'none' },
    ),
    getItem(
      t('Депо'),
      RouteNames.DEPOT,
      <Icon
        data-link
        onClick={(e) => {
          history.replace(RouteNames.DEPOT);
        }}
        component={depoIcon}
      />,
      [
        getItem(
          <Link
            className={style.menu__link}
            to={RouteNames.TERMINALS}
            onClick={(evt) => evt.preventDefault()}
          >
            {t('Терминалы')}
          </Link>,
          RouteNames.TERMINALS,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
        getItem(
          <Link
            className={style.menu__link}
            to={RouteNames.CONTAINERS}
            onClick={(evt) => evt.preventDefault()}
          >
            {t('Контейнеры')}
          </Link>,
          RouteNames.CONTAINERS,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
        getItem(
          <Link
            className={style.menu__link}
            to={RouteNames.RELEASES}
            onClick={(evt) => evt.preventDefault()}
          >
            {t('Релизы')}
          </Link>,
          RouteNames.RELEASES,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
      ],
      !props.isStaff && { display: 'none' },
    ),
    getItem(
      t('Продажи'),
      RouteNames.SALES,
      <Icon component={saleIcon} />,
      [
        getItem(
          <Link
            className={style.menu__link}
            to={RouteNames.SALES_DEAL}
            onClick={(evt) => evt.preventDefault()}
          >
            {t('Сделки')}
          </Link>,
          RouteNames.SALES_DEAL,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
      ],
      !props.isStaff && { display: 'none' },
    ),
    getItem(
      t('Rent'),
      RouteNames.RENT,
      <Icon component={rentIcon} />,
      [
        getItem(
          <Link
            className={style.menu__link}
            to={RouteNames.RENT_DEAL}
            onClick={(evt) => evt.preventDefault()}
          >
            {t('Сделки')}
          </Link>,
          RouteNames.RENT_DEAL,
          null,
          null,
          !props.isStaff && { display: 'none' },
        ),
      ],
      !props.isStaff && { display: 'none' },
    ),
    getItem(
      t('Логистика'),
      '/logistics',
      <Icon component={logisticsIcon} />,
      null,
      !props.isStaff && { display: 'none' },
    ),
    getItem(
      t('Финансы'),
      '/finance',
      <Icon component={financeIcon} />,
      null,
      !props.isStaff && { display: 'none' },
    ),
  ];

  return (
    <Menu
      ref={ref}
      className={style.menu}
      mode="inline"
      selectedKeys={activeKeys}
      openKeys={openKeys}
      items={items}
      onClick={(e) => props.onClick(e, openKeys)}
      onOpenChange={handleClickMenu}
    />
  );
};

export default Menus;
