import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { App, Form, Modal, notification, Space } from 'antd';

import { columns } from './columns';
import style from '../style.module.scss';
import { filtersReleases } from '../../../store/filters';
import { RootState, useStoreDispatch } from '../../../store';
import { useCsvExport, useParamsHistory } from '../../../hooks';
import {
  IColumnType,
  IPagination,
  Table,
} from '../../../components/ui/Table/Table';
import {
  setCount,
  setCurrentPage,
  setPage,
  setTables,
  getReleases,
  setOne,
  getFiltersReleases,
  deleteRelease,
  findOneRelease,
  setIsContainerDrawer,
  resetContainer,
  resetState,
  defaultValues,
} from '../../../store/releases';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import socket from '../../../socket';
import { IRelease } from '../../../store/releases';
import {
  CREATE,
  EDIT,
  getTypeForm,
  VIEW,
  HISTORY,
} from '../../../helpers/string-helpers';
import { FormRelease } from './FormRelease';
import FormContainer from '../../containers/formContainer';
import { setContainer } from '../../../store/containers';
import { ViewRelease } from './ViewRelease';
import {
  DELETE_RELEASE,
  FIND_ONE_RELEASE,
  GET_FILTERS_RELEASES,
  GET_RELEASES,
} from '../../../services/rest_releases';
import { HistoryReleases } from './HistoryReleases';
import { FormDeal } from '../../sales/deals/components';
import { PdfReleaseEN, PdfReleaseRU } from './pdf';
import { defaultValuesDeal } from '../../../store/sales';
import { Utils } from '../../../utils';
import { IContainers } from '../../../types/containers';
import { useTranslation } from 'react-i18next';

const TABLE_RELEASE = 'tableRelease';
const ROW_RELEASE = 'rowRelease';
const TOTAL_RELEASE = 'totalRelease';
const DATA_RELEASE = 'dataRelease';

interface ITableReleases {
  paths: { create: string; parent: string; edit: string; view: string };
}

export const TableReleases: React.FC<ITableReleases> = ({ paths }) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { history, location } = useParamsHistory();
  const dispatch = useStoreDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [typeForm, setTypeForm] = useState(EDIT);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isCreateDeal, setIsCreateDeal] = useState(false);
  const [containersDeal, setContainersDeal] = useState<any[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [lang, setLang] = useState<'ru' | 'en'>('ru');
  const { pathname } = location;
  const data = useSelector<RootState>(
    (state) => state.releases.releases,
  ) as IRelease[];
  const release = useSelector<RootState>(
    (state) => state.releases.release,
  ) as IRelease;
  const loading = useSelector<RootState>(
    (state) => state.releases.loading,
  ) as boolean;
  const isContainerDrawer = useSelector<RootState>(
    (state) => state.releases.isContainerDrawer,
  ) as boolean;
  const newContainer = useSelector(
    (state: RootState) => state.releases.newContainer as IContainers,
  );
  const page = useSelector<RootState>((state) => state.releases.page) as number;
  const count = useSelector<RootState>(
    (state) => state.releases.count,
  ) as number;
  const current = useSelector<RootState>(
    (state) => state.releases.currentPage,
  ) as number;
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  useEffect(() => {
    setIsOpenDrawer(false);
    dispatch(resetState());
    const reg = /\d+/g;
    const [, current] = pathname.split(paths.parent);
    const path = getTypeForm(current);
    if (!path) {
      if (!location?.state?.[TABLE_RELEASE]) {
        dispatch(
          getReleases({ current, page, clear: '', order: 'DESC' }),
        ).finally(() => {
          socket.off(GET_RELEASES);
        });
      }
      return;
    }
    switch (path) {
      case CREATE:
        const isCreate = pathname.split('/')?.at(-1) === path;
        if (isCreate) {
          setTypeForm(CREATE);
          setIsOpenDrawer(true);
          dispatch(setOne(defaultValues));
          return;
        }
        return history.replace(paths.create);
      case EDIT:
        const idEdit = pathname.split(`${EDIT}/`).at(-1) ?? '';
        const isEdit = reg.test(idEdit);
        if (isEdit) {
          setTypeForm(EDIT);
          dispatch(findOneRelease(+idEdit))
            .then((res) => {
              if (res?.payload) {
                dispatch(setOne(res.payload));
                setIsOpenDrawer(true);
              } else {
                history.push(paths.parent);
              }
            })
            .finally(() => {
              if (release?.delete) {
                setTypeForm(VIEW);
              }
              socket.off(FIND_ONE_RELEASE);
            });
          return;
        }
        return history.replace(paths.parent);
      case VIEW:
        const idView = pathname.split(`${VIEW}/`).at(-1) ?? '';
        const isView = reg.test(idView);
        if (isView) {
          setTypeForm(VIEW);
          dispatch(findOneRelease(+idView))
            .then((res) => {
              if (res?.payload) {
                dispatch(setOne(res.payload));
                setIsOpenDrawer(true);
              } else {
                history.push(paths.parent);
              }
            })
            .finally(() => {
              socket.off(FIND_ONE_RELEASE);
            });
          return;
        }
        history.replace(paths.parent);
        return;
      default:
        if (current) {
          history.replace(paths.parent);
        }
    }
  }, [pathname]);

  useEffect(() => {
    if (location?.state?.[TABLE_RELEASE]) {
      dispatch(setTables(location.state[TABLE_RELEASE]));
      dispatch(setCount(location.state?.[TOTAL_RELEASE]));
    }
  }, [dispatch, page, current, pathname]);

  const closeContainerDrawer = () => {
    dispatch(setIsContainerDrawer(false));
    dispatch(resetContainer(null));
    setIsCreateDeal(false);
  };
  const openCreateDeal = (containers: any[]) => {
    setContainersDeal(containers);
    dispatch(setIsContainerDrawer(true));
    setIsCreateDeal(true);
  };

  const onFinish = (data: any) => {
    const handlerNewContainer = (response: any) => {
      if (JSON.parse(response)?.message) {
        Utils.InfoOpenNotification(
          'topRight',
          JSON.parse(response).message,
          3,
          api,
        );
        return;
      }
      if (JSON.parse(response)?.data?.[0]) {
        Utils.InfoOpenNotification('topLeft', 'Добавлено', 3, api);
        dispatch(resetContainer(JSON.parse(response).data[0]));
        dispatch(setIsContainerDrawer(false));
        form.resetFields();
      }
    };
    socket.on('release-set-container', handlerNewContainer);
    data.notification = 'release-set-container';
    dispatch(setContainer(data)).finally(() => {
      socket.off('set-container');
      socket.off('release-set-container');
    });
  };

  const onClickLink = (data: any) => {
    // setIsOpenDrawer(true);
    const { id } = data?.[0];
    if (id) {
      history.push(`${paths.view}${id}`, {
        [ROW_RELEASE]: data?.[0],
        [TABLE_RELEASE]: [data?.[0]],
        [TOTAL_RELEASE]: count,
      });
    }
  };

  const showCardContainer = (data: any) => {
    setIsOpenDrawer(true);
    const { id } = data;
    if (id) {
      history.push(`${paths.view}${id}`, {
        [ROW_RELEASE]: data,
        [TABLE_RELEASE]: [data],
        [TOTAL_RELEASE]: count,
      });
    }
  };

  const handleShowEditForm = (payload: any) => {
    setIsOpenDrawer(true);
    const { id } = payload;
    if (id) {
      history.push(`${paths.edit}${id}`, {
        [TABLE_RELEASE]: [payload],
        [TOTAL_RELEASE]: count,
        [ROW_RELEASE]: payload,
      });
    }
  };

  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
    setTypeForm(EDIT);
    history.push(paths.parent);
  };

  const onHistory = () => {
    setTypeForm(HISTORY);
  };
  const onEdit = () => {
    setTypeForm(EDIT);
  };
  const onView = () => {
    setTypeForm(VIEW);
  };
  const onPdf = (lang: 'ru' | 'en') => {
    setLang(lang);
    setIsOpenModal(true);
  };

  const { generateCSV, copyToClipboard } = useCsvExport({
    fields: columns
      .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
      .map((item: any) => item.dataIndex), // Создаем массив из dataIndex
  });

  const handleCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    const rowsData = generateCSV(arSelectedRows, data);
    copyToClipboard(rowsData);
  };

  const fetchData = ({
    current,
    clear,
    page,
    order,
  }: {
    current: number;
    page: number;
    clear: string;
    order: string;
  }) => {
    dispatch(
      getReleases({
        current: current >= 1 ? current - 1 : current * page,
        page,
        order,
      }),
    ).finally(() => {
      socket.off(GET_RELEASES);
    });
  };

  const handleOrder = (order: string) => {
    fetchData({ current, page, clear: '', order });
  };

  const filtersFetch = (text: string | number, column: IColumnType<any>) => {
    dispatch(filtersReleases({ text: text, column: column })).finally(() => {
      socket.off('search-filters-releases');
    });
  };

  const filtersColumn = (value: []) => {
    if (value.length > 0) {
      dispatch(
        getFiltersReleases({
          data: value,
          current: current >= 1 ? current - 1 : current * page,
          page,
        }),
      ).finally(() => {
        socket.off(GET_FILTERS_RELEASES);
      });
    } else {
      fetchData({ current, page, clear: '', order: 'DESC' });
    }
  };

  const onChangePagination = (page: IPagination<any>) => {
    if (page?.current !== null && page?.pageSize !== null) {
      const current = page?.current || 0;
      const size = page?.pageSize || 20;
      dispatch(setCurrentPage((current - 1) * size));
      dispatch(setPage(page.pageSize));
    }
  };

  const handlerDelete = (deleteData: IRelease | IRelease[]) => {
    dispatch(deleteRelease(deleteData))
      .then(({ payload }) => {
        if (payload?.message) {
          message.error(payload.message, 3);
          return;
        }
        if (payload?.data) {
          fetchData({ current, page, clear: '', order: 'DESC' });
        }
      })
      .finally(() => {
        socket.off(DELETE_RELEASE);
      });
  };

  const handleDeleteCopyAll = (selectedRows: Set<number | undefined>) => {
    const deleteData = data?.filter((el) => selectedRows.has(el.id) && !el?.delete);
    if (deleteData?.length) {
      handlerDelete(deleteData);
    }
  };

  const getForm = useMemo(() => {
    if (!isOpenDrawer) return null;
    switch (typeForm) {
      case VIEW:
        return (
          <ViewRelease
            initialValue={release}
            typeForm={typeForm}
            isOpen={isOpenDrawer}
            onPdf={onPdf}
            onClose={onCloseDrawer}
            onDeal={openCreateDeal}
            onEdit={onEdit}
            onHistory={onHistory}
          />
        );
      case HISTORY:
        return <HistoryReleases />;
      default:
        return (
          <FormRelease
            initialValue={release}
            typeForm={typeForm}
            isOpen={isOpenDrawer}
            onPdf={onPdf}
            onClose={onCloseDrawer}
            onDeal={openCreateDeal}
            onEdit={onView}
            onHistory={onHistory}
          />
        );
    }
  }, [typeForm, isOpenDrawer]);

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Table
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        className={style.container__table}
        onClickLink={onClickLink}
        editRow={handleShowEditForm}
        deleteRow={handlerDelete}
        handleCopyAll={handleCopyAll}
        filtersFetch={filtersFetch}
        filters={filtersColumn}
        handleDeleteCopyAll={handleDeleteCopyAll}
        onRow={(record, rowIndex) => {
          showCardContainer(record);
        }}
        onChangePagination={onChangePagination}
        columns={columns}
        order={handleOrder}
        dataSource={data}
        loading={loading}
        style={{ width: '100%' }}
        height={'calc(100vh - 200px)'}
        pagination={{
          pageSize: page,
          total: Number(count),
          current: current,
          showSizeChanger: true,
        }}
      />

      <CustomDrawer
        isHeight
        minWidth={700}
        open={isOpenDrawer}
        onClose={onCloseDrawer}
      >
        {getForm}
      </CustomDrawer>
      <CustomDrawer
        isHeight
        open={isContainerDrawer}
        onClose={closeContainerDrawer}
      >
        {isCreateDeal ? (
          <FormDeal
            title={t('Создать сделку')}
            isOpen={isContainerDrawer}
            onClose={closeContainerDrawer}
            initialValue={{ ...defaultValuesDeal, containers: containersDeal }}
          />
        ) : (
          <FormContainer
            dataRowContainer={newContainer}
            form={form}
            typeForm={CREATE}
            onFinishForm={onFinish}
            title={t('Добавить контейнер')}
          />
        )}
      </CustomDrawer>
      {!isCreateDeal && isContainerDrawer ? contextHolder : null}
      <Modal
        open={isOpenModal}
        closeIcon
        centered
        width={'60vw'}
        footer={null}
        onCancel={() => setIsOpenModal(false)}
      >
        <div style={{ scale: '0.95' }}>
          {lang === 'en' ? (
            <PdfReleaseEN data={release} />
          ) : (
            <PdfReleaseRU data={release} />
          )}
        </div>
      </Modal>
    </Space>
  );
};
