import React, { useRef } from 'react';
import Scroll from '../../../../../components/ui/ScrollList';

const ScrollList: React.FC<any> = ({ children }) => {
  const ref = useRef(null)
  return (
    <Scroll
      setLastItemReached={(v) => v}
      height={200}
      totalTableActivity={1}
      data={children}
      tableRef={ref}
      paginationActivity={() => {}}
      isTable={false}
      endMessage={null}
    >
      {children}
    </Scroll>
  );
};

export default ScrollList;
