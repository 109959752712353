import PropTypes from "prop-types";
import {TwentyFourNavigationCloseSmall} from "./TwentyFourNavigationCloseSmall";

interface Props {
    state: "default";
    size: "s";
    className: any;
    onCloseModalConfirm?: () => void
}

export const CloseButton = ({ state, size, className,onCloseModalConfirm }: Props): JSX.Element => {
    return (
        <div className={`close-button ${className}`} onClick={onCloseModalConfirm}>
            <div className="wrapper">
                <TwentyFourNavigationCloseSmall className="element-navigation-close" />
            </div>
        </div>
    );
};

CloseButton.propTypes = {
    state: PropTypes.oneOf(["default"]),
    size: PropTypes.oneOf(["s"]),
};
