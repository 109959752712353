import { Flex } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewRow from '../../../../../../components/ui/List/ViewRow/ViewRow';
import { ITabView } from '../../../../../../store/sales';

const TabRepair: React.FC<ITabView> = ({deal}) => {
  const { t } = useTranslation();

  return (
    <Flex vertical>
      <ViewRow
        title={t('Сумма клиенту')}
        subtitle={deal?.amount_client}
        isDivider
      />
      <ViewRow title={t('Сумма нам')} subtitle={deal?.amount_us} isDivider />
      <ViewRow title={t('Итого')} subtitle={deal?.total_repair} isDivider />
    </Flex>
  );
};

export default TabRepair;
