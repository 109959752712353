import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Rest_agreement } from '../services/rest_agreement';

export interface ITypeFindAgreements {
  client_id: number;
  executor_id: number;
}

export interface IAgreement {
  readonly id?: number;
  readonly date_signing: Date | null;
  readonly date_string: string;
  readonly agreement_id: string;
  readonly type_id: number | null;
  readonly executor_id?: number | null;
  readonly executor?: string;
  readonly client_id?: number | null;
  readonly client?: string;
  readonly approve_to_id: number | null;
  readonly agreed?: boolean;
  readonly file_ids: number[];
  readonly date_issue: string | null;
  readonly date_course: string | null;
  readonly payment_term: string;
  readonly conversion: number | null;
  responsible_id?: number;
  type?: any;
  approving?: any;
  refused?: boolean;
  delete?: boolean;
  saveFiles?: any[]
}

export interface IForm {
  title?: string;
  initialValue: IAgreement;
  isOpen: boolean;
  typeForm: string;
  onClose: () => void;
  onEdit?: () => void;
}

interface IAgreementState {
  agreements: IAgreement[];
  agreement?: IAgreement;
  loading: boolean;
  loadingSave: boolean;
  count: number;
  page: number;
  currentPage: number;
  types: { id: number; value: string }[];
  saveFiles: any[];
}
export const defaultAgreement: IAgreement = {
  id: undefined,
  date_signing: null,
  date_issue: '',
  date_course: '',
  conversion: null,
  payment_term: '',
  date_string: '',
  agreement_id: '',
  type_id: null,
  executor_id: null,
  executor: '',
  client_id: null,
  client: '',
  approve_to_id: null,
  agreed: false,
  refused: undefined,
  file_ids: [],
  responsible_id: undefined,
  saveFiles: []
};
const initialState: IAgreementState = {
  agreements: [],
  agreement: defaultAgreement,
  saveFiles: [],
  types: [],
  loading: false,
  loadingSave: false,
  count: 0,
  page: 20,
  currentPage: 0,
};

export const getAgreementTypes = createAsyncThunk(
  'getAgreementTypes',
  async () => {
    return await Rest_agreement.getAgreementTypes();
  },
);

export const getFiltersAgreements = createAsyncThunk(
  'getFiltersAgreements',
  async (param?: any) => {
    return await Rest_agreement.getFiltersAgreements(param);
  },
);
export const getAgreements = createAsyncThunk(
  'getAgreements',
  async (param?: any) => {
    return await Rest_agreement.getAgreements(param);
  },
);

export const setAgreement = createAsyncThunk(
  'setAgreement',
  async (payload: any) => {
    return await Rest_agreement.setAgreement(payload);
  },
);
export const deleteAgreement = createAsyncThunk(
  'deleteAgreement',
  async (payload: IAgreement | IAgreement[]) => {
    return await Rest_agreement.deleteAgreement(payload);
  },
);
export const findOneAgreement = createAsyncThunk(
  'findOneAgreement',
  async (id: number) => {
    return await Rest_agreement.findOneAgreement(id);
  },
);

const agreementSlice = createSlice({
  name: 'agreement',
  initialState,
  reducers: {
    resetStore(state) {
      state = initialState;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setSaveFiles(state, action) {
      state.saveFiles = action.payload;
    },
    setTables(state, action) {
      state.agreements = action.payload;
    },
    setOneAgreement(state, action) {
      state.agreement = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAgreementTypes.fulfilled, (state, action) => {
      state.types = action.payload;
    });
    builder.addCase(setAgreement.fulfilled, (state, action) => {
      state.agreement = action.payload;
      state.loadingSave = false;
    });
    builder.addCase(setAgreement.pending, (state, action) => {
      state.loadingSave = true;
    });
    builder.addCase(setAgreement.rejected, (state, action) => {
      state.loadingSave = false;
    });
    builder.addCase(getAgreements.fulfilled, (state, action) => {
      state.count = action.payload?.count;
      state.agreements = action.payload?.rows;
      state.loading = false;
    });
    builder.addCase(getAgreements.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAgreements.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getFiltersAgreements.fulfilled, (state, action) => {
      state.count = action.payload?.count || 0;
      state.agreements = action.payload?.rows || [];
      state.loading = false;
    });
    builder.addCase(getFiltersAgreements.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFiltersAgreements.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(findOneAgreement.fulfilled, (state, action) => {
      state.agreement = action.payload;
      state.agreements = action.payload ? [action.payload] : [];
      state.loading = false;
    });
    builder.addCase(findOneAgreement.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(findOneAgreement.rejected, (state, action) => {
      state.loading = false;
    });
  },
});
export default agreementSlice.reducer;
export const {
  setLoading,
  setCount,
  setCurrentPage,
  setPage,
  setSaveFiles,
  setTables,
  setOneAgreement,
  resetStore
} = agreementSlice.actions;
