import { UserOutlined } from "@ant-design/icons";
import { Avatar, Flex, Typography } from "antd";
import style from '../../style.module.scss';

const Responsible: React.FC<any> = ({ avatar, title, description }) => {
  return (
    <div className={style["responsible"]}>
        <Avatar className={style["responsible__avatar"]} src={avatar}  icon={<UserOutlined />} />
        <Flex vertical gap={2} style={{alignSelf: 'baseline'}}>
          <Typography.Paragraph className={style["responsible__title"]} ellipsis={{ rows: 1, tooltip: { placement: 'topLeft', title: description } }}>{title}</Typography.Paragraph>
          <Typography.Paragraph className={style["responsible__description"]} ellipsis={{ rows: 1, tooltip: { placement: 'topLeft', title: description } }}>{description}</Typography.Paragraph>
        </Flex>
    </div>
  );
};

export default Responsible