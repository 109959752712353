import {DeleteOutlined, PlusOutlined, UserOutlined} from '@ant-design/icons';
import {
    Avatar,
    Button,
    Col,
    Divider,
    Flex,
    Form,
    Input,
    Modal,
    Select,
    SelectProps,
    Typography
} from "antd";
import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState, useStoreDispatch} from "../../store";
import {findExecutor, sourceContact} from "../../store/contact";
import {typeContact, typeEmail, typeMessenger, typePhone} from "../../store/types";
import {Utils} from "../../utils";
import {ITypeContact, ITypeQuality} from "../../types";
import {IContacts} from "../../types/contacts";
import {IUsers} from "../../types/users";
import PhoneInput from "react-phone-input-2";
import ru from "react-phone-input-2/lang/ru.json";
import {SelectSearch} from "../../components/ui/SelectSearch/SelectSearch";
import {findCompany} from "../../store/contractors";
import ModalConfirm from '../../components/ui/ModalConfirm/ModalConfirm';
import { messagesModal } from '../../constants';

const { title: warningTitle, subtitle } = messagesModal.warning;

const {Title, Text} = Typography;

interface AppProps {
    style?: React.CSSProperties,
    onClose?: () => void,
    event?: boolean,
    pagination?: { current: number; pageSize: number },
    title?: string,
    dataTypeContact?: Array<ITypeContact>,
    dataContacts?: IContacts[],
    dataTypeQuality?: ITypeQuality[],
    userInfoData?: IUsers[],
    onFinish?: (data: IContacts) => void,
    form?: any,
}

const FormContacts: FC<AppProps> = (props: any) => {
    const [confirmation, setConfirmation] = React.useState<boolean>(false);
    const dispatch = useStoreDispatch()
    const dataTypePhone = useSelector((state: RootState) => state.types.dataTypePhone);
    const dataTypeMessenger = useSelector((state: RootState) => state.types.dataTypeMessenger);
    const dataTypeEmail = useSelector((state: RootState) => state.types.dataTypeEmail);
    const dataTypeContact = useSelector((state: RootState) => state.types.dataTypeContact);
    const dataSourceContact = useSelector((state: RootState) => state.contact.dataSourceContact);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [companyData, setCompanyData] = useState<SelectProps['options']>([]);
    const [dataExecutor, setDataExecutor] = useState([]);
    const [accountableId, setAccountableId] = useState<string | null>(null);
    const [changeCompany, setChangeCompany] = useState<string[]>([]);
    const [selectedCompanies, setSelectedCompanies] = useState<string[] | null>(null);
    const [responsibleChange, setResponsibleChange] = useState('');
    const [responsible, setResponsible] = useState<string>('');

    const changePerson = (data: any, option: any) => {
        setResponsibleChange(option.children.props.children)
        setAccountableId(data)
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const addFormResponsible = () => {
        setResponsible(responsibleChange)
        setIsModalOpen(false);
    }

    const onNameChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const result: any = await dispatch(findExecutor(event.target.value.toLocaleLowerCase()));
        if (result.payload.length > 0) {
            setDataExecutor(result.payload)
        }
    };

    useEffect(() => {
        dispatch(typePhone())
        dispatch(typeMessenger())
        dispatch(typeEmail())
        dispatch(sourceContact())
        dispatch(typeContact())
        if (props.dataContacts && props.dataContacts.company) {
            const companies = props.dataContacts.company;
            const formattedCompanies = companies.map((company: string) => ({
                value: company,
                label: company
            }));
            setCompanyData(formattedCompanies);
            if (!props.event){
                setSelectedCompanies(companies);
            }else {
                setSelectedCompanies(null);
                setAccountableId(null)
            }
        } else {
            setCompanyData([]);
            setSelectedCompanies(null);
        }
        setResponsible(props.event ? Utils.getUserInfo().fio : props.dataContacts.fio);
        if (props.event){
            props.form.setFieldsValue({})
        }else {
            props.form.setFieldsValue({
                id: props.dataContacts.source,
                source: props.dataContacts.source,
                name: props.dataContacts.name,
                last_name: props.dataContacts.last_name,
                first_name: props.dataContacts.first_name,
                position: props.dataContacts.position,
                phone_first: props.dataContacts.phone_first,
                last_phone: props.dataContacts.last_phone,
                type_phone_first: props.dataContacts.type_phone_first,
                email_first: props.dataContacts.email_first,
                last_email: props.dataContacts.last_email,
                type_email_first: props.dataContacts.type_email_first,
                messenger_first: props.dataContacts.messenger_first,
                last_messenger: props.dataContacts.last_messenger,
                type_messenger_first: props.dataContacts.type_messenger_first,
                type_contact: props.dataContacts.type_contact,
                executor: props.dataContacts.executor,
                accountable: props.dataContacts.fio,
                company: props.dataContacts.company !== null ? props.dataContacts.company : null,
                comment: props.dataContacts.comment,
            })
        }
    }, [props.dataContacts,props.form])

    const onFinishContact = async (data: any) => {
        if (!props.event) {
            data.company = selectedCompanies === null ? selectedCompanies : props.dataContacts.company;
        }else {
            if (changeCompany.length > 0){
                data.company = changeCompany
            }else {
                data.company = null
            }
        }
        if (!props.event) {
            data.responsible_id = accountableId !== null ? accountableId : props.dataContacts.responsible_id;
            data.responsible_name = responsible
        }else {
            if (accountableId !== null){
                data.responsible_id = accountableId
                data.responsible_name = responsible
            }else {
                data.responsible_id = Utils.getUserInfo().id
                data.responsible_name = Utils.getUserInfo().fio
            }

        }
        data.user_id = Utils.getUserInfo().id
        data.email_first = data.email_first ?? data.email_first
        if (!props.event) {
            data.id = props.dataContacts.id
        }
        data.fio_contact = data.last_name + ' ' + data.name + ' ' + data.first_name
        data.event = props.event
        props.onFinish(data)
    };

    const handleSearchCompany = async (val: string) => {
        const result: any = await dispatch(findCompany(val.toLocaleLowerCase()));
        if (result.payload.length > 0) {
            setCompanyData(result.payload)
        }
    };

    const handleChangeCompany = (data: any) =>{
        setChangeCompany(data)
    }

    const closeForm = () =>{
        props.form.resetFields()
        props.onClose()
    }

    return (
        <>
            <Form
                form={props.form}
                autoComplete="off"
                layout="vertical"
                onFinish={onFinishContact}>
                <Flex justify={'space-between'} className={'drawer-header'}>
                    <Flex gap={20} justify={'center'} align={'center'}>
                        <Text
                            className={'drawer-title'}>{props.dataContacts.id ? 'Редактировать контакт' : 'Новый контакт'}</Text>
                    </Flex>
                </Flex>
                <Flex gap={10} className={'content--drawer'}>
                    <Flex vertical gap={8} style={{width: '100%'}}>
                        <Form.Item label="О клиенте" name="source"
                                   rules={[{required: true, message: 'Обязательное поле!'}]}>
                            <Select
                                placeholder="Источник"
                                showSearch
                                options={dataSourceContact}
                                value={props.dataContacts.source}
                            />
                        </Form.Item>
                        <Form.Item name={'last_name'}
                                   rules={[{required: true, message: 'Обязательное поле!'}]}>
                            <Input
                                placeholder="Фамилия"
                                value={props.dataContacts.last_name}
                            />
                        </Form.Item>
                        <Flex gap={12}>
                            <Form.Item name={'name'} style={{width: '60%'}}
                                       rules={[{required: true, message: 'Обязательное поле!'}]}>
                                <Input
                                    placeholder="Имя"
                                    value={props?.dataContacts?.name}
                                />
                            </Form.Item>
                            <Form.Item name={'first_name'} style={{width: '40%'}}>
                                <Input
                                    placeholder="Отчество"
                                    value={props?.dataContacts?.first_name}
                                />
                            </Form.Item>
                        </Flex>
                        <Form.Item name="position"
                                   rules={[{required: true, message: 'Обязательное поле!'}]}>
                            <Input
                                placeholder="Должность"
                                value={props?.dataContacts.position}
                            />
                        </Form.Item>
                        <Flex gap={12}>
                            <Form.Item name="phone_first" style={{width: '60%'}}
                                       rules={[{required: true, message: 'Обязательное поле!'}]}>
                                <PhoneInput
                                    placeholder="Номер телефона"
                                    enableSearch
                                    searchPlaceholder={"Поиск"}
                                    searchNotFound={'ничего не нашлось'}
                                    country={'ru'}
                                    localization={ru}
                                />
                            </Form.Item>
                            <Form.Item name="type_phone_first" style={{width: '40%'}}
                                       rules={[{required: true, message: 'Обязательное поле!'}]}>
                                <Select
                                    value={props?.dataContacts?.type_phone_first}
                                    placeholder="Рабочий"
                                    showSearch
                                    options={dataTypePhone}
                                />
                            </Form.Item>
                            <Flex gap={5}>
                                <Form.List name="last_phone">
                                    {(_fields, {add}) => (
                                        <>
                                            <DeleteOutlined style={{
                                                fontSize: '16px',
                                                color: '#D91528',
                                                cursor: "pointer"
                                            }}/>
                                            <PlusOutlined
                                                style={{fontSize: '16px', color: '#00B288', cursor: "pointer"}}
                                                onClick={() => add()}
                                            />
                                        </>
                                    )}
                                </Form.List>
                            </Flex>
                        </Flex>
                        <Form.List name="last_phone">
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map(({key, name, ...restField}) => (
                                        <Flex gap={12} key={key}>
                                            <Col style={{width: '60%'}}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'add_phone']}>
                                                    <PhoneInput
                                                        placeholder="Номер телефона"
                                                        enableSearch
                                                        searchPlaceholder={"Поиск"}
                                                        searchNotFound={'ничего не нашлось'}
                                                        country={'ru'}
                                                        localization={ru}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col style={{width: '40%'}}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'add_type_phone']}>
                                                    <Select
                                                        placeholder="Рабочий"
                                                        showSearch
                                                        options={dataTypePhone}
                                                        optionFilterProp="children"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Flex gap={5}>
                                                <DeleteOutlined onClick={() => remove(name)} style={{
                                                    fontSize: '16px',
                                                    color: '#D91528',
                                                    cursor: "pointer"
                                                }}/>
                                                <PlusOutlined
                                                    style={{
                                                        fontSize: '16px',
                                                        color: '#00B288',
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => add()}
                                                />
                                            </Flex>
                                        </Flex>
                                    ))}
                                </>
                            )}
                        </Form.List>
                        <Flex gap={12}>
                            <Form.Item name="email_first" style={{width: '60%'}}
                                       rules={[{type: 'email',required: true,
                                               message: 'Обязательное поле!',
                                           }]}>
                                <Input
                                    placeholder='e-mail'
                                    value={props?.dataContacts?.email_first}
                                />
                            </Form.Item>
                            <Form.Item name="type_email_first" style={{width: '40%'}}>
                                <Select
                                    value={props?.dataContacts?.type_email_first}
                                    placeholder="Рабочий"
                                    showSearch
                                    options={dataTypeEmail}
                                />
                            </Form.Item>
                            <Flex gap={5}>
                                <Form.List name="last_email">
                                    {(_fields, {add}) => (
                                        <>
                                            <DeleteOutlined style={{
                                                fontSize: '16px',
                                                color: '#D91528',
                                                cursor: "pointer"
                                            }}/>
                                            <PlusOutlined
                                                style={{fontSize: '16px', color: '#00B288', cursor: "pointer"}}
                                                onClick={() => add()}
                                            />
                                        </>
                                    )}
                                </Form.List>
                            </Flex>
                        </Flex>
                        <Form.List name="last_email">
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map(({key, name, ...restField}) => (
                                        <Flex gap={12} key={key}>
                                            <Col style={{width: '60%'}}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'add_email']}>
                                                    <Input placeholder="e-mail"/>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{width: '40%'}}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'add_type_email']}>
                                                    <Select
                                                        placeholder="Рабочий"
                                                        showSearch
                                                        options={dataTypeEmail}
                                                        optionFilterProp="children"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Flex gap={5}>
                                                <DeleteOutlined onClick={() => remove(name)} style={{
                                                    fontSize: '16px',
                                                    color: '#D91528',
                                                    cursor: "pointer"
                                                }}/>
                                                <PlusOutlined
                                                    style={{
                                                        fontSize: '16px',
                                                        color: '#00B288',
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => add()}
                                                />
                                            </Flex>
                                        </Flex>
                                    ))}
                                </>
                            )}
                        </Form.List>
                        <Flex gap={12}>
                            <Form.Item name="messenger_first" style={{width: '60%'}}>
                                <Input
                                    placeholder={'Мессенджер'}
                                    value={props?.dataContacts?.messenger_first}
                                />
                            </Form.Item>
                            <Form.Item name="type_messenger_first" style={{width: '40%'}}>
                                <Select
                                    placeholder='Рабочий'
                                    showSearch
                                    options={dataTypeMessenger}
                                    value={props?.dataContacts?.type_messenger_first}
                                />
                            </Form.Item>
                            <Flex gap={5}>
                                <Flex gap={5}>
                                    <Form.List name="last_messenger">
                                        {(_fields, {add}) => (
                                            <>
                                                <DeleteOutlined style={{
                                                    fontSize: '16px',
                                                    color: '#D91528',
                                                    cursor: "pointer"
                                                }}/>
                                                <PlusOutlined
                                                    style={{
                                                        fontSize: '16px',
                                                        color: '#00B288',
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => add()}
                                                />
                                            </>
                                        )}
                                    </Form.List>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Form.List name="last_messenger">
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map(({key, name, ...restField}) => (
                                        <Flex gap={12} key={key}>
                                            <Col style={{width: '60%'}}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'add_messenger']}>
                                                    <Input placeholder="Мессенджер"/>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{width: '40%'}}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'add_type_messenger']}>
                                                    <Select
                                                        placeholder="Рабочий"
                                                        showSearch
                                                        options={dataTypeMessenger}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Flex gap={5}>
                                                <DeleteOutlined onClick={() => remove(name)} style={{
                                                    fontSize: '16px',
                                                    color: '#D91528',
                                                    cursor: "pointer"
                                                }}/>
                                                <PlusOutlined
                                                    style={{
                                                        fontSize: '16px',
                                                        color: '#00B288',
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => add()}
                                                />
                                            </Flex>
                                        </Flex>
                                    ))}
                                </>
                            )}
                        </Form.List>
                        <Divider style={{marginBottom: 6, marginTop: 6}}/>
                        <Form.Item label="Компания" name="company"
                                   rules={[{required: true, message: 'Обязательное поле!'}]}>
                            <Select
                                value={selectedCompanies}
                                showSearch
                                placeholder={'Название компании'}
                                style={props.style}
                                mode="multiple"
                                filterOption={false}
                                onSearch={handleSearchCompany}
                                onChange={handleChangeCompany}
                                notFoundContent={null}
                                options={(companyData || []).map((d) => ({
                                    value: d.value,
                                    label: d.label,
                                }))}
                            />
                        </Form.Item>
                        <Divider style={{marginBottom: 6, marginTop: 6}}/>
                        <Form.Item name="type_contact">
                            <Select
                                placeholder="Тип контакта"
                                showSearch
                                options={dataTypeContact}
                                value={props?.dataContacts.type_contact}
                            />
                        </Form.Item>
                        <Divider style={{marginBottom: 6, marginTop: 6}}/>
                        <Flex gap={6} vertical>
                            <Title level={4} style={{marginBottom: 0}}>Дополнительно</Title>
                            <Flex justify={'space-between'} align={'center'} style={{paddingRight: 7}}>
                                <Flex gap={6} align={'center'}>
                                    <Avatar size={28} icon={<UserOutlined/>}/>
                                    <Form.Item name={'user_fio'}>
                                        <Text style={{
                                            marginTop: 0,
                                            color: '#05F'
                                        }}>{responsible}</Text>
                                    </Form.Item>
                                </Flex>
                                <Text
                                    style={{marginTop: 0, color: '#05F', cursor: "pointer"}}
                                    onClick={showModal}>
                                    Сменить
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex vertical style={{marginTop: 6}}>
                            <Title level={4} style={{marginBottom: 6}}>Комментарий</Title>
                            <Form.Item name="comment">
                                <Input.TextArea
                                    autoSize={{minRows: 3, maxRows: 20}}
                                    value={props?.dataContacts.comment}
                                />
                            </Form.Item>
                        </Flex>
                        <Flex gap={8} style={{marginTop: 10}}>
                            <Button type='primary'
                                    htmlType='submit'
                                    style={{width: '70%'}}>{props.dataContacts.id ? 'Сохранить изменения' : 'Добавить'}</Button>
                            <Button type={'text'} onClick={() => setConfirmation(true)}
                                    style={{width: '30%', color: '#E14453'}}>Отменить</Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Form>
            <ModalConfirm title={warningTitle} subtitle={subtitle} isOpen={confirmation} closeModal={() => setConfirmation(false)} actionAfterConsent={isModalOpen ? handleCancel : props.onClose} />
            <Modal
                title="Сменить ответственного"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                closable={false}
            >
                <Form layout="vertical" style={{height: '50vh', padding: 20}}>
                    <SelectSearch
                        defaults={responsibleChange}
                        notFoundContent={'Пользователь не найден.'}
                        dataSource={dataExecutor}
                        onChangeSelect={changePerson}
                        dropdownRender={onNameChange}
                        style={{width: '100%'}}
                        placeholder={'Ответственный'}
                    />
                    <Flex gap={8} style={{position: "absolute", bottom: 28, width: '100%'}}>
                        <Button type={'primary'} style={{width: '56%'}}
                                onClick={addFormResponsible}>Сменить</Button>
                        <Button type={'text'} style={{width: '30%', color: '#E14453'}}
                                onClick={() => setConfirmation(true)}>Отменить</Button>
                    </Flex>
                </Form>
            </Modal>
        </>
    );
};

export default FormContacts;
