import React, { useState } from 'react';
import { Rate, Typography } from 'antd';
import style from './style.module.scss';
import classnames from 'classnames';
import Icon from '@ant-design/icons/lib/components/Icon';
import { CircleIconDefault } from '../../../assets/image/svg';
import { useTranslation } from 'react-i18next';

interface IDesc {
  title: string;
  color: string;
  background: string;
}
interface IPropsRate {
  value?: number;
  desc: IDesc[];
  classRate?: string;
  classDescription?: string;
  disabled?: boolean
  isOne?: boolean
}


export const IRate: React.FC<IPropsRate> = ({
  desc,
  classDescription,
  classRate,
  value,
  isOne = false,
  disabled = true
}) => {
  const { t } = useTranslation();
  const [defaultValue, setDefaultValue] = useState(() => value || 1);
  const titles = isOne ? Array(5).fill(t(desc[0]?.title ?? '')): desc?.map((item) => item?.title) || undefined;
  const arrayDesc = isOne ? Array(5).fill(desc[0]): desc;
  return (
    <div className={style.container}>
      {value ? (
        <Rate
          className={classnames(style.container__rate, {
            [classRate ?? '']: classRate,
          })}
          disabled={disabled}
          tooltips={titles}
          value={value}
          character={<Icon component={CircleIconDefault} />}
        />
      ) : (
        <Rate
          className={classnames(style.container__rate, {
            [classRate ?? '']: classRate,
          })}
          disabled={disabled}
          tooltips={titles}
          onChange={setDefaultValue}
          value={defaultValue}
          character={<Icon component={CircleIconDefault} />}
        />
      )}
      {value ? (
        <Typography.Text
          ellipsis
          style={{color: arrayDesc?.[value - 1]?.color, background: arrayDesc?.[value - 1]?.background}}
          className={classnames(style.container__description, {
            [classDescription ?? '']: classDescription,
          })}>
          {t(arrayDesc?.[value - 1]?.title ?? '')}
        </Typography.Text>
      ) : null}
    </div>
  );
};
