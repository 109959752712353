import socket from "../socket";

export class Rest_contractors {

    static getFiltersContractors(payload: {}): Promise<any> {
        return new Promise(async (resolve, reject) => {
            socket.emit('get-filters-contractors', payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

    static updateContractors(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('update-contractors',payload)
        })
    }

    static setContractors(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            try {
                socket.emit('save-contractors', payload)
            }catch (e) {
                reject(e);
            }
        })
    }

    static getContractors(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-contractors', payload, (response: any) => {
                try {
                    response.data.forEach((el: any) => {
                        el.key = el.id;
                        el.tasks = '25.05.2024'
                        el.tasks_name = 'Название задачи'
                    });
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

    static getContractorsRequisites(id: number): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-contractors-requisites', id, (response: any) =>{
                try {
                    resolve(response);
                }catch (e) {
                    reject(e);
                }
            })
        })
    }

    static deleteContractors(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            try {
                socket.emit('delete-contractors', payload)
            } catch (error) {console.error(error)}
        })
    }

    static getFindCompany(payload: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            socket.emit('find-company', payload, (response: any) => {
                try {
                    resolve(response)
                } catch (e) { reject(e) }
            })
        })
    }

    static getFindMyCompany(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            socket.emit('find-my-company', (response: any) => {
                try {
                    resolve(response)
                } catch (e) { reject(e) }
            })
        })
    }
    static findCompanyById(id: number): Promise<any> {
        return new Promise(async (resolve, reject) => {
            socket.emit('find-company-by-id', id, (response: any) => {
                try {
                    resolve(response)
                } catch (e) { reject(e) }
            })
        })
    }
}
