import socket from "../socket";

export class CompanyRequest{
    static getTypePayment(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('type-payment', (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getTypePhone(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('type-phone', (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

    static getTypeMessenger(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('type-messenger', (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

    static getTypeEmail(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('type-email',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static getTypeSite(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('type-site',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static getTypeContact(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('type-contact',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static getTypeCompany(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('type-company',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static getTypeContainers(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('type-containers',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static getTypeQuality(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('type-quality',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }
}
