import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { IAgreement } from './agreement';
import { Rest_releases } from '../services/rest_releases';
import { Rest_rent } from '../services/rest_rent';

export interface ITypeFindAgreements {
  client_id: number;
  executor_id: number;
}
export interface ITabView {
  deal: IDeal;
  isOpen: boolean;
}
export interface IValues {
  [key: string]: any;
}

export interface ILocation {
  location?: string | null;
  options: any[]
}
export interface IReleaseList {
  release_id?: string | null;
  options: any[]
  ids: number[]
  saveFiles?: any[]
}
export interface IExpenses {
  title?: string | null; // название расхода
  bet?: string | null; // ставка
  payment?: string | null; //оплата поставщику
  agreed?: boolean; // согласование
}

export interface IFormDeal {
  onEdit?: () => void;
  onRelease?: () => void;
  onClose?: (isClear?: boolean) => void;
  isOpen: boolean;
  isDisabled?: boolean;
  width?: string;
  title?: string;
  initialValue: IDeal;
}

export interface ITabForm {
  isDisabled?: boolean;
  isOpen?: boolean;
  onOpenFormDeal?: () => void;
  setChangeSaveFiles?: (fuc: any) => void;
}

export interface IContainer {
  id?: number;
  num_container?: string;
  container_id?: number;
  status?: string | null;
  status_id?: number | null;
  issue_date?: string | null;
  departure_date?: dayjs.Dayjs | null;
  delivery_request_date?: dayjs.Dayjs | null;
  delivery_date?: dayjs.Dayjs | null;
  count_day_snp?: string | null;
  total_snp?: string | null;
  bet?: number | string | null;
  payment?: string;
  gtd?: boolean;
  price?: number | string | null;
  type_container?: string | number;
  deal?: {
    id: number;
    id_deal: string;
    status: string;
    status_id: number;
    issue_date?: string | null;
    departure_date?: string | null;
    bet?: number | string | null;
    payment?: string;
    gtd?: boolean;
    price?: number | string | null;
  } | null;
  rent?: {
    payment?: string;
    bet?: number | string | null;
    departure_date?: dayjs.Dayjs | null;
    delivery_request_date?: dayjs.Dayjs | null;
    delivery_date?: dayjs.Dayjs | null;
    count_day_snp?: string | null;
    total_snp?: string | null;
  } | null;
  release?: { id: number; release_id: string } | null;
  delete?: boolean;
  edit?: boolean;
}

interface IResponsible {
  avatar: string;
  name: string;
  id: number;
  isResponsible: boolean;
}

export interface IComments {
  comment: string;
  id_deal: string;
  user_id: number;
  user?: { avatar: string; fio: string; id: number; name: string };
  edit?: boolean;
  createdAt?: string;
}

export interface IDeal {
  id?: number;
  id_deal?: string;
  type_deal?: string;
  type_deal_id: number;
  type_deal_char: string;
  name_deal?: string;
  company?: string;
  company_id?: number;
  containers_ids: number[];
  phone?: string;
  my_company?: string;
  my_company_id?: number;
  agreement_id?: number;
  agreement?: string | null;
  contact_id?: number;
  contact?: any;
  releases?: IReleaseList[];
  location_release?: string | null;
  location_return?: ILocation[];
  related_accounts?: any[];
  related_deals?: any[];
  related_deals_ids?: number[];
  containers: IContainer[];
  bet?: string;
  currency_id?: number;
  type_container?: string;
  condition?: string;
  currency?: string;
  count_ktk?: string;
  total_ktk?: string;
  balance_ktk?: string;
  nds?: string;
  fine?: string;
  message_subject?: string;
  additionally?: string;
  count_day?: string;
  snp?: string;
  expenses?: IExpenses[];
  responsible_id?: number | null;
  responsible_name?: string | null;

  delete?: boolean;
  edit?: boolean;
}
interface IDealState {
  count: number;
  currentPage: number;
  page: number;
  loading: boolean;
  loadingOneDeal: boolean;
  locations: any[];
  idDeal: string;
  isEditDeal: boolean;
  containerDealStatuses: { id: number; value: string }[];
  dealType: { id: number; value: string; char: string }[];
  isEditDealContainers: boolean;
  responsible: IResponsible[];
  docs: number[];
  dealData: IDeal[];
  dealOneData: IDeal | {};
  containers: IContainer[];
  comments: IComments[];
  agreements: IAgreement[];
}

export const rentTypeId = 2; // id Аренды
export const rentTypeDefaultDealId = 5; // id сделки Аренды

export const defaultRelease = { id: null, release_id: null, ids: [], saveFiles: [], options: [] };
export const defaultLocationReturn = { location: null, options: [] };
export const defaultExpenses = { title: '', payment: '', bet: '',  agreed: false};

export const defaultValuesDeal: IDeal = {
  id: undefined,
  id_deal: '',
  type_deal: 'Аренда',
  type_deal_id: rentTypeDefaultDealId,
  type_deal_char: 'АА',
  name_deal: '',
  phone: '',
  my_company: '',
  my_company_id: undefined,
  company: '',
  company_id: undefined,
  contact_id: undefined,
  agreement_id: undefined,
  agreement: null,
  releases: [defaultRelease],
  location_release: null,
  location_return: [defaultLocationReturn],
  related_accounts: [], // связанные счета
  related_deals: [], // связанные сделки
  related_deals_ids: [],
  nds: undefined,
  currency: '',
  currency_id: undefined,
  type_container: undefined,
  condition: undefined,
  count_ktk: undefined,
  total_ktk: undefined,
  balance_ktk: undefined,
  bet: undefined,
  containers: [],
  containers_ids: [],
  fine: undefined,
  message_subject: undefined,
  additionally: undefined,
  count_day: undefined,
  snp: undefined,
  expenses: [defaultExpenses],
  responsible_id: null,
  responsible_name: null,
  delete: false,
  edit: true,
};

const initialState: IDealState = {
  containerDealStatuses: [],
  dealType: [],
  loading: false,
  loadingOneDeal: false,
  count: 1,
  currentPage: 1,
  page: 20,
  locations: [],
  idDeal: '',
  isEditDeal: false,
  isEditDealContainers: false,
  responsible: [],
  docs: [],
  dealData: [],
  dealOneData: {},
  containers: [],
  comments: [],
  agreements: [],
};

export const generateIdDeal = createAsyncThunk(
  'generateIdDeal',
  async (payload: string) => {
    return await Rest_rent.generateIdDeal(payload);
  },
);

export const findReleaseId = createAsyncThunk(
  'findReleaseId',
  async (payload: string) => {
    return await Rest_releases.findReleaseId(payload);
  },
);
export const findOneRelease = createAsyncThunk(
  'findOneRelease',
  async (payload: number) => {
    return await Rest_releases.findOneRelease(payload);
  },
);
export const findOneDeal = createAsyncThunk(
  'findOneDeal',
  async (id: number) => {
    return await Rest_rent.findOneDeal(id);
  },
);
// export const getDeals = createAsyncThunk('getDeals', async (param: any) => {
//   return await Rest_rent.getDeals(param);
// });
export const setDeals = createAsyncThunk(
  'setDeals',
  async (payload: IDeal) => {
    return await Rest_rent.setDeals(payload);
  },
);
export const deleteDeal = createAsyncThunk(
  'deleteDeal',
  async (payload: IDeal | IDeal[]) => {
    return await Rest_rent.deleteDeal(payload);
  },
);
export const getFiltersDeal = createAsyncThunk(
  'getFiltersDeal',
  async (param: any) => {
    return await Rest_rent.getFiltersDeal(param);
  },
);

const rentSlice = createSlice({
  name: 'rent',
  initialState,
  reducers: {
    setResetState(state) {
      state = initialState;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setLocations(state, action) {
      state.locations = action.payload;
    },
    setResponsible(state, action) {
      state.responsible = action.payload;
    },
    setEditDeal(state, action) {
      state.isEditDeal = action.payload;
    },
    setOneDeal(state, action) {
      state.dealOneData = action.payload;
    },
    setTableDeals(state, action) {
      state.dealData = action.payload;
    },
    setFiltersDeals(state, action) {
      state.dealData = action.payload || [];
    },
    setDocsDeal(state, action) {
      state.docs = action.payload;
    },
    setIsEditDealContainers(state, action) {
      state.isEditDealContainers = action.payload;
    },
    editDealContainerList(state, action) {
      state.containers = action.payload;
    },
  },
  extraReducers: (builder) => {
  },
});
export default rentSlice.reducer;
export const {
  setResponsible,
  setEditDeal,
  setDocsDeal,
  setIsEditDealContainers,
  setOneDeal,
  editDealContainerList,
  setLocations,
  setCount,
  setPage,
  setCurrentPage,
  setFiltersDeals,
  setLoading,
  setTableDeals,
  setResetState
} = rentSlice.actions;