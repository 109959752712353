import { apiAxios } from "../api/config";

export class Rest_files {
  static submitFiles(files: any[]): Promise<any> {
      return apiAxios.post('/uploadAllFiles', files);
    }
  static submitFile(file: any): Promise<any> {
      return apiAxios.post('/uploadFiles', file);
    }
  static getFiles(files: number[]): Promise<any> {
      return apiAxios.post('/downloadFiles', files);
  }
  static deleteFiles(files: number[]): Promise<any> {
      return apiAxios.post('/deleteFiles', files);
  }
  static getImageFiles(files: number[]): Promise<any> {
      return apiAxios.post('/images', files);
  }
  static submitImageFiles(files: any): Promise<any> {
      return apiAxios.post('/upload', files);
  }
}