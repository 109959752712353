import React from 'react';
import PropTypes from "prop-types";

interface Props {
    hovered: boolean;
    className: any;
    knobClassName: any;
}

const ScrollHistory = ({ hovered, className, knobClassName }: Props): JSX.Element => {
    return (
        <div className={`scroll hovered-${hovered} ${className}`}>
            <div className={`knob ${knobClassName}`}/>
        </div>
    );
};
ScrollHistory.propTypes = {
    hovered: PropTypes.bool,
};
export default ScrollHistory;
