import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { CloseCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import style from './style.module.scss';

interface IInput extends InputNumberProps {
  value?: any;
  name?: string;
  placeholder?: string;
  min?: number;
  maxLength?: number;
  className?: string;
  onChange?: (val: any) => void;
}

const FormInputNumber: React.FC<IInput> = ({
  value,
  onChange,
  name = '',
  className = '',
  min = 0,
  maxLength = 15,
  placeholder = 'Введите число',
  ...props
}) => {
  return (
    <div className={style.box}>
      <InputNumber<any>
        {...props}
        className={classnames(style.input, { [className]: className })}
        value={value}
        controls={false}
        placeholder={placeholder}
        min={min}
        maxLength={maxLength}
        onChange={(val) => {
          if (onChange) {
            onChange(val);
          }
        }}
      />
        <CloseCircleFilled
          hidden={!value || props?.disabled}
          className={style.input__icon}
          onClick={() => {
            if (onChange) {
              onChange(null);
            }
          }}
        />
    </div>
  );
};

export default FormInputNumber;
