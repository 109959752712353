import get from 'lodash.get';

import { IColumnType, IHiddenColumn } from './Table';
import {
  Avatar,
  Dropdown,
  Flex,
  MenuProps,
  Typography,
} from 'antd';
import Icon, { UserOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { TableCell, TruncatedText } from './styled';
import moment from 'moment';
import { Checkbox } from '../Checkbox/Checkbox';
import {
  burgerIcon,
  DeleteRed20,
  RepairIcon,
} from '../../../assets/image/svg';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import style from './style.module.scss';

interface Props<T> {
  t: any;
  item: any;
  data: T[];
  column: IColumnType<T>;
  selectedRows: any;
  itemIndex: number;
  date?: boolean;
  link?: boolean;
  hiddenColumn: IHiddenColumn<T>;
  onRow?: (record: any, rowIndex: number) => void;
  subMenu?: {
    label: string;
    getSubMenu: ({
      item,
      rows,
      data,
      onClose,
    }: {
      item: any;
      rows: Set<any>;
      data: T[];
      onClose: () => void;
    }) => MenuProps['items'];
  };
  editRow?: (item: any) => void;
  deleteRow?: (row: any) => void;
  onClickLink?: ((data: any) => void) | undefined;
  isChecked?: boolean;
  onCheck?: (checked: boolean) => void;
  handleCopyAll?: (() => void) | undefined;
  handleDeleteCopyAll?: (() => void | any[]) | undefined;
}

export function TableRowCell<T>({
  item,
  column,
  onRow,
  itemIndex,
  editRow,
  deleteRow,
  onClickLink,
  isChecked,
  onCheck,
  handleCopyAll,
  handleDeleteCopyAll,
  subMenu,
  data,
  t,
  selectedRows,
}: Props<T>): JSX.Element {
  const [openContext, setOpenContext] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const title = get(item, column?.dataIndex);
  const refTitle = !column.date ? title : moment(title).format('DD.MM.YYYY');

  const subTitle = get(
    item,
    typeof column.subTitle !== 'undefined' ? column.subTitle : '',
  );
  const avatar = get(
    item,
    typeof column.avatar !== 'undefined' ? column.avatar : '',
  );
  const checkbox =
    typeof column.checkbox !== 'undefined' ? column.checkbox : false;
  const menu = typeof column.menu !== 'undefined' ? column.menu : false;

  const viewFunction = () => {
    setOpenPopover(false);
    if (onRow) {
      onRow(item, itemIndex);
    }
  };

  const handleEdit = (row: any) => {
    setOpenPopover(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    editRow ? editRow(row) : null;
  };

  const handleDelete = (row: any) => {
    setOpenPopover(false);
    if (!row.delete) {
      setConfirmation(true);
    } else {
      if (deleteRow) {
        deleteRow(row);
      }
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (newOpen) {
      setOpenContext(false);
    }
    setOpenPopover(newOpen);
  };

  const getAllValues = (obj: any) => {
    let values: any = [];
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        values = values.concat(getAllValues(obj[key]));
      } else {
        values.push(obj[key]);
      }
    }
    return values;
  };

  const handleCopy = async (row: any, column: any) => {
    setOpenPopover(false);
    const values1 = getAllValues(row).join(', ');
    await navigator.clipboard.writeText(values1);
  };


  const items: MenuProps['items'] = [
    {
      label: <div onClick={handleCopyAll}>{t('Копировать выделенное')}</div>,
      key: '1',
    },
    {
      label: <div onClick={handleDeleteCopyAll}>{t('Удалить выделенное')}</div>,
      key: '2',
    },
  ];

  const openChangeContext = (open: boolean) => {
    setOpenContext(open);
    setOpenPopover(false);
  };

  const onCloseModalConfirm = () => {
    setConfirmation(false);
  };

  const onOkModalConfirm = (row: any) => {
    setConfirmation(false);
    if (deleteRow) {
      deleteRow(row);
    }
  };
  const renderTitle = (
    type: string | undefined,
    refTitle: string | string[] | undefined,
    column?: IColumnType<any>,
  ) => {
    if (type === 'date' && refTitle) {
      // Parse the ISO 8601 date string
      const date = moment(refTitle, 'DD.MM.YYYY');
      if (date.isValid()) {
        // Format the date according to 'DD.MM.YYYY'
        return date.format('DD.MM.YYYY');
      } else {
        return moment(refTitle).format('DD.MM.YYYY');
      }
    }
    return refTitle || '';
  };

  return (
    <>
      <Dropdown
        menu={{ items }}
        open={openContext}
        trigger={['contextMenu']}
        onOpenChange={openChangeContext}
      >
        <TableCell style={{ width: column.width }}>
          {column.render ? (
            column.render(column, item)
          ) : (
            <Flex align={'center'} style={{ width: column.width }}>
              <Flex>
                {checkbox ? (
                  <div onClick={(e) => e.stopPropagation()}>
                    {!item.delete ? (
                      <Checkbox isChoice={isChecked} onChange={onCheck} />
                    ) : (
                      <DeleteRed20 />
                    )}
                  </div>
                ) : null}
              </Flex>

              <Flex align={'center'}>
                {menu ? (
                    <Dropdown
                      overlayClassName={style.menu}
                      open={openPopover}
                      onOpenChange={handleOpenChange}
                      menu={{
                        items: openPopover
                          ? [
                              {
                                key: 'link1',
                                label: (
                                  <Typography.Text
                                    className={style.menu__item}
                                  >
                                    {t('Посмотреть')}
                                  </Typography.Text>
                                ),
                                onClick: viewFunction
                              },
                              {
                                key: 'link4',
                                disabled: !!item?.delete,
                                style: !!item?.delete ? {opacity: 0.5} : {},
                                label: (
                                  <Typography.Text
                                    className={style.menu__item}
                                  >
                                    {t('Редактировать')}
                                  </Typography.Text>
                                ),
                                onClick: () => handleEdit(item)
                              },
                              {
                                key: 'link2',
                                label: (
                                  <Typography.Text
                                    className={style.menu__item}
                                  >
                                    {t('Копировать данные')}
                                  </Typography.Text>
                                ),
                                onClick: () => handleCopy(item, column)
                              },
                              {
                                key: 'link3',
                                label: (
                                  <Typography.Text
                                    className={style.menu__item}
                                  >
                                    {!item.delete
                                      ? t('Удалить')
                                      : t('Снять пометку на удаление')}
                                  </Typography.Text>
                                ),
                                onClick: () => handleDelete(item)
                              },
                              subMenu
                                ? {
                                    key: 'link5',
                                    disabled: !!item?.delete,
                                    style: !!item?.delete ? {opacity: 0.5} : {},
                                    popupClassName: style.menu__submenu,
                                    popupOffset: [20, 0],
                                    label: (
                                      <Typography.Text
                                        className={style.menu__item}

                                      >
                                        {t(subMenu.label)}
                                      </Typography.Text>
                                    ),
                                    onClick: () => handleEdit(item),
                                    children: subMenu
                                      ? subMenu.getSubMenu({
                                          item,
                                          rows: selectedRows,
                                          data,
                                          onClose: () =>
                                            handleOpenChange(false),
                                        })
                                      : [],
                                  }
                                : { key: 'link5', label: null, style: {display: 'none'} },
                            ]
                          : [],
                      }}
                      placement="bottomLeft"
                      trigger={['click']}
                    >
                      <Typography.Link
                        className={style.menu__action}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenPopover(true);
                        }}
                      >
                        <Icon component={burgerIcon} />
                      </Typography.Link>
                    </Dropdown>
                ) : null}
                <Flex gap={6} align={'center'}>
                  <Flex>
                    {typeof column.avatar !== 'undefined' ? (
                      avatar === null || avatar === undefined ? (
                        <Avatar size={36}>
                          {title
                            ?.substring(0, 2)
                            .toUpperCase()
                            .replace(/['"]+/g, '')}
                        </Avatar>
                      ) : (
                        <Avatar
                          size={36}
                          src={avatar}
                          icon={<UserOutlined />}
                        />
                      )
                    ) : (
                      ''
                    )}
                  </Flex>
                  <Flex vertical>
                    <TruncatedText>
                      {column.link ? (
                        <Typography.Link
                          ellipsis
                          style={{ color: '#0148D7' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (onClickLink) {
                              onClickLink([
                                item,
                                { column: Number(column.key) },
                              ]);
                            }
                          }}
                        >
                          {refTitle}
                        </Typography.Link>
                      ) : (
                        itemIndex ? renderTitle(column.type, refTitle, column) : null
                      )}
                      {column.dataIndex === 'repair' &&
                        item.repair === true && <Icon component={RepairIcon} />}
                      <div>{subTitle}</div>
                    </TruncatedText>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
          <ModalConfirm
            isOpen={confirmation}
            closeModal={onCloseModalConfirm}
            actionAfterConsent={() => onOkModalConfirm(item)}
          />
        </TableCell>
      </Dropdown>
    </>
  );
}
