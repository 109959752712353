import { IRelease } from "../pages/releases/types";
import socket from "../socket";

// генерация номера релиза
export const GENERATE_ID_RELEASES = 'generate-id-releases';
// получение фильтров для таблицы
export const GET_FILTERS_RELEASES = 'get-filters-releases';
// получение релизов для таблицы
export const GET_RELEASES = 'get-releases';
// создание и обновление релиза
export const SET_RELEASE = 'set-release';
// получение одного релиза
export const FIND_ONE_RELEASE = 'find-one-release';
// удаление релиза
export const DELETE_RELEASE = 'delete-release';

export class Rest_releases {
    static generateIdReleaseId(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(GENERATE_ID_RELEASES, param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getFiltersReleases(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(GET_FILTERS_RELEASES, param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getReleases(payload?: number[]): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(GET_RELEASES, payload,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static setRelease(payload: IRelease): Promise<any>{
      return new Promise(async (resolve, reject) => {
          socket.emit(SET_RELEASE, payload,  (response: any) => {
              try {
                  resolve(response);
              } catch (e) {
                  reject(e);
              }
          })
      })
  }
    static findOneRelease(id: number): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(FIND_ONE_RELEASE, id,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

    static deleteRelease(payload: IRelease): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(DELETE_RELEASE, payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

  }