import React from 'react';

import { useTranslation } from "react-i18next";
import { Col, Divider, Flex, Row, Space, Typography } from "antd";

import Icon from "@ant-design/icons";
import { FullscreenControl, Map, Placemark, YMaps } from "@pbe/react-yandex-maps";

import { copyIcon } from "../../assets/image/svg";

import './style.scss'

const {Title} = Typography;

interface Props {
    dataRow?: any
}

const FormCardModalTerminal: React.FC<Props> = ({dataRow}) => {
    const {t} = useTranslation();
    return (
        <Space direction={"vertical"}>
            <Row>
                <Col span={12}><div className={'label-card-14'}>{t("NameTerminal")}</div></Col>
                <Col span={12}><div className={'label-card-14'}>{t("Parent")}</div></Col>
            </Row>
            <Row>
                <Col span={12}>
                    <div className={'text-card-14'}>{dataRow.city_child.name}</div>
                </Col>
                <Col span={12}>
                    <div className={'text-card-14'}>{dataRow.city_child.city_terminals.name}</div>
                </Col>
            </Row>
            <Divider style={{marginTop: 5,marginBottom: 5}}/>
            <Row>
                <Col span={12}><div className={'label-card-14'}>{t("Address")}</div></Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className={'text-card-14'}>{dataRow.address_terminal}</div>
                    </Col>
            </Row>
            <Divider style={{marginTop: 5,marginBottom: 5}}/>
            <Row>
                <Col span={24}>
                    <div className={'label-card-14'}>{t("Contacts")}</div>
                </Col>
            </Row>
            <Row>
                <Col span={12}><div className={'text-card-14'}>{t("Director")}</div></Col>
                <Col span={12}>
                    <Flex vertical gap={5}>
                        <Flex gap={120}>
                            {dataRow.phone_director !== null &&
                                <>
                                    <div className={'text-card-14'}>{dataRow?.phone_director}</div>
                                    <Icon component={copyIcon}/>
                                </>
                            }
                        </Flex>
                        {dataRow.last_phone_director !== null &&
                            <Flex vertical gap={5}> {
                                dataRow.last_phone_director?.map((item: any, index: number) =>
                                    <Flex key={index} gap={120}>
                                        <div className={'text-card-14'}>{item.add_phone_director}</div>
                                        <Icon component={copyIcon}/>
                                    </Flex>
                                )
                            }
                            </Flex>
                        }
                    </Flex>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <div className={'text-card-14'}>{t("Dispatcher")}</div></Col>
                <Col span={12}>
                    <Flex vertical gap={5}>
                        <Flex gap={120}>
                            {dataRow.phone_dispatcher !== null &&
                                <>
                                    <div className={'text-card-14'}>{dataRow.phone_dispatcher}</div>
                                    <Icon component={copyIcon}/>
                                </>
                            }
                        </Flex>
                        {dataRow.last_phone_dispatcher !== null &&
                            <Flex vertical gap={5}> {
                                dataRow.last_phone_dispatcher?.map((item: any, index: number) =>
                                    <Flex key={index} gap={120}>
                                        <div className={'text-card-14'}>{item.add_phone_dispatcher}</div>
                                        <Icon component={copyIcon}/>
                                    </Flex>
                                )
                            }
                            </Flex>
                        }
                    </Flex>
                </Col>
            </Row>
            <Divider style={{marginTop: 5, marginBottom: 5}}/>
            <Row>
                <Col span={24}><div className={'label-card-14'}>{t("Capacity")}</div></Col>
            </Row>
            <Row>
                <Col span={24}><div className={'text-card-14'}>{dataRow.capacity}</div></Col>
            </Row>
            <Divider style={{marginTop: 5,marginBottom: 5}}/>
            <Flex vertical style={{marginBottom: 89}}>
                <Title level={5}>Карта</Title>
                {dataRow.city_child.city_terminals.lat !== null && dataRow.city_child.city_terminals.lon ?
                    <YMaps>
                        <Map state={{center: [dataRow.city_child.city_terminals.lon,
                                dataRow.city_child.city_terminals.lat], zoom: 5}} width={'100%'} height={150}>
                            <Placemark geometry={[dataRow.city_child.city_terminals.lon,
                                dataRow.city_child.city_terminals.lat]}/>
                            <FullscreenControl/>
                        </Map>
                    </YMaps>
                    :
                    <div className={'box-map'}>
                        <div className={'box-map text'}>Яндекс карты</div>
                    </div>
                }
            </Flex>
        </Space>
    );
};

export default FormCardModalTerminal;
