// components/CancelReasonModal.tsx
import React, { useState } from 'react';
import { Modal, Checkbox, Button } from 'antd';
import Icon from '@ant-design/icons';
import styles from '../style.module.scss';

interface CancelReasonModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (selectedReasons: string[]) => void;
}

const CancelReasonModal: React.FC<CancelReasonModalProps> = ({ isOpen, onClose, onSave }) => {
    const reasons = [
        { id: 1, reason: 'Не прошли по ставке' },
        { id: 2, reason: 'Отменился букинг' },
        { id: 3, reason: 'Нет закупа/субаренды' },
        { id: 4, reason: 'Нет ОС от клиента' },
        { id: 5, reason: 'Стоп локация' },
    ];

    const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

    const handleCheckboxChange = (reason: string) => {
        setSelectedReasons((prev) =>
            prev.includes(reason) ? prev.filter((r) => r !== reason) : [...prev, reason]
        );
    };

    const handleSave = () => {
        onSave(selectedReasons); // Передаем выбранные причины в основной компонент
        onClose();
    };

    return (
        <Modal
            style={{ maxWidth: '500px' }}
            className={styles.CancelReasonModal}
            title="Пожалуйста, выберите причину отмены запроса"
            open={isOpen}
            onCancel={onClose}
            footer={[
                <Button key="save" type="primary" onClick={handleSave} disabled={selectedReasons.length === 0}>
                    Сохранить
                </Button>,
                <Button key="cancel" type="link" danger onClick={onClose}>
                    Отменить
                </Button>,
            ]}
        >
            <div className={styles.checkboxGroup}>
                {reasons.map((item) => (
                    <Checkbox
                        key={item.id}
                        onChange={() => handleCheckboxChange(item.reason)}
                        checked={selectedReasons.includes(item.reason)}
                    >
                        {item.reason}
                    </Checkbox>
                ))}
            </div>
        </Modal>
    );
};

export default CancelReasonModal;
