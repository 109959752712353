import React from 'react';

import { Button, Col, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { columnsTransporter } from '../../constants';
import { RouteNames } from '../../../../router/names';
import { FormDatePicker } from '../../../../components/ui/Form';
import { Checkbox } from '../../../../components/ui/Checkbox/Checkbox';
import { ViewTable } from '../../../../components/ui/List/ViewTable/ViewTable';
import ViewRow from '../../../../components/ui/List/ViewRow/ViewRow';

import style from '../../style.module.scss';
import { ITab } from '../../../../store/releases';
import TooltipButton from '../../../../components/ui/TooltipButton/TooltipButton';

export const BaseTab: React.FC<ITab> = ({initialValue}) => {
  const { t } = useTranslation();

  const terminalLink = initialValue.terminal_id
    ? `${RouteNames.TERMINALS_VIEW}${initialValue.terminal_id}`
    : '';

  return (
    <div className={style.tab}>
      <Flex justify="space-between" align="center" gap={10}>
        <Col span={12}>
          {initialValue.is_transporter && (
            <Checkbox isChoice disabled>
              <Typography.Text className={style.checkboxes__label}>
                {t('Выдача физическому лицу')}
              </Typography.Text>
            </Checkbox>
          )}
          {initialValue.no_number && (
            <Checkbox isChoice={initialValue.no_number} disabled>
              <Typography.Text className={style.checkboxes__label}>
                {t('Безномерная выдача')}
              </Typography.Text>
            </Checkbox>
          )}
        </Col>
        <Col span={11}>
          <Typography.Text
            className={style.label}
            style={{ paddingLeft: '10px' }}
          >
            {t('Дата действия релиза')}
          </Typography.Text>
          <div className={style['date-block']}>
            <div className={style['date-block__item']}>
              <FormDatePicker
                isString
                value={initialValue?.date_action_start_string}
                disabled
              />
            </div>
            <div
              className={style['date-block__separator']}
              style={{ marginTop: '15px' }}
            />
            <div className={style['date-block__item']} style={{ padding: 0 }}>
              <FormDatePicker
                isString
                value={initialValue?.date_action_end_string}
                disabled
              />
            </div>
          </div>
        </Col>
      </Flex>
      <ViewRow
        title={t('Кол-во КТК')}
        subtitle={initialValue?.containers_count}
        isDivider
      />
      <ViewRow
        title={t('Подрядчик')}
        subtitle={initialValue?.contractor}
        isDivider
      />

      {!initialValue?.is_transporter && (
        <>
          <ViewRow title={t('Данные водителей')} isDivider />
          {!!initialValue?.transporter?.length && <ViewTable
            columns={columnsTransporter}
            data={initialValue.transporter}
          />}
        </>
      )}
      {initialValue?.is_transporter && (
        <>
          <ViewRow title={t('ИНН Физического лица')} subtitle={initialValue?.inn} isDivider />
          <ViewRow
            title={t('Наименование')}
            subtitle={initialValue?.company}
            isDivider
          />
        </>
      )}
      <ViewRow
        title={t('Терминал')}
        subtitle={initialValue?.terminal ? <TooltipButton propsButton={{type: 'default', size: 'middle'}}>{initialValue?.terminal}</TooltipButton> : ''}
        link={terminalLink}
      />
    </div>
  );
};
