// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_upload__QX01s {
  min-height: min-content;
}
.style_upload__QX01s .ant-upload {
  width: auto !important;
  height: auto !important;
}

.style_link__NTcjg {
  height: auto;
  padding: 0;
  margin: 0;
  background: none;
  box-shadow: none;
}
.style_link__NTcjg:hover {
  background: transparent !important;
  box-shadow: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/UploadExcelFile/style.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AACI;EACE,sBAAA;EACA,uBAAA;AACN;;AAGA;EACE,YAAA;EACA,UAAA;EACA,SAAA;EACA,gBAAA;EACA,gBAAA;AAAF;AACE;EACE,kCAAA;EACA,2BAAA;AACJ","sourcesContent":[".upload {\n  min-height: min-content;\n  :global {\n    .ant-upload {\n      width: auto !important;\n      height: auto !important;\n    }\n  }\n}\n.link {\n  height: auto;\n  padding: 0;\n  margin: 0;\n  background: none;\n  box-shadow: none;\n  &:hover {\n    background: transparent !important;\n    box-shadow: none !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload": `style_upload__QX01s`,
	"link": `style_link__NTcjg`
};
export default ___CSS_LOADER_EXPORT___;
