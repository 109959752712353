export enum RouteNames {
    LOGIN = '/login',
    REGISTRATION = '/registration',
    FORGET_PASSWORD = '/forget_password',
    CONTRACTORS = '/contractors',
    CONTACT = '/contact',
    STAFF = '/staff',
    MAIN = '/',
    CRM = '/crm',
    CRM_ID = '/crm/:id',
    CRM_AGREEMENT = '/crm/agreements',
    CRM_AGREEMENT_CREATE = '/crm/agreements/create',
    CRM_AGREEMENT_EDIT = '/crm/agreements/edit/',
    CRM_AGREEMENT_VIEW = '/crm/agreements/view/',
    CRM_COMPANY = '/crm/company',
    CRM_COMPANY_CREATE = '/crm/company/create',
    CRM_COMPANY_EDIT = '/crm/company/edit/',
    CRM_COMPANY_VIEW = '/crm/company/view/',
    CRM_CONTACTS = '/crm/contacts',
    CRM_CONTACTS_CREATE = '/crm/contacts/create',
    CRM_CONTACTS_EDIT = '/crm/contacts/edit/',
    CRM_CONTACTS_VIEW = '/crm/contacts/view/',
    CONTAINERS = '/containers',
    CONTAINERS_ONE = '/containers/one/:id',
    CONTAINERS_ID = '/containers/:id',
    CONTAINERS_CREATE = '/containers/create',
    CONTAINERS_EDIT = '/containers/edit/',
    CONTAINERS_VIEW = '/containers/view/',
    TERMINALS = '/terminals',
    TERMINALS_ID = '/terminals/:id',
    TERMINALS_CREATE = '/terminals/create',
    TERMINALS_EDIT = '/terminals/edit/',
    TERMINALS_VIEW = '/terminals/view/',
    DEPOT = '/depot',
    SALES = '/sales',
    SALES_DEAL = '/sales/deals',
    SALES_DEAL_ID = '/sales/deals/:id',
    SALES_DEAL_CREATE = '/sales/deals/0',
    SALES_DEAL_EDIT = '/sales/deals/',
    SALES_DEAL_VIEW = '/sales/deals/view/',
    SALES_DEAL_VIEW_ID = '/sales/deals/view/:id',

    RELEASES = '/releases',
    RELEASES_ID = '/releases/:id',
    RELEASES_CREATE = '/releases/create',
    RELEASES_EDIT = '/releases/edit/',
    RELEASES_VIEW = '/releases/view/',
    RELEASES_VIEW_ID = '/releases/view/:id',

    RENT = '/rent',
    RENT_DEAL = '/rent/deals',
    RENT_DEAL_ID = '/rent/deals/:id',
    RENT_DEAL_CREATE = '/rent/deals/0',
    RENT_DEAL_EDIT = '/rent/deals/',
    RENT_DEAL_VIEW = '/rent/deals/view/',
    RENT_DEAL_VIEW_ID = '/rent/deals/view/:id',

    RENT_REQUESTS = '/rent/requests',
    RENT_REQUESTS_CREATE = '/rent/requests/0',
    RENT_REQUESTS_EDIT = '/rent/requests/',
    RENT_REQUESTS_VIEW = '/rent/requests/view/',
    RENT_REQUESTS_VIEW_ID = '/rent/deals/requests/:id',

    PAGE_ERROR = '/error',
    NOT_FOUND = '*',
}
