import { useEffect, useState } from 'react';

import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { AutoComplete, Button, Flex, Input, Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { columns } from './columns';
import { FormDeal, ViewDeal } from './components';
import { RouteNames } from '../../../router/names';
import Container from '../../../components/Container';
import { Table } from '../../../components/ui/Table/Table';
import { RootState, useStoreDispatch } from '../../../store';
import { CREATE, EDIT } from '../../../helpers/string-helpers';

import { useBreadcrumb, useMenu, useParamsHistory } from '../../../hooks';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import {
  defaultValuesDeal,
  findOneDeal,
  IDeal,
  setOneDeal,
  setResetState,
} from '../../../store/rent';
import socket from '../../../socket';
import { filterResultKtk } from './utils';
import { FIND_ONE_DEAL_RENT } from '../../../services/rest_rent';
import { openInNewTab } from '../../../helpers/link-helper';

const EditPageDeal = () => {
  useBreadcrumb(['Аренда', 'Сделки']);
  useMenu({
    openKey: RouteNames.RENT,
    activeKeys: [RouteNames.RENT, RouteNames.RENT_DEAL],
  });
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const dealOneData = useSelector<RootState>(
    (state) => state.rent.dealOneData,
  ) as IDeal;
  const loadingOneDeal = useSelector<RootState>(
    (state) => state.rent.loadingOneDeal,
  ) as boolean;
  const [typeForm, setTypeForm] = useState<string>('');
  const [openDrawerDeal, setOpenDrawerDeal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  useEffect(() => {
    dispatch(setResetState());
    const reg = /\d+/g;
    const [, current] = pathname.split(RouteNames.RENT_DEAL_EDIT);
    if (!current) return history.push(RouteNames.RENT_DEAL);

    const isCreate = pathname.split('/')?.at(-1) === '0';

    if (isCreate) {
      setTypeForm(CREATE);
      dispatch(
        setOneDeal(
          location?.state?.rent
            ? {
                ...defaultValuesDeal,
                ...location.state.rent,
                containers: filterResultKtk(location.state.deal?.containers),
              }
            : { ...defaultValuesDeal },
        ),
      );
      setOpenDrawerDeal(true);
      return;
    }

    const isEdit = reg.test(current);
    if (isEdit) {
      return;
      dispatch(findOneDeal(+current))
        .then((res) => {
          if (res?.payload?.deal?.id) {
            setOpenDrawerDeal(true);
            setTypeForm(EDIT);
          } else {
            history.push(RouteNames.RENT_DEAL);
          }
        })
        .catch((err) => {
          history.push(RouteNames.RENT_DEAL);
        })
        .finally(() => {
          socket.off(FIND_ONE_DEAL_RENT);
        });
      return;
    }
    return history.push(RouteNames.RENT_DEAL);
  }, [pathname]);

  const onRelease = () => {
    openInNewTab(RouteNames.RELEASES_CREATE);
  };

  const onCloseFormDeal = (isClear?: boolean) => {
    if (!isClear) {
      dispatch(setResetState());
    }
    setOpenDrawerDeal(false);
    history.push(RouteNames.RENT_DEAL);
  };

    const handleShowDrawerDeal = () => {
      history.push(RouteNames.RENT_DEAL_CREATE);
    };

  return (
    <Container>
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('Deal')}</div>
        </Flex>
        <Flex gap={16}>
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff' }}
            onClick={handleShowDrawerDeal}
          />
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            popupMatchSelectWidth={false}
          >
            <Input placeholder="Введите текст" />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          loading={loadingOneDeal}
          columns={columns}
          dataSource={[dealOneData]}
          height={'calc(-162px + 100vh)'}
          pagination={{
            current: 1,
            pageSize: 1,
            total: 1,
            showSizeChanger: true,
          }}
        />
      </Space>
      <CustomDrawer
        open={openDrawerDeal && !loadingOneDeal}
        onClose={onCloseFormDeal}
        isHeight
        minWidth={1000}
      >
        {typeForm !== CREATE && (dealOneData?.delete || !dealOneData?.edit) ? (
          <ViewDeal
            initialValue={dealOneData}
            onClose={onCloseFormDeal}
            isOpen={openDrawerDeal}
            onRelease={onRelease}
            onEdit={() => setTypeForm(EDIT)}
          />
        ) : (
          <FormDeal
            title={
              typeForm === CREATE ? 'Создать сделку' : 'Редактировать сделку'
            }
            initialValue={dealOneData}
            isOpen={openDrawerDeal}
            onClose={onCloseFormDeal}
            onRelease={onRelease}
          />
        )}
      </CustomDrawer>
    </Container>
  );
};

export default EditPageDeal;
