import React from 'react';

import classnames from 'classnames';
import { Divider, Flex, Typography } from 'antd';

import { useParamsHistory } from '../../../../hooks';
import style from '../../style.module.scss';

interface IRow {
  title: string;
  subtitle: string | {text: string, link: string}[] | React.ReactNode;
  isDivider?: boolean;
  link?: string;
}

const Row: React.FC<IRow> = ({ title, subtitle, isDivider, link }) => {
  const {history} = useParamsHistory();
  const toPathLink = () => {
    if (link) {
      history.replace(link);
    }
  }
  return (
    <>
      <Flex gap={4} vertical className={style["view-row"]}>
          <Typography.Title level={4}  ellipsis={{tooltip: {placement: "topLeft", title}}} className={style["view-row__title"]}>
            {title}
          </Typography.Title>
        {Array.isArray(subtitle) ? (
          subtitle?.map(({text, link}, i) => (
            <Typography.Title key={text} level={5} ellipsis={{tooltip: {placement: "topLeft", title: text}}} className={classnames(style['view-row__subtitle'], {[style['view-row__subtitle_link']] : link})} onClick={toPathLink}>
              {text}
            </Typography.Title>
          ))
        ) : (
            <Typography.Title level={5} ellipsis={{tooltip: {placement: "topLeft", title: subtitle}}} className={classnames(style['view-row__subtitle'], {[style['view-row__subtitle_link']] : link})} onClick={toPathLink}>
              {subtitle}
            </Typography.Title>
        )}
      </Flex>
      {isDivider && <Divider style={{ marginTop: 8, marginBottom: 8 }} />}
    </>
  );
};

export default Row;
