// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_icon__\\+rmh3 {
  color: #858FA3;
  background: none;
}
.style_icon__\\+rmh3:hover {
  color: #0E1117;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/CopyButton/style.module.scss","webpack://./src/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA;EACE,cCsCmB;EDrCnB,gBAAA;AADF;AAEE;EACE,cC+BiB;AD/BrB","sourcesContent":["@import '../../../assets/scss/_variables';\n\n.icon {\n  color: $color-icon-default;\n  background: none;\n  &:hover {\n    color: $color-text-default;\n  }\n}","$button-color: #0055FF;\n$button-color--hover: #0041C3;\n$button-color--hover-before: #003CB4;\n$button-color--text: #ffffff;\n$button-color--radius: 6px;\n$button-color--default: #445371;\n$button-color--border-icon: #E2E4E9;\n$button-color--border-icon-hover: #109BFF;\n\n$content-color: #F8F8F8;\n$sider-color: #FFFFFF;\n$border-color: #E2E4E9;\n$shadow-color: #E2E4E9;\n$white-color: #fff;\n$bg-error-message: #0e11170a;\n$bg-color: #fff;\n$tab-color: #fff;\n$tab-text-color: #0E1117;\n$tab-active-color: #0148D6;\n$tab-bg-active-color: #E1E9FA;\n$tab-text-active-color: #0148D6;\n$tab-bg-active-color: #E1E9FA;\n$tab-text-active-color: #0E1117;\n$dark-color: #0E1117;\n$error-color: #E14453;\n$success-color: #00B288;\n$error-light-color: #FCE8EA;\n$white-color: #fff;\n// background\n$bg-light-color: #F8F8F8;\n$bg-success-color: #E6F6EC;\n$bg-error-color: #FCE8EA;\n\n$hover-item-menu: rgba(0, 85, 255, 0.10);\n$color-hover-icon-menu: #0148D7;\n$color-bg-hover-icon-menu: #E1E9FA;\n$hover-item-menu: rgba(0, 85, 255, 0.10);\n$color-text-default: #0E1117;\n$color-label-page-default: #445371; // по макету\n$color-input-icon: #0e111740;\n$color-label-default: #a3a9b1;\n$color-icon-default: #858FA3;\n$color-link-default: #05F;\n$color-checkbox-default: #37F;\n$color-primary: #05F;\n\n$font-page: -apple-system, Manrope, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\nsans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `style_icon__+rmh3`
};
export default ___CSS_LOADER_EXPORT___;
