import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Flex, Typography } from 'antd';

import Row from './view/Row';
import style from '../style.module.scss';
import { RootState } from '../../../store';
import { IAgreement, IForm } from '../types';
import { useParamsHistory } from '../../../hooks';
import { RouteNames } from '../../../router/names';
import { fileTypes } from '../../../helpers/input-helpers';
import UploaderDocs from '../../../components/ui/UploaderDocs';
import CopyButton from '../../../components/ui/CopyButton/CopyButton';
import TooltipButton from '../../../components/ui/TooltipButton/TooltipButton';
import { CopyBufferIconDefault, EditViewIconDefault } from '../../../assets/image/svg';

export const ViewAgreements: React.FC<IForm> = ({
  onClose,
  isOpen,
  title = 'Договор',
}) => {
  const { t } = useTranslation();
  const { location, history } = useParamsHistory();
  const { pathname } = location;
  const initialValue = useSelector<RootState>(
    (state) => state.agreement.agreement,
  ) as IAgreement;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={style['tabs-form']}>
      <Flex vertical gap={24}>
        <Flex justify="space-between" align="center">
          <Flex gap={10} align="center" style={{ marginTop: 15 }}>
            <Typography.Text className={style.title}>
              {initialValue?.id ? `Договор №${initialValue.agreement_id}` : t(title)}
            </Typography.Text>
          </Flex>
          <Flex gap={2} align={'center'}>
            <TooltipButton
              propsTooltip={{ title: t('Редактировать'), placement: 'topLeft' }}
              propsButton={{
                icon: <EditViewIconDefault />,
                type: 'text',
                className: style.icon,
                style: { padding: 0, height: 'auto' },
                onClick: () => {
                    history.push(
                        RouteNames.CRM_AGREEMENT_EDIT + initialValue.id);
                    }
              }}
            />
            <CopyButton
              className={style.icon}
              text={pathname}
              textSuccess={t('Ссылка скопирована')}
              icon={CopyBufferIconDefault}
            />
          </Flex>
        </Flex>
        <Flex justify="space-between" align="start">
          <Col span={10}>
            <Flex vertical gap={15}>
              <Row
                title="Дата подписания"
                subtitle={initialValue?.date_string}
                isDivider
              />
              <Row
                title="Тип договора"
                subtitle={initialValue?.type}
                isDivider
              />
              <Row
                title="Исполнитель"
                subtitle={initialValue?.executor}
                isDivider
                link={
                  initialValue?.executor_id
                    ? `${RouteNames.CRM_COMPANY_VIEW}${initialValue.executor_id}`
                    : ''
                }
              />
              <Row title="Заказчик" subtitle={initialValue?.client} isDivider    link={
                  initialValue?.client_id
                    ? `${RouteNames.CRM_COMPANY_VIEW}${initialValue.client}`
                    : ''
                } />
            </Flex>
          </Col>
          <Col span={13}>
            <Typography.Text
              className={style.label}
              style={{ paddingLeft: '10px' }}
            >
              {t('Файлы')}
            </Typography.Text>
            <div
              className={classnames(style['file-box'], 'all-custom-v-scroll')}
            >
              <UploaderDocs
                isView
                isOpen={isOpen}
                ids={initialValue?.file_ids}
                accept={[
                  fileTypes.doc,
                  fileTypes.xls,
                  fileTypes.pdf,
                  fileTypes.jpeg,
                ].join(',')}
              />
            </div>
          </Col>
        </Flex>
      </Flex>
      <Flex
        gap={8}
        justify="end"
        style={{ margin: '32px 0 10px auto', maxWidth: '500px' }}
      >
        <Button
          onClick={handleClose}
          type="primary"
          style={{ maxWidth: '174px', minWidth: '174px' }}
        >
          {t('Закрыть')}
        </Button>
      </Flex>
    </div>
  );
};
