import { useEffect, useState } from 'react';

import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { AutoComplete, Button, Flex, Input, Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { columns } from './columns';
import { FormDeal, ViewDeal } from './components';
import { RouteNames } from '../../../router/names';
import Container from '../../../components/Container';
import { Table } from '../../../components/ui/Table/Table';
import { RootState, useStoreDispatch } from '../../../store';
import { useBreadcrumb, useMenu, useParamsHistory } from '../../../hooks';
import { EDIT, getTypeForm, VIEW } from '../../../helpers/string-helpers';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import {
  findOneDeal,
  IDeal,
  setResetState,
} from '../../../store/sales';
import socket from '../../../socket';
import { FIND_ONE_DEAL_SALES } from '../../../services/rest_deal';

const ViewPageDeal = () => {
  useBreadcrumb(['Продажи', 'Сделки']);
  useMenu({openKey: RouteNames.SALES, activeKeys: [RouteNames.SALES, RouteNames.SALES_DEAL]});
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const dealOneData = useSelector<RootState>(
    (state) => state.sales.dealOneData,
  ) as IDeal;
  const loadingOneDeal = useSelector<RootState>(
    (state) => state.sales.loadingOneDeal,
  ) as boolean;
  const [openDrawerDeal, setOpenDrawerDeal] = useState<boolean>(false);
  const [typeForm, setTypeForm] = useState(VIEW);
  const [current, setCurrent] = useState<number>(1);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const onCloseFormDeal = () => {
    dispatch(setResetState());
    setOpenDrawerDeal(false);
    history.push(RouteNames.SALES_DEAL);
  };
  const onEditFormDeal = () => {
    setTypeForm(EDIT);
  };

  useEffect(() => {
    dispatch(setResetState());
    const reg = /\d+/g;
    const [, current] = pathname.split(RouteNames.SALES_DEAL);
    const path = getTypeForm(current);

    if (!path) return;

    switch (path) {
      case VIEW:
        const idView = pathname.split(`${VIEW}/`).at(-1) ?? '';
        const isView = reg.test(idView);
        if (isView) {
          dispatch(findOneDeal(+idView))
            .then((res) => {
              if (res?.payload?.deal?.id) {
                setOpenDrawerDeal(true);
              } else {
                history.push(RouteNames.SALES_DEAL);
              }
            })
            .catch((err) => {
              history.push(RouteNames.SALES_DEAL);
            })
            .finally(() => {
              socket.off(FIND_ONE_DEAL_SALES);
            });
          return;
        }
        return history.push(RouteNames.SALES_DEAL);
      default:
        history.push(RouteNames.SALES_DEAL);
    }
  }, [pathname]);

  return (
    <Container>
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('Deal')}</div>
        </Flex>
        <Flex gap={16}>
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff' }}
          />
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            popupMatchSelectWidth={false}
          >
            <Input placeholder="Введите текст" />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          loading={loadingOneDeal}
          columns={columns}
          dataSource={[dealOneData]}
          height={'calc(-162px + 100vh)'}
          pagination={{
            pageSize: 1,
            total: 1,
            current: current,
            showSizeChanger: true,
          }}
        />
      </Space>
      <CustomDrawer open={openDrawerDeal} onClose={onCloseFormDeal} isHeight>
        {typeForm === EDIT ? (
          <FormDeal
            initialValue={dealOneData}
            onClose={onCloseFormDeal}
            isOpen={openDrawerDeal && !loadingOneDeal}
          />
        ) : (
          <ViewDeal
            initialValue={dealOneData}
            onClose={onCloseFormDeal}
            onEdit={onEditFormDeal}
            isOpen={openDrawerDeal && !loadingOneDeal}
          />
        )}
      </CustomDrawer>
    </Container>
  );
};

export default ViewPageDeal;
