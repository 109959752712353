import React, { useRef, useEffect } from "react";
import { Form, Input, Select, Checkbox } from "antd";
import styles from "./style.module.scss";

interface Column {
  title: string;
  dataIndex: string;
  key: string;
}

interface EditableTableProps {
  fields?: any[];
  columns?: Column[];
  rowCount?: number;
  onAddRow?: () => void;
  columnWidths?: string;
  selectedRows?: Set<number>;
  onRowSelect?: (index: number, selected: boolean) => void;
  isEditable?: boolean;
}

const EditableTable: React.FC<EditableTableProps> = ({
  fields = [],
  columns = [],
  rowCount = 0,
  onAddRow,
  columnWidths = "1fr",
  selectedRows = new Set(),
  onRowSelect = () => {},
  isEditable = true,
}) => {
  const tableBodyRef = useRef<HTMLDivElement>(null);
  const checkboxContainerRef = useRef<HTMLDivElement>(null);

  const selectOptions: Record<string, { value: string; label: string }[]> = {
        //подтягивание данных не хочет корректно отображаться
        // type: (dataTypeContainers || []).map((container) => ({
        //   value: container?.id?.toString() || "",
        //   label: container?.name || container?.label || "Не указано", 
        // })),
        type: [
          { value: "20DC", label: "20DC" },
          { value: "40HC", label: "40HC" },
          { value: "20REF", label: "20REF" },
          { value: "40REF", label: "40REF" },
          { value: "20OT", label: "20OT" },
          { value: "40OT", label: "40OT" },
          { value: "20FR", label: "20FR" },
          { value: "40FR", label: "40FR" },
        ],
        condition: [
          { value: "NEW", label: "NEW" },
          { value: "CW", label: "CW" },
          { value: "IICL", label: "IICL" },
        ],
        paymentOption: [
          { value: "Наличный", label: "Наличный" },
          { value: "Безналичный", label: "Безналичный" },
        ],
      };

  useEffect(() => {
    const syncScroll = () => {
      if (tableBodyRef.current && checkboxContainerRef.current) {
        checkboxContainerRef.current.scrollTop = tableBodyRef.current.scrollTop;
      }
    };

    tableBodyRef.current?.addEventListener("scroll", syncScroll);
    return () => {
      tableBodyRef.current?.removeEventListener("scroll", syncScroll);
    };
  }, []);

  return (
    <div className={`${styles["table-wrapper"]} ${
      !isEditable ? styles["non-editable"] : ""}`}
    >
      <div
        className={styles["table"]}
        style={{ "--columns": columnWidths } as React.CSSProperties}
      >
        <div className={styles["table-header"]}>
          {columns.map((col: Column) => (
            <div key={col.key} className={styles["table-header-cell"]}>
              {col.title}
            </div>
          ))}
        </div>
        <div className={styles["table-body"]} ref={tableBodyRef}>
          {fields.slice(0, rowCount).map((field: any, index: number) => (
            <div key={`row-${field.key}`} className={styles["table-row"]}>
              {columns.map((col: Column) => (
                <div key={col.key} className={styles["table-cell"]}>
                  <Form.Item name={[field.name, col.dataIndex]} noStyle>
                    {col.dataIndex in selectOptions ? (
                      <Select
                        options={selectOptions[col.dataIndex]}
                        placeholder="Выберите"
                        disabled={!isEditable}
                      />
                    ) : (
                      <Input
                        placeholder={`${col.title}`}
                        disabled={!isEditable}
                        className={styles["table-input"]}
                      />
                    )}
                  </Form.Item>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {isEditable && (
        <div
          className={styles["checkbox-container"]}
          ref={checkboxContainerRef}
          style={{
            position: "absolute",
            left: -40,
            top: 0,
            height: "100%",
            overflowY: "hidden",
          }}
        >
          {fields.slice(0, rowCount).map((field: any, index: number) => (
            <Checkbox
              key={`checkbox-${field.key}-${index}`}
              checked={selectedRows.has(index)}
              onChange={(e) => onRowSelect(index, e.target.checked)}
              className={styles["checkbox-item"]}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default EditableTable;
