import React from 'react';
import { Tooltip, Button, TooltipProps, ButtonProps } from 'antd';

interface ITooltipButton {
  propsTooltip?: TooltipProps;
  propsButton: ButtonProps;
  children?: React.ReactNode
}

const TooltipButton :React.FC<ITooltipButton> = ({propsTooltip, propsButton, children}) => {
  return (
    propsTooltip ? <Tooltip {...propsTooltip}>
      {children ? <Button {...propsButton}>{children}</Button> : <Button {...propsButton} />}
    </Tooltip> : children ? <Button {...propsButton}>{children}</Button> : <Button {...propsButton} />
  )
}

export default TooltipButton;
