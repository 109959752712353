import React from 'react';
import {Button, Modal} from "antd";
import Icon from "@ant-design/icons";
import {CloseButton} from "./CloseButton";
import {errorImportIcon} from "../../../assets/image/svg";
import style  from './style.module.scss'


interface ModalConfirmProps {
    actionAfterConsent?: () => void;
    actionAfterReject?: () => void;
    isOpen: boolean;
    closeModal: () => void;
    title?: string;
    subtitle?: string;
}

const ModalConfirm = ({isOpen, closeModal, actionAfterConsent, actionAfterReject, title = 'Поставить на удаление', subtitle = 'Уверены, что хотите поставить на удаление?'}: ModalConfirmProps) => {

    const onCloseModalConfirm = () => {
        closeModal();
        if (typeof actionAfterReject  === 'function') {
            actionAfterReject();
        }
    }

    const onOkModalConfirm = () => {
        closeModal();
        if (typeof actionAfterConsent  === 'function') {
            actionAfterConsent();
        }

    }
    return (
        <Modal closable={false} footer={null} open={isOpen} centered zIndex={2000}>
            <div className={style.screen}>
                <div className={style["icon-container"]}>
                    <Icon component={errorImportIcon} className={style["element-alert-attention"]}/>
                </div>
                <div className={style["content-confirm"]}>
                    <div className={style["body"]}>
                        <div className={style["text"]}>
                            <div className={style["header"]}>{title}</div>
                            <p className={style["subtitle"]}>
                                {subtitle}
                            </p>
                        </div>
                        <CloseButton className={style["close-button-instance"]} size="s" state="default"
                                    onCloseModalConfirm={onCloseModalConfirm}/>
                    </div>
                    <div className={style["action-buttons"]}>
                        <div className={style["function-button"]}>
                            <div className={style["label-wrapper"]}>
                                <Button onClick={onOkModalConfirm}>Да</Button>
                            </div>
                        </div>
                        <div className={style["div"]}>
                            <div className={style["label-wrapper"]}>
                                <Button onClick={onCloseModalConfirm}>Нет</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalConfirm;
