// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_menu__G3Kt9 ul[role=menu]:first-of-type {
  padding-right: 20px !important;
}
.style_menu__action__oUAJE {
  padding: 4px 0 0 15px;
}
.style_menu__submenu__TwP6f ul[role=menu] {
  min-width: 150px;
}
.style_menu__submenu__TwP6f li[role=menuitem]:has([data-id=link]):hover {
  background-color: rgba(0, 85, 255, 0.1) !important;
}
.style_menu__submenu__TwP6f li[role=menuitem]:has([data-id=link]):hover span {
  color: #05F !important;
}

.style_container__over__ypVfa {
  margin: 0 16px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #b8b9b9 #dddddd;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Table/style.module.scss"],"names":[],"mappings":"AAGE;EACE,8BAAA;AAFJ;AAKE;EACE,qBAAA;AAHJ;AAOI;EACE,gBAAA;AALN;AAQI;EACE,kDAAA;AANN;AAQM;EACE,sBAAA;AANR;;AAaE;EACE,cAAA;EACA,cAAA;EACA,qBAAA;EACA,gCAAA;AAVJ","sourcesContent":["@import '../../../assets/scss/variables';\n\n.menu {\n  ul[role=\"menu\"]:first-of-type {\n    padding-right: 20px !important;\n  }\n\n  &__action {\n    padding: 4px 0 0 15px;\n  }\n\n  &__submenu {\n    ul[role=\"menu\"] {\n      min-width: 150px;\n    }\n\n    li[role=\"menuitem\"]:has([data-id=\"link\"]):hover {\n      background-color: $hover-item-menu !important;\n\n      span {\n        color: $color-link-default !important;\n      }\n    }\n  }\n}\n\n.container {\n  &__over {\n    margin: 0 16px;\n    overflow: auto;\n    scrollbar-width: thin;\n    scrollbar-color: #b8b9b9 #dddddd;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `style_menu__G3Kt9`,
	"menu__action": `style_menu__action__oUAJE`,
	"menu__submenu": `style_menu__submenu__TwP6f`,
	"container__over": `style_container__over__ypVfa`
};
export default ___CSS_LOADER_EXPORT___;
