import {Card, Flex, notification, Row} from "antd";
import {FC, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {RouteNames} from "../../router/names";
import Button from "../../components/ui/Button/Button";
import LoginForm from "../../components/LoginForm/LoginForm";
import {Utils} from "../../utils";

const Login: FC = () => {
    const router = useHistory()
    const location = useLocation();
    const [api, contextHolder] = notification.useNotification();

    const clickRegistration = () => {
        router.push(RouteNames.REGISTRATION)
    }

    useEffect(() => {
        const param: any = location.state;
        if (param !== undefined && param !== null){
            if (param?.param?.message) {
                Utils.InfoOpenNotification('topRight', param.param.message,5, api);
            }
            router.replace({
                pathname: location.pathname,
                state: null,
            });
        }
    }, []);

    return (
        <div className="container-login">
            {contextHolder}
            <div style={{position: 'absolute', right: 20, top: 30}}>
                <p style={{display: 'inline', margin: 10, fontWeight: 600, fontFamily: 'Manrope'}}>Нет аккаунта?</p>
                <Button onClick={clickRegistration} type='primary' style={{width: 200}}>Зарегистрироваться</Button>
            </div>
            <Row justify="space-around" align="middle"  className="h100">
                <Card className={'card-login'}>
                    <Flex justify='center' align='center' vertical>
                        <LoginForm clickRegistration={clickRegistration}/>
                    </Flex>
                </Card>
            </Row>
        </div>
    );
};

export default Login;
