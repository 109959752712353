// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
  width: 100%;
}
.input.header-depo {
  width: 114px;
}

.space {
  width: 100%;
  padding: 8px 12px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/select-search.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,YAAA;AAEJ;;AACA;EACE,WAAA;EACA,iBAAA;AAEF","sourcesContent":[".input{\n  width: 100%;\n  &.header-depo {\n    width: 114px;\n  }\n}\n.space{\n  width: 100%;\n  padding: 8px 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
