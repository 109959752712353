import socket from "../socket";

export class Rest_park{
    static getPark(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-park', (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
}