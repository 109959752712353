import React, { ReactNode, useState } from 'react';
import classnames from 'classnames';
import {
  TableProps,
  Table,
  FormListFieldData,
  Form,
  Tooltip,
  Input,
  Button,
  Typography,
  Select,
  TablePaginationConfig,
} from 'antd';
import style from './style.module.scss';
import { EditIcon } from '../../../../assets/image/svg';
import { ColumnType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

export interface IColumnsEditTable extends ColumnType<any> {
  title: React.ReactNode;
  dataIndex: any;
  type?: 'select' | 'input' | 'date';
  source?: any[];
  placeholder?: string;
  onHandler?: (evt?: any) => void;
}

interface IFormTable extends TableProps {
  label?: string;
  className?: string;
  name: string;
  defaultColumns: IColumnsEditTable[];
  pagination?: false | TablePaginationConfig;
}

interface EditTableCellProps {
  title: React.ReactNode;
  dataIndex: any;
  record: any;
  type?: 'select' | 'input' | 'date';
  index: any;
  source?: any[];
  placeholder?: string;
  onHandler?: (evt?: any) => void;
  fields: FormListFieldData[];
  data: any[];
  render?: (value: any, record: any, index: number) => ReactNode;
}

const EditableCell: React.FC<React.PropsWithChildren<EditTableCellProps>> = ({
  title,
  children,
  dataIndex,
  record,
  type,
  index,
  source,
  placeholder,
  onHandler,
  fields,
  data,
  render,
  ...restProps
}) => {
  const [editCell, setEditCell] = useState();
  const toggleEditContainer = (index: any) => {
    setEditCell(index);
  };
  return (
    <td {...restProps}>
      {type ? (
        <Tooltip placement={'top'} title={data?.[index]?.[dataIndex]}>
          {editCell === `${fields[index].name}${dataIndex}` ? (
            <Form.Item name={[fields[index].name, dataIndex]}>
              {type === 'input' ? (
                <Input
                  autoFocus
                  allowClear
                  style={{ width: '100%' }}
                  onBlur={() => toggleEditContainer(null)}
                />
              ) : (
                <Select
                  allowClear
                  placeholder={placeholder}
                  style={{ width: '100%' }}
                  options={source}
                  onSelect={onHandler}
                  optionLabelProp="label"
                  onBlur={() => toggleEditContainer(null)}
                />
              )}
            </Form.Item>
          ) : (
            <Button
              style={{
                paddingLeft: '10px',
                paddingRight: '5px',
                width: '100%',
                minWidth: '120px',
                justifyContent: 'space-between',
              }}
              icon={<EditIcon />}
              iconPosition="end"
              onClick={() =>
                toggleEditContainer(`${fields[index].name}${dataIndex}`)
              }
            >
              <Typography.Text ellipsis>
                {data?.[index]?.[dataIndex]}
              </Typography.Text>
            </Button>
          )}
        </Tooltip>
      ) :
      render ? (
        render(data?.[index]?.[dataIndex], data?.[index], index)
      ) : (
        <Typography.Text
          ellipsis={{
            tooltip: {
              placement: 'leftBottom',
              title: data?.[index]?.[dataIndex],
            },
          }}
        >
          {data?.[index]?.[dataIndex]}
        </Typography.Text>
      )}
    </td>
  );
};

const EditTableRow: React.FC = ({ ...props }: any) => (
  <tr {...props} key={props?.className} />
);

const FormTable: React.FC<IFormTable> = ({
  label,
  name,
  className = '',
  defaultColumns,
  pagination = false,
  ...props
}) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const data = Form.useWatch(name, { form, preserve: true });
  return (
    <Form.List name={name}>
      {(fields: FormListFieldData[], operation, meta: { errors: any }) => (
        <div
          className={classnames(style.container, { [className]: className })}
        >
          {label && (
            <Typography.Text className={style.label}>
              {t(label)}
            </Typography.Text>
          )}
          <Table
            {...props}
            dataSource={fields?.length ? fields : undefined}
            columns={defaultColumns?.map((col: any) => {
              return {
                ...col,
                onCell: (record: any, index: any) => ({
                  record,
                  dataIndex: col?.dataIndex,
                  type: col.type,
                  source: col?.source,
                  placeholder: col?.placeholder,
                  link: col?.link,
                  onHandler: col?.onHandler,
                  index,
                  fields,
                  data,
                  render: col?.render,
                }),
              };
            })}
            components={{
              body: {
                row: EditTableRow,
                cell: EditableCell,
              },
            }}
            pagination={pagination}
          />
        </div>
      )}
    </Form.List>
  );
};

export default FormTable;
