
import socket from "../socket";
import { IAgreement, ITypeFindAgreements } from "../store/agreement";

export const FIND_AGREEMENTS_COMPANY = 'find-agreements-company';
export const GET_FILTERS_AGREEMENTS = 'get-filters-agreements';
export const TYPE_AGREEMENT = 'type-agreement';
export const GET_AGREEMENTS = 'get-agreements';
export const SET_AGREEMENT = 'set-agreement';
export const FIND_ONE_AGREEMENT = 'find-one-agreement';
export const DELETE_AGREEMENT = 'delete-agreement';

export class Rest_agreement {
    static findAgreementsCompany(payload: ITypeFindAgreements): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(FIND_AGREEMENTS_COMPANY, payload,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getFiltersAgreements(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(GET_FILTERS_AGREEMENTS, param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getAgreementTypes(payload?: number[]): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(TYPE_AGREEMENT, payload,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getAgreements(payload?: number[]): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(GET_AGREEMENTS, payload,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static setAgreement(payload: IAgreement): Promise<any>{
      return new Promise(async (resolve, reject) => {
          socket.emit(SET_AGREEMENT, payload,  (response: any) => {
              try {
                  resolve(response);
              } catch (e) {
                  reject(e);
              }
          })
      })
  }
    static findOneAgreement(id: number): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(FIND_ONE_AGREEMENT, id,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

    static deleteAgreement(payload: IAgreement | IAgreement[]): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(DELETE_AGREEMENT, payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

  }