import {Utils} from "../utils";


export class Helpers {
    static localGroupEntities = async (entities: string) => {
        try {
            const local = await Utils.decrypt(Utils.getStorage('ent'));
            let arResult = local.find((item: any) => {
                return item.entities === entities
            })
            return arResult?.status
        } catch (e) {
            console.error(e)
        }
    }
}
