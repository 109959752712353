import React from 'react';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Space } from 'antd';

import { columns } from './columns';
import style from '../style.module.scss';
import { filtersAgreements } from '../../../store/filters';
import { RootState, useStoreDispatch } from '../../../store';
import { useCsvExport, useParamsHistory } from '../../../hooks';
import {
  IColumnType,
  IPagination,
  Table,
} from '../../../components/ui/Table/Table';
import {
  setCount,
  setCurrentPage,
  setLoading,
  setPage,
  setTables,
  getAgreements,
  getAgreementTypes,
  setOneAgreement,
  getFiltersAgreements,
  deleteAgreement,
  findOneAgreement,
  setAgreement,
} from '../../../store/agreement';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import { FormAgreements } from './FormAgreements';
import socket from '../../../socket';
import { IAgreement } from '../types';
import {
  CREATE,
  EDIT,
  getTypeForm,
  VIEW,
} from '../../../helpers/string-helpers';
import { ViewAgreements } from './ViewAgreements';
import { setSaveFiles } from '../../../store/sales';

interface ITableAgreements {
  paths: {create: string, parent: string, edit: string, view: string};
}

export const TableAgreements: React.FC<ITableAgreements> = ({paths}) => {
  const [isViewForm, setIsViewForm] = useState(false);
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const dispatch = useStoreDispatch();
  const data = useSelector<RootState>(
    (state) => state.agreement.agreements,
  ) as IAgreement[];
  const loading = useSelector<RootState>(
    (state) => state.agreement.loading,
  ) as boolean;
  const page = useSelector<RootState>(
    (state) => state.agreement.page,
  ) as number;
  const count = useSelector<RootState>(
    (state) => state.agreement.count,
  ) as number;
  const current = useSelector<RootState>(
    (state) => state.agreement.currentPage,
  ) as number;
  const types = useSelector<RootState>(
    (state) => state.agreement.types,
  ) as any[];



  useEffect(() => {
    setIsOpenDrawer(false);
    dispatch(setOneAgreement({}));
    const reg = /\d+/g;
    const [, current] = pathname.split(paths.parent);
    const path = getTypeForm(current);
    if (!path) {
      if (!location?.state?.table) {
        dispatch(getAgreements({ current, page, clear: '', order: 'DESC' }))
      }
      return;
    }
    switch (path) {
      case CREATE:
        const isCreate = pathname.split('/')?.at(-1) === path;
        if (isCreate) {
          setIsViewForm(false);
          setIsOpenDrawer(true);
          dispatch(setOneAgreement({}));
          return;
        }
        return history.replace(paths.create);
      case EDIT:
        const idEdit = pathname.split(`${EDIT}/`).at(-1) ?? '';
        const isEdit = reg.test(idEdit);
        if (isEdit) {
          setIsViewForm(false);
          dispatch(findOneAgreement(+idEdit)).then((res) => {
            if (res?.payload) {
              dispatch(setOneAgreement(res.payload));
              setIsOpenDrawer(true);
            } else {
              history.push(paths.parent);
            }
          }).finally(() => {
            socket.off('find-one-agreement');
          })
          return;
        }
        return history.replace(paths.parent);
      case VIEW:
        const idView = pathname.split(`${VIEW}/`).at(-1) ?? '';
        const isView = reg.test(idView);
        if (isView) {
          setIsViewForm(true);
          dispatch(findOneAgreement(+idView)).then((res) => {
            if (res?.payload) {
              dispatch(setOneAgreement(res.payload));
              setIsOpenDrawer(true);
            } else {
              history.push(paths.parent);
            }
          }).finally(() => {
            socket.off('find-one-agreement');
          })
          return;
        }
        history.replace(paths.parent);
        return;
      default:
        if (current) {
          history.replace(paths.parent);
        }
    }
  }, [pathname]);

  useEffect(() => {
    if (location?.state?.table) {
      dispatch(setTables(location.state.table));
      dispatch(setCount(location.state.count));
    }
  }, [dispatch, page, current, pathname]);

  useEffect(() => {
    if (!types?.length) {
      dispatch(getAgreementTypes()).finally(() => {
        socket.off('type-agreement');
      });
      return;
    } else {
      socket.off('type-agreement');
    }
  }, []);

  const onClickLink = (data: any) => {
    setIsOpenDrawer(true);
    const { id } = data?.[0];
    if (id) {
      history.push(`${paths.view}${id}`, {
        row: data?.[0],
        table: [data?.[0]],
        count,
      });
    }
  };

  const showCardContainer = (data: any) => {
    setIsOpenDrawer(true);
    const { id } = data;
    if (id) {
      history.push(`${paths.view}${id}`, {
        row: data,
        table: [data],
        count,
      });
    }
  };

  const handleShowEditForm = (payload: any) => {
    setIsOpenDrawer(true);
    const { id } = payload;
    if (id) {
      history.push(`${paths.edit}${id}`, {
        table: [payload],
        count,
        row: payload,
      });
    }
  };

  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
    history.push(paths.parent);
  };

  const submit = (val: IAgreement) => {


}

  const { generateCSV, copyToClipboard } = useCsvExport({
    fields: columns
      .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
      .map((item: any) => item.dataIndex), // Создаем массив из dataIndex
  });

  const handleCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    const rowsData = generateCSV(arSelectedRows, data);
    copyToClipboard(rowsData);
  };

  const fetchData = ({
    current,
    clear,
    page,
    order,
  }: {
    current: number;
    page: number;
    clear: string;
    order: string;
  }) => {
    dispatch(
      getAgreements({
        current: current >= 1 ? current - 1 : current * page,
        page,
        order,
      }),
    )
  };

  const handleOrder = (order: string) => {
    fetchData({ current, page, clear: '', order });
  };

  const filtersFetch = (text: string | number, column: IColumnType<any>) => {
    dispatch(filtersAgreements({ text: text, column: column })).finally(() => {
      socket.off('search-filters-agreements');
    })
  };

  const filtersColumn = (value: []) => {
    if (value.length > 0) {
      dispatch(
        getFiltersAgreements({
          data: value,
          current: current >= 1 ? current - 1 : current * page,
          page,
        }),
      ).finally(() => {
        socket.off('get-filters-agreements');
      });
    } else {
      fetchData({ current, page, clear: '', order: 'DESC' });
    }
  };

  const onChangePagination = (page: IPagination<any>) => {
    if (page?.current !== null && page?.pageSize !== null) {
      const current = page?.current || 0;
      const size = page?.pageSize || 20;
      dispatch(setCurrentPage((current - 1) * size));
      dispatch(setPage(page.pageSize));
    }
  };

  const handleDeleteRow = (row: any) => {
    dispatch(
      deleteAgreement({
        current: (current - 1) * page,
        page,
        id: row.id,
        delete: row.delete !== true,
      }),
    ).finally(() => {
      fetchData({ current, page, clear: '', order: 'DESC' });
    });
  };

  const handleDeleteCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    dispatch(setLoading(true));
    arSelectedRows.forEach((item: any) => {
      // Оптимизированная фильтрация
      const deleteData = data?.find((el) => el.id === item && !el.delete);

      if (deleteData) {
        dispatch(
          deleteAgreement({
            current: (current - 1) * page,
            page,
            id: item,
            delete: !deleteData.delete,
          }),
        );
      }
    });
    dispatch(setLoading(false));
    fetchData({ current, page, clear: '', order: 'DESC' });
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Table
        className={style.container__table}
        onClickLink={onClickLink}
        editRow={handleShowEditForm}
        deleteRow={handleDeleteRow}
        handleCopyAll={handleCopyAll}
        filtersFetch={filtersFetch}
        filters={filtersColumn}
        handleDeleteCopyAll={handleDeleteCopyAll}
        onRow={(record, rowIndex) => {
          showCardContainer(record);
        }}
        onChangePagination={onChangePagination}
        columns={columns}
        order={handleOrder}
        dataSource={data}
        loading={loading}
        style={{ width: '100%' }}
        height={'calc(100vh - 350px)'}
        pagination={{
          pageSize: page,
          total: count,
          showSizeChanger: true,
        }}
      />

      <CustomDrawer
        isHeight
        minWidth={700}
        open={isOpenDrawer}
        onClose={onCloseDrawer}
      >
        {isViewForm ? (
          <ViewAgreements
            onClose={onCloseDrawer}
            isOpen={isOpenDrawer}
          />
        ) : (
          <FormAgreements
            onClose={onCloseDrawer}
            isOpen={isOpenDrawer}
          />
        )}
      </CustomDrawer>
    </Space>
  );
};
