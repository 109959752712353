import { ALL_TYPE_DEAL } from "../constants";
import socket from "../socket";
import { IComments, IContainer, IDeal } from "../store/sales";

export class Rest_deal{
    static getTypeDeal(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit(ALL_TYPE_DEAL, null,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static generateIdDeal(payload: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('generate-id-deal-sales', payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }

    static getFiltersDeal(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-filters-deal-sales', param, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getContainerDealStatuses(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-dealContainer-statusAll', null,  (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getDeals(param?: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-deals-sales', param, (response: any, req: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static setDeals(payload: {deal: IDeal, containers: IContainer[], comments: IComments[]}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('set-deal-sales', payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static findOneDeal(id: number): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-one-deal-sales', id, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static findAllCommentsDeal(id: number): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-all-comments-deal-sales', id, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static deleteDeal(payload: any): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('delete-deal-sales', payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
}
