import { useCallback, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  AutoComplete,
  Col,
  Flex,
  Form,
  FormListFieldData,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';

import Icon from '@ant-design/icons';

import { DeleteRedBtn, dragdrop } from '../../../../../../assets/image/svg';
import { RootState, useStoreDispatch } from '../../../../../../store';
import {
  findCompany,
  mapperSearchCompany,
} from '../../../../../../store/contractors';
import style from '../../../style.module.scss';
import { Utils } from '../../../../../../utils';
import { IComments, ITabForm } from '../../../../../../store/sales';
import ScrollList from '../../../../../../components/ui/ScrollList';
import classnames from 'classnames';
import Comment from '../../list/Comment';
import moment from 'moment';
import { checksDatePeriodComment } from '../../../../../../helpers/date-helpers';
import TooltipButton from '../../../../../../components/ui/TooltipButton/TooltipButton';
import { mapperSearchYandexLocation } from './utils';
import { delay } from 'lodash';
import { FormSelectSearch } from '../../../../../../components/ui/Form';
import { useTranslation } from 'react-i18next';

interface ICompanySelect {
  loading?: boolean;
  id: number;
  label: string;
  form: 'my_company' | 'company';
  open: boolean;
  placeholder: string;
  data: [];
  onSelect: (id: string, record: any) => void;
  onSearch?: (text: string) => void;
  onBlur: (evt: React.FocusEvent<HTMLInputElement>) => void;
}

const TabDeal: React.FC<ITabForm> = ({ form, isDisabled }) => {
  const { t } = useTranslation();
  const initUser = {
    avatar: Utils.getUserInfo().avatar,
    name: Utils.getUserInfo().fio,
    fio: Utils.getUserInfo().fio,
    id: Utils.getUserInfo().id,
  };
  const initialComment: IComments[] = Form.useWatch('comments', {
    form,
    preserve: true,
  });
  const id_deal = Form.useWatch('id_deal', { form, preserve: true });
  const textComment = Form.useWatch('comment', { form, preserve: true });
  const fieldsRef = useRef<HTMLDivElement>(null);
  const refComments = useRef<HTMLDivElement>(null);
  const typeDeal = Form.useWatch('type_deal', { form, preserve: true });
  const dealType = useSelector<RootState>((state) => state.sales.dealType) as {
    id: number;
    value: string;
    char: string;
  }[];
  const dispatch = useStoreDispatch();

  const idDeal = useSelector(
    (state: RootState) => state.sales.idDeal as string,
  );
  const dataCurrency = useSelector(
    (state: RootState) => state.stores.dataCurrency,
  );
  const dataPercent = useSelector(
    (state: RootState) => state.stores.dataPercent,
  );
  const dataCompany = useSelector(
    (state: RootState) => state.companies.dataCompany,
  );
  const dataMyCompany = useSelector(
    (state: RootState) => state.companies.dataMyCompany,
  );

  const handleSelectCompany = useCallback((id: string, record: any) => {
    const fields = form.getFieldsValue();
    form.setFieldsValue({
      ...fields,
      phone: record?.phone,
      company: record?.value,
      company_id: record?.id,
      contact_id: record?.contact_id,
    });
  }, []);

  const handleSelectMyCompany = useCallback((id: string, record: any) => {
    form.setFieldValue('account_num', record?.score);
    form.setFieldValue('my_company', record?.value);
    form.setFieldValue('my_company_id', record?.id);
  }, []);

  const handleSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const item = dataCompany.find(
      (comp: any) => comp?.value === evt.target.value,
    );
    if (!item) {
      form.setFieldValue('company', '');
      form.setFieldValue('company_id', null);
    }
  };
  const handleSearchBlurMyCompany = (
    evt: React.FocusEvent<HTMLInputElement>,
  ) => {
    const item = dataMyCompany.find(
      (comp: any) => comp?.value === evt.target.value,
    );
    if (!item) {
      form.setFieldValue('my_company', '');
      form.setFieldValue('my_company_id', null);
    }
  };
  const handleSearchCompany = (text: string) => {
    delay(() => {
      if (text?.length >= 2) {
        dispatch(findCompany(text.toLocaleLowerCase()))
          .then((res) => {
            dispatch(mapperSearchCompany(res.payload));
          })
          .catch(console.error);
      }
    }, 1000);
  };

  const handleSelectLocation = useCallback(
    (id: string, record: any, name: [string, number]) => {
      form.setFieldValue(name, record?.label || null);
    },
    [],
  );

  const [items, setItems] = useState<ICompanySelect[]>([]);

  useEffect(() => {
    const items: ICompanySelect[] = [
      {
        id: 1,
        label: t('Контрагент'),
        form: 'company',
        open: false,
        placeholder: t('Выберите контрагента'),
        data: dataCompany,
        onSelect: handleSelectCompany,
        onSearch: handleSearchCompany,
        onBlur: handleSearchBlur,
      },
      {
        id: 3,
        label: t('Компания'),
        form: 'my_company',
        open: false,
        placeholder: t('Выберите компанию'),
        data: dataMyCompany,
        onSelect: handleSelectMyCompany,
        onBlur: handleSearchBlurMyCompany,
      },
    ];
    setItems(typeDeal === 'Закуп' ? items.reverse() : items);
  }, [typeDeal, JSON.stringify(dataCompany), JSON.stringify(dataMyCompany)]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems: any = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    const [item] = reorderedItems;
    const find = dealType.find((type) => type?.id === item?.id);
    form.setFieldValue('type_deal', find?.value || '');
    form.setFieldValue('type_deal_id', find?.id || null);
    form.setFieldValue('type_deal_char', `П${find?.char || 'З'}`);
    form.setFieldValue('id_deal', `П${find?.char || 'З'} ${idDeal}`);

    setItems(reorderedItems);
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <Flex vertical>
      <Flex vertical gap={8} style={{ marginBottom: '24px' }}>
        <Flex vertical>
          <Row gutter={[16, 8]}>
            <Col span={16}>
              <Form.Item
                name={'name_deal'}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Input
                  placeholder={t('Название сделки')}
                  allowClear
                  disabled={form.getFieldValue('id')}
                  required
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'id_deal'}>
                <Input placeholder={'ID П0007'} allowClear disabled />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="my_company_id" noStyle />
          <Form.Item name="company_id" noStyle />
          <Form.Item name="contact_id" noStyle />

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" isDropDisabled={isDisabled}>
              {(provided) => (
                <div ref={provided.innerRef} {...(provided.droppableProps ? provided.droppableProps : {})}>
                  {items.map((item, index) => (
                    <Draggable
                      key={`${item.id}`}
                      draggableId={`${item.id}`}
                      index={index}
                      isDragDisabled={isDisabled}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                          }}
                        >
                          <Flex gap={11} align={'center'}>
                            <div className={style['drag-drop-icon']}>
                              <Icon component={dragdrop} />
                            </div>
                            <Form.Item
                              label={item.label}
                              name={item.form}
                              className={classnames(style.label, {
                                [style.label__disabled]:
                                  !!form.getFieldValue('id'),
                              })}
                              rules={[
                                {
                                  required: true,
                                  message: t('Обязательное поле'),
                                },
                              ]}
                            >
                              <AutoComplete
                                popupClassName="certain-category-search-dropdown"
                                allowClear
                                options={item.data}
                                style={{ width: '100%' }}
                                onSearch={item?.onSearch}
                                onSelect={item?.onSelect}
                                onBlur={item?.onBlur}
                                placeholder={item.placeholder}
                                disabled={isDisabled}
                              />
                            </Form.Item>
                          </Flex>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Row gutter={[14, 10]}>
            <Col span={12}>
              <Form.Item name="phone" noStyle />
              <Form.Item name="account_num" label={t('Номер счета')}>
                <Input disabled allowClear />
              </Form.Item>
              <Col span={22} style={{ paddingLeft: 0 }}>
                <Form.Item noStyle name="currency_id" />
                <Form.Item label={t('Валюта')} name={'currency'}>
                  <Select
                    options={dataCurrency}
                    onSelect={(_, opt) =>
                      form.setFieldValue('currency_id', opt?.id)
                    }
                  />
                </Form.Item>
                <Form.Item label={t('НДС')} name={'nds'}>
                  <Select options={dataPercent} />
                </Form.Item>
                <Space direction={'vertical'}>
                  <Typography.Link className={style['text-card-14']}>
                    {t('Создать счет')}
                  </Typography.Link>
                  <Typography.Link className={style['text-card-14']}>
                    {t('Отправить счет')}
                  </Typography.Link>
                </Space>
              </Col>
            </Col>
            <Col span={12} style={{ paddingLeft: '5px' }}>
              <Form.List name="route">
                {(
                  fields: FormListFieldData[],
                  operation,
                  meta: { errors: any },
                ) => (
                  <div className={style['box-route']} ref={fieldsRef}>
                    <Typography.Text
                      className={style.label}
                      style={{ paddingLeft: '15px' }}
                    >
                      {t('Маршрут')}
                    </Typography.Text>
                    <ScrollList
                      setLastItemReached={(v) => v}
                      height={200}
                      totalTableActivity={1}
                      data={fields}
                      tableRef={fieldsRef}
                      paginationActivity={() => {}}
                      isTable={false}
                      endMessage={null}
                    >
                      {fields.map(({ ...field }, index: number) => (
                        <div
                          className={style['box-route__item']}
                          key={field.key}
                        >
                          <TooltipButton
                            propsTooltip={
                              index > 1 ? { title: t('Удалить') } : undefined
                            }
                            propsButton={{
                              className: style['box-route__delete'],
                              type: 'text',
                              icon: <DeleteRedBtn />,
                              style:
                                index > 1
                                  ? {}
                                  : { opacity: 0, cursor: 'default' },
                              onClick:
                                index > 1
                                  ? () => operation.remove(field.name)
                                  : () => {},
                            }}
                          />
                          <FormSelectSearch
                            api={Utils.yandexCity}
                            mapResponse={mapperSearchYandexLocation}
                            style={{
                              marginBottom: '21px',
                              width: '100%',
                              maxWidth: fieldsRef?.current
                                ? fieldsRef?.current.getBoundingClientRect()
                                    .width -
                                  40 +
                                  'px'
                                : '250px',
                            }}
                            allowClear
                            showSearch
                            placeholder={index ? t('Куда') : t('Откуда')}
                            defaultValue={
                              form.getFieldValue(['route', field.name]) || null
                            }
                            defaultActiveFirstOption={false}
                            suffixIcon={null}
                            filterOption={false}
                            onChange={(val: string, opt: any) =>
                              handleSelectLocation(val, opt, [
                                'route',
                                field.name,
                              ])
                            }
                            notFoundContent={null}
                          />
                        </div>
                      ))}
                      <Typography.Link
                        className={style['text-card-14']}
                        style={{ paddingLeft: '15px' }}
                        onClick={() => operation.add('')}
                      >
                        {t('Добавить локацию')}
                      </Typography.Link>
                    </ScrollList>
                  </div>
                )}
              </Form.List>
            </Col>
          </Row>
        </Flex>
      </Flex>
      <Form.Item name="comments" noStyle />
      <div
        className={classnames(style['box-list'], {
          [style['box-list_border']]: initialComment?.length,
        })}
      >
        <Typography.Title level={4} className={style['subtitle']}>
          {t('Комментарий')}
        </Typography.Title>
        <Form.Item name="comment_last" noStyle />
        <Form.Item name="comment">
          <Input.TextArea
            className={classnames('all-custom-v-scroll', style.textarea)}
            onKeyDown={(evt) => {
              if (evt.key === 'Enter' && !evt.shiftKey) {
                evt.preventDefault();
                const res = [
                  {
                    id_deal,
                    user: initUser,
                    user_id: initUser.id,
                    comment: textComment,
                    createdAt: moment().toISOString(),
                  },
                  ...initialComment,
                ];
                form.setFieldValue('comments', res);
                form.setFieldValue('comment_last', textComment);
                form.setFieldValue('comment', '');
                if (refComments?.current?.children?.[0]) {
                  refComments.current.children[0].scrollTo(0, 0);
                }
              }
            }}
            placeholder={t('Введите комментарий')}
            autoSize={{ minRows: 1, maxRows: 5 }}
          />
        </Form.Item>
        {!!initialComment?.length && (
          <div className={style['comments-box']} ref={refComments}>
            <ScrollList
              totalTableActivity={1}
              setLastItemReached={(v) => v}
              height={
                initialComment?.length >= 3
                  ? 200
                  : (initialComment?.length || 1) * 80
              }
              data={initialComment}
              tableRef={refComments}
              paginationActivity={() => {}}
              isTable={false}
              endMessage={null}
            >
              {initialComment?.map((comment, index) => (
                <Comment
                  key={index}
                  comment={comment}
                  isDelete={
                    initUser.id === comment?.user_id &&
                    checksDatePeriodComment(comment?.createdAt)
                  }
                  deleteComment={() => {
                    const res = initialComment.filter((_, i) => i !== index);
                    form.setFieldValue('comments', res);
                  }}
                />
              ))}
            </ScrollList>
          </div>
        )}
      </div>
    </Flex>
  );
};

export default TabDeal;
