import React, { useEffect, useState } from 'react';
import {
  Divider,
  Flex,
  Form,
  App,
  notification,
  Space,
  Typography,
} from 'antd';
import FormContractors from './formContractors';
import { RootState, useStoreDispatch } from '../../store';
import {
  deleteContractors,
  getContractors,
  findCompanyById,
  saveContractors,
  updateContractors,
  getFiltersContractors,
} from '../../store/contractors';
import { columns } from './columns';
import { Utils } from '../../utils';
import {
  typeSite,
} from '../../store/types';
import {
  getCustomsServices,
  getGeographyPresence,
  getRentKTK,
  getSaleKTK,
  getTerminalStorage,
} from '../../store/store';
import { useSelector } from 'react-redux';
import FormEditContractors from './formEditContractors';
import { entitiesInfo } from '../../store/users';
import socket from '../../socket';
import Button from '../../components/ui/Button/Button';
import {
  IColumnType,
  IPagination,
  Table,
} from '../../components/ui/Table/Table';
import { SettingOutlined } from '@ant-design/icons/lib';
import FormCardContractors from './formCardContractors';
import { useCsvExport, useParamsHistory } from '../../hooks';
import { filtersContractors, filtersTerminals } from '../../store/filters';
import { getFiltersTerminals } from '../../store/terminals';
import { RouteNames } from '../../router/names';
import { CREATE, EDIT, getTypeForm, VIEW } from '../../helpers/string-helpers';
import { useTranslation } from 'react-i18next';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';

const { Title } = Typography;

const TABLE_CONTRACTOR = 'tableContractor';
const ROW_CONTRACTOR = 'rowContractor';
const TOTAL_CONTRACTOR = 'totalContractor';
const DATA_CONTRACTOR = 'dataContractor';

const Contractors = () => {
  const {message} = App.useApp();
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { location, history } = useParamsHistory();
  const { pathname } = location;
  const [form] = Form.useForm();
  const [formCard] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataRequisites, setDataRequisites] = useState<any>([]);
  const [dataBankRequisites, setDataBankRequisites] = useState([]);
  const [data, setData] = useState<any[]>(Utils.defaultTable);
  const [current, setCurrent] = useState<number>(1);
  const [page, setPage] = useState<number>(20);
  const [total, setTotal] = useState(0);
  const [dataContractors, setDataContractors] = useState<any>({});
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const [isAddCompany, setIsAddCompany] = useState(false);
  const { generateCSV, copyToClipboard } = useCsvExport({
    fields: columns
      .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
      .map((item: any) => item.dataIndex), // Создаем массив из dataIndex
  });

  useEffect(() => {
    const reg = /\d+/g;
    const [, current] = pathname.split(RouteNames.CRM_COMPANY);
    const path = getTypeForm(current);

    if (!path) {
      if (location?.state?.[DATA_CONTRACTOR]) {
        setData(location.state[DATA_CONTRACTOR]);
      }
      return;
    };

    switch (path) {
      case CREATE:
        const isCreate = pathname.split('/')?.at(-1) === path;
        if (isCreate) {
          setDataContractors({});
          form.resetFields();
          setOpenEdit(true);
          setOpen(true);
          localStorage.removeItem('vd');
          return;
        }
        return history.replace(RouteNames.CRM_COMPANY_CREATE);
      case EDIT:
        const idEdit = pathname.split(`${EDIT}/`).at(-1) ?? '';
        const isEdit = reg.test(idEdit);
        if (isEdit) {
          const row = location?.state?.[ROW_CONTRACTOR];
          if (row) {
            setDataContractors(row);
            setData([row]);
            setOpenEdit(true);
            setOpen(true);
            return;
          }
          dispatch(findCompanyById(+idEdit)).then((res) => {
            const container = res?.payload?.[0];
            console.log(container);

            if (container) {
              setDataContractors(container);
              setData([container]);
              setOpenEdit(true);
              setOpen(true);
            } else {
              history.push(RouteNames.CRM_COMPANY);
            }
          });
          return;
        }
        return history.replace(RouteNames.CRM_COMPANY);
      case VIEW:
        const idView = pathname.split(`${VIEW}/`).at(-1) ?? '';
        const isView = reg.test(idView);
        if (isView) {
          const row = location?.state?.[ROW_CONTRACTOR];
          if (row) {
            setDataContractors(row);
            setData([row]);
            setOpen(true);
            setOpenEdit(false);
            return;
          }
          dispatch(findCompanyById(+idView)).then((res) => {
            const container = res?.payload?.[0];
            if (container) {
              setDataContractors(container);
              setData([container]);
              setOpen(true);
              setOpenEdit(false);
            } else {
              history.push(RouteNames.CRM_COMPANY);
            }
          });
          return;
        }
        history.replace(RouteNames.CRM_COMPANY);
        return;
      default:
        if (current) {
          history.replace(RouteNames.CRM_COMPANY);
        }
    }
  }, [pathname]);

  const onClose = () => {
    setOpen(false);
    const currentDate = location?.state?.[DATA_CONTRACTOR];
    history.push(RouteNames.CRM_COMPANY, currentDate ? {[DATA_CONTRACTOR]: currentDate} : undefined);
  };

  const fetchData = async (current: number, page: number, order: string) => {
    const table = location?.state?.[TABLE_CONTRACTOR];
    if (table) {
      setData(table);
      setTotal(location.state?.[TOTAL_CONTRACTOR] ?? 0);
      return;
    }
    setLoading(true);
    dispatch(
      getContractors({
        current: (current - 1) * page,
        page: page,
        order: order,
      }),
    ).then((response) => {
      if (response.payload.success === 1) {
        setLoading(false);
        setData(response.payload.data);
        setTotal(response.payload.count.count);
        setLoading(false);
      } else {
        message.error(response.payload.message);
        setLoading(false);
      }
    });
  };

  const handleUpdateContractors = (response: any) => {
    if (JSON.parse(response).success === 0) {
      Utils.InfoOpenNotification(
        'topRight',
        JSON.parse(response).message,
        20,
        api,
      );
    } else {
      setData((prevData) => {
        return prevData.map((item) =>
          item.id === JSON.parse(response).data[0].id
            ? { ...JSON.parse(response).data[0] }
            : item,
        );
      });

      if (Utils.getUserInfo().id === JSON.parse(response).data[0].user_id) {
        Utils.openNotification('topLeft', JSON.parse(response).message, api);
      }
      form.resetFields();
    }
    onClose();
    fetchData(current, page, 'DESC');
  };

  useEffect(() => {
    dispatch(typeSite());
    dispatch(getRentKTK());
    dispatch(getTerminalStorage());
    dispatch(getSaleKTK());
    dispatch(getCustomsServices());
    dispatch(getGeographyPresence());
    if (Utils.getStorage('ent') === null) {
      dispatch(entitiesInfo(Utils.role())).then((response: any) => {
        response.payload.res_client.map(async (item: any) => {
          if (item.entities === 'addCompany') {
            if (Utils.role().id === response.payload.role_id) {
              setIsAddCompany(item.status);
              Utils.setStorage(
                'ent',
                await Utils.encrypt(
                  JSON.stringify(response.payload.res_client),
                ),
              );
            }
          }
        });
      });
    } else {
      Utils.decrypt(Utils.getStorage('ent')).map((item: any) => {
        if (item.entities === 'addCompany') {
          setIsAddCompany(item.status);
        }
      });
    }
    fetchData(current, page, 'DESC').then((r) => r);

    socket.on('res-update-contractors', handleUpdateContractors);
    socket.on('res-create-contractors', handleCreateContractors);
    socket.on('res-update-entities', handleUpdateEntities);
    socket.on('res-update-group-entities', handleUpdateGroupEntities);
    socket.on('res-delete-contractors', resHandleDeleteRowContractors);

    return () => {
      socket.off('res-update-contractors', handleUpdateContractors);
      socket.off('res-create-contractors', handleCreateContractors);
      socket.off('res-update-entities', handleUpdateEntities);
      socket.off('res-update-group-entities', handleUpdateGroupEntities);
      socket.off('res-delete-contractors', resHandleDeleteRowContractors);
    };
  }, []);

  const resHandleDeleteRowContractors = (response: any) => {
    setData((prevData) => {
      // Проверьте, что response является массивом, если это необходимо
      if (!Array.isArray([response])) {
        console.error('Invalid response data:', [response]);
        return prevData;
      }

      // Предположим, что response это объект с обновленными данными
      const updatedItem = response;
      return prevData.map((item) =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item,
      );
    });
  };

  const handleUpdateGroupEntities = (response: any) => {
    if (Utils.role().type === 'group') {
      if (Utils.role().id === response.role_id) {
        response.res_client.map(async (item: any) => {
          item.entities === 'addCompany' && setIsAddCompany(item.status);
        });
      }
    }
  };

  const handleUpdateEntities = (response: any) => {
    if (Utils.role().type === 'role') {
      if (Utils.role().id === response.role_id) {
        response.res_client.map((item: any) => {
          item.entities === 'addCompany' && setIsAddCompany(item.status);
        });
      }
    }
  };

  const handleCreateContractors = (response: any) => {
    setLoading(false);
    if (response.success === 0) {
      Utils.InfoOpenNotification('topRight', response.message, 20, api);
    } else {
      setTotal(response?.count?.count);
      response.data.key = response?.data?.id;
      setData((prev: any) => {
        return [response.data, ...prev];
      });
      setOpen(false);
      fetchData(current, page, 'DESC');
      form.resetFields();
      Utils.openNotification('topLeft', 'Добавлено', api);
    }
  };

  const onChangePagination = async (page: IPagination<any>) => {
    if (page.current != null && page.pageSize != null) {
      setCurrent(page.current);
      setPage(page.pageSize);
      await fetchData(page.current, page.pageSize, 'DESC');
    }
  };

  const showDrawer = async () => {
    form.resetFields();
    setDataContractors({});
    setOpen(true);
    setOpenEdit(true);
    localStorage.removeItem('vd');
  };

  const onFinish = async (data: any, type: string) => {
    data.short_address =
      dataRequisites.length > 0 ? dataRequisites[10]?.meaning : null;
    data.contractors_id = data?.id;

    setDataContractors(data);
    setOpen(false);
    setOpenEdit(false);

    if (!data?.id) {
      await dispatch(saveContractors(data));
    } else {
      await dispatch(updateContractors(data));
    }
    localStorage.removeItem('vd');
  };

  const handleEditContractors = async (row: any) => {
    const { id } =row;
      if (id) {
    history.push(`${RouteNames.CRM_COMPANY_VIEW}${row?.id}`, {
      [DATA_CONTRACTOR]: data,
      [TABLE_CONTRACTOR]: [row],
      [ROW_CONTRACTOR]: row,
      [TOTAL_CONTRACTOR]: total,
    });
  }
  };

  const onClickLink = async (row: any) => {
      const { id } = row?.[0];
      if (id) {
        history.push(`${RouteNames.CRM_COMPANY_VIEW}${id}`, {
          [DATA_CONTRACTOR]: data,
          [TABLE_CONTRACTOR]: [row?.[0]],
          [ROW_CONTRACTOR]: row?.[0],
          [TOTAL_CONTRACTOR]: total,
        });
      }
    };

  const handleShowEditForm = (row: any) => {
    setOpenEdit(false);
    setOpen(false)
    history.push(`${RouteNames.CRM_COMPANY_EDIT}${row?.id}`, {
      [DATA_CONTRACTOR]: data,
      [TABLE_CONTRACTOR]: [row],
      [ROW_CONTRACTOR]: row,
      [TOTAL_CONTRACTOR]: total,
    });
  };

  const handleCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    const rowsData = generateCSV(arSelectedRows, data);
    copyToClipboard(rowsData);
  };

  const handleDeleteRow = (row: any) => {
    dispatch(
      deleteContractors({
        current: (current - 1) * page,
        page: page,
        id: row.id,
        delete: row.delete !== true,
      }),
    );
  };

  const handleDeleteCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    arSelectedRows.forEach((item: any) => {
      // Оптимизированная фильтрация
      const deleteData = data.find(
        (el) => el.id === item && el.delete !== true,
      );

      if (deleteData) {
        dispatch(
          deleteContractors({
            current: (current - 1) * page,
            page: page,
            id: item,
            delete: deleteData.delete !== true,
          }),
        );
      }
    });
  };

  const filtersFetch = (text: string | number, column: IColumnType<any>) => {
    dispatch(filtersContractors({ text: text, column: column }));
  };

  const filtersColumn = (value: []) => {
    if (value.length > 0) {
      dispatch(
        getFiltersContractors({
          data: value,
          current: (current - 1) * page,
          page: page,
        }),
      ).then((response) => {
        setData(response.payload.rows);
        setTotal(response.payload.count);
      });
    } else {
      fetchData(current, page, 'DESC').then((r) => r);
    }
  };

  const handleOrder = (order: string) => {
    fetchData(current, page, order);
  };
  return (
    <>
      {contextHolder}
      <Flex
        justify={'space-between'}
        align={'flex-start'}
        style={{ margin: '20px 28px 0px' }}
      >
        <Title level={2}>{t('Компания')}</Title>
        <Flex gap={16}>
          <Button onClick={showDrawer} type={'primary'}>
            {t('Создать')}
          </Button>
          <Button icon={<SettingOutlined />} type={'text'} />
        </Flex>
      </Flex>
      <Divider style={{ margin: 0 }} />
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onClickLink={onClickLink}
          dataSource={data}
          columns={columns}
          loading={loading}
          order={handleOrder}
          editRow={handleShowEditForm}
          filters={filtersColumn}
          filtersFetch={filtersFetch}
          height={'calc(100vh - 309px)'}
          pagination={{
            pageSize: page,
            total: Number(total),
            current: current,
            showSizeChanger: true,
          }}
          style={{ overflow: 'auto hidden' }}
          onChangePagination={onChangePagination}
          deleteRow={handleDeleteRow}
          handleDeleteCopyAll={handleDeleteCopyAll}
          handleCopyAll={handleCopyAll}
          onRow={handleEditContractors}
        />
      </Space>

      <CustomDrawer open={open} onClose={onClose}>
        {openEdit ? (
          <FormEditContractors
            dataContractors={dataContractors}
            form={form}
            setDataRequisites={setDataRequisites}
            onFinishEdit={onFinish}
            onCloseEdit={onClose}
            title={dataContractors?.id ? ('Редактировать компанию') : t('Новая компания')}
          />
        ) : (
          <FormCardContractors
            onClose={onClose}
            handleShowEditForm={() => {
              setOpenEdit(true);
            }}
            dataContractors={dataContractors}
            formCard={formCard}
            title={'Редактировать компанию'}
          />
        )}
      </CustomDrawer>
      {/* <CustomDrawer open={openEdit} onClose={onClose}>
        <FormEditContractors
          setDataRequisites={setDataRequisites}
          dataContractors={dataContractors}
          dataTypePhone={dataTypePhone}
          dataTypeMessenger={dataTypeMessenger}
          dataTypeEmail={dataTypeEmail}
          onFinishEdit={onFinish}
          onCloseEdit={onClose}
          title={'Редактировать компанию'}
        />
      </CustomDrawer> */}
    </>
  );
};

export default Contractors;
