import React from 'react';
import classnames from 'classnames';
import table from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Flex, Typography } from 'antd';
import IconButton from '../../IconButton';
interface ITable {
  columns: { key: string; title: string; style: any }[];
  data?: any[];
  className?: string;
}
export const ViewTable: React.FC<ITable> = ({
  columns,
  data,
  className = '',
}) => {
  const { t } = useTranslation();
  return (
    <div className={classnames(table['block-ktk'], { [className]: className })}>
      <div className={table['overlap-group']} style={{ marginBottom: '10px' }}>
        <div className={table['table']}>
          <div className={table['table__container']}>
            <div className={table['table__header']}>
              <div
                className={classnames(
                  table['table__index'],
                  table['label-card-16'],
                )}
              >
                №
              </div>
              {columns?.map(({ key, title, style }) => (
                <div
                  key={key}
                  style={style}
                  className={classnames(
                    table['table__cell'],
                    table['label-card-16'],
                  )}
                >
                  {t(title)}
                </div>
              ))}
            </div>

            <div className={table['table__body']}>
              {data?.map((field, i) => (
                <div key={i} className={table['table__row']} data-row="row">
                  <div
                    className={classnames(
                      table['table__index'],
                      table['text-card-14'],
                    )}
                  >
                    {field.delete ? (
                      <IconButton
                        className={table['menu-icon__button-error']}
                        iType="delete"
                        title={t(field?.reason || 'Удален')}
                        style={{ cursor: 'default' }}
                        placement="topRight"
                      />
                    ) : (
                      i + 1
                    )}
                  </div>
                  {columns.map(({ key, title, style }, i) => (
                    <div
                      key={key + i}
                      className={classnames(
                        table['table__cell'],
                        table['text-card-14'],
                      )}
                      style={style}
                    >
                      <Typography.Text
                        ellipsis={{
                          tooltip: {
                            placement: 'leftBottom',
                            title: field?.[key] || '',
                          },
                        }}
                      >
                        {field?.[key] || ''}
                      </Typography.Text>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
