import React, { useEffect, useState, forwardRef } from 'react';
import { Input, InputRef } from 'antd';

interface NumericInputProps {
    style?: React.CSSProperties,
    onChange: (value: number) => void,
    placeholder?: string,
    maxLength: number,
    value?: any,
    onClear?: any
}

// Используем forwardRef для передачи ref
const NumericInput = forwardRef<InputRef, NumericInputProps>((props, ref) => {
    const { value, onChange, maxLength, placeholder, style, onClear }: any = props;
    const [inputValue, setInputValue] = useState<string>('0');

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault(); // Предотвращаем стандартное поведение
        const pastedValue = e.clipboardData.getData('Text').replace(/[^0-9]/g, '');
        updateValue(pastedValue);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/[^0-9]/g, '');
        updateValue(newValue);
    };

    const updateValue = (newValue: string) => {
        const truncatedValue = newValue.substring(0, maxLength);
        onChange(Number(truncatedValue));
        setInputValue(truncatedValue);
    };

    const handleBlur = () => {
        let valueTemp = String(inputValue);
        if (valueTemp) {
            if (valueTemp.endsWith('.') || valueTemp === '-') {
                valueTemp = inputValue.slice(0, -1);
            }
            onChange(Number(valueTemp));
        }
        if (valueTemp === ''){
            setInputValue('0');
        }
    };
    const handleClick = (e: any) => {
        if (Number(e.target.value) === 0){
            setInputValue('')
        }
    }
    return (
        <Input ref={ref}
               id={inputValue}
               style={style}
               value={inputValue}
               onClick={(e: any) => handleClick(e)}
               onChange={handleChange}
               onPaste={handlePaste}
               onBlur={handleBlur}
               allowClear
               placeholder={placeholder}
               onClear={() => onClear(value)}
               maxLength={maxLength}
               defaultValue={0}
        />
    );
});

export default NumericInput;
