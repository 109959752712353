import React from 'react';
import style from '../../style.module.scss';
import { Collapse, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Label } from './Label';
import { Checkbox } from '../../../../components/ui/Checkbox/Checkbox';
import { ViewTable } from '../../../../components/ui/List/ViewTable/ViewTable';
import { columnsKtk, columnsTransporter } from '../../constants';
import Icon from '@ant-design/icons';
import { DeleteRed20 } from '../../../../assets/image/svg';
import { IRelease } from '../../../../store/releases';

interface IColumn {
  title?: string;
  prevData?: IRelease;
  data?: IRelease;
}
export const Column: React.FC<IColumn> = ({ data, prevData, title = '' }) => {
  const { t } = useTranslation();
  const getItems = (title: string, data: any, columns: any) => [
    {
      key: title,
      label: <Label label={t(title)} />,
      children: (
        <ViewTable
          className={style['history__table']}
          columns={columns}
          data={data || []}
        />
      ),
    },
  ];
  return (
    <Flex gap={10} vertical>
      <div className={style['history__row']}>
        <Typography.Text className={style.checkboxes__label}> </Typography.Text>
        <Label label={t('Было')} />
        <Label label={t('Стало')} />
      </div>
      {(prevData?.delete || data?.delete) && (
        <div className={style['history__row']}>
          <Label
            label={t('Отмечен на удаление')}
            selected={prevData?.delete !== data?.delete}
          />
          <Label
            className={style['history__background']}
            value={prevData?.delete ? <Icon component={DeleteRed20} /> : ''}
          />
          <Label
            className={style['history__background']}
            value={data?.delete ? <Icon component={DeleteRed20} /> : ''}
          />
        </div>
      )}
      <div className={style['history__row']}>
        <Label
          label={t('Выдача физическому лицу')}
          selected={prevData?.is_transporter !== data?.is_transporter}
        />
        <div className={style['history__background']}>
          <Checkbox isChoice={prevData?.is_transporter} disabled></Checkbox>
        </div>
        <div className={style['history__background']}>
          <Checkbox isChoice={data?.is_transporter} disabled></Checkbox>
        </div>
      </div>
      <div className={style['history__row']}>
        <Label
          label={t('Безномерная выдача')}
          selected={prevData?.no_number !== data?.no_number}
        />
        <div className={style['history__background']}>
          <Checkbox isChoice={prevData?.no_number} disabled></Checkbox>
        </div>
        <div className={style['history__background']}>
          <Checkbox isChoice={data?.no_number} disabled></Checkbox>
        </div>
      </div>
      <div className={style['history__row']}>
        <Label
          label={t('Дата действия релиза')}
          selected={
            prevData?.date_action_start_string !==
              data?.date_action_start_string ||
            prevData?.date_action_end_string !== data?.date_action_end_string
          }
        />
        <Label
          className={style['history__background']}
          label={
            prevData?.date_action_start_string
              ? prevData?.date_action_start_string + ' - '
              : ''
          }
          value={prevData?.date_action_end_string ?? ''}
        />
        <Label
          className={style['history__background']}
          label={(data?.date_action_start_string ?? '') + ' - '}
          value={data?.date_action_end_string}
        />
      </div>
      <div className={style['history__row']}>
        <Label
          label={t('Номер релиза')}
          selected={prevData?.release_id !== data?.release_id}
        />
        <Label
          className={style['history__background']}
          value={prevData?.release_id}
        />
        <Label
          className={style['history__background']}
          value={data?.release_id}
        />
      </div>
      <div className={style['history__row']}>
        <Label
          label={t('Кол-во КТК')}
          selected={prevData?.containers_count !== data?.containers_count}
        />
        <Label
          className={style['history__background']}
          value={prevData?.containers_count || 0}
        />
        <Label
          className={style['history__background']}
          value={data?.containers_count || 0}
        />
      </div>
      <div className={style['history__row']}>
        <Label
          label={t('Подрядчик')}
          selected={prevData?.contractor !== data?.contractor}
        />
        <Label
          className={style['history__background']}
          value={prevData?.contractor}
        />
        <Label
          className={style['history__background']}
          value={data?.contractor}
        />
      </div>
      <div className={style['history__row']}>
        <Label
          label={t('Файлы')}
          selected={prevData?.file_ids?.length !== data?.file_ids?.length}
        />
        <Label
          className={style['history__background']}
          value={prevData?.file_ids?.length || 0}
        />
        <Label
          className={style['history__background']}
          value={data?.file_ids?.length || 0}
        />
      </div>
      {(!prevData?.is_transporter || !data?.is_transporter) && (
        <>
          <div className={style['history__row']}>
            <Label
              label={t('Данные перевозчика') + ':'}
              selected={
                JSON.stringify(prevData?.containers) !==
                JSON.stringify(data?.containers)
              }
            />
          </div>
          <div className={style['history__column']}>
            <Collapse
              collapsible="icon"
              items={getItems(
                'Было',
                prevData?.transporter,
                columnsTransporter,
              )}
            />
            <Collapse
              collapsible="icon"
              items={getItems('Стало', data?.transporter, columnsTransporter)}
            />
            {/* <Flex vertical gap={5}>
                <Label label={t('Было')} />
                <ViewTable
                  className={style['history__table']}
                  columns={columnsTransporter}
                  data={prevData?.transporter || []}
                />
              </Flex>
              <Flex vertical gap={5}>
                <Label label={t('Стало')} />
                <ViewTable
                  className={style['history__table']}
                  columns={columnsTransporter}
                  data={data?.transporter || []}
                />
              </Flex> */}
          </div>
        </>
      )}
      <div className={style['history__row']}>
        <Label
          label={t('Терминал')}
          selected={prevData?.terminal !== data?.terminal}
        />
        <Label
          className={style['history__background']}
          value={prevData?.terminal}
        />
        <Label
          className={style['history__background']}
          value={data?.terminal}
        />
      </div>
      <div className={style['history__row']}>
        <Label
          label={t('Таблица ктк') + ':'}
          selected={
            JSON.stringify(prevData?.containers) !==
            JSON.stringify(data?.containers)
          }
        />
      </div>
      <div className={style['history__column']}>
        <Collapse
          collapsible="icon"
          items={getItems('Было', prevData?.containers, columnsKtk)}
        />
        <Collapse
          collapsible="icon"
          items={getItems('Стало', data?.containers, columnsKtk)}
        />
      </div>
    </Flex>
  );
};
