import React from 'react';
import { Divider, Flex, Menu, MenuProps } from 'antd';
import ColumnString from './columnString';
import ColumnDate from './columnDate';
import ColumnCheckbox from './columnCheckbox';
import ColumnRangeInteger from './columnRangeInteger';
import Icon from '@ant-design/icons';
import ColumnDropdown from './columnDropdown';
import {sortIconDown, sortIconUp} from "../../../assets/image/svg";
import style from './style.module.scss';

interface ColumnsFiltersProps {
  column: any,
  columnFilters: any,
  columnIndex: number,
  searchFilter: (row: string, column: any) => void,
  handleChange: (option: any[], date: [any, any], dataInt: [number | '' | null | undefined, number | '' | null | undefined], checked: boolean, columnIndex: number) => void,
  startIntRange?: any,
  endIntRange?: any,
  setStartIntRange?: any,
  setEndIntRange?: any,
  startDate: Date | null,
  endDate: Date | null,
  optionsFilter?: any,
  handleDateChange: (type: ('start' | 'end'), date: any) => void,
  handleRangeInteger: (type: ('start' | 'end'), data: any) => void,
  handleOrder?: any,
  bull: number[],
  clearAllFilters: (index: number) => void,
  dataType?: any,
  setColumnFilters?: any
}

type MenuItem = Required<MenuProps>['items'][number];

const ColumnsFilters = ({
                          column,
                          columnFilters,
                          columnIndex,
                          searchFilter,
                          handleChange,
                          startIntRange,
                          endIntRange,
                          setStartIntRange,
                          setEndIntRange,
                          startDate,
                          endDate,
                          optionsFilter,
                          handleDateChange,
                          handleRangeInteger,
                          handleOrder,
                          bull,
                          clearAllFilters,
                          dataType,
                          setColumnFilters
                        }: ColumnsFiltersProps) => {
  const items: MenuItem[] = [
    {
      label: 'Сначала новые',
      key: 'DESC',
      icon: <Icon component={sortIconUp} />,
    },
    {
      label: 'Сначала старые',
      key: 'ASC',
      icon: <Icon component={sortIconDown} />,
    },
  ];
  //console.log(columnFilters)

  return (
    <Flex style={{ width: '100%' }} vertical justify="center">
      {column.type === 'string' ? (
        <ColumnString
          columnFilters={columnFilters && Object.keys(columnFilters).length > 0 ? 
          columnFilters[column?.dataIndex]: null}
          column={column}
          searchFilter={searchFilter}
          handleChange={handleChange}
          columnIndex={columnIndex}
          startIntRange={startIntRange}
          endIntRange={endIntRange}
          startDate={startDate}
          endDate={endDate}
          optionsFilter={optionsFilter}
        />
      ) : column.type === 'dropdown' ? (
         <ColumnDropdown
           dataType={dataType}
           column={column}
           searchFilter={searchFilter}
           handleChange={handleChange}
           columnFilters={columnFilters && Object.keys(columnFilters).length > 0 ? 
            columnFilters[column?.dataIndex]: null}
           columnIndex={columnIndex}
           startIntRange={startIntRange}
           endIntRange={endIntRange}
           startDate={startDate}
           endDate={endDate}
         />
      ) : column.type === 'date' ? (
        <ColumnDate
          column={column}
          columnFilters={columnFilters && columnFilters[columnIndex]}
          columnIndex={columnIndex}
          handleDateChange={handleDateChange}
          startIntRange={startIntRange}
          endIntRange={endIntRange}
          startDate={startDate}
          endDate={endDate}
          handleChange={handleChange}
        />
      ) : column.type === 'boolean' ? (
        <ColumnCheckbox
          handleChange={handleChange}
          columnIndex={columnIndex}
          startDate={startDate}
          endDate={endDate}
          startIntRange={startIntRange}
          endIntRange={endIntRange}
        />
      ) : column.type === 'range_integer' ? (
        <ColumnRangeInteger
          setColumnFilters={setColumnFilters}
          columnFilters={columnFilters}
          column={column}
          setEndIntRange={setEndIntRange}
          setStartIntRange={setStartIntRange}
          handleDateChange={handleRangeInteger}
          handleChange={handleChange}
          columnIndex={columnIndex}
          startDate={startDate}
          endDate={endDate}
          startIntRange={startIntRange}
          endIntRange={endIntRange}
        />
      ) : column.type === '' && null}
      <Flex>
        <Menu mode="inline" style={{ borderInlineEnd: 0 }} defaultSelectedKeys={['1']} onClick={(e) =>handleOrder(e,columnIndex)}
              items={items} />
      </Flex>
      {bull.length > 0 &&
        <>
           <Divider style={{marginBottom: 10}}/>
           <div className={style.clear__filters} onClick={() => clearAllFilters(columnIndex)}>Сбросить все фильтры</div>
        </>
      }

    </Flex>
  );
};

export default ColumnsFilters;
