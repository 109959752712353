import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AutoComplete, Button, Flex, Input, App, Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { RouteNames } from '../../../router/names';
import Container from '../../../components/Container';
import { filtersDealSales } from '../../../store/filters';
import { RootState, useStoreDispatch } from '../../../store';
import { useBreadcrumb, useCsvExport, useMenu, useParamsHistory } from '../../../hooks';
import {
  IColumnType,
  IPagination,
  Table,
} from '../../../components/ui/Table/Table';
import {
  deleteDeal,
  getDeals,
  getFiltersDeal,
  IDeal,
  setCount,
  setCurrentPage,
  setFiltersDeals,
  setLoading,
  setPage,
  setTableDeals,
} from '../../../store/sales';
import { columns } from './columns';
import socket from '../../../socket';
import { SEARCH_FILTERS_DEAL_SALES } from '../../../services/rest_filters';
import { DELETE_DEAL_SALES, GET_DEALS_SALES } from '../../../services/rest_deal';
import { COUNT_SALES_DEAL, ROW_SALES_DEAL, TABLE_SALES_DEAL } from './constants';
import { SessionStorageManager } from '../../../components/ui/Table/sessionStorageManager';

const Deals = () => {
  const { t } = useTranslation();
  const [activeButtons, setActiveButtons] = useState<any>({});
  const [searchColumn, setSearchColumn] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const dealData = useSelector<RootState>(
    (state) => state.sales.dealData,
  ) as IDeal[];
  const loading = useSelector<RootState>(
    (state) => state.sales.loading,
  ) as boolean;
  const page = useSelector<RootState>((state) => state.sales.page) as number;
  const count = useSelector<RootState>((state) => state.sales.count) as number;
  const current = useSelector<RootState>(
    (state) => state.sales.currentPage,
  ) as number;
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  // для фильтров
    const [columnFilters, setColumnFilters] = useState({});
    const storageManager = new SessionStorageManager(location.pathname);
    const storageLocal: any = storageManager.getItem();

  const { generateCSV, copyToClipboard } = useCsvExport({
    fields: columns
      .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
      .map((item: any) => item.dataIndex), // Создаем массив из dataIndex
  });

  useBreadcrumb(['Продажи', 'Сделки']);
  useMenu({openKey: RouteNames.SALES, activeKeys: [RouteNames.SALES, RouteNames.SALES_DEAL]});

  const handleButtonClickSearch = (option: any) => {
    setActiveButtons((prevActiveButtons: any) => {
      setSearchColumn((prev) =>
        prevActiveButtons[option.key]
          ? prev.filter((item: string) => item !== option.dataIndex)
          : [...prev, option.dataIndex],
      );
      return {
        ...prevActiveButtons,
        [option.key]: !prevActiveButtons[option.key],
      };
    });
  };

  const handleSearch = (val: string) => {
    setSearchValue(val);
    dispatch(filtersDealSales({ text: val, data: searchColumn })).then(
      (res) => {
        dispatch(setFiltersDeals(res.payload?.rows));
      },
    ).finally(() => socket.off(SEARCH_FILTERS_DEAL_SALES))
  };

  const handleShowDrawerDeal = () => {
    history.push(RouteNames.SALES_DEAL_CREATE, { table: dealData, count });
  };

  const onClickLink = (data: any) => {
    const { id } = data?.[0];
    if (id) {
      history.push(`${RouteNames.SALES_DEAL_VIEW}${id}`, {
        [ROW_SALES_DEAL]: data?.[0],
        [TABLE_SALES_DEAL]: dealData,
        [COUNT_SALES_DEAL]: count,
      });
    }
  };

  const showCardContainer = (data: any) => {
    const { id } = data;
    if (id) {
      history.push(`${RouteNames.SALES_DEAL_VIEW}${id}`, {
        [ROW_SALES_DEAL]: data,
        [TABLE_SALES_DEAL]: dealData,
        [COUNT_SALES_DEAL]: count,
      });
    }
  };

  const handleShowEditForm = (payload: any) => {
    const { id, edit } = payload;
    if (id) {
      history.push(`${edit ? RouteNames.SALES_DEAL_EDIT : RouteNames.SALES_DEAL_VIEW}${id}`, {
        [TABLE_SALES_DEAL]: dealData,
        [COUNT_SALES_DEAL]: count,
        [ROW_SALES_DEAL]: payload,
      });
    }
  };

  const handleCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    const rowsData = generateCSV(arSelectedRows, dealData);
    copyToClipboard(rowsData);
  };

  const fetchData = ({
    current,
    clear = '',
    page,
    order,
  }: {
    current: number;
    page: number;
    clear?: string;
    order: string;
  }) => {
    dispatch(
      getDeals({
        current: current >= 1 ? current - 1 : current * page,
        page,
        order,
        clear,
      }),
    )
      .catch((error) => {
        dispatch(setLoading(false));
      })
      .finally(() => socket.off(GET_DEALS_SALES));
  };

  const handleOrder = (order: string) => {
    fetchData({ current, page, order });
  };

  const filtersFetch = (text: string | number, column: IColumnType<any>) => {
    dispatch(filtersDealSales({ text: text, column: column })).finally(() => {
      socket.off(SEARCH_FILTERS_DEAL_SALES)
    });
  };

  const filtersColumn = (
    data: any,
    current: number,
    page: number,
    order: string,
) => {
     dispatch(setLoading(true));

     if (storageLocal && storageLocal.pagination !== undefined) {
      storageLocal.pagination.current = current;
      storageLocal.pagination.page = page;
      storageManager.setItem(storageLocal);
    }

    dispatch(setCurrentPage(current));

    if (data && Object.keys(data).length > 0) {
      dispatch(
        getFiltersDeal({
          data,
          current,
          page,
          order: order ? order : 'DESC',
        }),
      ).then((response) => {
        console.log(response?.payload);

        dispatch(setTableDeals(response.payload.rows));
        dispatch(setCount(response.payload.count));
        dispatch(setLoading(false));
      });
    } else {
      fetchData({ current, page, order: 'DESC' });
    }
  };

  const onChangePagination = (page: IPagination<any>) => {
    if (page?.current !== null && page?.pageSize !== null) {
      const current = page.current || 0;
      const size = page.pageSize || 20;
      dispatch(setCurrentPage((current - 1) * size));
      dispatch(setPage(page.pageSize));
    }
  };

  const handleDeleteRow = (row: any) => {
    dispatch(deleteDeal(row)).finally(() => {
      fetchData({ current, page, clear: '', order: 'DESC' });
      socket.off(DELETE_DEAL_SALES);
    });
  };

  const handleDeleteCopyAll = async (selectedRows: Set<number | undefined>) => {
    const deleteData = dealData?.filter(
      (el) => selectedRows.has(el.id) && !el.delete,
    );

    if (deleteData?.length) {
      dispatch(deleteDeal(deleteData)).finally(() => {
        fetchData({ current, page, clear: '', order: 'DESC' });
        socket.off(DELETE_DEAL_SALES);
      });
    }
  };


  useEffect(() => {
    if (location?.state?.[TABLE_SALES_DEAL]) {
      dispatch(setTableDeals(location.state[TABLE_SALES_DEAL]));
      dispatch(setCount(location.state?.[COUNT_SALES_DEAL] ?? 0));
    }
    if (!location?.state?.[TABLE_SALES_DEAL]) {
      dispatch(getDeals({ current, page, order: 'DESC' })).finally(() => {
        socket.off(GET_DEALS_SALES);
      })
    }
    // if (storageLocal) {
    //   dispatch(setCurrentPage(storageLocal.pagination.current));
    //   setColumnFilters(storageLocal.arrFilters);
    //   dispatch(setPage(storageLocal.pagination.page));
    //   filtersColumn(
    //       storageLocal.fiters,
    //       storageLocal.pagination.current,
    //       storageLocal.pagination.page,
    //       'DESC',
    //   );
    // }
  }, [dispatch, page, current, pathname]);

  return (
    <Container>
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('Сделки')}</div>
        </Flex>
        <Flex gap={16}>
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
            onClick={handleShowDrawerDeal}
          />
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            options={columns}
            defaultValue={searchValue}
            popupMatchSelectWidth={false}
            onSearch={handleSearch}
            dropdownRender={() => (
              <>
                <div
                  className={'text-card-12'}
                  style={{ padding: '10px 10px' }}
                >
                  {t('Выбор колонок по которым будет производиться поиск')}
                </div>
                <Flex
                  gap={5}
                  style={{ width: 300, flexWrap: 'wrap', padding: 10 }}
                >
                  {columns.map(
                    (option) =>
                      option.title !== '' &&
                      option.dataIndex !== 'date_entry' &&
                      option.dataIndex !== 'arrival_date' &&
                      option.dataIndex !== 'departure_date' &&
                      option.dataIndex !== 'booking_before' && (
                        <Button
                          size={'small'}
                          key={option.key}
                          type="primary"
                          className={
                            activeButtons[Number(option.key)]
                              ? 'active-search-btn'
                              : ''
                          }
                          onClick={() => handleButtonClickSearch(option)}
                        >
                          {option.title}
                        </Button>
                      ),
                  )}
                </Flex>
              </>
            )}
          >
            <Input placeholder={t('Введите текст')} />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onClickLink={onClickLink}
          editRow={handleShowEditForm}
          deleteRow={handleDeleteRow}
          handleCopyAll={handleCopyAll}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          filtersFetch={filtersFetch}
          filters={filtersColumn}
          handleDeleteCopyAll={handleDeleteCopyAll}
          onRow={(record, rowIndex) => {
            showCardContainer(record);
          }}
          onChangePagination={onChangePagination}
          columns={columns}
          order={handleOrder}
          dataSource={dealData}
          loading={loading}
          height={'calc(-162px + 100vh)'}
          pagination={{
            pageSize: page,
            total: Number(count),
            current: current,
            showSizeChanger: true,
          }}
        />
      </Space>
    </Container>
  );
};

export default Deals;
