import React from 'react';
import { useEffect, useState } from 'react';

import { Space } from 'antd';
import { useSelector } from 'react-redux';

import { columns } from './columns';
import socket from '../../../socket';
import style from '../style.module.scss';
import { FormAgreements } from './FormAgreements';
import { ViewAgreements } from './ViewAgreements';
import { filtersAgreements } from '../../../store/filters';
import { RootState, useStoreDispatch } from '../../../store';
import { useCsvExport, useParamsHistory } from '../../../hooks';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import {
  CREATE,
  EDIT,
  getTypeForm,
  VIEW,
} from '../../../helpers/string-helpers';
import {
  IColumnType,
  IPagination,
  Table,
} from '../../../components/ui/Table/Table';
import {
  DELETE_AGREEMENT,
  FIND_ONE_AGREEMENT,
  GET_AGREEMENTS,
  GET_FILTERS_AGREEMENTS,
  TYPE_AGREEMENT,
} from '../../../services/rest_agreement';
import {
  defaultAgreement,
  deleteAgreement,
  findOneAgreement,
  getAgreements,
  getAgreementTypes,
  getFiltersAgreements,
  IAgreement,
  setCount,
  setCurrentPage,
  setOneAgreement,
  setPage,
  setTables,
} from '../../../store/agreement';
import { COUNT_AGREEMENT, ROW_AGREEMENT, TABLE_AGREEMENT } from '../constants';

interface ITableAgreements {
  paths: { create: string; parent: string; edit: string; view: string };
}

export const TableAgreements: React.FC<ITableAgreements> = ({ paths }) => {
  const [typeForm, setTypeForm] = useState(CREATE);
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const dispatch = useStoreDispatch();
  const initialValue = useSelector<RootState>(
    (state) => state.agreement.agreement,
  ) as IAgreement;
  const data = useSelector<RootState>(
    (state) => state.agreement.agreements,
  ) as IAgreement[];
  const loading = useSelector<RootState>(
    (state) => state.agreement.loading,
  ) as boolean;
  const page = useSelector<RootState>(
    (state) => state.agreement.page,
  ) as number;
  const count = useSelector<RootState>(
    (state) => state.agreement.count,
  ) as number;
  const current = useSelector<RootState>(
    (state) => state.agreement.currentPage,
  ) as number;
  const types = useSelector<RootState>(
    (state) => state.agreement.types,
  ) as any[];
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  useEffect(() => {
    setIsOpenDrawer(false);
    dispatch(setOneAgreement({}));
    const reg = /\d+/g;
    const [, current] = pathname.split(paths.parent);
    const path = getTypeForm(current);
    if (!path) {
      if (!location?.state?.[TABLE_AGREEMENT]) {
        dispatch(
          getAgreements({ current, page, clear: '', order: 'DESC' }),
        ).finally(() => {
          socket.off(GET_AGREEMENTS);
        });
      }
      return;
    }
    switch (path) {
      case CREATE:
        const isCreate = pathname.split('/')?.at(-1) === path;
        if (isCreate) {
          setTypeForm(CREATE);
          dispatch(setOneAgreement({...defaultAgreement}));
          setIsOpenDrawer(true);
          return;
        }
        return history.replace(paths.create);
      case EDIT:
        const idEdit = pathname.split(`${EDIT}/`).at(-1) ?? '';
        const isEdit = reg.test(idEdit);
        if (isEdit) {
          setTypeForm(EDIT);
          dispatch(findOneAgreement(+idEdit))
            .then((res) => {
              if (res?.payload) {
                dispatch(setOneAgreement(res.payload));
                if (res.payload?.delete) {
                  setTypeForm(VIEW);
                }
                setIsOpenDrawer(true);
              } else {
                history.push(paths.parent);
              }
            })
            .finally(() => {
              socket.off(FIND_ONE_AGREEMENT);
            });
          return;
        }
        return history.replace(paths.parent);
      case VIEW:
        const idView = pathname.split(`${VIEW}/`).at(-1) ?? '';
        const isView = reg.test(idView);
        if (isView) {
          setTypeForm(VIEW);
          dispatch(findOneAgreement(+idView))
            .then((res) => {
              if (res?.payload) {
                dispatch(setOneAgreement(res.payload));
                setIsOpenDrawer(true);
              } else {
                history.push(paths.parent);
              }
            })
            .finally(() => {
              socket.off(FIND_ONE_AGREEMENT);
            });
          return;
        }
        history.replace(paths.parent);
        return;
      default:
        if (current) {
          history.replace(paths.parent);
        }
    }
  }, [pathname]);

  useEffect(() => {
    if (location?.state?.[TABLE_AGREEMENT]) {
      dispatch(setTables(location.state[TABLE_AGREEMENT]));
      dispatch(setCount(location.state?.[COUNT_AGREEMENT] ?? 0));
    }
  }, [dispatch, page, current, pathname]);

  useEffect(() => {
    if (!types?.length) {
      dispatch(getAgreementTypes()).finally(() => {
        socket.off(TYPE_AGREEMENT);
      });
      return;
    } else {
      socket.off(TYPE_AGREEMENT);
    }
  }, []);

  const onClickLink = (data: any) => {
    setIsOpenDrawer(true);
    const { id } = data?.[0];
    if (id) {
      history.push(`${paths.view}${id}`, {
        [ROW_AGREEMENT]: data?.[0],
        [TABLE_AGREEMENT]: [data?.[0]],
        [COUNT_AGREEMENT]: count,
      });
    }
  };

  const showCardContainer = (data: any) => {
    setIsOpenDrawer(true);
    const { id } = data;
    if (id) {
      history.push(`${paths.view}${id}`, {
        [ROW_AGREEMENT]: data,
        [TABLE_AGREEMENT]: [data],
        [COUNT_AGREEMENT]: count,
      });
    }
  };

  const handleShowEditForm = (payload: any) => {
    setIsOpenDrawer(true);
    const { id } = payload;
    if (id) {
      history.push(`${paths.edit}${id}`, {
        [TABLE_AGREEMENT]: [payload],
        [COUNT_AGREEMENT]: count,
        [ROW_AGREEMENT]: payload,
      });
    }
  };

  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
    dispatch(setOneAgreement({...defaultAgreement}));
    history.push(paths.parent);
  };

  const { generateCSV, copyToClipboard } = useCsvExport({
    fields: columns
      .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
      .map((item: any) => item.dataIndex), // Создаем массив из dataIndex
  });

  const handleCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    const rowsData = generateCSV(arSelectedRows, data);
    copyToClipboard(rowsData);
  };

  const fetchData = ({
    current,
    clear,
    page,
    order,
  }: {
    current: number;
    page: number;
    clear: string;
    order: string;
  }) => {
    dispatch(
      getAgreements({
        current: current >= 1 ? current - 1 : current * page,
        page,
        order,
      }),
    ).finally(() => {
      socket.off(GET_AGREEMENTS);
    });
  };

  const handleOrder = (order: string) => {
    fetchData({ current, page, clear: '', order });
  };

  const filtersFetch = (text: string | number, column: IColumnType<any>) => {
    dispatch(filtersAgreements({ text: text, column: column })).finally(() => {
      socket.off('search-filters-agreements');
    });
  };

  const filtersColumn = (value: []) => {
    if (value.length > 0) {
      dispatch(
        getFiltersAgreements({
          data: value,
          current: current >= 1 ? current - 1 : current * page,
          page,
        }),
      ).finally(() => {
        socket.off(GET_FILTERS_AGREEMENTS);
      });
    } else {
      fetchData({ current, page, clear: '', order: 'DESC' });
    }
  };

  const onChangePagination = (page: IPagination<any>) => {
    if (page?.current !== null && page?.pageSize !== null) {
      const current = page?.current || 0;
      const size = page?.pageSize || 20;
      dispatch(setCurrentPage((current - 1) * size));
      dispatch(setPage(page.pageSize));
    }
  };

  const handleDeleteRow = (row: any) => {
    dispatch(deleteAgreement(row)).finally(() => {
      fetchData({ current, page, clear: '', order: 'DESC' });
      socket.off(DELETE_AGREEMENT);
    });
  };

  const handleDeleteCopyAll = (selectedRows: Set<number | undefined>) => {
    const deleteData = data?.filter(
      (el) => selectedRows.has(el.id) && !el.delete,
    );
    // Оптимизированная фильтрация
    if (deleteData?.length) {
      dispatch(deleteAgreement(deleteData)).finally(() => {
        fetchData({ current, page, clear: '', order: 'DESC' });
        socket.off(DELETE_AGREEMENT);
      });
    }
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Table
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        className={style.container__table}
        onClickLink={onClickLink}
        editRow={handleShowEditForm}
        deleteRow={handleDeleteRow}
        handleCopyAll={handleCopyAll}
        filtersFetch={filtersFetch}
        filters={filtersColumn}
        handleDeleteCopyAll={handleDeleteCopyAll}
        onRow={(record, rowIndex) => {
          showCardContainer(record);
        }}
        onChangePagination={onChangePagination}
        columns={columns}
        order={handleOrder}
        dataSource={data}
        loading={loading}
        style={{ width: '100%' }}
        height={'calc(100vh - 350px)'}
        pagination={{
          pageSize: page,
          total: Number(count),
          current: current,
          showSizeChanger: true,
        }}
      />

      <CustomDrawer
        isHeight
        minWidth={700}
        open={isOpenDrawer}
        onClose={onCloseDrawer}
      >
        {typeForm === VIEW ? (
          <ViewAgreements
            typeForm={typeForm}
            isOpen={isOpenDrawer}
            initialValue={initialValue}
            onEdit={() => setTypeForm(CREATE)}
            onClose={onCloseDrawer}
          />
        ) : (
          <FormAgreements
            typeForm={typeForm}
            isOpen={isOpenDrawer}
            initialValue={initialValue}
            onClose={onCloseDrawer}
          />
        )}
      </CustomDrawer>
    </Space>
  );
};
