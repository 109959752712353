import React, { useEffect, useState } from 'react';
import Container from '../../components/Container';
import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  App,
  Modal,
  notification,
  Space,
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { RootState, useStoreDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import '../../assets/scss/terminal.scss';
import { SelectSearch } from '../../components/ui/SelectSearch/SelectSearch';
import {
  deleteTerminal,
  getFiltersTerminals,
  getTerminal,
  getTerminalCity,
  findTerminalOne,
  searchTerminalCity,
  setTerminal,
} from '../../store/terminals';
import { delay } from 'lodash';
import socket from '../../socket';
import FormModalTerminal from './formModalTerminal';
import { Utils } from '../../utils';
import {
  IColumnType,
  IPagination,
  Table,
} from '../../components/ui/Table/Table';
import { columns } from './columns';
import { useHistory } from 'react-router-dom';
import { RouteNames } from '../../router/names';
import { getCurrency, getPhoneMask } from '../../store/store';
import { useSelector } from 'react-redux';
import FormCardModalTerminal from './formCardModalTerminal';
import { useBreadcrumb, useCsvExport, useMenu, useParamsHistory } from '../../hooks';
import { filtersTerminals } from '../../store/filters';
import { CREATE, EDIT, getTypeForm, VIEW } from '../../helpers/string-helpers';
import { StorageData } from '../../types';
import { SessionStorageManager } from '../../components/ui/Table/sessionStorageManager';

const TABLE_TERMINAL = 'tableTerminal';
const ROW_TERMINAL = 'rowTerminal';
const TOTAL_TERMINAL = 'totalTerminal';
const DATA_TERMINAL = 'dataTerminal';

const Index: React.FC = () => {
  const { message } = App.useApp();
  const { history, location } = useParamsHistory();
  const {pathname} = location;
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const router = useHistory();
  const [searchCity, setSearchCity] = useState<string | null>(null);
  const [city, setCity] = useState<[]>([]);
  const [cityData, setDataCity] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalViewOpen, setIsModalViewOpen] = useState(false);
  const [isMap, setIsMap] = useState<boolean>(false);
  const [data, setData] = useState<any[]>(Utils.defaultTable);
  const [current, setCurrent] = useState<number>(1);
  const [page, setPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [dataRow, setDataRow] = useState<{}>({});
  const dataCurrency = useSelector(
    (state: RootState) => state.stores.dataCurrency,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [typeForm, setTypeForm] = useState<string | null>(null);
  const [columnFilters, setColumnFilters] = useState({});
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const storageManager = new SessionStorageManager(location.pathname);
  const storageLocal: any = storageManager.getItem();

  useBreadcrumb(['Депо', 'Терминалы']);

  useMenu({openKey: RouteNames.DEPOT, activeKeys: [RouteNames.DEPOT, RouteNames.TERMINALS]});

   // проверка url
   useEffect(() => {
    const reg = /\d+/g;
    const [, current] = pathname.split(RouteNames.TERMINALS);
    const path = getTypeForm(current);
    switch (path) {
      case CREATE:
        const isCreate = pathname.split('/')?.at(-1) === path;
        if (isCreate) {
          setTypeForm(CREATE);
          setIsModalOpen(true);
          setIsMap(false);
          setDataRow([]);
          return;
        }
        return history.replace(RouteNames.TERMINALS_CREATE);
      case EDIT:
        const idEdit = pathname.split(`${EDIT}/`).at(-1) ?? '';
        const isEdit = reg.test(idEdit);
        if (isEdit) {
          if (location?.state?.[ROW_TERMINAL]) {
            setDataRow(location?.state[ROW_TERMINAL]);
            setData(location?.state[TABLE_TERMINAL]);
            setIsModalOpen(true);
            setIsMap(true);
            setTypeForm(EDIT);
            return;
          }
          dispatch(findTerminalOne(idEdit)).then((res) => {
            const terminal = res?.payload;
            if (terminal) {
              setDataRow(terminal);
              setData([terminal]);
              setIsModalOpen(true);
              setIsMap(true);
              setTypeForm(EDIT);
            } else {
              history.push(RouteNames.TERMINALS);
            }
          });
          return;
        }
        return history.push(RouteNames.TERMINALS);
      case VIEW:
        const idView = pathname.split(`${VIEW}/`).at(-1) ?? '';
        const isView = reg.test(idView);
        if (isView) {
          if (location?.state?.[ROW_TERMINAL]) {
            setDataRow(location?.state[ROW_TERMINAL]);
            setData(location?.state[TABLE_TERMINAL]);
            setIsModalViewOpen(true);
            return;
          }
          dispatch(findTerminalOne(idView)).then((res) => {
            const terminal = res?.payload;
            if (terminal) {
              setDataRow(terminal);
              setData([terminal]);
              setIsModalViewOpen(true);
            } else {
              history.push(RouteNames.TERMINALS);
            }
          });
          return;
        }
        return history.push(RouteNames.TERMINALS);
      default:
        history.push(RouteNames.TERMINALS);
    }
  }, [pathname]);

  const { generateCSV, copyToClipboard } = useCsvExport({
    fields: columns
      .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
      .map((item: any) => item.dataIndex), // Создаем массив из dataIndex
  });

  const fetchData = (current: number, page: number, order: string) => {
    setLoading(true);
    if (storageLocal?.fiters) {
      filtersColumn(storageLocal?.fiters, current, page, 'DESC');
    }else {
      dispatch(
          getTerminal({current: (current - 1) * page, page: page, order: order}),
      ).then((response) => {
        if (response.payload.success === 1) {
          setData(response.payload.data);
          setTotal(response.payload.count.count);
          setLoading(false);
        } else {
          message.error(response.payload.message);
          setLoading(false);
        }
      });
    }
  };

  const filtersColumn = (
      data: any,
      current: number,
      page: number,
      order: string,
  ) => {
    setLoading(true);

    if (storageLocal && storageLocal.pagination !== undefined) {
      storageLocal.pagination.current = current;
      storageLocal.pagination.page = page;
      storageManager.setItem(storageLocal);
    }

    setCurrent(current);
    if (data && Object.keys(data).length > 0) {
      console.log(data);
      dispatch(
          getFiltersTerminals({
            data: data,
            current: current,
            page: page,
            order: order ? order : 'DESC',
          }),
      ).then((response) => {
        setData(response.payload.rows);
        setTotal(response.payload.count);
        setLoading(false);
      });
    } else {
      fetchData(current, page, 'DESC');
    }
  };

  useEffect(() => {
    dispatch(getTerminalCity()).then((response) => {
      setDataCity(response.payload);
    });
    if (storageLocal) {
      setCurrent(storageLocal.pagination.current);
      setColumnFilters(storageLocal.arrFilters);
      setCurrent(storageLocal.pagination.current);
      setPage(storageLocal.pagination.page);
      filtersColumn(
          storageLocal.fiters,
          storageLocal.pagination.current,
          storageLocal.pagination.page,
          'DESC',
      );
    } else {
      fetchData(current, page, 'DESC');
    }
    socket.once('res-search-terminal-city', (response: any) => {
      setCity(response);
    });
    socket.once('res-set-terminal-city', (response: any) => {
      setDataCity(response);
    });
    socket.on('res-set-terminal', resSetTerminal);
    socket.on('res-delete-terminal', resHandleDeleteRow);

    return () => {
      socket.off('res-set-terminal', resSetTerminal);
      socket.off('res-delete-terminal', resHandleDeleteRow);
    };
  }, [typeForm]);

  const resHandleDeleteRow = (response: any) => {
    setData((prevData) => {
      // Проверьте, что response является массивом, если это необходимо
      if (!Array.isArray([response])) {
        console.error('Invalid response data:', [response]);
        return prevData;
      }

      // Предположим, что response это объект с обновленными данными
      const updatedItem = response;
      return prevData.map((item) =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item,
      );
    });
  };

  const resSetTerminal = (responses: any) => {
    const response = JSON.parse(responses);
    if (response.success === 0) {
      Utils.InfoOpenNotification('topRight', response.message, 20, api);
      return; // Early return to avoid further processing
    }
    response.data.stock = response.data.stock[0].add_stock;
    response.data.stock_array = [{ add_stock: response.data.stock }];
    // Handle successful response
    if (typeForm === 'create') {
      // Add new data to the beginning of the list
      const updatedData = [response.data, ...data];
      setData(updatedData);
    } else {
      // Update existing data in the list
      setData((prevData) => {
        const index = prevData.findIndex(
          (item) => item.id === response.data.id,
        );

        if (index === -1) {
          return prevData; // Return previous data if the item is not found
        }

        // Update the item at the found index
        return [
          ...prevData.slice(0, index),
          { ...prevData[index], ...response.data },
          ...prevData.slice(index + 1),
        ];
      });
    }

    // Close the modal and show a notification
    setIsModalOpen(false);
    Utils.openNotification(
      'topLeft',
      typeForm === 'create' ? 'Добавлено' : 'Обновлено',
      api,
    );
  };

  const onChangeSelect = (event: any, option: any) => {
    // console.log(option);
  };

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCity(event.target.value.toLocaleLowerCase());
    if (event.target.value === '') {
      setSearchCity(null);
    }
    dispatch(searchTerminalCity(event.target.value.toLocaleLowerCase())).then(
      (response) => {
        setCity(response.payload);
      },
    );
  };

  const onClickAddTerminal = () => {
    form.resetFields();
    setDataRow({});
    setTypeForm('create');
    setIsModalOpen(true);
    //history.push(RouteNames.TERMINALS_CREATE, {table: data, total});
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
    form.resetFields();
    const currentData = location.state?.[DATA_TERMINAL] || data;
    history.push(RouteNames.TERMINALS, { [TABLE_TERMINAL]: currentData, [TOTAL_TERMINAL]: total });
  };

  const handleCancelViewModal = () => {
    setIsModalViewOpen(false);
    form.resetFields();
    const currentData = location.state?.[DATA_TERMINAL] || data;
    history.push(RouteNames.TERMINALS, { [TABLE_TERMINAL]: currentData, [TOTAL_TERMINAL]: total });
  };

  const onFinishAddTerminal = (payload: any) => {
    Object.assign(payload, { type_form: typeForm });
    dispatch(setTerminal(payload));
  };

  const onClickLink = (data: any) => {
    if (data[1].column === 1) {
      router.push({
        pathname: RouteNames.CONTAINERS,
        search: `?child=${encodeURIComponent(data[0].city_child_terminals_id)}
                &city=${encodeURIComponent(0)}
                &name=${encodeURIComponent(' - ' + data[0].city_child.name)}`,
      });
    }
    if (data[1].column === 2) {
      router.push({
        pathname: RouteNames.CONTAINERS,
        search: `?child=${encodeURIComponent(0)}
                &city=${encodeURIComponent(
                  data[0].city_child.city_terminals.id,
                )}
                &name=${encodeURIComponent(
                  data[0].city_child.city_terminals.name,
                )}`,
      });
    }
  };

  const handleShowEditForm = (row: { id: number }) => {
    setDataRow(row);
    setIsModalOpen(true);
    setIsMap(true);
    setTypeForm('update');
    //history.push(`${RouteNames.TERMINALS_EDIT}${row?.id}`, {table: [row], data, row, total});
  };

  const viewCardTerminal = (row: any) => {
    setIsModalViewOpen(true);
    setDataRow(row);
    // history.push(`${RouteNames.TERMINALS_VIEW}${row?.id}`, {table: [row], data, row, total});
  };

  const handleDeleteTerminal = (row: any) => {
    dispatch(
      deleteTerminal({
        current: (current - 1) * page,
        page: page,
        id: row.id,
        delete: row.delete !== true,
      }),
    );
  };

  const handleCopyAll = (selectedRows: number[]) => {
    const arSelectedRows = Array.from(selectedRows);
    const rowsData = generateCSV(arSelectedRows, data);
    copyToClipboard(rowsData);
  };

  const handleDeleteCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    arSelectedRows.forEach((item: any) => {
      // Оптимизированная фильтрация
      const deleteData = data.find(
        (el) => el.id === item && el.delete !== true,
      );
      if (deleteData) {
        dispatch(
          deleteTerminal({
            current: (current - 1) * page,
            page: page,
            id: item,
            delete: deleteData.delete !== true,
          }),
        );
      }
    });
  };

  const filtersFetch = (text: string | number, column: IColumnType<any>) => {
    dispatch(filtersTerminals({ text: text, column: column }));
  };

  const handleOrder = (order: string) => {
    fetchData(current, page, order);
  };

  const onChangePagination = (page: IPagination<any>) => {
    setLoading(true);
    setAllSelected(false);
    setSelectedRows(new Set());
    setCurrent(page.current);
    setPage(page.pageSize);

    try {
      const storageData: StorageData = {
        bull: storageLocal?.bull,
        pagination: {
          page: page.pageSize,
          current: page.current,
          total: page.total,
        },
      };
      storageManager.setItem(storageData);
      if (page.current != null && page.pageSize != null) {
        fetchData(page.current, page.pageSize, 'DESC');
      }
    } catch (error) {
      console.error('Error changing pagination:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {contextHolder}
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <div className={'drawer-title'}>{t('TerminalTable')}</div>
        <Flex gap={16}>
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
            onClick={onClickAddTerminal}
          />
          <SelectSearch
            dataSource={searchCity !== null ? city : cityData}
            onChangeSelect={onChangeSelect}
            dropdownRender={onNameChange}
            placeholder={t('Поиск')}
            type={'page'}
            style={{ width: 200 }}
            defaults={'Екатеринбург'}
            hiddenBtn={false}
            onClick={onClickAddTerminal}
          />
          <Input
            allowClear
            prefix={<SearchOutlined />}
            className={'input header-depo'}
            placeholder={t('Поиск')}
          />
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
          setColumnFilters={setColumnFilters}
          columnFilters={columnFilters}
          onClickLink={onClickLink}
          dataSource={data}
          columns={columns}
          deleteRow={handleDeleteTerminal}
          loading={loading}
          order={handleOrder}
          filters={filtersColumn}
          filtersFetch={filtersFetch}
          height={'calc(-162px + 100vh)'}
          editRow={handleShowEditForm}
          pagination={{
            pageSize: page,
            total: Number(total),
            current: current,
            showSizeChanger: true,
          }}
          onChangePagination={onChangePagination}
          handleCopyAll={handleCopyAll}
          handleDeleteCopyAll={handleDeleteCopyAll}
          style={{ overflow: 'auto hidden' }}
          onRow={(record, rowIndex) => {
            viewCardTerminal(record);
          }}
        />
      </Space>
      <Modal
        maskClosable={false}
        title={
          typeForm === 'create' ? 'Добавить терминал' : 'Редактировать терминал'
        }
        open={isModalOpen}
        onCancel={handleCancelModal}
        width={548}
        footer={null}
      >
        <Divider style={{ marginTop: 0, marginBottom: 16 }} />
        <Flex vertical className={'modal-content'}>
          <FormModalTerminal
            form={form}
            dataCityRedux={cityData}
            defaultCity={'Екатеринбург'}
            showMapEdit={isMap}
            dataCurrency={dataCurrency}
            onFinishAddTerminal={onFinishAddTerminal}
            dataRow={dataRow}
            typeForm={typeForm}
            onClose={handleCancelModal}
          />
        </Flex>
      </Modal>
      <Modal
        open={isModalViewOpen}
        maskClosable={false}
        onCancel={handleCancelViewModal}
        title="О терминале"
        style={{ top: 30 }}
        width={548}
        footer={null}
      >
        <Divider style={{ marginTop: 0, marginBottom: 16 }} />
        <Flex vertical className={'modal-content'}>
          <FormCardModalTerminal dataRow={dataRow} />
        </Flex>
      </Modal>
    </Container>
  );
};

export default Index;
