// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal-content {
  padding: 12px 0 !important;
}

.ant-modal-title {
  padding-left: 24px;
}

.modal-content {
  padding-left: 16px;
  padding-right: 16px;
}

.box-map {
  display: flex;
  min-height: 100px;
  padding: 6px 12px 6px 12px;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid rgba(68, 83, 113, 0.15);
  background: #FFF;
}
.box-map.text {
  border: 0;
  overflow: hidden;
  color: #858FA3;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 84px;
  flex: 1 0;
  align-self: stretch;
  place-content: center;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/terminal.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AACA;EACE,kBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,mBAAA;AAGF;;AADA;EACE,aAAA;EACA,iBAAA;EACA,0BAAA;EACA,uBAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;EACA,yCAAA;EACA,gBAAA;AAIF;AAHE;EACE,SAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,gEAAA;EACA,6CAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,mBAAA;EACA,qBAAA;AAKJ","sourcesContent":[".ant-modal-content{\n  padding: 12px 0 !important;\n}\n.ant-modal-title{\n  padding-left: 24px;\n}\n.modal-content{\n  padding-left: 16px;\n  padding-right: 16px;\n}\n.box-map{\n  display: flex;\n  min-height: 100px;\n  padding: 6px 12px 6px 12px;\n  align-items: flex-start;\n  gap: 8px;\n  flex: 1 0 0;\n  align-self: stretch;\n  border-radius: 6px;\n  border: 1px solid rgba(68, 83, 113, 0.15);\n  background: #FFF;\n  &.text{\n    border: 0;\n    overflow: hidden;\n    color:  #858FA3;\n    text-align: center;\n    font-variant-numeric: lining-nums tabular-nums stacked-fractions;\n    font-feature-settings: 'clig' off, 'liga' off;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 84px;\n    flex: 1 0 0;\n    align-self: stretch;\n    place-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
