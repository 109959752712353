// для accept Uploader
export const fileTypes = {
  doc: '.doc,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword',
  xls: '.xlsx,.xls,application/excel,application/vnd.msexcel,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: '.csv,text/csv,application/csv,text/comma-separated-values',
  pdf: '.pdf,application/pdf',
  image: 'image/*',
  jpeg: 'image/jpeg',
};
export const checkFileTypes = (keys: string, key: string) => {
  if (key && keys) {
    return keys.split(',').includes(key);
  }
  return false;
};

export const getFileNameAndType = (name: string) => {
  if (!name) return { type: '', title: '' };
  const [title, ...arrName] = name?.split('.');
  return {
    title: title ?? '',
    type: arrName?.length ? arrName.at(-1) ?? '' : '',
  };
};
export const checkSearchString = (search: string, maxLen = 11) => {
  if (!search?.trim()) {
    return '';
  }
  const currentString = /\s+/g.test(search.trim())
    ? search.trim().replace(/\s+/g, ' ').split(' ')
    : search.trim().slice(0, maxLen);
  return currentString;
};

export function downloadContent({
  content,
  url,
  fileName = 'text.txt',
  type = 'text/plain',
  isOpen = false,
}: {
  content: string;
  fileName?: string;
  type?: string;
  url?: string;
  isOpen?: boolean;
}) {
  const link = document.createElement('a');
  const file = new Blob([content], { type });
  link.href = url || URL.createObjectURL(file);
  link.download = fileName;
  link.click();
}

// Текстовый инпут запрет ввода и вставки только текст и пробелы
export const keyHandlerInputText = (
  evt: React.KeyboardEvent<HTMLInputElement>,
) => {
  const keys = ['ArrowRight', 'ArrowLeft', 'Backspace'];
  const specialCharRegex = new RegExp('[a-zа-яё\\s]', 'i');
  if (
    !specialCharRegex.test(evt.key) &&
    !(evt.ctrlKey && /\D/.test(evt.key)) &&
    !keys.includes(evt.key)
  ) {
    evt.preventDefault();
    return false;
  }
};

export const handlePasteInputText = (
  evt: React.ClipboardEvent<HTMLInputElement>,
) => {
  const win = window as any;
  const pasted = evt.clipboardData || win?.['clipboardData'];

  if (pasted) {
    const pastedText = pasted.getData('Text');
    const specialCharRegex = new RegExp('[^a-zа-яё\\s]', 'ig');
    if (specialCharRegex.test(pastedText)) {
      evt.preventDefault();
      return false;
    }
  }
};
// Текстовый инпут запрет ввода и вставки только числа и пробелы
export const keyHandlerInputNumber = (
  evt: React.KeyboardEvent<HTMLInputElement>,
) => {
  const specialCharRegex = new RegExp('[0-9\\s]', 'i');
  const keys = ['ArrowRight', 'ArrowLeft', 'Backspace'];
  if (
    !specialCharRegex.test(evt.key) &&
    !(evt.ctrlKey && /\D/.test(evt.key)) &&
    !keys.includes(evt.key)
  ) {
    evt.preventDefault();
    return false;
  }
};

export const handlePasteInputNumber = (
  evt: React.ClipboardEvent<HTMLInputElement>,
) => {
  const win = window as any;
  const pasted = evt.clipboardData || win?.['clipboardData'];

  if (pasted) {
    const pastedText = pasted.getData('Text').trim();
    const specialCharRegex = new RegExp('[^0-9]', 'ig');
    if (specialCharRegex.test(pastedText)) {
      evt.preventDefault();
      return false;
    }
  }
};
// запрет спец символов и кавычек
export const regNoQuotesSymbol = /[^а-яА-ЯеЁA-Za-z0-9\s-_]/;
// проверка номера ктк
export const regNumberKTK = /[A-Z]{4}\d{7}/;
