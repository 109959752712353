import React, { useEffect, useState } from 'react';
import TooltipButton from '../TooltipButton/TooltipButton';
import classnames from 'classnames';
import style from './style.module.scss';
import Icon, {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  HomeOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  CopyBufferIconDefault,
  CopyIconDefault,
  DeleteIconDefault,
  moreIcon,
  PlusIconDefault,
  uploadIcon,
} from '../../../assets/image/svg';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

type buttonType =
  | 'edit'
  | 'delete'
  | 'copy'
  | 'buffer'
  | 'pluse'
  | 'excel'
  | 'check'
  | 'close'
  | 'menu'
  | 'depo';
interface IButton extends ButtonProps {
  iType: buttonType;
  title?: string;
  placement?: TooltipPlacement;
  className?: string;
  onClick?: () => void;
}

// компонент для интерактивных иконок
export const IconButton: React.FC<IButton> = ({
  iType,
  onClick,
  className = '',
  title = '',
  placement = 'topLeft',
  ...props
}) => {
  const { t } = useTranslation();
  const [currentTitle, setCurrentTitle] = useState('');
  const [currentIcon, setCurrentIcon] = useState<any>(null);
  useEffect(() => {
    switch (iType) {
      case 'depo':
        setCurrentTitle(t(title || 'Сохранить'));
        setCurrentIcon(<HomeOutlined />);
        return;
      case 'menu':
        setCurrentTitle(t(title || 'Сохранить'));
        setCurrentIcon(<Icon component={moreIcon} />);
        return;
      case 'close':
        setCurrentTitle(t(title || 'Сохранить'));
        setCurrentIcon(<CloseOutlined />);
        return;
      case 'check':
        setCurrentTitle(t(title || 'Сохранить'));
        setCurrentIcon(<CheckOutlined />);
        return;
      case 'edit':
        setCurrentTitle(t(title || 'Редактировать'));
        setCurrentIcon(<EditOutlined />);
        return;
      case 'delete':
        setCurrentTitle(t(title || 'Удалить'));
        return setCurrentIcon(<Icon component={DeleteIconDefault} />);
      case 'copy':
        setCurrentTitle(t(title || 'Копировать'));
        return setCurrentIcon(<Icon component={CopyIconDefault} />);
      case 'buffer':
        setCurrentTitle(t(title || 'Копировать'));
        return setCurrentIcon(<Icon component={CopyBufferIconDefault} />);
      case 'pluse':
        setCurrentTitle(t(title || 'Копировать'));
        return setCurrentIcon(<Icon component={PlusIconDefault} />);
      case 'excel':
        setCurrentTitle(t(title || 'Выгрузить'));
        return setCurrentIcon(<Icon component={uploadIcon} />);
    }
  }, [iType]);

  return iType ? (
    <TooltipButton
      propsButton={{
        ...props,
        className: classnames(style.button, { [className]: className }),
        icon: currentIcon,
        onClick,
      }}
      propsTooltip={{ title: currentTitle, placement }}
    />
  ) : null;
};
