import { IContainer } from "../../../store/sales";
import {uniqBy} from 'lodash';

export const mapperSearchYandexLocation = ({ response }: any) => {
  return uniqBy(response?.GeoObjectCollection?.featureMember?.map(
    (item: any, i: number) => {
      const text =
        item?.GeoObject?.metaDataProperty?.GeocoderMetaData
          ?.AddressDetails?.Country?.AddressLine;
      return { value: text, label: text };
    }
  ), (item: { value: string, label: string }) => item?.value);
}

// для сделок
const SALES = 3; // id в продаже
// мапер для таблицы ктк в сделках
export const getterKtk = (container: IContainer): IContainer => ({
      id: container?.id || undefined,
      num_container: container.num_container,
      container_id: container?.id,
      type_container: container?.type_container,
      status: null,
      status_id: null,
      bet: null,
      price: null,
      payment: '',
      gtd: false,
      issue_date: null,
      departure_date: null,
      delete: false,
      release: container?.release ?? null,
      deal: null,
      edit: false,
    });
export const mapperKtk = (containers: any[]): IContainer[] | [] => {
  if (Array.isArray(containers) && containers?.length) {
    return containers.map(getterKtk);
  }
  return [];
};
export const filterResultKtk = (containers: any[]): IContainer[] | [] => {
  if (Array.isArray(containers) && containers?.length) {
    return containers.filter((item) => !item?.deal).map(getterKtk);
  }
  return [];
};

export const mapperCompany = (payload: any[]) => {
  return  payload.map((company: any) => ({...company, phone: company?.['phone_first'] ?? ''}))
};

export const calculationTotalContainer = (containers: IContainer[]) => {
  return containers?.reduce((acc, curr) => acc + Number(curr?.payment || 0) + Number(curr?.price || 0),0) || 0
};