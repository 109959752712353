import React from 'react';

import { Dropdown, MenuProps, Tooltip, Typography, UploadFile } from 'antd';

import Icon from '@ant-design/icons/lib/components/Icon';
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, FileImageOutlined, FileJpgOutlined, FilePdfOutlined, FileUnknownOutlined, FileWordOutlined } from '@ant-design/icons';

import style from '../UploaderDocs.module.scss';
import TooltipButton from '../../TooltipButton/TooltipButton';
import { moreIcon } from '../../../../assets/image/svg';
import { getFileNameAndType } from '../../../../helpers/input-helpers';

interface IUploaderDocsItem {
  file: UploadFile;
  handlePreview: (file: UploadFile) => void;
  openConfirmDeleteFile: (file: UploadFile) => void;
  isView?: boolean;
}

const getIcons = (type: string) => {
  const currentType = typeof type === 'string' ? type.toLowerCase() : '';
  switch (currentType) {
    case 'jpg':
    case 'jpeg':
      return <FileJpgOutlined />;
    case 'png':
      return <FileImageOutlined />;
    case 'pdf':
      return <FilePdfOutlined />;
    case 'doc':
    case 'docx':
      return <FileWordOutlined />;
    case 'xls':
    case 'xlsx':
      return <FileExcelOutlined />;
    default:
      return <FileUnknownOutlined />;
  }
};

const UploaderDocsItem: React.FC<IUploaderDocsItem> = ({
  file,
  handlePreview,
  openConfirmDeleteFile,
  isView
}) => {
  const getItems = (
    preview: (e: any) => void,
    remove: (e: any) => void,
  ): MenuProps['items'] => [
    {
      key: 1,
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'leftTop', title: 'Посмотреть' }}
          propsButton={{
            onClick: preview,
            className: style['file__button'],
            icon: <DownloadOutlined />,
          }}
        >Скачать</TooltipButton>
      ),
    },
    isView ?  null : {
      key: 2,
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'leftTop', title: 'Удалить' }}
          propsButton={{
            onClick: remove,
            className: [
              style['file__button'],
              style['file__button_delete'],
            ].join(' '),
            icon: <DeleteOutlined />,
          }}
        >Удалить</TooltipButton>
      ),
    },
  ];
  return (
    <>
      <div
        className="ant-upload ant-upload-select"
        style={{ cursor: 'default' }}>
        <div className={style['file']}>
          <div className={style['file__icon']}>
            {getIcons(getFileNameAndType(file?.name).type)}
          </div>
          <div className={style['file__menu']}>
            <Dropdown
              menu={{
                items: getItems(
                  () => handlePreview(file),
                  () => {
                    openConfirmDeleteFile(file);
                  },
                ),
              }}
              placement="bottomLeft"
              trigger={['click']}>
              <Typography.Link className="" onClick={(e) => e.preventDefault()}>
                <Icon component={moreIcon} />
              </Typography.Link>
            </Dropdown>
          </div>
        </div>
      </div>
      <Tooltip title={getFileNameAndType(file?.name).title} placement="top">
        <Typography.Text ellipsis style={{ cursor: 'default' }}>
          {getFileNameAndType(file?.name).title}
        </Typography.Text>
      </Tooltip>
    </>
  );
};

export default UploaderDocsItem;
