import React from 'react';
import style from './style.module.scss';
import { Button, Flex, Typography } from 'antd';
import { useParamsHistory } from '../../hooks';
import { RouteNames } from '../../router/names';
import { SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TableAgreements } from './components/TableAgreements';
import { pathsCrm } from './constants';

interface IAgreementsCRM {
  isEdit?: boolean;
  title?: string;
}

export const AgreementsCRM: React.FC<IAgreementsCRM> = ( { title = 'договора'}) => {
  const {history} = useParamsHistory();
  const { t } = useTranslation();

  return (
    <div className={style.container}>
      <Flex
        justify={'space-between'}
        align={'flex-start'}
        style={{ margin: '20px 28px 0px' }}
      >
        <Typography.Title style={{textTransform: 'capitalize'}} level={2}>{t(title)}</Typography.Title>
        <Flex gap={16}>
          <Button onClick={() => history.push(RouteNames.CRM_AGREEMENT_CREATE)} type={'primary'}>
            {t('Создать')}
          </Button>
          <Button icon={<SettingOutlined />} type={'text'} />
        </Flex>
      </Flex>
      <TableAgreements paths={pathsCrm} />
    </div>
  )
}
