// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h100 {
  height: 100vh;
}

.login-form {
  width: 500px;
}

.radio {
  margin-bottom: 10px;
  width: 100%;
}

.radio-button {
  width: 50%;
}

.login-form-forgot {
  float: right;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.ahref {
  color: #05F;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.card-login {
  display: inline-flex;
  padding: 26px 57px 26px 57px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.15);
}

.container-login .ant-card-head-wrapper {
  text-transform: lowercase;
}`, "",{"version":3,"sources":["webpack://./src/pages/auth/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,mBAAA;EACA,WAAA;AAGF;;AADA;EACE,UAAA;AAIF;;AADA;EACE,YAAA;AAIF;;AAFA;EACE,WAAA;AAKF;;AAFA;EACE,WAAA;EACA,kBAAA;EACA,gEAAA;EACA,6CAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAKF;;AAHA;EACE,oBAAA;EACA,4BAAA;EACA,sBAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;EACA,0CAAA;AAMF;;AAFE;EACE,yBAAA;AAKJ","sourcesContent":[".h100{\n  height: 100vh;\n}\n.login-form{\n  width: 500px;\n}\n.radio{\n  margin-bottom: 10px;\n  width: 100%;\n}\n.radio-button{\n  width: 50%;\n}\n\n.login-form-forgot {\n  float: right;\n}\n.ant-col-rtl .login-form-forgot {\n  float: left;\n}\n\n.ahref {\n  color: #05F;\n  text-align: center;\n  font-variant-numeric: lining-nums tabular-nums stacked-fractions;\n  font-feature-settings: 'clig' off, 'liga' off;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 20px;\n}\n.card-login{\n  display: inline-flex;\n  padding: 26px 57px 26px 57px;\n  flex-direction: column;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 50px;\n  border-radius: 12px;\n  background: #FFF;\n  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.15);\n}\n\n.container-login {\n  .ant-card-head-wrapper{\n    text-transform: lowercase;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
