import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RestFilters} from "../services/rest_filters";

interface IFiltersState {
    dataFilters: []
}

const initialState: IFiltersState = {
    dataFilters: []
};
export const filtersContainers = createAsyncThunk(
    'filtersContainers',
    async (payload: {}) => {
        return await RestFilters.filtersContainers(payload)
    }
)
export const filtersTerminals = createAsyncThunk(
    'filtersTerminals',
    async (payload: {}) => {
        return await RestFilters.filtersTerminals(payload)
    }
)
export const filtersContractors = createAsyncThunk(
    'filtersContractors',
    async (payload: {}) => {
        return await RestFilters.filtersContractors(payload)
    }
)
export const filtersContacts = createAsyncThunk(
    'filtersContacts',
    async (payload: {}) => {
        return await RestFilters.filtersContacts(payload)
    }
)
export const filtersDealSales = createAsyncThunk(
    'filtersDealSales',
    async (param: any) => {
        return await RestFilters.filtersDealSales(param)
    }
)
export const filtersAgreements = createAsyncThunk(
    'filtersAgreements',
    async (param: any) => {
        return await RestFilters.filtersAgreements(param)
    }
  )
export const filtersReleases = createAsyncThunk(
    'filtersReleases',
    async (param: any) => {
        return await RestFilters.filtersReleases(param)
    }
  )
const filtersSlice = createSlice({
    name: 'filtersSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(filtersContainers.fulfilled, (state, action) => {
            state.dataFilters = [];
            state.dataFilters = action.payload.rows
        })
        builder.addCase(filtersTerminals.fulfilled, (state, action) => {
            state.dataFilters = [];
            state.dataFilters = action.payload.rows
        })
        builder.addCase(filtersContractors.fulfilled, (state, action) => {
            state.dataFilters = [];
            state.dataFilters = action.payload.rows
        })
        builder.addCase(filtersContacts.fulfilled, (state, action) => {
            state.dataFilters = [];
            state.dataFilters = action.payload.rows
        })
        builder.addCase(filtersDealSales.fulfilled, (state, action) => {
            state.dataFilters = [];
            state.dataFilters = action.payload.rows;
        })
        builder.addCase(filtersAgreements.fulfilled, (state, action) => {
            state.dataFilters = [];
            state.dataFilters = action.payload.rows;
        })
    },
});
export default filtersSlice.reducer;
