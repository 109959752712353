import {StorageData} from "../../../types";


export class SessionStorageManager {
  private storageKey: string;

  constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  // Получение данных из localStorage
  getItem(): StorageData | null {
    const data = sessionStorage.getItem(this.storageKey);
    return data ? JSON.parse(data) : null;
  }

  // Сохранение данных в localStorage
  setItem(data: StorageData): void {
    sessionStorage.setItem(this.storageKey, JSON.stringify(data));
  }


  // Обновление данных
  updateItem(updatedBull: any): void {
    const storageData: StorageData = this.getItem() || { 
      pagination: {current: 0, page: 0, total: 0}, 
      bull: [],
      fiters: {} };
    Object.assign(storageData,  updatedBull);
    this.setItem(storageData);
  }


  // Метод для удаления значения из sessionStorage
  removeItem(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error("Error removing item from sessionStorage:", error);
    }
  }

  // Метод для очистки sessionStorage
  clear(): void {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.error("Error clearing sessionStorage:", error);
    }
  }
}