import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { setBreadcrumb } from '../store/store';
import { message } from 'antd';

interface RowData {
  [key: string]: any;
}

interface UseCsvExportProps {
  fields: string[];
}

export const useCsvExport = ({ fields }: UseCsvExportProps) => {
  const extractValue = (obj: any, key: string) => {
    const keys = key.split('.'); // Разделяем ключи для доступа к вложенным объектам
    let value = obj;
    for (const k of keys) {
      value = value ? value[k] : '';
    }
    return value;
  };
  const generateCSV = useCallback(
    (selectedRows: any[], data: RowData[]) => {
      return selectedRows
        .map((id) => {
          const row = data.find((item) => item.id === id);
          if (row && !row.delete) {
            return fields
              .map((field) => extractValue(row, field) || '')
              .map((value) => `"${String(value).replace(/"/g, '""')}"`)
              .join(',');
          }
          return '';
        })
        .filter((row) => row.trim() !== '')
        .join('\n');
    },
    [fields],
  );

  const copyToClipboard = useCallback((rowsData: string) => {
    navigator.clipboard
      .writeText(rowsData.trim())
      .then(() => {})
      .catch((err) => {
        console.error('Не удалось скопировать данные: ', err);
      });
  }, []);

  return {
    generateCSV,
    copyToClipboard,
  };
};

export const useParamsHistory = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation() as {pathname: string, state: any, search: any};

  return { history, id, location };
};

export const useBreadcrumb = (str: string[]) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translation = str.map((title) => t(title ?? ''))

  useEffect(() => {
      dispatch(setBreadcrumb(translation));
  }, [dispatch, JSON.stringify(translation)])
};



export const useLocalizeDocumentAttributes = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.resolvedLanguage) {
      document.documentElement.lang = i18n.resolvedLanguage;

    }
  }, [i18n, i18n.resolvedLanguage]);
}

export const useDefaultTranslation = () => {
  const { t, ...rest } = useTranslation();
  const tDefault = (key: string) => t(key, {default: key});

  return {tDefault, ...rest};
}
