import * as XLSX from "xlsx";

interface IRows {
  [key: string]: any;
}

interface IDownloadXlsx {
  type?: string;
  nameFile: string;
  nameList: string;
  nameColumns: string[];
  rows: IRows[];
}

const handleDownloadXlsx = ({nameFile, nameColumns, nameList, rows, type = 'xlsx'}: IDownloadXlsx) => {
  // const rows = data.map((item: any) => ({
  //     date: moment(item.date).format('DD.MM.YYYY H:mm'),
  //     pair: item.pair[1] + '-' + item.pair[0],
  //     user_name: item.user_name,
  // }));
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(rows);

  const colLengths = Object.keys(rows[0]).map((k) => k.toString().length)
  for (const d of rows) {
      Object.values(d).forEach((element, index) => {
          if (element !== undefined) {
              const length = element?.toString()?.length || 1;
              if (colLengths[index] < length) {
                  colLengths[index] = length
              }
          }
      })
  }
  worksheet["!cols"] = colLengths.map((l,index) => {
      if (index === 3 || index === 6 || index === 7 || index === 8 || index === 9
          || index === 10 || index === 11) {
          return {
              wch: 7,
          }
      }else {
          return {
              wch: 20,
          }
      }
  })
  XLSX.utils.book_append_sheet(workbook, worksheet, nameList);

  XLSX.utils.sheet_add_aoa(worksheet, [
    nameColumns,
  ]);
  if (type === 'xlsx') {
    XLSX.writeFile(workbook, `${nameFile}.xlsx`, {compression: true});
  }else {
    XLSX.writeFile(workbook, `${nameFile}.csv`, {compression: true});
  }
}

export {handleDownloadXlsx};
