import { useEffect, useState } from 'react';

import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { AutoComplete, Button, Flex, Input, App, Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { columns } from './columns';
import { FormDeal, ViewDeal } from './components';
import { RouteNames } from '../../../router/names';
import Container from '../../../components/Container';
import { Table } from '../../../components/ui/Table/Table';
import { RootState, useStoreDispatch } from '../../../store';
import { CREATE, EDIT } from '../../../helpers/string-helpers';

import { useBreadcrumb, useParamsHistory } from '../../../hooks';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import { findOneDeal, IDeal, setDeals, setOneDeal } from '../../../store/sales';
import socket from '../../../socket';
import { mapperKtk } from './utils';

const EditPageDeal = () => {
  const { message } = App.useApp();
  useBreadcrumb(['Продажи', 'Сделки']);
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const dealOneData = useSelector<RootState>(
    (state) => state.sales.dealOneData,
  ) as IDeal;
  const loadingOneDeal = useSelector<RootState>(
    (state) => state.sales.loadingOneDeal,
  ) as boolean;
  const [typeForm, setTypeForm] = useState<string>('');
  const [openDrawerDeal, setOpenDrawerDeal] = useState<boolean>(false);
  const onCloseFormDeal = () => {
    setOpenDrawerDeal(false);
    history.push(RouteNames.SALES_DEAL);
  };

  const onEditFormDeal = () => {
    history.replace(`${RouteNames.SALES_DEAL_EDIT}${dealOneData?.id}`);
  };

  useEffect(() => {
    const reg = /\d+/g;
    const [, current] = pathname.split(RouteNames.SALES_DEAL_EDIT);
    if (!current) return history.push(RouteNames.SALES_DEAL);

    const isCreate = pathname.split('/')?.at(-1) === '0';

    if (isCreate) {
      setTypeForm(CREATE);
      dispatch(
        setOneDeal(
          location?.state?.deal
            ? {
                ...location.state.deal,
                containers: mapperKtk(location.state.deal?.containers),
              }
            : {},
        ),
      );
      setOpenDrawerDeal(true);
      return;
    }

    const isEdit = reg.test(current);
    if (isEdit) {
      dispatch(findOneDeal(+current))
        .then((res) => {
          if (res?.payload?.deal?.id) {
            setOpenDrawerDeal(true);
            setTypeForm(EDIT);
          } else {
            history.push(RouteNames.SALES_DEAL);
          }
        })
        .catch((err) => {
          history.push(RouteNames.SALES_DEAL);
        });
      return;
    }
    return history.push(RouteNames.SALES_DEAL);
  }, [pathname]);

  return (
    <Container>
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('Deal')}</div>
        </Flex>
        <Flex gap={16}>
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff' }}
          />
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            popupMatchSelectWidth={false}
          >
            <Input placeholder="Введите текст" />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          loading={loadingOneDeal}
          columns={columns}
          dataSource={[dealOneData]}
          height={'calc(-162px + 100vh)'}
          pagination={{
            pageSize: 1,
            total: 1,
            showSizeChanger: true,
          }}
        />
      </Space>
      <CustomDrawer
        open={openDrawerDeal && !loadingOneDeal}
        onClose={onCloseFormDeal}
        isHeight
      >
        {dealOneData?.delete ? (
          <ViewDeal
            onClose={() => {
              dispatch(setOneDeal({}));
              setOpenDrawerDeal(false);
              history.push(RouteNames.SALES_DEAL);
            }}
            onEdit={onEditFormDeal}
            isOpen
          />
        ) : (
          <FormDeal
            initialValue={dealOneData}
            isDisabled={!!dealOneData?.id}
            title={
              typeForm === CREATE ? 'Создать сделку' : 'Редактировать сделку'
            }
            onClose={onCloseFormDeal}
            isOpen
          />
        )}
      </CustomDrawer>
    </Container>
  );
};

export default EditPageDeal;
