import axios from 'axios';

export const URL_API_USER = process.env.REACT_APP_API_URL;

export function authConfigAxios(apiURL?: string) {
  const requestBase = axios.create({
    baseURL: apiURL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  requestBase.interceptors.request.use(
    (config) => {
      config.params = { ...config.params };
      // проверка токена
      const token = localStorage.getItem('_in');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  requestBase.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        // возможем такой сценарий какие то действия;
        return Promise.reject(error?.response?.message || 'Ошибка доступа 401');
      }
      if (error?.response?.status === 404) {
        // возможем такой сценарий какие то действия;
        return Promise.reject(error?.response?.message || 'Ошибка запроса 404');
      }
      return Promise.reject(error?.response?.message || 'Ошибка сервера');
    },
  );

  return requestBase;
}


export const apiAxios = authConfigAxios(URL_API_USER);
