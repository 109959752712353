import React from 'react';
import {Col, Flex, Modal, Row, Typography} from "antd";
import Button from "../../components/ui/Button/Button";
import ModalConfirm from '../../components/ui/ModalConfirm/ModalConfirm';
import { messagesModal } from '../../constants';

const { title: warningTitle, subtitle } = messagesModal.warning;

const {Text} = Typography;

interface Props {

}

interface Props {
    isModalOpen?: boolean,
    handleOk?: () => void,
    handleCancel?: () => void,
    title?: string | undefined
}

const InfoModal: React.FC<Props> = ({
                                        isModalOpen,
                                        handleOk,
                                        handleCancel,
                                        title
                                    }) => {
    const [confirmation, setConfirmation] = React.useState<boolean>(false);
    return (
        <>
            <Modal footer={null} closable={false} centered open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Flex vertical className={'modal-content'}>
                    <Flex justify={'center'} align={"center"}>
                        <Text strong>{title}</Text>
                    </Flex>
                    <Row gutter={12} style={{marginTop: 50}}>
                        <Col span={16}>
                            {
                                title !== 'Ошибка: "Родитель" не соответствует адресу терминала!' &&
                            <Button type={'primary'} style={{width: '100%'}}
                                    onClick={handleOk}>Внести</Button>
                            }
                        </Col>
                        <Col span={8}>
                            <Button type={'text'} style={{width: '100%', color: '#E14453'}}
                                    onClick={() => setConfirmation(true)}>Отменить</Button>
                        </Col>
                    </Row>
                </Flex>
            </Modal>
            <ModalConfirm title={warningTitle} subtitle={subtitle} isOpen={confirmation} closeModal={() => setConfirmation(false)} actionAfterConsent={handleCancel} />
        </>
    );
};

export default InfoModal;
