const BASE_TAB = 'baseTab';
const APPROVAL_TAB = 'approvalTab';
const SIGNING_TAB = 'signingTab';
const RELEASE_TAB = 'releaseTab';
const CHAT_TAB = 'chatTab';


export {
    BASE_TAB,
    APPROVAL_TAB,
    SIGNING_TAB,
    RELEASE_TAB,
    CHAT_TAB,
  };