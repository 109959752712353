import React from 'react';
import {useTranslation} from "react-i18next";
import {Col, Divider, Flex, Row, Space} from "antd";
import Icon from "@ant-design/icons";
import {copyIcon} from "../../assets/image/svg";
import './style.scss'
import {RouteNames} from "../../router/names";
import {useHistory} from "react-router-dom";

interface Props {
    dataRow?: any
}

const FormCardModalTerminal: React.FC<Props> = ({dataRow}) => {
    const {t} = useTranslation();
    const router = useHistory();

    const onClickStock = (val: string) => {
        router.push({
            pathname: RouteNames.CONTAINERS,
            search: `?stock=${encodeURIComponent(val)}`
        });
    }

    return (
        <Space direction={"vertical"}>
            <Row>
                <Col span={12}>
                    <div className={'label-card-14'}>{t("NameTerminal")}</div>
                    <div className={'text-card-14'}>{dataRow.city_child.name}</div>
                </Col>
                <Col span={12}>
                    <div className={'label-card-14'}>{t("Parent")}</div>
                    <div className={'text-card-14'}>{dataRow.city_child.city_terminals.name}</div>
                </Col>
            </Row>
            <Divider style={{marginTop: 5, marginBottom: 5}}/>
            <Row>
                <Col span={24}>
                    <div className={'label-card-14'}>{t("Address")}</div>
                </Col>
                <Col span={24}>
                    <div className={'text-card-14'}>{dataRow.address_terminal}</div>
                </Col>
            </Row>
            <Divider style={{marginTop: 5, marginBottom: 5}}/>
            <Row>
                <Col span={24}>
                    <div className={'label-card-14'}>{t("Contacts")}</div>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <div className={'text-card-14'}>{t("Director")}</div>
                </Col>
                <Col span={12}>
                    <Flex vertical gap={5}>
                        <Flex gap={120}>
                            {dataRow.phone_director !== null &&
                                <>
                                    <div className={'text-card-14'}>{dataRow?.phone_director}</div>
                                    <Icon component={copyIcon}/>
                                </>
                            }
                        </Flex>
                        {dataRow.last_phone_director !== null &&
                            <Flex vertical gap={5}> {
                                dataRow.last_phone_director?.map((item: any, index: number) =>
                                    <Flex key={index} gap={120}>
                                        <div className={'text-card-14'}>{item.add_phone_director}</div>
                                        <Icon component={copyIcon}/>
                                    </Flex>
                                )
                            }
                            </Flex>
                        }
                    </Flex>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <div className={'text-card-14'}>{t("Dispatcher")}</div>
                </Col>
                <Col span={12}>
                    <Flex vertical gap={5}>
                        <Flex gap={120}>
                            {dataRow.phone_dispatcher !== null &&
                                <>
                                    <div className={'text-card-14'}>{dataRow.phone_dispatcher}</div>
                                    <Icon component={copyIcon}/>
                                </>
                            }
                        </Flex>
                        {dataRow.last_phone_dispatcher !== null &&
                            <Flex vertical gap={5}> {
                                dataRow.last_phone_dispatcher?.map((item: any, index: number) =>
                                    <Flex key={index} gap={120}>
                                        <div className={'text-card-14'}>{item.add_phone_dispatcher}</div>
                                        <Icon component={copyIcon}/>
                                    </Flex>
                                )
                            }
                            </Flex>
                        }
                    </Flex>
                </Col>
            </Row>
            <Divider style={{marginTop: 5, marginBottom: 5}}/>
            <Row>
                <Col span={24}>
                    <div className={'label-card-14'}>{t("Capacity")}</div>
                </Col>
                <Col span={24}>
                    <div className={'text-card-14'}>{dataRow.capacity}</div>
                </Col>
            </Row>
            <Divider style={{marginTop: 5, marginBottom: 5}}/>
            <Row>
                <Col span={24}>
                    <div className={'label-card-14'}>{t("Stock")}</div>
                </Col>
                <Col span={24}>
                    {
                        dataRow?.stock_array?.map((item: any, index: number) =>
                            <div className={'text-card-14'} key={index}
                                 style={{color: "blue", cursor: 'pointer', padding: 4}}
                            onClick={() =>onClickStock(item?.add_stock)}>{item?.add_stock}</div>
                        )
                    }
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className={'label-card-14'}>{t("StockInstruction")}</div>
                </Col>
                <Col span={24}>
                    <div className={'text-card-14'}>{dataRow?.stock_model?.text}</div>
                </Col>
            </Row>
        </Space>
    );
};

export default FormCardModalTerminal;
