import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {IContractorRequisites, IContractors} from "../types/contractors";
import {Rest_contractors} from "../services/rest_contractors";

interface IContractorsState {
    dataContractors: Array<IContractors>
    saveDataContractors: Array<IContractors>
    dataRequisites: Array<IContractorRequisites>
    dataCompany: []
    dataMyCompany: []
}

const initialState: IContractorsState = {
    dataContractors: [],
    saveDataContractors: [],
    dataRequisites: [],
    dataCompany: [],
    dataMyCompany: [],
};

export const updateContractors = createAsyncThunk(
    'updateContractors',
    async (payload: {}) => {
        return await Rest_contractors.updateContractors(payload)
    }
)
export const saveContractors = createAsyncThunk(
    'saveContact',
    async (payload: {}) => {
        return await Rest_contractors.setContractors(payload)
    }
)
export const getContractors = createAsyncThunk(
    'getContractors',
    async (payload: {}) => {
        return await Rest_contractors.getContractors(payload);
    }
)
export const getContractorsRequisites = createAsyncThunk(
    'getContractorsRequisites',
    async (id: number) => {
        return await Rest_contractors.getContractorsRequisites(id);
    }
)
export const deleteContractors = createAsyncThunk(
    'deleteContractors',
    async (payload: {}) => {
        return await Rest_contractors.deleteContractors(payload);
    }
)
export const findCompany = createAsyncThunk(
    'findCompany',
    async (payload: any) => {
        return await Rest_contractors.getFindCompany(payload)
    }
)
export const findMyCompany = createAsyncThunk(
    'findMyCompany',
    async () => {
        return await Rest_contractors.getFindMyCompany()
    }
)
export const findCompanyById = createAsyncThunk(
    'findCompanyById',
    async (id: number) => {
        return await Rest_contractors.findCompanyById(id)
    }
)
export const getFiltersContractors = createAsyncThunk(
    'getFiltersContractors',
    async (payload: {}) => {
        return await Rest_contractors.getFiltersContractors(payload)
    }
)
const contractorsSlice = createSlice({
    name: 'contractors',
    initialState,
    reducers: {
        mapperSearchCompany: (state, action) => {
            state.dataCompany = action.payload.map((company: any) => ({...company, phone: company?.['phone_first'] ?? ''}))
        },
        clearSearchCompany: (state) => {
            state.dataCompany = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(saveContractors.fulfilled, (state, action) => {
            state.saveDataContractors = action.payload.data
        })
        builder.addCase(getContractors.fulfilled, (state, action) => {
            state.dataContractors = action.payload.data
        })
        builder.addCase(updateContractors.fulfilled, (state, action) => {
            state.dataContractors = action.payload.data
        })
        builder.addCase(getContractorsRequisites.fulfilled, (state, action) => {
            state.dataRequisites = action.payload.data
        })
        builder.addCase(findCompany.fulfilled, (state, action) => {
           // state.dataCompany = action.payload
        })
        builder.addCase(findMyCompany.fulfilled, (state, action) => {
            state.dataMyCompany = action.payload
        })
    },
});
export default contractorsSlice.reducer;
export const {
    mapperSearchCompany,
    clearSearchCompany
} = contractorsSlice.actions;

