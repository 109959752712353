import { IColumnType } from '../../components/ui/Table/Table';
import {Link} from "react-router-dom";

export const columns: IColumnType<any>[] = [
  {
    dataIndex: 'id',
    checkbox: true,
    menu: true,
    key: '0',
  },
  {
    title: 'Наименование терминала',
    dataIndex: 'city_child.name',
    link: true,
    type: 'string',
    width: '20%',
    key: '1',
  },
  {
    title: 'Родитель',
    dataIndex: 'city_child.city_terminals.name',
    width: '20%',
    type: 'string',
    link: true,
    key: '2',
  },
  {
    title: 'Сток',
    dataIndex: 'stock',
    link: true,
    type: 'string',
    width: '20%',
    key: '3',
    render: (col, item) => {
      const stockItem = Array.isArray(item?.stock) ? item?.stock?.[0]?.add_stock : item?.stock;
      return (
          stockItem ? <Link to={`/containers/?stock=${stockItem}&name=${stockItem}`}>
            {stockItem}
          </Link> : null
      );
    }
  },
  {
    title: 'Адрес',
    dataIndex: 'address_terminal',
    type: 'string',
    width: '60%',
    key: '4',
  },
  {
    title: 'Контакты',
    dataIndex: 'phone_director',
    type: 'string',
    width: '20%',
    key: '5',
  },
  {
    title: 'Вместимость',
    dataIndex: 'capacity',
    type: 'string',
    width: '20%',
    key: '6',
  },
  {
    title: '',
    dataIndex: 'hidden',
    width: '20%',
    key: '7',
  },
];
