import { useState } from 'react';
import { useEffect } from 'react';
import React, { useRef } from 'react';

import { delay } from 'lodash';
import classnames from 'classnames';
import { Drawer, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { CloseOutlined, HolderOutlined } from '@ant-design/icons';

import style from './CustomDrawer.module.scss';
import TooltipButton from '../ui/TooltipButton/TooltipButton';
import { addPropsToChildren } from '../../helpers/children-helpers';

interface IDrawer {
  closeIcon?: React.ReactNode;
  classCloseIcon?: string;
  minWidth?: number;
  open: boolean;
  isHeight?: boolean;
  title?: any;
  onClose: () => void;
  children: React.ReactElement | React.ReactElement[] | null;
}

const CustomDrawer: React.FC<IDrawer> = ({
  open,
  onClose,
  children,
  isHeight,
  closeIcon,
  classCloseIcon = '',
  minWidth = 550,
}) => {
  const { t } = useTranslation();
  const refContainer = useRef<HTMLDivElement>(null);
  const lineRef = useRef<HTMLDivElement>(null);
  const [isResize, setIsResize] = useState(false);
  const [height, setHeight] = useState(() => 'auto');
  const [width, setWidth] = useState(() => minWidth + 'px');
  const [widthContent, setWidthContent] = useState(() => 0);

  useEffect(() => {
    if (refContainer?.current && open && isHeight) {
      const parent = refContainer.current.closest('.ant-drawer-body');
      if (parent) {
        if (refContainer.current.scrollLeft) {
          setWidth(
            refContainer.current.getBoundingClientRect().width +
              refContainer.current.scrollLeft +
              'px',
          );
        }
        delay(() => {
          setHeight(parent.getBoundingClientRect().height + 'px');
        }, 0);
      }
    }
  }, [open]);

  const handleMouseMove = (evt: React.MouseEvent) => {
    if (lineRef?.current) {
      const widthWindow = window.innerWidth;
      const width = evt.clientX;
      const maxWidth = widthWindow - width;
      if (maxWidth > minWidth && width > 100) {
        lineRef.current.style.left = `${width}px`;
        lineRef.current.dataset.coord = `${maxWidth}`;
      }
    }
  };
  const handleMouseDown = (evt: React.MouseEvent) => {
    if (lineRef?.current) {
      const width = evt.clientX;
      lineRef.current.style.left = `${width}px`;
      lineRef.current.style.opacity = '1';
    }
  };
  const closeResize = (evt: React.MouseEvent) => {
    if (refContainer?.current && lineRef?.current) {
      setWidth(`${lineRef.current.dataset.coord}px `);
      setWidthContent(Number(lineRef.current.dataset.coord));
    }
    setIsResize(false);
  };
  const openResize = (evt: React.MouseEvent) => {
    setIsResize(true);
    delay(() => handleMouseDown(evt), 0);
  };

  return (
    <Drawer
      className={style.drawer}
      width={'auto'}
      placement="right"
      closable={false}
      forceRender={false}
      open={open}
    >
      {isResize && (
        <div
          onMouseMove={handleMouseMove}
          onMouseUp={closeResize}
          className={style.board}
        >
          <div ref={lineRef} className={style.board__line} />
        </div>
      )}
      <div
        ref={refContainer}
        className={style.drawer__container}
        style={{ height, maxWidth: width, width }}
      >
        <TooltipButton
          propsTooltip={{ title: t('Расширить окно') }}
          propsButton={{
            className: style['drawer__resize-button'],
            shape: 'default',
            icon: <HolderOutlined style={{ cursor: 'e-resize' }} />,
            onMouseUp: closeResize,
            onMouseDown: openResize,
          }}
        />
        <div
          className={classnames(style.drawer__close, {
            [classCloseIcon]: classCloseIcon,
          })}
          onClick={onClose}
        >
          <Tooltip title={t('Закрыть')}>
            {closeIcon ? closeIcon : <CloseOutlined />}
          </Tooltip>
        </div>
        {addPropsToChildren(children, { width: widthContent })}
      </div>
    </Drawer>
  );
};

export default CustomDrawer;
