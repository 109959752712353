import React, { useEffect } from 'react';
import { Button, Flex, Form } from 'antd';
import NumericInput from '../NumericInput/NumericInput';
import NumericInputFilter from "./NumericInputFilter";


interface ColumnRangeIntegerProps {
  column: any,
  columnIndex: number,
  handleDateChange: (type: ('start' | 'end'), date: any) => void,
  startDate?: Date | null,
  endDate?: Date | null,
  handleChange: (option: any[], dates: [Date | null | undefined, Date | null | undefined],
    dataInt: [number | '' | null | undefined, number | '' | null | undefined],
    checked: boolean, columnIndex: number) => void,
  startIntRange?: any,
  endIntRange?: any,
  columnFilters?: any,
  setStartIntRange?: any,
  setEndIntRange?: any,
  setColumnFilters?: any
}

const ColumnRangeInteger = ({
  column,
  columnIndex,
  handleDateChange,
  startDate,
  endDate,
  handleChange,
  startIntRange,
  endIntRange,
  columnFilters,
  setStartIntRange,
  setEndIntRange,
  setColumnFilters
}: ColumnRangeIntegerProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
      form.setFieldsValue({
        start: columnFilters?.[columnIndex]?.[0] || 0,
        end: columnFilters?.[columnIndex]?.[1] || 0
      })
  }, [columnFilters])

  const handleChangeDate = (type: 'start' | 'end', data: any) => {
    if (type === 'start') {
      setStartIntRange(data)
    } else {
      setEndIntRange(data)
    }
  }

  const onClear = (type: 'start' | 'end', data: any) => {
    if (type === 'start') {
      form.setFieldsValue({
        start: 0,
      })
    } else {
      form.setFieldsValue({
        end: 0,
      })
    }
  };

  const handleClear = () => {
    handleChange([], [startDate, endDate], [null, null],
      false, columnIndex);
    form.setFieldsValue({
      start: 0,
      end: 0
    })
  }

  const onFinish = (data: any) => {
    handleChange([startDate, endDate], [startDate, endDate],
      [data.start, data.end], false, columnIndex)
  }

  return (
    <Form onFinish={onFinish} form={form}>
      <Flex vertical>
        <Flex gap={7}>
          <Form.Item name={'start'}>
            <NumericInputFilter
              onClear={(e: any) => onClear('start', e)}
              style={{ width: 120 }}
              placeholder={column?.title}
              onChange={(e) => handleChangeDate('start', e)}
              maxLength={column.maxLength}
            />
          </Form.Item>
          <Form.Item name={'end'}>
            <NumericInputFilter
              onClear={(e: any) => onClear('end', e)}
              style={{ width: 120 }}
              placeholder={column?.title}
              onChange={(e) => handleChangeDate('end', e)}
              maxLength={column.maxLength}
            />
          </Form.Item>
        </Flex>
        <Flex justify={'flex-end'} gap={7}>
          <Button size={'small'}
            onClick={() => handleClear()}>Сбросить</Button>
          <Button type="primary" size={'small'} htmlType={'submit'}>ОК</Button>
        </Flex>
      </Flex>
    </Form>
  );
};

export default ColumnRangeInteger;
