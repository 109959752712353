import React, { useRef } from 'react';
import ScrollList from '../../ScrollList';

interface IScroll {
  height?: number;
  children: any
}
// скролит какой то текст можно использовать в тултипе
export const Scroll: React.FC<IScroll> = ({ children, height = 200 }) => {
  const ref = useRef(null)
  return (
    <ScrollList
      setLastItemReached={(v) => v}
      height={height}
      totalTableActivity={1}
      data={children}
      tableRef={ref}
      paginationActivity={() => {}}
      isTable={false}
      endMessage={null}
    >
      {children}
    </ScrollList>
  );
};