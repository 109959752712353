// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tel-input .form-control {
  width: 100% !important;
  height: 32px !important;
  border-radius: 6px !important;
  border: 1px solid #d9d9d9;
}

.react-tel-input .flag-dropdown {
  border-radius: 6px 0 0 6px;
  border: 1px solid #d9d9d9;
}`, "",{"version":3,"sources":["webpack://./src/pages/terminals/style.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,uBAAA;EACA,6BAAA;EACA,yBAAA;AACF;;AACA;EACE,0BAAA;EACA,yBAAA;AAEF","sourcesContent":[".react-tel-input .form-control{\n  width: 100% !important;\n  height: 32px !important;\n  border-radius: 6px !important;\n  border: 1px solid #d9d9d9;\n}\n.react-tel-input .flag-dropdown{\n  border-radius: 6px 0 0 6px;\n  border: 1px solid #d9d9d9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
