// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_button__vA-me {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  outline: none;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  height: auto !important;
  width: auto !important;
  padding: 0 !important;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/IconButton/style.module.scss"],"names":[],"mappings":"AAEA;EACE,kCAAA;EACA,mCAAA;EACA,aAAA;EACA,gBAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,qBAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,wBAAA;EACA,uBAAA;AADF","sourcesContent":["@import '../../../assets/scss/variables';\n\n.button {\n  text-rendering: optimizeLegibility;\n  -webkit-font-smoothing: antialiased;\n  outline: none;\n  box-shadow: none;\n  display: inline-flex;\n  align-items: center;\n  height: auto !important;\n  width: auto !important;\n  padding: 0 !important;\n  font-style: normal;\n  line-height: 0;\n  text-align: center;\n  text-transform: none;\n  vertical-align: -0.125em;\n  background: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `style_button__vA-me`
};
export default ___CSS_LOADER_EXPORT___;
