import React, {FC} from 'react';
import './style.scss'
import {useTranslation} from "react-i18next";
import {IContainersStatus} from "../../../types/containers";

export interface Props {
    type?: string,
    objectList?: Array<IContainersStatus> | undefined,
    arrayList?: string[],
    onClickArray?: (payload: string) => void,
    onClickObject?: (payload: IContainersStatus) => void
}

const ListComponent: FC<Props> = ({
                                      type,
                                      objectList,
                                      arrayList,
                                      onClickArray,
                                      onClickObject
                                  }) => {
    const {t} = useTranslation();
    return (
        <div className={'list-ul'}>
            {
                type === 'array' ?
                    arrayList?.map((elem: any, index: number) => (
                        <div className={'list-li'} key={index}
                             onClick={() => onClickArray && onClickArray(t(elem))}>{t(elem)}</div>
                    ))
                    :
                    objectList?.map((elem: any, index: number) => (
                        <div className={'list-li'} key={index}
                             onClick={() => onClickObject && onClickObject(elem)}>{t(elem.name)}</div>
                    ))
            }
        </div>
    );
};

export default ListComponent;
