import { memo, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Flex,
  Form,
  notification,
  Space,
  Typography,
} from 'antd';

import { SettingOutlined } from '@ant-design/icons';

import socket from '../../socket';
import { Utils } from '../../utils';
import { columns } from './columns';
import FormContacts from './formContact';
import ViewContacts from './viewContacts';
import { ITypeQuality } from '../../types';
import { IUsers } from '../../types/users';
import { RouteNames } from '../../router/names';
import { IContacts } from '../../types/contacts';
import { filtersContacts } from '../../store/filters';
import { RootState, useStoreDispatch } from '../../store';
import { entitiesInfo, UserInfo } from '../../store/users';
import { useCsvExport, useParamsHistory } from '../../hooks';
import { CREATE, EDIT, getTypeForm, VIEW } from '../../helpers/string-helpers';
import {
  IColumnType,
  IPagination,
  Table,
} from '../../components/ui/Table/Table';
import {
  deleteContact,
  findContactById,
  getContact,
  getFiltersContact,
  saveContact,
  updateContact,
} from '../../store/contact';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';

const { Title } = Typography;

const Contacts = () => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { location, history } = useParamsHistory();
  const { pathname } = location;
  const [form] = Form.useForm();
  const [openEdit, setOpenEdit] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [event, setEvent] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddContact, setIsAddContact] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(1);
  const [page, setPage] = useState<number>(20);
  const [dataContact, setDataContact] = useState<IContacts[]>([]);

  const userInfoData: IUsers[] = useSelector(
    (state: RootState) => state.users.userInfoData,
  );
  const dataTypeContact = useSelector(
    (state: RootState) => state.types.dataTypeContact,
  );
  const dataTypeQuality: ITypeQuality[] = useSelector(
    (state: RootState) => state.types.dataTypeQuality,
  );
  const totalData: number = useSelector(
    (state: RootState) => state.contact.totalData,
  );
  const [changeContacts, setChangeContacts] = useState<any[]>(
    Utils.defaultTable,
  );

  const [api, contextHolder] = notification.useNotification();
  const { generateCSV, copyToClipboard } = useCsvExport({
    fields: columns
      .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
      .map((item: any) => item.dataIndex), // Создаем массив из dataIndex
  });

  const fetchData = (current: number, page: number, order: string) => {
    setLoading(true);
    dispatch(
      getContact({ current: (current - 1) * page, page: page, order: order }),
    ).then((response) => {
      if (response.payload.data.rows.length > 0) {
        setChangeContacts(response.payload.data.rows);
        setLoading(false);
      }
    });
  };

  const deleteContactHandler = async (row: any) => {
    dispatch(
      deleteContact({
        current: (current - 1) * page,
        page: page,
        id: row.id,
        delete: row.delete !== true,
      }),
    );
  };

  const findEntitiesStatus = (response: any) => {
    response.res_client.forEach((item: any) => {
      item.entities === 'addContact' && setIsAddContact(item.status);
    });
  };

  const returnUpdateContact = (response: any) => {
    if (JSON.parse(response).success === 0) {
      Utils.InfoOpenNotification(
        'topRight',
        JSON.parse(response).message,
        20,
        api,
      );
    } else {
      setChangeContacts((prevData) => {
        return prevData.map((item) =>
          item.id === JSON.parse(response).data.id
            ? { ...JSON.parse(response).data }
            : item,
        );
      });

      if (Utils.getUserInfo().id === JSON.parse(response).data.user_id) {
        Utils.openNotification('topLeft', JSON.parse(response).message, api);
      }
      setOpenEdit(false);
      form.resetFields();
    }
  };

  const resUpdateEntities = (response: any) => {
    if (Utils.role().id === response.role_id) {
      response.res_client.map((item: any) => {
        item.entities === 'addContact' && setIsAddContact(item.status);
      });
    }
  };

  const resUpdateGroupEntities = (response: any) => {
    if (Utils.role().id === response.role_id) {
      response.res_client.map((item: any) => {
        item.entities === 'addContact' && setIsAddContact(item.status);
      });
    }
  };

  const returnNewContact = (response: any) => {
    if (JSON.parse(response).success === 0) {
      Utils.InfoOpenNotification(
        'topRight',
        JSON.parse(response).message,
        20,
        api,
      );
    } else {
      setChangeContacts((prev: any) => {
        return [JSON.parse(response).data, ...prev];
      });
      if (Utils.getUserInfo().id === JSON.parse(response).data.user_id) {
        Utils.openNotification('topLeft', JSON.parse(response).message, api);
      }
      setOpen(false);
      form.resetFields();
    }
  };

  const returnDeleteContact = (response: any) => {
    setChangeContacts((prevData) => {
      // Проверьте, что response является массивом, если это необходимо
      if (!Array.isArray([response])) {
        console.error('Invalid response data:', [response]);
        return prevData;
      }
      // Предположим, что response это объект с обновленными данными
      const updatedItem = response;
      return prevData.map((item) =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item,
      );
    });
  };

  useEffect(() => {
    dispatch(UserInfo({ id: Utils.getUserInfo().id }));
    fetchData(current, page, 'DESC');
    dispatch(entitiesInfo(Utils.role())).then((response: any) => {
      findEntitiesStatus(response.payload);
    });

    socket.on('return-update-contact', returnUpdateContact);
    socket.on('res-update-entities', resUpdateEntities);
    socket.on('res-update-group-entities', resUpdateGroupEntities);
    socket.on('return-new-contact', returnNewContact);
    socket.on('return-delete-contact', returnDeleteContact);

    return () => {
      socket.off('return-delete-contact', returnDeleteContact);
      socket.off('return-new-contact', returnNewContact);
      socket.off('res-update-entities', resUpdateEntities);
      socket.off('res-update-group-entities', resUpdateGroupEntities);
      socket.off('return-update-contact', returnUpdateContact);
    };
  }, [current, dispatch, page]);

  const showDrawer = () => {
    history.replace(RouteNames.CRM_CONTACTS_CREATE);
  };

  const handleEditContact = async (data: any) => {
    const { id } = data || {};
    if (id) {
      history.replace(`${RouteNames.CRM_CONTACTS_VIEW}${id}`);
    }
  };

  const onChange = async (pagination: IPagination<any>) => {
    if (pagination.current != null && pagination.pageSize != null) {
      await fetchData(current, pagination.pageSize, 'DESC');
    }
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    history.replace(RouteNames.CRM_CONTACTS);
  };

  const onCloseEdit = () => {
    setOpenEdit(false);
    history.replace(RouteNames.CRM_CONTACTS);
  };

  const handleShowEditForm = (data: any) => {
    setOpen(false);
    const { id } = data || {};
    if (id) {
      history.push(`${RouteNames.CRM_CONTACTS_EDIT}${id}`);
    }
  };

  const onFinish = (data: IContacts) => {
    if (event) {
      dispatch(saveContact(data));
    } else {
      dispatch(updateContact(data));
    }
  };

  const handleCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    const rowsData = generateCSV(arSelectedRows, changeContacts);
    copyToClipboard(rowsData);
  };

  const handleDeleteCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    arSelectedRows.forEach((item: any) => {
      // Оптимизированная фильтрация
      const deleteData = changeContacts.find(
        (el) => el.id === item && el.delete !== true,
      );

      if (deleteData) {
        dispatch(
          deleteContact({
            current: (current - 1) * page,
            page: page,
            id: item,
            delete: deleteData.delete !== true,
          }),
        );
      }
    });
  };

  useEffect(() => {
    const reg = /\d+/g;
    const [, current] = pathname.split(RouteNames.CRM_CONTACTS);
    const path = getTypeForm(current);
    if (!path) return;
    console.log('path', pathname);
    switch (path) {
      case CREATE:
        const isCreate = pathname.split('/')?.at(-1) === path;
        if (isCreate) {
          form.resetFields();
          setEvent(true);
          setOpen(true);
          return;
        }
        return history.replace(RouteNames.CRM_CONTACTS_CREATE);
      case EDIT:
        const idEdit = pathname.split(`${EDIT}/`).at(-1) ?? '';
        const isEdit = reg.test(idEdit);
        if (isEdit) {
          dispatch(findContactById(+idEdit)).then((res) => {
            const container = res?.payload;
            if (container) {
              setDataContact(container);
              setOpenEdit(true);
              setEvent(true);
            } else {
              history.push(RouteNames.CRM_CONTACTS);
            }
          });
          return;
        }
        return history.replace(RouteNames.CRM_CONTACTS);
      case VIEW:
        const idView = pathname.split(`${VIEW}/`).at(-1) ?? '';
        const isView = reg.test(idView);
        if (isView) {
          dispatch(findContactById(+idView)).then((res) => {
            const container = res?.payload;
            if (container) {
              form.resetFields();
              setDataContact(container);
              setOpen(true);
              setEvent(false);
            } else {
              history.push(RouteNames.CRM_CONTACTS);
            }
          });
          return;
        }
        history.replace(RouteNames.CRM_CONTACTS);
        return;
      default:
        if (current) {
          history.replace(RouteNames.CRM_CONTACTS);
        }
    }
  }, [pathname]);

  const handleOrder = (order: string) => {
    fetchData(current, page, order);
  };

  const filtersFetch = (text: string | number, column: IColumnType<any>) => {
    dispatch(filtersContacts({ text: text, column: column }));
  };

  const filtersColumn = async (value: []) => {
    if (value.length > 0) {
      dispatch(
        getFiltersContact({
          data: value,
          current: (current - 1) * page,
          page: page,
        }),
      ).then((response) => {
        setChangeContacts(response.payload.rows);
        // setTotal(response.payload.count)
      });
    } else {
      await fetchData(current, page, 'DESC');
    }
  };

  return (
    <>
      {contextHolder}
      <Flex
        justify={'space-between'}
        align={'flex-start'}
        style={{ margin: '20px 28px 0px' }}
      >
        <Title level={2}>{t('Контакты')}</Title>
        <Flex gap={16}>
          <Button onClick={showDrawer} type={'primary'}>
            {t('Создать')}
          </Button>
          <Button icon={<SettingOutlined />} type={'text'} />
        </Flex>
      </Flex>
      <Divider style={{ margin: 0 }} />
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          dataSource={changeContacts}
          columns={columns}
          loading={loading}
          filters={filtersColumn}
          filtersFetch={filtersFetch}
          order={handleOrder}
          pagination={{
            pageSize: page,
            total: Number(totalData),
            showSizeChanger: true,
          }}
          style={{ overflow: 'auto hidden' }}
          onChangePagination={onChange}
          editRow={handleShowEditForm}
          height={'calc(100vh - 309px)'}
          deleteRow={deleteContactHandler}
          handleDeleteCopyAll={handleDeleteCopyAll}
          handleCopyAll={handleCopyAll}
          onRow={(record) => {
            form.resetFields();
            return handleEditContact(record);
          }}
        />
      </Space>
      <CustomDrawer open={open} onClose={onClose}>
        {event ? (
          <FormContacts
            form={form}
            onFinish={onFinish}
            onClose={onClose}
            event={event}
            pagination={{ pageSize: page, current: current }}
            title={'Контакты'}
            dataTypeContact={dataTypeContact}
            dataContacts={dataContact}
            dataTypeQuality={dataTypeQuality}
            userInfoData={userInfoData}
          />
        ) : (
          <ViewContacts
            onClose={onClose}
            dataContacts={dataContact}
            form={form}
            title={'Редактировать компанию'}
            handleShowEditForm={() => handleShowEditForm(dataContact)}
            event={event}
            pagination={{ pageSize: page, current: current }}
            dataTypeContact={dataTypeContact}
            dataTypeQuality={dataTypeQuality}
            userInfoData={userInfoData}
            handleEditContact
          />
        )}
      </CustomDrawer>
      <CustomDrawer open={openEdit} onClose={onCloseEdit}>
        <FormContacts
          event={event}
          form={form}
          onFinish={onFinish}
          onClose={onCloseEdit}
          pagination={{ pageSize: page, current: current }}
          title={'Контакты'}
          dataTypeContact={dataTypeContact}
          dataContacts={dataContact}
          dataTypeQuality={dataTypeQuality}
          userInfoData={userInfoData}
        />
      </CustomDrawer>
    </>
  );
};

export default memo(Contacts);
