import React, { useRef, useState } from "react";
import { Typography, Row, Col, Button } from "antd";
import IconButton from "../../../../../components/ui/IconButton";
import { useReactToPrint } from "react-to-print";
import { Logo } from "../../../../../assets/image/svg";
import { Signature1, Signature2 } from "../../../../../assets/image/svg";
import StampMain from '../../../../../assets/image/StampMain.png'
import StampRU from '../../../../../assets/image/StampRU.png'
import StampEN from '../../../../../assets/image/StampEN.png'
import style from "./style.module.scss";

interface PdfSigningProps {
  formPdfData: any;
  defaultValues?: any;
  onClose: () => void;
  selectedOption: 'standard' | 'editable' | 'signature';
}

export const PdfSigningRU: React.FC<PdfSigningProps> = ({ formPdfData, onClose, selectedOption, defaultValues }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrint = useReactToPrint({ contentRef });

  const [localData, setLocalData] = useState({
    contractor_name: defaultValues.contractor_name,
    contractor_inn: defaultValues.contractor_inn,
    customer_name: formPdfData.customer_name,
    customer_inn: defaultValues.customer_inn,
    type: formPdfData.type,
    count_containers: formPdfData.count_containers,
    days: formPdfData.days,
    release_location: formPdfData.release_location,
    return_location: formPdfData.return_location,
    rate: formPdfData.rate,
    currency: formPdfData.currency,
    fine: formPdfData.fine
  });

  const handleEditField = (field: keyof typeof localData, newValue: string) => {
    setLocalData((prev) => ({ ...prev, [field]: newValue }));
  };

  const handleSave = () => {
    console.log("Сохранённые данные PDF:", localData);
    reactToPrint();
  };

  return (
    <div className={style["pdf"]}>
      <div className={style["pdf__page"]} ref={contentRef}>
        <div className={style["pdf__header"]}>
          <Logo />
        </div>
        <div className={style["pdf__content"]}>
          <div className={style["pdf__requestInfo"]}>
            <Typography.Text style={{ marginRight: "10px" }}>
              Заявка №
            </Typography.Text>

            <Typography.Text className={style["pdf__line"]}>
              {formPdfData.id_request}
            </Typography.Text>

            <Typography.Text className={style["pdf__text"]}>
              *<span style={{ marginRight: "20px" }}></span>
              от
            </Typography.Text>

            <Typography.Text className={style["pdf__line"]}>
              {formPdfData.date_request}
            </Typography.Text>
            <Typography.Text className={style["pdf__text"]}>
              {" "}
              года
            </Typography.Text>
          </div>

          <div className={style["pdf__requestInfo"]}>
            <Typography.Text
              className={`${style["pdf__text"]} ${style["margin"]}`}
            >
              к Договору оказания услуг по предоставлению контейнеров
            </Typography.Text>
            <Typography.Text className={style["pdf__text"]}> №</Typography.Text>
            <Typography.Text className={style["pdf__line"]}></Typography.Text>

            <Typography.Text className={style["pdf__text"]}>
              *<span style={{ marginRight: "20px" }}></span>
              от
            </Typography.Text>

            <Typography.Text className={style["pdf__line"]}></Typography.Text>
            <Typography.Text className={style["pdf__text"]}>
              {" "}
              года
            </Typography.Text>
          </div>

          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "15px",
            }}
            className={style["pdf__table"]}
          >
            <colgroup>
              <col style={{ width: "35%" }} />
              <col style={{ width: "35%" }} />
              <col style={{ width: "30%" }} />
            </colgroup>
            <tbody>
              <tr>
                <th
                  className={style["pdf__table__cel"]}
                  style={{
                    borderBottom: "1px solid transparent",
                    width: "35%",
                  }}
                >
                  <Typography.Text className={style["pdf__table__cel__text"]}>
                    Исполнитель
                  </Typography.Text>
                </th>
                <td className={style["pdf__table__cel"]}>
                  <Typography.Text className={style["pdf__table__cel__text"]}>
                    Наименование
                  </Typography.Text>
                </td>
                <td className={style["pdf__table__cel"]}>
                  <Typography.Text className={style["pdf__table__cel__text"]}>
                    ИНН
                  </Typography.Text>
                </td>
              </tr>
              <tr>
                <td className={style["pdf__table__cel"]}></td>
                <td className={style["pdf__table__cel"]}>
                  <Typography.Text 
                    // editable={selectedOption === 'editable'}
                    editable={
                      selectedOption === 'editable'
                        ? { onChange: (val) => handleEditField('contractor_name', val) }
                        : false
                    }
                    className={style["pdf__table__cel__text"]}
                  >
                    {localData.contractor_name}
                  </Typography.Text>
                </td>
                <td className={style["pdf__table__cel"]}>
                  <Typography.Text 
                    editable={
                      selectedOption === 'editable'
                        ? { onChange: (val) => handleEditField('contractor_inn', val) }
                        : false
                    }
                    className={style["pdf__table__cel__text"]}
                  >
                    {localData.contractor_inn}
                  </Typography.Text>
                </td>
              </tr>
              <tr>
                <th
                  className={style["pdf__table__cel"]}
                  style={{
                    borderBottom: "1px solid transparent",
                  }}
                >
                  <Typography.Text className={style["pdf__table__cel__text"]}>
                    Заказчик
                  </Typography.Text>
                </th>
                <td className={style["pdf__table__cel"]}>
                  <Typography.Text className={style["pdf__table__cel__text"]}>
                    Наименование
                  </Typography.Text>
                </td>
                <td className={style["pdf__table__cel"]}>
                  <Typography.Text className={style["pdf__table__cel__text"]}>
                    ИНН
                  </Typography.Text>
                </td>
              </tr>
              <tr>
                <td className={style["pdf__table__cel"]}></td>
                <td className={style["pdf__table__cel"]}>
                  <Typography.Text 
                    className={style["pdf__table__cel__text"]}
                    editable={
                      selectedOption === 'editable'
                        ? { onChange: (val) => handleEditField('customer_name', val) }
                        : false
                    }
                  >
                    {localData.customer_name}
                  </Typography.Text>
                </td>
                <td className={style["pdf__table__cel"]}>
                  <Typography.Text 
                    className={style["pdf__table__cel__text"]}
                    editable={
                      selectedOption === 'editable'
                        ? { onChange: (val) => handleEditField('customer_inn', val) }
                        : false
                    }
                  >
                    {localData.customer_inn}
                  </Typography.Text>
                </td>
              </tr>
            </tbody>
          </table>

          <div className={style["pdf__container"]}>
            <table className={style["pdf__table"]}>
              <colgroup>
                <col style={{ width: "35%" }} />
                <col style={{ width: "21%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "22%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th
                    className={style["pdf__table__cel"]}
                    colSpan={4}
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__title"]}>
                      Предоставление Контейнера:
                    </Typography.Text>
                  </th>
                </tr>
                <tr>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Типоразмер контейнеров
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}> 
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('type', val) }
                          : false
                      }
                    >
                      {localData.type}
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Количество контейнеров
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}> 
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('count_containers', val) }
                          : false
                      }
                    >
                      {localData.count_containers}
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td
                    className={style["pdf__table__cel"]}
                    style={{
                      borderRight: "1px solid transparent",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Дата (период) выдачи
                    </Typography.Text>
                    
                  </td>
                  <td className={style["pdf__table__cel"]}></td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('days', val) }
                          : false
                      }
                    >
                      {localData.days} дней
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td
                    className={style["pdf__table__cel"]}
                    style={{
                      borderRight: "1px solid transparent",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Пункт выдачи контейнеров
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}></td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('release_location', val) }
                          : false
                      }
                    >
                      {localData.release_location}
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}> 
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={style["pdf__table"]} style={{ marginTop: "0px" }}>
              <colgroup>
                <col style={{ width: "56%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "22%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th
                    className={style["pdf__table__cel"]}
                    colSpan={3}
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      borderTop: "none",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__title"]}>
                      Возврат Контейнера:
                    </Typography.Text>
                  </th>
                </tr>
                <tr>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Пункт возврата контейнеров
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('return_location', val) }
                          : false
                      }
                    >
                      {localData.return_location}
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Инструкция по передаче контейнеров в пункте возврата
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={style["pdf__table"]} style={{ marginTop: "0px" }}>
              <colgroup>
                <col style={{ width: "56%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "22%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th
                    className={style["pdf__table__cel"]}
                    colSpan={3}
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      borderTop: "none",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__title"]}>
                      Общие условия:
                    </Typography.Text>
                  </th>
                </tr>
                <tr>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Период (срок) нормативного пользования
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <th
                    className={style["pdf__table__cel"]}
                    style={{
                      borderBottom: "1px solid transparent",
                      verticalAlign: "bottom",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Согласованная стоимость услуг и порядок оплаты
                    </Typography.Text>
                    
                  </th>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Стоимость услуг
                    </Typography.Text>
                    <br /> <span className={style["pdf__table__cel__text__note"]}>(за 1 контейнер)</span>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Порядок оплаты
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td
                    className={style["pdf__table__cel"]}
                    style={{
                      verticalAlign: "top",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text__note"]}>
                      (если отличается от порядка, установленного Договором)
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('rate', val) }
                          : false
                      }
                    >
                    {localData.rate} {localData.currency}
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>

                <tr>
                  <th
                    className={style["pdf__table__cel"]}
                    style={{
                      borderBottom: "1px solid transparent",
                      verticalAlign: "bottom",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Ставка сбора сверхнормативное использование контейнеров
                    </Typography.Text>
                  </th>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      от 1 до 10 суток
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      с 11-х суток
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td
                    className={style["pdf__table__cel"]}
                    style={{
                      verticalAlign: "top",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text__note"]}>
                      (если отличается от ставки, установленной Договором)
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>

                <tr>
                  <td
                    className={style["pdf__table__cel"]}
                    style={{
                      lineHeight: "128%",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Размер штрафа за перемещение Контейнера по несогласованному маршруту
                    </Typography.Text>
                    <br />
                    <Typography.Text className={style["pdf__table__cel__text__note"]}>
                      (если отличается от размера штрафа, установленного Договором)
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]} colSpan={2}>
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('fine', val) }
                          : false
                      }
                    >
                      {localData.fine}
                    </Typography.Text>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Typography.Text className={style["pdf__table__cel__title"]}>
          Заказчик гарантирует оплату услуг согласно условиям Договора и настоящей Заявки. <br/>
          Настоящая Заявка является неотъемлемой частью Договора, составлена в двух экземплярах, <br/>
          имеющих одинаковую юридическую силу, по одному для каждой из Сторон.
        </Typography.Text>
        <Typography.Text 
          className={style["pdf__table__cel__text__note"]}
          style={{
            fontWeight: '400',
            marginTop: '8px',
            marginBottom: '17px'
          }}
        >
          *Номер Заявки представляет собой уникальный код, присвоенный Исполнителем для идентификации документа и целей внутреннего учета и не отражает колич﻿ество и порядковый номер Заявок, согласованных в рамках Договора.
        </Typography.Text>
        <Row gutter={52}>
          <Col span={12} className={style["pdf__sign"]}>
            <Typography.Text 
              className={style["pdf__table__cel__text"]}
              style={{
                fontSize: '10px',
              }}
            >
              от Исполнителя
            </Typography.Text>
            <div 
              className={style["pdf__sign__field"]}
              style={{width: '100%'}}
            >
              <span className={style["sign__text"]}>(должность)</span>
            </div>
            <div className={style["pdf__sign__field"]}>
              <span className={style["sign__text"]}>(подпись)</span>
              <span className={style["sign__text"]}>(фамилия, инициалы)</span>
            </div>
            {selectedOption === 'signature' && (
              <div className={style["signatures"]}>
                <img src={StampMain}/>
                <Signature1/>
              </div>
            )}
          </Col>
          <Col span={12} className={style["pdf__sign"]} style={{position: 'relative'}}>
            <Typography.Text 
              className={style["pdf__table__cel__text"]}
              style={{
                fontSize: '10px',
              }}
            >
              от Заказчика
            </Typography.Text>
            <div 
              className={style["pdf__sign__field"]}
              style={{
                width: '100%',
              }}
            >
              <span className={style["sign__text"]}>(должность)</span>
            </div>
            <div className={style["pdf__sign__field"]}>
              <span className={style["sign__text"]}>(подпись)</span>
              <span className={style["sign__text"]}>(фамилия, инициалы)</span>
            </div>
            {selectedOption === 'signature' && (
              <div className={style["signatures"]} style={{position: 'absolute', bottom: '-14%', left: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={StampRU}/>
                <Signature2 />
              </div>
            )}
          </Col>
        </Row>

      </div>
      <div style={{marginTop: 4, display: 'flex', justifyContent: 'space-evenly'}}>
        <Button type="primary" style={{width: '42%', height: '35px'}} onClick={handleSave}>
          Сохранить
        </Button>
        <Button
            type="text"
            style={{ width: "30%", color: "#E14453" }}
            onClick={onClose}
          >
            Отменить
        </Button>
      </div>
    </div>
  );
};



















//ИНГЛИШ
export const PdfSigningEN: React.FC<PdfSigningProps> = ({ formPdfData, onClose, selectedOption, defaultValues }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrint = useReactToPrint({ contentRef });

  const [localData, setLocalData] = useState({
    contractor_name: defaultValues.contractor_name,
    contractor_inn: defaultValues.contractor_inn,
    customer_name: formPdfData.customer_name,
    customer_inn: defaultValues.customer_inn,
    type: formPdfData.type,
    count_containers: formPdfData.count_containers,
    days: formPdfData.days,
    release_location: formPdfData.release_location,
    return_location: formPdfData.return_location,
    rate: formPdfData.rate,
    currency: formPdfData.currency,
    fine: formPdfData.fine
  });

  const handleEditField = (field: keyof typeof localData, newValue: string) => {
    setLocalData((prev) => ({ ...prev, [field]: newValue }));
  };

  const handleSave = () => {
    console.log("Сохранённые данные PDF:", localData);
    reactToPrint();
  };

  return (
    <div className={style["pdf"]}>
      <div className={style["pdf__page"]} ref={contentRef}>
        <div className={style["pdf__header"]}>
          <Logo />
        </div>
        <div className={style["pdf__content"]}>
          <div className={style["pdf__requestInfo"]}>
            <Typography.Text style={{ marginRight: "10px" }}>
              Request №
            </Typography.Text>

            <Typography.Text className={style["pdf__line"]}>
              {formPdfData.id_request}
            </Typography.Text>

            <Typography.Text className={style["pdf__text"]}>
              *<span style={{ marginRight: "20px" }}></span>
              dated
            </Typography.Text>

            <Typography.Text className={style["pdf__line"]}>
              {formPdfData.date_request}
            </Typography.Text>
          </div>

          <div className={style["pdf__requestInfo"]}>
            <Typography.Text
              className={`${style["pdf__text"]} ${style["margin"]}`}
            >
              to the Service Contract for the Provision of Containers
            </Typography.Text>
            <Typography.Text className={style["pdf__text"]}> №</Typography.Text>
            <Typography.Text className={style["pdf__line"]}></Typography.Text>

            <Typography.Text className={style["pdf__text"]}>
              *<span style={{ marginRight: "20px" }}></span>
              dated
            </Typography.Text>

            <Typography.Text className={style["pdf__line"]}></Typography.Text>
          </div>

          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "15px",
            }}
            className={style["pdf__table"]}
          >
            <colgroup>
              <col style={{ width: "35%" }} />
              <col style={{ width: "65%" }} />
            </colgroup>
            <tbody>
              <tr>
                <th
                  className={style["pdf__table__cel"]}
                  style={{
                    borderBottom: "1px solid transparent",
                    width: "35%",
                  }}
                >
                  <Typography.Text className={style["pdf__table__cel__text"]}>
                  Customer
                  </Typography.Text>
                </th>
                <td className={style["pdf__table__cel"]} style={{textAlign: 'center'}}>
                  <Typography.Text className={style["pdf__table__cel__text"]} >
                  Company name
                  </Typography.Text>
                </td>
                {/* <td className={style["pdf__table__cel"]}>
                  <Typography.Text className={style["pdf__table__cel__text"]}>
                    ИНН
                  </Typography.Text>
                </td> */}
              </tr>
              <tr>
                <td className={style["pdf__table__cel"]}></td>
                <td className={style["pdf__table__cel"]} style={{textAlign: 'center'}}>
                  <Typography.Text 
                    // editable={selectedOption === 'editable'}
                    editable={
                      selectedOption === 'editable'
                        ? { onChange: (val) => handleEditField('contractor_name', val) }
                        : false
                    }
                    className={style["pdf__table__cel__text"]}
                  >
                    {localData.contractor_name}
                  </Typography.Text>
                </td>
                {/* <td className={style["pdf__table__cel"]}>
                  <Typography.Text 
                    editable={
                      selectedOption === 'editable'
                        ? { onChange: (val) => handleEditField('contractor_inn', val) }
                        : false
                    }
                    className={style["pdf__table__cel__text"]}
                  >
                    {localData.contractor_inn}
                  </Typography.Text>
                </td> */}
              </tr>
              <tr>
                <th
                  className={style["pdf__table__cel"]}
                  style={{
                    borderBottom: "1px solid transparent",
                  }}
                >
                  <Typography.Text className={style["pdf__table__cel__text"]}>
                  Contractor
                  </Typography.Text>
                </th>
                <td className={style["pdf__table__cel"]} style={{textAlign: 'center'}}>
                  <Typography.Text className={style["pdf__table__cel__text"]}>
                  Company name
                  </Typography.Text>
                </td>
              </tr>
              <tr>
                <td className={style["pdf__table__cel"]}></td>
                <td className={style["pdf__table__cel"]} style={{textAlign: 'center'}}>
                  <Typography.Text 
                    className={style["pdf__table__cel__text"]}
                    editable={
                      selectedOption === 'editable'
                        ? { onChange: (val) => handleEditField('customer_name', val) }
                        : false
                    }
                  >
                    {localData.customer_name}
                  </Typography.Text>
                </td>
              </tr>
            </tbody>
          </table>

          <div className={style["pdf__container"]}>
            <table className={style["pdf__table"]}>
              <colgroup>
                <col style={{ width: "35%" }} />
                <col style={{ width: "21%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "22%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th
                    className={style["pdf__table__cel"]}
                    colSpan={4}
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__title"]}>
                    Container provision:
                    </Typography.Text>
                  </th>
                </tr>
                <tr>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    Standard size of Containers
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}> 
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('type', val) }
                          : false
                      }
                    >
                      {localData.type}
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    Quantity of containers
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}> 
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('count_containers', val) }
                          : false
                      }
                    >
                      {localData.count_containers}
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td
                    className={style["pdf__table__cel"]}
                    style={{
                      borderRight: "1px solid transparent",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    Date (period) of provision   of Containers
                    </Typography.Text>
                    
                  </td>
                  <td className={style["pdf__table__cel"]}></td>
                  <td className={style["pdf__table__cel"]} style={{borderRight: 'none'}}>
                    <Typography.Text
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('days', val) }
                          : false
                      }
                    >
                      {localData.days} days
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]} style={{borderLeft: 'none'}}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td
                    className={style["pdf__table__cel"]}
                    style={{
                      borderRight: "1px solid transparent",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    Release point of Containers
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}></td>
                  <td className={style["pdf__table__cel"]} style={{borderRight: 'none'}}>
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('release_location', val) }
                          : false
                      }
                    >
                      {localData.release_location}
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]} style={{borderLeft: 'none'}}> 
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td
                    className={style["pdf__table__cel"]}
                    style={{
                      borderRight: "1px solid transparent",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                      Instructions for handover of Containers at the Release point
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}></td>
                  <td className={style["pdf__table__cel"]} style={{borderRight: 'none'}}>
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('release_location', val) }
                          : false
                      }
                    >
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]} style={{borderLeft: 'none'}}> 
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                    >
                    
                    </Typography.Text>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={style["pdf__table"]} style={{ marginTop: "0px" }}>
              <colgroup>
                <col style={{ width: "56%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "22%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th
                    className={style["pdf__table__cel"]}
                    colSpan={3}
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      borderTop: "none",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__title"]}>
                    Container return:
                    </Typography.Text>
                  </th>
                </tr>
                <tr>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    Return point of Containers
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('return_location', val) }
                          : false
                      }
                    >
                      {localData.return_location}
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    Instructions for handover of   Containers at the return point
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={style["pdf__table"]} style={{ marginTop: "0px" }}>
              <colgroup>
                <col style={{ width: "56%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "22%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th
                    className={style["pdf__table__cel"]}
                    colSpan={3}
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      borderTop: "none",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__title"]}>
                    General terms:
                    </Typography.Text>
                  </th>
                </tr>
                <tr>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    Period (term) of normative use
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]} style={{borderRight: 'none'}}>
                    
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]} style={{borderLeft: 'none'}}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <th
                    className={style["pdf__table__cel"]}
                    style={{
                      borderBottom: "1px solid transparent",
                      verticalAlign: "bottom",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    The agreed cost of services and the procedure for payment
                    </Typography.Text>
                    
                  </th>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    Cost of services
                    </Typography.Text>
                    <br /> <span className={style["pdf__table__cel__text__note"]}>(for 1 container)</span>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    Settlement procedure
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td
                    className={style["pdf__table__cel"]}
                    style={{
                      verticalAlign: "top",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text__note"]}>
                    (if different from the procedure stipulated by the Contract)
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('rate', val) }
                          : false
                      }
                    >
                    {localData.rate} {localData.currency}
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>

                <tr>
                  <th
                    className={style["pdf__table__cel"]}
                    style={{
                      borderBottom: "1px solid transparent",
                      verticalAlign: "bottom",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    Rate of fee for excessive use of Containers
                    </Typography.Text>
                  </th>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    from 1 to 10 days
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    from the 11th day
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td
                    className={style["pdf__table__cel"]}
                    style={{
                      verticalAlign: "top",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text__note"]}>
                    (if different from the procedure stipulated by the Contract)
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]}>
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    
                    </Typography.Text>
                  </td>
                </tr>

                <tr>
                  <td
                    className={style["pdf__table__cel"]}
                    style={{
                      lineHeight: "128%",
                    }}
                  >
                    <Typography.Text className={style["pdf__table__cel__text"]}>
                    The amount of penalty for   transfer of Container along a non-agreed route
                    </Typography.Text>
                    <br />
                    <Typography.Text className={style["pdf__table__cel__text__note"]}>
                    (if different   from the procedure stipulated by the Contract)
                    </Typography.Text>
                  </td>
                  <td className={style["pdf__table__cel"]} colSpan={2}>
                    <Typography.Text 
                      className={style["pdf__table__cel__text"]}
                      editable={
                        selectedOption === 'editable'
                          ? { onChange: (val) => handleEditField('fine', val) }
                          : false
                      }
                    >
                      {localData.fine}
                    </Typography.Text>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Typography.Text className={style["pdf__table__cel__title"]}>
        The Customer undertakes, within 14 (fourteen) calendar days from the date of notification by the Contractor by e-mail, by sending a release for issuance from the Terminal or in any other way provided for by the current legislation, of the readiness for issuance of the container, to collect the container from the Terminal. After the expiration of the specified period, the Customer independently and at his own expense bears the costs associated with the storage of the container at the Terminal. In this case, the Contractor has the right to issue an invoice for payment - reimbursement of the Terminal&apos;s expenses at the expense of the Customer.
        </Typography.Text>
        <Typography.Text 
          className={style["pdf__table__cel__text__note"]}
          style={{
            fontWeight: '400',
            marginTop: '8px',
            marginBottom: '17px'
          }}
        >
          The Customer guarantees payment for services in accordance with the terms of the Contract and this Request.
          This Request is an integral part of the Contract, drawn up in two copies having the same legal force, one for each of the Parties.
        </Typography.Text>
        <Row gutter={52}>
          <Col span={12} className={style["pdf__sign"]} style={{position: 'relative', gap: '15px'}}>
            <Typography.Text 
              className={style["pdf__table__cel__text"]}
              style={{
                fontSize: '10px',
                marginBottom: '7px'
              }}
            >
              The Customer:
            </Typography.Text>
            <div style={{
                  display: 'flex',
                  fontSize: '10px',
                  marginBottom: '0px'
                }}>
              <div 
                className={style["pdf__sign__field"]}
                style={{
                  // marginBottom: '5px',
                  width: '65%',
                  borderTop: 'none',
                  borderBottom: '1px solid #969799'
                }}
              ></div>
              / 
              <div 
                className={style["pdf__sign__field"]}
                style={{
                  // marginBottom: '5px',
                  width: '30%',
                  borderTop: 'none',
                  borderBottom: '1px solid #969799'
                }}
              ></div>
            </div>
            <Typography.Text 
              className={style["pdf__table__cel__text"]}
              style={{
                fontSize: '10px',
              }}
            >
              L.S.
            </Typography.Text>
            {selectedOption === 'signature' && (
              <div className={style["signatures"]} style={{position: 'absolute', bottom: '2%', left: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={StampMain}/>
                <Signature1/>
              </div>
            )}
          </Col>
          <Col span={12} className={style["pdf__sign"]} style={{position: 'relative', gap: '5px'}}>
            <Typography.Text 
              className={style["pdf__table__cel__text"]}
              style={{
                fontSize: '10px',
              }}
            >
              The Contractor: 
            </Typography.Text>
            <Typography.Text 
              className={style["pdf__table__cel__text"]}
              style={{
                fontSize: '10px',
                marginBottom: '7px'
              }}
            >
              Director
            </Typography.Text>
            <div style={{
                  display: 'flex',
                  fontSize: '10px',
                  marginBottom: '7px'
                }}>
              <div 
                className={style["pdf__sign__field"]}
                style={{
                  marginBottom: '5px',
                  width: '65%',
                  borderTop: 'none',
                  borderBottom: '1px solid #969799'
                }}
              ></div>
              / Alexey Mychko
            </div>
            
            <Typography.Text 
              className={style["pdf__table__cel__text"]}
              style={{
                fontSize: '10px',
              }}
            >
              L.S.
            </Typography.Text>
            {selectedOption === 'signature' && (
              <div className={style["signatures"]} style={{position: 'absolute', bottom: '0%', left: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={StampEN}/>
                <Signature2 />
              </div>
            )}
          </Col>
        </Row>

      </div>
      <div style={{marginTop: 4, display: 'flex', justifyContent: 'space-evenly'}}>
        <Button type="primary" style={{width: '42%', height: '35px'}} onClick={handleSave}>
          Сохранить
        </Button>
        <Button
            type="text"
            style={{ width: "30%", color: "#E14453" }}
            onClick={onClose}
          >
            Отменить
        </Button>
      </div>
    </div>
  );
};




