import React from 'react';
import { ITabView } from '../../../../../../store/rent';


const TabDeal: React.FC<ITabView> = () => {

  return <div>'VIEW'</div>;
};

export default TabDeal;
