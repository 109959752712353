// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_view__UD9qz {
  width: 100% !important;
}
.style_view__title__hLwx6 {
  margin: 0 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #a3a9b1 !important;
}
.style_view__subtitle__0TQgn {
  margin: 0 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.style_view__subtitle_link__WuHtx {
  cursor: pointer;
  color: #05F !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/List/ViewRow/style.module.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;AADF;AAEE;EACE,oBAAA;EACA,0BAAA;EACA,2BAAA;EACA,yBAAA;AAAJ;AAGE;EACE,oBAAA;EACA,0BAAA;EACA,2BAAA;AADJ;AAEI;EACE,eAAA;EACA,sBAAA;AAAN","sourcesContent":["@import '../../../../assets/scss/variables';\n\n.view {\n  width: 100% !important;\n  &__title {\n    margin: 0 !important;\n    font-size: 14px !important;\n    font-weight: 500 !important;\n    color: $color-label-default !important;\n  }\n\n  &__subtitle {\n    margin: 0 !important;\n    font-size: 12px !important;\n    font-weight: 400 !important;\n    &_link {\n      cursor: pointer;\n      color: $color-link-default !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view": `style_view__UD9qz`,
	"view__title": `style_view__title__hLwx6`,
	"view__subtitle": `style_view__subtitle__0TQgn`,
	"view__subtitle_link": `style_view__subtitle_link__WuHtx`
};
export default ___CSS_LOADER_EXPORT___;
