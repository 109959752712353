import React from "react";

export const TwentyFourNavigationCloseSmall = ({ className }: any) => {
    return (
        <svg
            className={`twenty-four-navigation-close-small ${className}`}
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="path"
                clipRule="evenodd"
                d="M10.9396 12.0008L6.84814 16.0923L7.9088 17.153L12.0003 13.0615L16.0918 17.153L17.1524 16.0923L13.061 12.0008L17.1524 7.90929L16.0918 6.84863L12.0003 10.9401L7.90882 6.84863L6.84816 7.90929L10.9396 12.0008Z"
                fill="#445371"
                fillRule="evenodd"
            />
        </svg>
    );
};
