import React from 'react';
import { Layout, Spin } from 'antd';

const loadingStyle: React.CSSProperties = {
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  margin: 'auto',
  background: '#F8F8F8'
};

const Loading = () => {
  return (
    <Layout style={loadingStyle}><Spin /></Layout>
  )
}

export default Loading
