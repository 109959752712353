import { IColumnType } from '../../components/ui/Table/Table';

export const columns: IColumnType<any>[] = [
  {
    dataIndex: 'id',
    checkbox: true,
    menu: true,
    type: 'string',
    key: '0',
  },
  {
    title: 'Наименование терминала',
    dataIndex: 'city_child.name',
    link: true,
    type: 'string',
    width: '20%',
    key: '1',
  },
  {
    title: 'Родитель',
    dataIndex: 'city_child.city_terminals.name',
    width: '20%',
    type: 'string',
    link: true,
    key: '2',
  },
  {
    title: 'Адрес',
    dataIndex: 'address_terminal',
    type: 'string',
    width: '60%',
    key: '3',
  },
  {
    title: 'Контакты',
    dataIndex: 'phone_director',
    type: 'string',
    width: '20%',
    key: '4',
  },
  {
    title: 'Вместимость',
    dataIndex: 'capacity',
    type: 'string',
    width: '20%',
    key: '5',
  },
  {
    title: '',
    dataIndex: 'hidden',
    width: '20%',
    key: '6',
  },
];
