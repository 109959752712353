import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {UserRequest} from '../services/rest_users'
import {IUsers} from "../types/users";
import {Utils} from "../utils";

interface IUsersState {
    usersData: Array<IUsers>,
    userInfoData: Array<IUsers>
    auth: boolean,
    dataEntities: []
}

const initialState: IUsersState = {
    usersData: [],
    userInfoData: [],
    auth: false,
    dataEntities: []
};
export const Registration = createAsyncThunk(
    'Users',
    async (payload: []) => {
        const response: any = await UserRequest.registration(payload);
        const jRes = JSON.parse(response)
        await Utils.setStorage('_in',jRes.data)
        return response
    }
)
export const Login = createAsyncThunk(
    'Users',
    async (payload: []) => {
        const response: any = await UserRequest.login(payload);
        const jRes = JSON.parse(response)
        await Utils.setStorage('_in',jRes.data)
        return response
    }
)
export const resetPassword = createAsyncThunk(
    'resetPassword',
    async (email: string) => {
        return await UserRequest.resetPassword(email)
    }
)
export const UserInfo = createAsyncThunk(
    'UserInfo',
    async (payload: {}) => {
        return await UserRequest.userInfo(payload)
    }
)
export const refUserInfo = createAsyncThunk(
    'refUserInfo',
    async (payload: {}) => {
        const response: any = await UserRequest.refUserInfo(payload);
        return response
    }
)
export const entitiesInfo = createAsyncThunk(
    'entitiesInfo',
    async (payload: {}) => {
        const response: any = await UserRequest.entitiesInfo(payload);
        return response
    }
)
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setAuth(state,action) {
            state.auth = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(Registration.fulfilled, (state, action) => {
            state.usersData = action.payload
            const jRes = JSON.parse(action.payload)
            if (jRes.success === 1){
                state.auth = true
            }
        })
        builder.addCase(UserInfo.fulfilled, (state, action) => {
            state.userInfoData = action.payload
        })
        builder.addCase(refUserInfo.fulfilled, (state, action) => {
            state.userInfoData = action.payload
        })
        builder.addCase(entitiesInfo.fulfilled, (state, action) => {
            state.dataEntities = action.payload
        })
    },
});
export default usersSlice.reducer;
export const { setAuth } = usersSlice.actions
