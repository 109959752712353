import React from "react";

const addPropsToReactElement = (element: React.ReactElement, props: any) => {
  if (React.isValidElement(element)) {
    return React.cloneElement(element, props);
  }
  return element;
};

const addPropsToChildren = (
  children: React.ReactElement | React.ReactElement[] | null,
  props: any,
) => {
  if (!children) return null;
  if (!Array.isArray(children)) {
    return addPropsToReactElement(children, props);
  }
  return children.map((childElement) =>
    addPropsToReactElement(childElement, props),
  );
};


export {addPropsToChildren, addPropsToReactElement};