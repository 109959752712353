import React, { useRef } from 'react';
import IconButton from '../../../../components/ui/IconButton';
import releaseStyle from '../../style.module.scss';
import { Flex, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { Logo } from '../../../../assets/image/svg';
import { useReactToPrint } from 'react-to-print';
import { getSizeKtk } from '../utils';
import { IRelease } from '../../../../store/releases';



const title = 'Релиз выдачи';
const titleEn = 'Release Order';

const companyEn = {
  title: 'MyContainers',
  subtitle: [],
};
const contactsEn = {
  title: '',
  subtitle: [],
};
const contactsInfoEn = {
  title: '',
};
const company = {
  title: 'Общество с ограниченной ответственностью “МОЙ КОНТЕЙНЕР”',
  subtitle: [
    '620105, Свердловская обл. г. Екатеринбург, ул. Рябинина, д. 19, кв. 254',
    'info@mycontainers.ru, op@mycontainers.ru',
    'ОГРН 1206600033551, ИНН 6658535373, КПП 665801001',
    'Р/с 40702810502500066258 ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"',
    'К/с 30101810845250000999, БИК 044525999',
  ],
};
const contacts = {
  title: 'Major Cargo Service, сток Мой контейнер',
  subtitle: [
    'Major Cargo Service, сток Мой контейнер',
    'координаты: 55.490809; 37.576654',
    'адрес: г. Москва, Щербинка, ул. Восточная д.16, стр.1',
    'Круглосуточно',
    '!!! Необходимо позвонить минимум за 2 часа до приезда',
  ],
};
const contactsInfo = {
  title: 'Контакты компании:',
  subtitle: [
    ' lms@mycontainers.ru Марина 89193922083',
    'bv@mycontainers.ru Владислав 89122513746',
  ],
};

const Info = ({
  title,
  subtitle = [],
  position = 'end',
}: {
  title: string;
  subtitle?: string[];
  position?: 'end' | 'start' | 'center';
}) => {
  return (
    <Flex vertical align={position} style={{ maxWidth: '200px', margin: 0 }}>
      <Typography.Title level={5} className={releaseStyle['pdf__logo-title']}>
        {title}
      </Typography.Title>
      {subtitle?.map((str, i) => (
        <Typography.Paragraph
          key={i}
          className={releaseStyle['pdf__logo-text']}
          style={{ fontSize: '16px', whiteSpace: 'nowrap' }}
        >
          {str}
        </Typography.Paragraph>
      ))}
    </Flex>
  );
};
const Title = ({
  title,
  subtitle = '',
}: {
  title: string;
  subtitle?: string;
}) => {
  return (
    <Flex
      justify="center"
      align="baseline"
      gap={10}
      style={{ maxWidth: '600px', margin: '0 auto 30px  auto' }}
    >
      <Typography.Title level={4} className={releaseStyle['pdf__title']}>
        {title}
      </Typography.Title>
      <Typography.Paragraph
        className={releaseStyle['pdf__line']}
        style={{ fontSize: '16px' }}
      >
        {subtitle}
      </Typography.Paragraph>
    </Flex>
  );
};
const Line = ({ title, subtitle = '' }: { title: any; subtitle?: string }) => {
  return (
    <Flex vertical align="center" justify='end'>
      <Typography.Text className={releaseStyle['pdf__line']}>
        {title ?? ''}
      </Typography.Text>
      {!!subtitle && (
        <Typography.Text className={releaseStyle['pdf__small']} style={{margin: 0}}>
          {subtitle}
        </Typography.Text>
      )}
    </Flex>
  );
};
interface IPdf {
  data: IRelease;
}
export const PdfReleaseRU: React.FC<IPdf> = ({ data }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrint = useReactToPrint({ contentRef });
  return (
    <div className={releaseStyle['pdf']}>
      <IconButton
        className={releaseStyle['pdf__print']}
        iType="print"
        title="Печать"
        onClick={reactToPrint}
      />

      <div className={releaseStyle['pdf__page']} ref={contentRef}>
        <div className={releaseStyle['pdf__header']}>
          <Icon component={Logo} />
          <Info title={company.title} subtitle={company.subtitle} />
        </div>
        <div className={releaseStyle['pdf__content']}>
          <Title title={title} subtitle={data.release_id} />
          <Flex gap={10}>
            <Typography.Paragraph className={releaseStyle['pdf__subtitle']}>
              Дата действия релиза
            </Typography.Paragraph>
            <Typography.Paragraph className={releaseStyle['pdf__subtitle']}>
              c
            </Typography.Paragraph>
            <Typography.Text className={releaseStyle['pdf__line']}>
              {data.date_action_start_string}
            </Typography.Text>
            <Typography.Paragraph className={releaseStyle['pdf__subtitle']}>
              по
            </Typography.Paragraph>

            <Typography.Text className={releaseStyle['pdf__line']}>
              {data.date_action_end_string}
            </Typography.Text>
          </Flex>
          <Flex gap={10}>
            <Typography.Paragraph className={releaseStyle['pdf__subtitle']}>
              КТК
            </Typography.Paragraph>

            <Line title={data.containers_count} subtitle="количество" />
            <Flex>
              {'('}
              <Line title={getSizeKtk(data.type)} subtitle="размер" />
              {')'}
            </Flex>
          </Flex>
          <Flex vertical gap={10}>
            <Typography.Paragraph className={releaseStyle['pdf__subtitle']}>
              Номера контейнеров:
            </Typography.Paragraph>
            <div className={releaseStyle['pdf__grid']}>
              {data.containers.map((cont, key) => (
                <Typography.Text key={key} className={releaseStyle['pdf__line']}>
                  {cont?.num_container || 'Без номера'}
                </Typography.Text>
              ))}
              {Array(30 - data?.containers?.length || 0)
                .fill('')
                .map((el, i) => (
                  <Typography.Text
                    key={i}
                    className={releaseStyle['pdf__line']}
                  >
                    {el}
                  </Typography.Text>
                ))}
            </div>
          </Flex>
        </div>
        <Flex vertical gap={10} style={{ marginTop: 'auto' }}>
          <Info
            position="start"
            title={contacts.title}
            subtitle={contacts.subtitle}
          />
          <Flex vertical gap={10} style={{ maxWidth: '200px' }}>
            <Typography.Paragraph className={releaseStyle['pdf__logo-title']}>
              Диспетчер 8 9XX XXX XX XX
            </Typography.Paragraph>

            <Typography.Paragraph style={{ lineHeight: '0.8' }}>
              <Typography.Text className={releaseStyle['pdf__logo-title']}>
                ВАЖНО:{' '}
              </Typography.Text>
              <Typography.Text
                className={releaseStyle['pdf__logo-text']}
                style={{ whiteSpace: 'normal' }}
              >
                при выдаче необходимо называть диспетчеру номер релиза /{' '}
              </Typography.Text>
              <Typography.Text className={releaseStyle['pdf__subtitle']}>
                {data?.release_id ?? ''}
              </Typography.Text>
            </Typography.Paragraph>
          </Flex>
          <Info
            position="start"
            title={contactsInfo.title}
            subtitle={contactsInfo.subtitle}
          />
          <div className={releaseStyle['pdf__header']} style={{ marginTop: '20px' }}>
            <Icon component={Logo} />
            <Info title={''} subtitle={company.subtitle} />
          </div>
        </Flex>
      </div>
    </div>
  );
};
export const PdfReleaseEN: React.FC<IPdf> = ({ data }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrint = useReactToPrint({ contentRef });
  return (
    <div className={releaseStyle['pdf']}>
    <IconButton
      className={releaseStyle['pdf__print']}
      iType="print"
      title="Печать"
      onClick={reactToPrint}
    />

    <div className={releaseStyle['pdf__page']} ref={contentRef}>
      <div className={releaseStyle['pdf__header']}>
        <Icon component={Logo} />
        <Info title={companyEn.title} subtitle={companyEn.subtitle} />
      </div>
      <div className={releaseStyle['pdf__content']}>
        <Title title={titleEn} />
        <Flex gap={10} vertical>
          <Flex gap={10}>
            <Typography.Paragraph className={releaseStyle['pdf__subtitle']}>
            Realease Number
            </Typography.Paragraph>

            <Line title={data.release_id || ''} />
          </Flex>
          <Flex gap={10}>
            <Typography.Paragraph className={releaseStyle['pdf__subtitle']}>
            Quantity of containers
            </Typography.Paragraph>

            <Line title={data.containers_count ?? 0} />
          </Flex>
          <Flex gap={10}>
            <Typography.Paragraph className={releaseStyle['pdf__subtitle']}>
              Effective On
            </Typography.Paragraph>

            <Line title={data.date_action_start_string} />
          </Flex>
          <Flex gap={10}>
            <Typography.Paragraph className={releaseStyle['pdf__subtitle']}>
              Expires On
            </Typography.Paragraph>

            <Line title={data.date_action_end_string} />
          </Flex>

        </Flex>
        <Flex vertical gap={10}>
          <Typography.Paragraph className={releaseStyle['pdf__subtitle']}>
          Depot:
          </Typography.Paragraph>
          <div className={releaseStyle['pdf__grid']}>
            {data.containers.map((cont) => (
              <Typography.Text className={releaseStyle['pdf__line']}>
                {cont?.num_container || 'There is no number'}
              </Typography.Text>
            ))}
            {Array(30 - data?.containers?.length || 0)
              .fill('')
              .map((el, i) => (
                <Typography.Text
                  key={i}
                  className={releaseStyle['pdf__line']}
                >
                  {el}
                </Typography.Text>
              ))}
          </div>
        </Flex>
      </div>
      <Flex vertical gap={10}>
      <Flex gap={10}>
            <Typography.Paragraph className={releaseStyle['pdf__subtitle']}>
            Depot Contact
            </Typography.Paragraph>

            <Line title={contactsEn.title} />
          </Flex>
          <Flex gap={10}>
            <Typography.Paragraph className={releaseStyle['pdf__subtitle']}>
            Contact
            </Typography.Paragraph>

            <Line title={contactsInfoEn.title} />
          </Flex>
      </Flex>
    </div>
  </div>
  );
};
