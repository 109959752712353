// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crm .ant-tabs-top .ant-tabs-nav::before {
  border-bottom: 0 !important;
}
.crm .ant-tabs-tab {
  background: #FFFFFF !important;
  display: flex !important;
  height: 24px !important;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0 !important;
  border-radius: 10px !important;
  padding: 7px 18px !important;
  margin: 0 0 0 12px !important;
}
.crm .ant-tabs-tab:hover {
  background: #e1e9fa !important;
  color: #0148d6 !important;
}
.crm .ant-tabs-ink-bar {
  background: #FFFFFF !important;
}
.crm .ant-tabs-ink-bar:hover {
  background: #e1e9fa !important;
}
.crm .ant-tabs-tab-btn {
  color: #1f1f1f !important;
}
.crm .ant-tabs-tab-active {
  background: #e1e9fa !important;
  color: #0148d6 !important;
}
.crm .ant-tabs-tab-active:hover {
  background: #e1e9fa !important;
  color: #0148d6 !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/srm-container.scss"],"names":[],"mappings":"AAEE;EACE,2BAAA;AADJ;AAIE;EACE,8BAAA;EACA,wBAAA;EACA,uBAAA;EACA,kCAAA;EACA,8BAAA;EACA,yBAAA;EACA,8BAAA;EACA,4BAAA;EACA,6BAAA;AAFJ;AAII;EACE,8BAAA;EACA,yBAAA;AAFN;AAME;EACE,8BAAA;AAJJ;AAMI;EACE,8BAAA;AAJN;AAQE;EACE,yBAAA;AANJ;AASE;EACE,8BAAA;EACA,yBAAA;AAPJ;AASI;EACE,8BAAA;EACA,yBAAA;AAPN","sourcesContent":[".crm {\n\n  .ant-tabs-top .ant-tabs-nav::before {\n    border-bottom: 0 !important;\n  }\n\n  .ant-tabs-tab {\n    background: #FFFFFF !important;\n    display: flex !important;\n    height: 24px !important;\n    justify-content: center !important;\n    align-items: center !important;\n    flex-shrink: 0 !important;\n    border-radius: 10px !important;\n    padding: 7px 18px !important;\n    margin: 0 0 0 12px !important;\n\n    &:hover {\n      background: #e1e9fa !important;\n      color: #0148d6 !important;\n    }\n  }\n\n  .ant-tabs-ink-bar {\n    background: #FFFFFF !important;\n\n    &:hover {\n      background: #e1e9fa !important;\n    }\n  }\n\n  .ant-tabs-tab-btn {\n    color: #1f1f1f !important;\n  }\n\n  .ant-tabs-tab-active {\n    background: #e1e9fa !important;\n    color: #0148d6 !important;\n\n    &:hover {\n      background: #e1e9fa !important;\n      color: #0148d6 !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
