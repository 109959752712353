import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Empty, Flex, Typography } from 'antd';
import {useHistory, useLocation} from 'react-router-dom';

const Page404 = () => {
  const history = useHistory();
  const location = useLocation();
  const reload = () => {
    history.push('/');
  };
  return (
      <Flex justify={'center'} align={'center'} style={{ height: '100vh' }}>
        {
            location.pathname === 'login' &&
            <Empty
                description={
                  <Flex vertical gap={40}>
                    <Typography.Title level={2} type="warning">
                      404 Oops!
                    </Typography.Title>
                    <Typography.Paragraph>
                      Страница не существует.<br></br>Попробуйте изменить путь или
                      вернитесь на главную.
                    </Typography.Paragraph>
                  </Flex>
                }>
              <Button type="default" icon={<ArrowLeftOutlined />} onClick={reload}>
                Вернуться на сайт?
              </Button>
            </Empty>
        }
      </Flex>
  );
};

export default Page404;
