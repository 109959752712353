import React, { useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AutoComplete,
  Dropdown,
  Flex,
  Form,
  FormListFieldData,
  MenuProps,
  App,
  Select,
  Typography,
} from 'antd';

import Icon from '@ant-design/icons/lib/components/Icon';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

import style from '../../../style.module.scss';
import { messagesModal } from '../../../../../../constants';
import { RouteNames } from '../../../../../../router/names';
import { moreIcon } from '../../../../../../assets/image/svg';
import { findContainers } from '../../../../../../store/containers';
import {
  checkSearchString,
  fileTypes,
} from '../../../../../../helpers/input-helpers';
import { openInNewTab } from '../../../../../../helpers/link-helper';
import { RootState, useStoreDispatch } from '../../../../../../store';
import UploaderDocs from '../../../../../../components/ui/UploaderDocs';
import { handleDownloadXlsx } from '../../../../../../helpers/xlsx-helper';
import {
  FORMAT_DATE_KTK,
  keyNamesKtk,
  nameColumnsKtk,
} from '../../../constants';
import ModalConfirm from '../../../../../../components/ui/ModalConfirm/ModalConfirm';
import {
  IContainer,
  ITabForm,
  IValues,
  setIsEditDealContainers,
  setSaveFiles,
} from '../../../../../../store/sales';
import {
  FormDatePicker,
  FormInputNumber,
} from '../../../../../../components/ui/Form';
import TooltipButton from '../../../../../../components/ui/TooltipButton/TooltipButton';
import UploadExcelFile from '../../../../../../components/ui/UploadExcelFile/UploadExcelFile';
import { filterResultKtk, mapperKtk } from '../../../utils';

const { title, subtitle } = messagesModal.delete;

const checkEditContainers = (containers: IContainer[]) =>
  containers.some((item) => !!item?.edit);

const TabDoc: React.FC<ITabForm> = ({ form, isOpen }) => {
  const { message } = App.useApp();
  const { t } = useTranslation();
  const id_deal: string = Form.useWatch('id_deal', {
    form,
    preserve: true,
  });
  const containers: IContainer[] = Form.useWatch('containers', {
    form,
    preserve: true,
  });
  const docs = Form.useWatch('docs', {
    form,
    preserve: true,
  });
  const [options, setOptions] = React.useState<IContainer[] | []>([]);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [formValues, setFormValues] = React.useState<IValues>({});

  const [values, setValues] = React.useState<IValues>({});
  const dispatch = useStoreDispatch();
  const [value, setValue] = useState<string>('');
  const [indexCurrent, setIndexCurrent] = useState<number>(0);
  const containerDealStatuses = useSelector<RootState>(
    (state) => state.sales.containerDealStatuses,
  ) as { id: number; value: string }[];

  const handlerUpload = () => {
    const rows = containers.map(
      ({ num_container, type, departure_date, status, bet, price }) => ({
        num_container,
        type,
        departure_date,
        status,
        bet,
        price,
      }),
    );
    handleDownloadXlsx({
      nameFile: `${t('ктк сделки')} ${id_deal ?? ''}`,
      nameColumns: nameColumnsKtk,
      nameList: `${t('Лист')} 1`,
      rows,
    });
  };

  const handlerReceivingData = (newContainers: IContainer[]) => {
    // HACK: функционал заполнения
    // const addResult = newContainers.map((item) => {
    //   const departure_date = item?.departure_date ? dayjs(item?.departure_date, FORMAT_DATE_KTK)?.isValid() ? item?.departure_date : '' : '';
    //   const {id, value} = item?.status ? containerDealStatuses.find(({value}) => value.toLowerCase() === `${item.status}`.toLowerCase()) || {id: null, value: null} : {id: null, value: null};
    //   return {...item, status: value, status_id: id, departure_date};
    // });
    const result = newContainers.filter(
      ({ num_container }) =>
        containers.findIndex(
          (cont) =>
            cont.num_container?.toLocaleLowerCase() ===
            num_container?.toLocaleLowerCase(),
        ) === -1,
    );
    const ids = result?.map(({ num_container }) => num_container);
    dispatch(findContainers({ data: ids, key: 'num_container' }))
      .then((res) => {
        const resultContainers = res?.payload?.length
          ? mapperKtk(res.payload)
          : [];
        form.setFieldsValue({
          containers: [...resultContainers, ...containers],
        });
        message.success(`${t('Добавлено')} ${resultContainers.length} ктк`);
      })
      .catch((err) => {
        message.error(`${t('Произошла ошибка')}, добавлено 0 ктк`);
        console.error(t('Произошла ошибка'), err);
      });
  };

  const handlerSaveFiles = (files: any[]) => {
    dispatch(setSaveFiles(files));
  };

  const removeField = (remove: (index: number) => void) => {
    remove(indexCurrent);
    setIndexCurrent(0);
  };

  const editContainer = (
    edit: (defaultValue?: any, insertIndex?: number) => void,
    index: number,
  ) => {
    const fields = form.getFieldsValue();
    const { containers } = fields;
    const container = containers[index];

    setFormValues((prev) => ({
      ...prev,
      [`departure_date${index}`]: container?.departure_date,
      [`price${index}`]: container?.price,
    }));

    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        ...container,
      },
    }));

    const result = containers.map((item: any, i: number) =>
      i === index ? { ...item, ...values[index], edit: true } : item,
    );
    form.setFieldsValue({ containers: result });
    // проверка на открытие редактирования контейнера
    dispatch(setIsEditDealContainers(checkEditContainers(result)));
  };

  const getItems = (
    remove: () => void,
    edit: (defaultValue?: any, insertIndex?: number) => void,
    index: number,
  ): MenuProps['items'] => [
    {
      key: 1,
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'left', title: t('Редактировать') }}
          propsButton={{
            onClick: () => editContainer(edit, index),
            icon: <EditOutlined />,
            shape: 'circle',
          }}
        />
      ),
    },
    {
      key: 2,
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'left', title: t('Удалить') }}
          propsButton={{
            onClick: () => remove(),
            icon: <DeleteOutlined />,
            shape: 'circle',
          }}
        />
      ),
    },
  ];

  const addRow = ({
    value,
    id,
    addContainer,
  }: {
    value: string;
    id?: number;
    addContainer: (defaultValue?: any, insertIndex?: number) => void;
  }) => {
    const container: IContainer | undefined = options?.find(
      (opt: IContainer) => opt?.num_container === value,
    );
    const isUnique = !containers?.some(
      (item: any) => item?.num_container === value,
    );
    if (isUnique && container) {
      const newContainer = {
        id,
        num_container: value,
        container_id: id,
        type: container?.type_container,
        status: null,
        status_id: null,
        bet: container?.price ?? null,
        price: '',
        departure_date: container?.departure_date || null,
        delete: container?.delete,
        edit: false,
      };
      setValues((prev) => ({ ...prev, [value]: {} }));
      setValue('');
      addContainer({ ...newContainer }, 0);
    } else {
      message.warning(t('Этот контейнер уже добавлен!'));
      setValue('');
    }
  };

  const handleContainer = (data: any) => {
    setOptions([]);
    setValue(data);
  };

  const getFindContainer = (text: string) => {
    const currentString = checkSearchString(text);
    if (Array.isArray(currentString)) {
      dispatch(findContainers({ data: currentString, key: 'num_container' }))
        .then((res) => {
          const resultContainers = res?.payload?.length
            ? res.payload.map((item: IContainer) => ({
                num_container: item.num_container,
                container_id: item?.id,
                type: item?.type_container,
                status: null,
                status_id: null,
                bet: item?.price ?? null,
                price: '',
                departure_date: item?.departure_date || null,
                edit: false,
              }))
            : [];
          setValue('');
          form.setFieldsValue({
            containers: [...resultContainers, ...containers],
          });
        })
        .catch((err) => console.log('Произошла ошибка', err));
      return;
    }
    if (text?.length >= 2) {
      dispatch(findContainers(text))
        .then((res) => {
          setOptions(filterResultKtk(res.payload));
        })
        .catch((err) => console.log('Произошла ошибка', err));
    }
  };

  const submitContainer = (index: number) => {
    const fields = form.getFieldsValue();
    const { containers } = fields;
    const container = containers[index];
    const price = formValues[`price${index}`] || '';
    const departure_date = formValues[`departure_date${index}`] || null;
    const status =
      containerDealStatuses.find(({ id }) => id === container?.status_id)
        ?.value || null;
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        price,
        status,
        departure_date,
        status_id: container?.status_id,
      },
    }));

    const result = containers.map((item: any, i: number) =>
      i === index
        ? { ...item, status, price, departure_date, edit: false }
        : item,
    );
    form.setFieldsValue({ containers: result });
    // проверка на открытие редактирования контейнера
    dispatch(setIsEditDealContainers(checkEditContainers(result)));
  };
  const closeEditContainer = (index: number) => {
    const fields = form.getFieldsValue();
    const { containers } = fields;
    setFormValues((prev) => ({}));
    setValues((prev) => ({}));

    const result = containers.map((item: any, i: number) =>
      i === index ? { ...item, ...values[index], edit: false } : item,
    );
    form.setFieldsValue({ containers: result });
    // проверка на открытие редактирования контейнера
    dispatch(setIsEditDealContainers(checkEditContainers(result)));
  };

  return (
    <Flex gap={30} vertical>
      <Form.Item name="docs" className={style['file-box']}>
        <UploaderDocs
          isOpen={isOpen}
          ids={docs}
          accept={[
            fileTypes.doc,
            fileTypes.xls,
            fileTypes.pdf,
            fileTypes.jpeg,
          ].join(',')}
          setDataIds={(files) => {
            form.setFieldValue('docs', files);
          }}
          handlerSaveFiles={handlerSaveFiles}
        />
      </Form.Item>
      <div className={style['overlap-group']}>
        <div className={style['table']}>
          <div className={style['table__container']}>
            <div className={style['table__header']}>
              {nameColumnsKtk.map((title) => (
                <div
                  key={title}
                  className={classnames(
                    style['table__cell'],
                    style['label-card-16'],
                  )}
                >
                  {t(title)}
                </div>
              ))}
              <div className={style['menu-icon']}>
                <div className={style['label-card-16']}>
                  <Dropdown
                    overlayClassName={style.drop}
                    menu={{
                      items: [
                        {
                          key: 'link1',
                          label: (
                            <UploadExcelFile
                              keyNames={keyNamesKtk}
                              data-id="link"
                              classNameLink={style['menu-icon__link']}
                              onChange={handlerReceivingData}
                            />
                          ),
                        },
                        {
                          key: 'link2',
                          label: (
                            <Typography.Text
                              data-id="link"
                              className={classnames(style['menu-icon__link'])}
                              onClick={handlerUpload}
                              data-disabled={!containers?.length}
                              disabled={!containers?.length}
                            >
                              {t('Выгрузить в Excel')}
                            </Typography.Text>
                          ),
                        },
                      ],
                    }}
                    placement="bottom"
                    trigger={['click']}
                  >
                    <Typography.Link
                      className=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <Icon component={moreIcon} />
                    </Typography.Link>
                  </Dropdown>
                </div>
              </div>
            </div>
            <Form.List name="containers">
              {(
                fields: FormListFieldData[],
                operation,
                meta: { errors: any },
              ) => (
                <div className={style['table__body']}>
                  <AutoComplete
                    value={value}
                    placeholder={`${t('Введите')} ${t('№КТК')}`}
                    style={{ width: '26%', minWidth: '110px' }}
                    options={options?.map((opt: IContainer) => ({
                      id: opt?.id,
                      value: opt?.num_container,
                    }))}
                    onSelect={(_, { value, id }) =>
                      addRow({ value, id, addContainer: operation?.add })
                    }
                    onChange={handleContainer}
                    onSearch={(text: string) => getFindContainer(text)}
                    // maxLength={11}
                    allowClear
                  />

                  {fields.map(({ ...field }) => (
                    <div
                      key={field.key}
                      className={style['table__row']}
                      data-row="row"
                    >
                      <div className={classnames(style['table__cell'])}>
                        <Typography.Link
                          className={classnames(style['text-card-14'], {
                            [style['default-link']]:
                              containers?.[field.name]?.['container_id'],
                          })}
                          ellipsis
                          onClick={() =>
                            openInNewTab(
                              `${RouteNames.CONTAINERS_VIEW}${
                                containers?.[field.name]?.['container_id']
                              }`,
                            )
                          }
                        >
                          {containers?.[field.name]?.['num_container']}
                        </Typography.Link>
                      </div>
                      <div
                        className={classnames(
                          style['table__cell'],
                          style['text-card-14'],
                        )}
                      >
                        <Typography.Text
                          ellipsis={{
                            tooltip: {
                              placement: 'topLeft',
                              title: containers?.[field.name]?.['type'] || '',
                            },
                          }}
                        >
                          {containers?.[field.name]?.['type'] || ''}
                        </Typography.Text>
                      </div>
                      <div
                        className={classnames(
                          style['table__cell'],
                          style['text-card-14'],
                        )}
                      >
                        {containers?.[field.name]?.['edit'] ? (
                          <FormDatePicker
                            format={FORMAT_DATE_KTK}
                            value={
                              containers?.[field.name]?.['departure_date'] || null
                            }
                            handlerChange={(value: string | null) => {
                              setFormValues((prev) => ({
                                ...prev,
                                [`departure_date${field.name}`]: value,
                              }));
                            }}
                          />
                        ) : (
                          <Typography.Text
                            ellipsis={{
                              tooltip: {
                                placement: 'topLeft',
                                title:
                                  containers?.[field.name]?.['departure_date'] ||
                                  t('Добавьте дату'),
                              },
                            }}
                          >
                            {containers?.[field.name]?.['departure_date'] ||
                              t('Добавьте дату')}
                          </Typography.Text>
                        )}
                      </div>
                      <div
                        className={classnames(
                          style['table__cell'],
                          style['text-card-14'],
                        )}
                      >
                        {containers?.[field.name]?.['edit'] ? (
                          <Form.Item name={[field.name, 'status_id']}>
                            {
                              <Select
                                placeholder={t('Добавьте статус')}
                                style={{ width: '100%' }}
                                options={containerDealStatuses?.map(
                                  ({ id, value }) => ({
                                    value: id,
                                    label: value,
                                  }),
                                )}
                              />
                            }
                          </Form.Item>
                        ) : (
                          <Typography.Text
                            ellipsis={{
                              tooltip: {
                                placement: 'leftBottom',
                                title:
                                  containers?.[field.name]?.status ||
                                  t('Добавьте статус'),
                              },
                            }}
                          >
                            {containers?.[field.name]?.status ||
                              t('Добавьте статус')}
                          </Typography.Text>
                        )}
                      </div>
                      <div
                        className={classnames(
                          style['table__cell'],
                          style['text-card-14'],
                        )}
                      >
                        <Typography.Text
                          className={style['text-card-14']}
                          ellipsis={{
                            tooltip: {
                              placement: 'topLeft',
                              title: containers?.[field.name]?.['bet'] ?? '',
                            },
                          }}
                        >
                          {containers?.[field.name]?.['bet'] ?? ''}
                        </Typography.Text>
                      </div>
                      <div
                        className={classnames(
                          style['table__cell'],
                          style['text-card-14'],
                        )}
                      >
                        <Form.Item name={[field.name, 'price']} noStyle />
                        {containers?.[field.name]?.['edit'] ? (
                          <FormInputNumber
                            name={`${field.name}`}
                            placeholder={t('Добавьте цену')}
                            value={formValues?.[`price${field.name}`]}
                            controls={false}
                            min={0}
                            maxLength={15}
                            onChange={(val) => {
                              setFormValues((prev) => ({
                                ...prev,
                                [`price${field.name}`]: val,
                              }));
                            }}
                          />
                        ) : (
                          <Typography.Text
                            ellipsis={{
                              tooltip: {
                                placement: 'topLeft',
                                title:
                                  containers?.[field.name]?.price ||
                                  t('Добавьте цену'),
                              },
                            }}
                          >
                            {containers?.[field.name]?.price ||
                              t('Добавьте цену')}
                          </Typography.Text>
                        )}
                      </div>
                      <Flex className={style['table__menu']} align="center">
                        <div className={style['text-card-14']}>
                          {containers?.[field.name]?.['edit'] ? (
                            <Dropdown
                              overlayClassName={style.drop}
                              open
                              placement="bottom"
                              menu={{
                                items: [
                                  {
                                    key: '1',
                                    label: (
                                      <TooltipButton
                                        propsTooltip={{ title: t('Сохранить') }}
                                        propsButton={{
                                          className: style['table__button'],
                                          shape: 'circle',
                                          icon: <CheckOutlined />,
                                          onClick: () =>
                                            submitContainer(field.name),
                                        }}
                                      />
                                    ),
                                  },
                                  {
                                    key: '2',
                                    label: (
                                      <TooltipButton
                                        propsTooltip={{ title: t('Закрыть') }}
                                        propsButton={{
                                          className: style['table__button'],
                                          shape: 'circle',
                                          icon: <CloseOutlined />,
                                          onClick: () =>
                                            closeEditContainer(field.name),
                                        }}
                                      />
                                    ),
                                  },
                                ],
                                style: {
                                  background: 'transparent',
                                  boxShadow: 'none',
                                },
                              }}
                              trigger={['click']}
                            >
                              <Typography.Link
                                className=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <Icon component={moreIcon} />
                              </Typography.Link>
                            </Dropdown>
                          ) : (
                            <Dropdown
                              overlayClassName={style.drop}
                              menu={{
                                items: getItems(
                                  () => {
                                    setConfirmation(true);
                                    setIndexCurrent(field.name);
                                  },
                                  operation.add,
                                  field.name,
                                ),
                                style: {
                                  background: 'transparent',
                                  boxShadow: 'none',
                                },
                              }}
                              placement="bottom"
                              trigger={['click']}
                            >
                              <Typography.Link
                                className=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <Icon component={moreIcon} />
                              </Typography.Link>
                            </Dropdown>
                          )}
                        </div>
                      </Flex>
                    </div>
                  ))}
                  <ModalConfirm
                    title={title}
                    subtitle={subtitle}
                    isOpen={confirmation}
                    closeModal={() => setConfirmation(false)}
                    actionAfterConsent={() => removeField(operation.remove)}
                  />
                </div>
              )}
            </Form.List>
          </div>
        </div>
      </div>
    </Flex>
  );
};

export default TabDoc;
