import React from 'react';
import classnames from 'classnames';
import table from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import IconButton from '../../IconButton';
import { openInNewTab } from '../../../../helpers/link-helper';
interface ITable {
  columns: { key: string; title: string; style: React.CSSProperties, link?: {id: string, path: string} }[];
  data?: any[];
  className?: string;
}
export const ViewTable: React.FC<ITable> = ({
  columns,
  data,
  className = '',
}) => {
  const { t } = useTranslation();
  return (
    <div className={classnames(table['block-ktk'], { [className]: className })}>
      <div className={table['overlap-group']} style={{ marginBottom: '10px' }}>
        <table className={table['table']}>
          <thead className={table['table__header-fixed']}>
            <tr className={table['table__header-fixed-row']}>
              <th
                className={classnames(
                  table['table__header-fixed-cell'],
                  table['label-card-16'],
                )}
                style={{ minWidth: 'auto' }}
              >
                №
              </th>
              {columns?.map(({ key, title, style }) => (
                <th
                  key={key}
                  style={style}
                  className={classnames(
                    table['table__header-fixed-cell'],
                    table['label-card-16'],
                  )}
                >
                  {t(title)}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className={table['table__body']}>
            {data?.map((field, i) => (
              <tr key={i} className={table['table__row']} data-row="row">
                <td className={classnames(table['table__cell'])}>
                  {field.delete ? (
                    <IconButton
                      className={table['menu-icon__button-error']}
                      iType="delete"
                      title={t(field?.reason || 'Удален')}
                      style={{ cursor: 'default' }}
                      placement="topRight"
                    />
                  ) : (
                    i + 1
                  )}
                </td>
                {columns.map(({ key, title, link, style }, i) => (
                  <td
                    key={key + i}
                    className={classnames(
                      table['table__cell'],
                      table['text-card-14'],
                    )}
                    style={style}
                  >
                    {link ? (
                      <Typography.Link
                        className={classnames(table['text-card-14'], {
                          [table['default-link']]: field?.[link?.id],
                        })}
                        ellipsis
                        onClick={() => {
                          if (!field?.[link?.id]) return;
                          openInNewTab(`${link?.path}${field?.[link?.id]}`);
                        }}
                      >
                        {field?.[key] || ''}
                      </Typography.Link>
                    ) : (
                      <Typography.Text
                        ellipsis={{
                          tooltip: {
                            placement: 'leftBottom',
                            title: field?.[key] || '',
                          },
                        }}
                      >
                        {field?.[key] || ''}
                      </Typography.Text>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
