import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Icon, {PlusCircleOutlined} from '@ant-design/icons';
import {Flex, Popover} from 'antd';
import {Checkbox as Checkboxs} from '../Checkbox/Checkbox';
import {
    AddHiddenColumn,
    TableHeaderCell,
    TableHeaderCellColor,
} from './styled';
import {IColumnType, IPagination} from './Table';
import {useDispatch, useSelector} from 'react-redux';
import {clearFiltersFetch} from '../../../store/filters';
import ColumnsFilters from './columnsFilters';
import {useLocation} from 'react-router-dom';
import {SessionStorageManager} from './sessionStorageManager';
import {
    bullIconGreen,
    burgerBlueIcon,
    RepairIcon,
} from '../../../assets/image/svg';
import {StorageData} from '../../../types';
import {RootState} from '../../../store';
import {useTranslation} from 'react-i18next';

interface Props<T> {
    columns: IColumnType<T>[];
    content?: React.JSX.Element;
    sortData?: (column: IColumnType<T>) => void;
    hiddenColumn?: number[];
    order?: (sort: string) => void;
    filtersFetch?:
        | ((text: string | number, column: IColumnType<any>) => void)
        | undefined;
    filters?: any;
    onSelectAll: (checked: boolean) => void;
    allSelected: boolean | undefined;
    columnFilters?: any;
    setColumnFilters?: any;
    pagination: IPagination<T>;
}

export function TableHeader<T>({
                                   columns,
                                   content,
                                   hiddenColumn = [],
                                   order,
                                   filtersFetch,
                                   filters,
                                   onSelectAll,
                                   allSelected,
                                   columnFilters,
                                   setColumnFilters,
                                   pagination,
                               }: Props<T>): JSX.Element {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const findCheck = columns.filter(
        (el: IColumnType<T>) => el.checkbox !== undefined,
    );
    const [openPopovers, setOpenPopovers] = useState<{ [key: number]: boolean }>(
        {},
    );
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startIntRange, setStartIntRange] = useState<string | null>('0');
    const [endIntRange, setEndIntRange] = useState<string | null>('0');
    const [toggle, setToggle] = useState<boolean>(true);
    const [bull, setBull] = useState<number[]>([]);
    const dataFilters: any = useSelector(
        (state: RootState) => state.filters.dataFilters,
    );
    const dataTypeQuality: any = useSelector(
        (state: RootState) => state.types.dataTypeQuality,
    );
    const dataTypeDepartment: any = useSelector(
        (state: RootState) => state.types.dataTypeDepartment,
    );
    const dataTypeContainers: any = useSelector(
        (state: RootState) => state.types.dataTypeContainers,
    );
    const storageManager = new SessionStorageManager(location.pathname);
    const storageLocal: StorageData | null = storageManager.getItem();

    useEffect(() => {
        setBull(storageLocal?.bull ? storageLocal?.bull : []);
    }, []);

    const optionsFilter = useMemo(() => {
        return dataFilters; // Убираем фильтрацию, оставляем все контейнеры
    }, [dataFilters]);

    const searchFilter = useCallback(
        (row: string, column: any) => {
            if (row.length > 0 && filtersFetch) {
                filtersFetch(row, !toggle ? column.subTitle : column.dataIndex);
            }
        },
        [toggle],
    );

    const updateBullState = async (option: any, columnIndex: number) => {
        // Получаем текущее состояние bull
        const currentBull: any = await new Promise((resolve) => {
            setBull((prev) => {
                resolve(prev);
                return prev; // Возвращаем предыдущее состояние
            });
        });

        const updatedBull =
            option.length > 0
                ? Array.from(new Set([...currentBull, columnIndex]))
                : currentBull.filter((x: any) => x !== columnIndex);

        setBull(updatedBull);
        return updatedBull;
    };

    // Обработка изменения фильтров для каждой колонки
    const handleChange = async (
        option: any[],
        date: [any | null, any | null],
        dataInt: [number | '' | null | undefined, number | '' | null | undefined],
        checked: boolean,
        columnIndex: number,
    ) => {
        // Установка дат
        if (date[0] === null && date[1] === null) {
            setStartDate(null);
            setEndDate(null);
        }

        // Установка диапазона целых чисел
        if (dataInt[0] === null && dataInt[1] === null) {
            setStartIntRange(null);
            setEndIntRange(null);
        }

        // Обновление состояния bull
        const resUpdateBull: any = await updateBullState(option, columnIndex);

        // Обновление фильтров
        if (setColumnFilters) {
            setColumnFilters((prev: any) => {
                const column = columns[columnIndex];
                let updatedFilters: any = {};

                // Обновление фильтров на основе типа колонки
                if (
                    column.type === 'range_integer' &&
                    dataInt[0] !== null &&
                    dataInt[1] !== null
                ) {
                    updatedFilters = {...prev, [columnIndex]: dataInt};
                } else if (
                    column.type === 'date' &&
                    date[0] !== null &&
                    date[1] !== null
                ) {
                    updatedFilters = {...prev, [columnIndex]: date};
                } else {
                    updatedFilters = {...prev, [columnIndex]: option};
                }

                const newFilters = columns.reduce((acc: any, column, index) => {
                    const columnFilters = updatedFilters[index];
                    if (columnFilters && columnFilters.length > 0) {
                        acc[column.dataIndex] = columnFilters;
                    }
                    return acc;
                }, {} as { [key: string]: string[] });

                // Обновление данных
                if (filters) {
                    filters([newFilters], 1, storageLocal?.pagination?.page || 10);
                }

                const storageData: StorageData = {
                    bull: resUpdateBull,
                    fiters: [newFilters],
                    arrFilters: updatedFilters,
                };
                storageManager.updateItem(storageData);

                setColumnFilters((prev: any) => ({...prev, ...[newFilters]}));
                setOpenPopovers((prevState) => ({
                    ...prevState,
                    [columnIndex]: false,
                }));
                return updatedFilters;
            });
        }
    };

    const handleDateChange = (type: 'start' | 'end', date: any) => {
        const newStartDate = type === 'start' ? date : startDate;
        const newEndDate = type === 'end' ? date : endDate;
        // Обновляем состояние
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handleOrder = (row: any, index: number) => {
        if (order) {
            order(row.key);
        }
        setOpenPopovers((prevState) => ({...prevState, [index]: false}));
    };

    const handleRangeInteger = (type: 'start' | 'end', data: any) => {
        const newStartDate =
            type === 'start' ? data : startIntRange !== null ? startIntRange : 0;
        const newEndDate = type === 'end' ? data : endIntRange;
        // Обновляем состояние
        setStartIntRange(newStartDate);
        setEndIntRange(newEndDate);
    };

    const clearAllFilters = (index: number) => {
        setBull([]);
        setStartDate(null);
        setEndDate(null);
        setStartIntRange('0');
        setEndIntRange('0');
        filters([{}], 1, pagination.pageSize, 'DESC');
        setColumnFilters([]);

        const storageData: StorageData = {
            bull: [],
            fiters: [{}],
            arrFilters: [],
        };
        storageManager.updateItem(storageData);

        if (setColumnFilters) {
            setColumnFilters(() => []);
        }
        setOpenPopovers((prevState) => ({...prevState, [index]: false}));
    };

    // Функция фильтрации для конкретной колонки
    const filter = useCallback(
        (column: IColumnType<T>, columnIndex: number) => (
            <ColumnsFilters
                dataType={
                    column.dataIndex === 'quality'
                        ? dataTypeQuality
                        : column.dataIndex === 'type_container'
                            ? dataTypeContainers
                            : dataTypeDepartment
                }
                column={column}
                columnFilters={columnFilters}
                columnIndex={columnIndex}
                searchFilter={searchFilter}
                handleChange={handleChange}
                startIntRange={startIntRange}
                endIntRange={endIntRange}
                setStartIntRange={setStartIntRange}
                setEndIntRange={setEndIntRange}
                startDate={startDate}
                endDate={endDate}
                optionsFilter={optionsFilter}
                handleDateChange={handleDateChange}
                handleRangeInteger={handleRangeInteger}
                handleOrder={handleOrder}
                bull={bull}
                setColumnFilters={setColumnFilters}
                clearAllFilters={clearAllFilters}
            />
        ),
        [
            dataFilters,
            searchFilter,
            columnFilters,
            startIntRange,
            endIntRange,
            startDate,
            endDate,
        ],
    ); // Добавляем columnFilters в зависимости

    const handleOpenChange = useCallback((index: number, visible: boolean) => {
        setOpenPopovers((prevState) => ({...prevState, [index]: visible}));
    }, []);

    const handleSelectAll = (e: any) => {
        onSelectAll(e);
    };

    const onClickRow = () => {
        setToggle(true);
        dispatch(clearFiltersFetch());
    };

    return (
        <tr>
            {columns.map(
                (column, columnIndex) =>
                    !hiddenColumn.includes(columnIndex) &&
                    (column.dataIndex !== 'hidden' ? (
                        <TableHeaderCell
                            key={`table-head-cell-${columnIndex}`}
                            style={column.checkbox !== undefined ? {width: column.width, background: 'transparent'} : {width: column.width}}>
                            <Popover
                                placement="bottom"
                                content={filter(column, columnIndex)} // Передаем индекс колонки
                                open={column.checkbox !== undefined ? false : openPopovers[columnIndex]}
                                onOpenChange={(visible) =>
                                    handleOpenChange(columnIndex, visible)
                                }
                                trigger="click">
                                <Flex gap={10} align={'center'}>
                                    {findCheck.length > 0 && column.checkbox !== undefined && (
                                        <Checkboxs
                                            isChoice={allSelected}
                                            onChange={handleSelectAll}
                                        />
                                    )}
                                    {!!columnIndex && (
                                        <Flex gap={3} style={{width:'100%'}}>
                                            <TableHeaderCellColor>
                                                <Flex gap={5}>
                                                    <Icon
                                                        component={burgerBlueIcon}
                                                        onClick={onClickRow}
                                                    />
                                                    {column.dataIndex === 'repair' ? (
                                                        <Icon component={RepairIcon}/>
                                                    ) : (
                                                        <Flex align={'center'}>
                                                            {t(column.title ?? '')}
                                                            {bull.includes(columnIndex) ? (
                                                                <Icon component={bullIconGreen}/>
                                                            ) : (
                                                                <div style={{width: 16}}/>
                                                            )}
                                                        </Flex>
                                                    )}

                                                </Flex>
                                            </TableHeaderCellColor>
                                        </Flex>
                                    )}
                                </Flex>
                            </Popover>
                        </TableHeaderCell>
                    ) : (
                        <TableHeaderCell
                            key={`table-head-cell-${columnIndex}`}
                            style={{width: column.width, background: 'transparent'}}
                        >
                            <AddHiddenColumn>
                                <Flex justify='center' align='center'>
                                    <Popover placement="bottomRight" content={content}>
                                        <PlusCircleOutlined/>
                                    </Popover>
                                </Flex>
                            </AddHiddenColumn>
                        </TableHeaderCell>
                    )),
            )}
        </tr>
    );
}
