
interface IMapperSelect {
  items: any[];
  keys?: {[key: string] : any}
}
export const mapperSelect = ({items, keys = {label: 'value', value: 'id'}}: IMapperSelect) : any[] => {
  const [label, value] = Object.keys(keys);
  return items?.length
    ? items.map((item: any, i) => {
      return ({
        ...item,
        [label]: item?.[keys[label]],
        [value]: item?.[keys[value]],
      })})
    : [];
}