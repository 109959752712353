import { Flex, Tag, Typography } from "antd";
import classnames from 'classnames';
import style from '../../style.module.scss';

export const Label = ({
  label = '',
  value = '',
  selected = false,
  className = ''
}: { label?: string; value?: any, className?: string, selected?: boolean }) => (
  <Flex className={className} wrap gap={5}>
    <Typography.Paragraph className={classnames(style.label, {[style['label_selected']]: selected})}>{ selected ? <Tag bordered={false} color='green' style={{fontSize: '16px'}}>{label ?? ''}</Tag> : label ?? ''}</Typography.Paragraph>
    <Typography.Text className={style.label}>{value ?? ''}</Typography.Text>
  </Flex>
);