import { IContainer } from "../types";
export const getEmpty = (release: {id?: number, release_id?: string, type: string }) => ({
  id: undefined,
  num_container: '',
  container_id: null,
  size: getSizeKtk(release?.type),
  type_container_id: release?.type,
  type_container: release?.type,
  child_terminal_id: undefined,
  comment: '',
  count: 1,
  release_id: release?.release_id ?? null,
  release: release ?? null,
  delete: false,
  edit: true,
});
export const getterKtk = (container: IContainer): IContainer => ({
  id: undefined,
  num_container: container?.num_container,
  container_id: container?.id,
  size: getSizeKtk(container?.type_container),
  type_container_id: container?.type_container_id,
  type_container: container?.type_container,
  child_terminal_id: container?.child_terminal_id,
  comment: '',
  count: container?.count || 1,
  release_id: container?.release_id ?? null,
  release: container?.release ?? null,
  delete: false,
  edit: true,
});
export const mapperKtk = (containers: any[]): IContainer[] | [] => {
if (Array.isArray(containers) && containers?.length) {
return containers.map(getterKtk);
}
return [];
};

export const getSizeKtk = (type?: string) => type && typeof type === 'string' ? type.replace(/\D/g, '') : '';

export const filterKtk = ({
  containers,
  terminal_id,
}: {
  containers: IContainer[];
  terminal_id: number;
}) =>
  containers?.filter((item: IContainer) =>
    item?.child_terminal_id ? item?.child_terminal_id === terminal_id : true,
  ) || [];


const keys = [
  {key: 'release_id', type: 'string', title: ''},
  {key: 'containers_count', type: 'string'},
  {key: 'containers', type: 'array'},
  {key: 'date_action_start_string', type: 'data', title: 'Дата подписания'},
  {key: 'date_action_end_string', type: 'data'},
  {key: 'terminal', type: 'string', title: 'Терминал'},
  {key: 'contractor', type: 'string', title: 'Подрядчик'},
  {key: 'no_number', type: 'string', title: 'Безномерная выдача'},
  {key: 'is_transporter', type: 'string', title: 'Выдача физическому лицу'},
  {key: 'file_ids', type: 'length', title: 'Файлы'},
  {key: 'transporter', type: 'array'}
]