import React from 'react';

import { useSelector } from 'react-redux';
import { Col, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import Row from './Row';
import { IRelease } from '../../types';
import style from '../../style.module.scss';
import { RootState } from '../../../../store';
import { columnsTransporter } from '../../constants';
import { RouteNames } from '../../../../router/names';
import { FormDatePicker } from '../../../../components/ui/Form';
import { Checkbox } from '../../../../components/ui/Checkbox/Checkbox';
import { ViewTable } from '../../../../components/ui/List/ViewTable/ViewTable';

export const BaseTab: React.FC = () => {
  const { t } = useTranslation();
  const initialValue = useSelector(
    (state: RootState) => state.releases.release as IRelease,
  );

  const terminalLink = initialValue.terminal_id
    ? `${RouteNames.TERMINALS_VIEW}${initialValue.terminal_id}`
    : '';

  return (
    <div className={style.tab}>
      <Flex justify="space-between" align="center" gap={10}>
        <Col span={12}>
          {initialValue.is_transporter && (
            <Checkbox isChoice disabled>
              <Typography.Text className={style.checkboxes__label}>
                {t('Выдача физическому лицу')}
              </Typography.Text>
            </Checkbox>
          )}
          {initialValue.no_number && (
            <Checkbox isChoice disabled>
              <Typography.Text className={style.checkboxes__label}>
                {t('Безномерная выдача')}
              </Typography.Text>
            </Checkbox>
          )}
        </Col>
        <Col span={11}>
          <Typography.Text
            className={style.label}
            style={{ paddingLeft: '10px' }}
          >
            {t('Дата действия релиза')}
          </Typography.Text>
          <div className={style['date-block']}>
            <div className={style['date-block__item']}>
              <FormDatePicker
                isString
                value={initialValue?.date_action_start_string}
                disabled
              />
            </div>
            <div
              className={style['date-block__separator']}
              style={{ marginTop: '15px' }}
            />
            <div className={style['date-block__item']} style={{ padding: 0 }}>
              <FormDatePicker
                isString
                value={initialValue?.date_action_end_string}
                disabled
              />
            </div>
          </div>
        </Col>
      </Flex>
      <Row
        title={t('Кол-во КТК')}
        subtitle={initialValue?.containers_count}
        isDivider
      />
      <Row
        title={t('Подрядчик')}
        subtitle={initialValue?.contractor}
        isDivider
      />
      {initialValue?.is_transporter ? (
        <>
          <Row title={t('Данные водителей')} />
          <ViewTable
            columns={columnsTransporter}
            data={initialValue.transporter}
          />
        </>
      ) : (
        <>
          <Row title={t('ИНН')} subtitle={initialValue?.inn} isDivider />
          <Row
            title={t('Наименование')}
            subtitle={initialValue?.company}
            isDivider
          />
        </>
      )}
      <Row
        title={t('Терминал')}
        subtitle={initialValue?.terminal}
        link={terminalLink}
        isDivider
      />
    </div>
  );
};
