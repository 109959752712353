import React, { useMemo } from 'react';

import { Button, Flex } from 'antd';

import TabContainers from './tabs/view/TabContainers';
import style from '../style.module.scss';
import TabDeal from './tabs/view/TabDeal';
import ITabs from '../../../../components/ui/Tabs';
import { useParamsHistory } from '../../../../hooks';
import { TAB_DEAL, TAB_CONTAINERS } from '../constants';
import { ITab } from '../../../../components/ui/Tabs/Tabs';
import { CopyBufferIconDefault, EditViewIconDefault } from '../../../../assets/image/svg';
import CopyButton from '../../../../components/ui/CopyButton/CopyButton';
import TooltipButton from '../../../../components/ui/TooltipButton/TooltipButton';
import { IFormDeal } from '../../../../store/rent';
import { URL_API_USER } from '../../../../api/config';

const ViewDeal: React.FC<IFormDeal> = ({
  initialValue,
  onClose,
  onEdit,
  isOpen,
}) => {
  const { location } = useParamsHistory();
  const { pathname } = location;

  const items: ITab[] = useMemo(
    () => [
      {
        key: TAB_DEAL,
        label: 'Сделка',
        forceRender: isOpen,
        content: <TabDeal deal={initialValue}isOpen={isOpen}/>,
      },
      {
        key: TAB_CONTAINERS,
        label: 'Контейнеры',
        forceRender: isOpen,
        content: <TabContainers deal={initialValue} isOpen={isOpen}/>,
      },
    ],
    [isOpen],
  );
  return (
    <div className={style['tabs-form']}>
      <Flex justify={'space-between'} className={'drawer-header'}>
        <Flex vertical style={{ width: '100%' }} justify={'space-between'}>
          <Flex justify={'space-between'} align={'center'}>
            <h2 className={'drawer-title'}>Сделка {initialValue.id_deal}</h2>
            <Flex gap={2}>
              <TooltipButton
                propsButton={{
                  icon: <EditViewIconDefault />,
                  type: 'text',
                  onClick: onEdit,
                }}
              />
              <CopyButton
                text={URL_API_USER + pathname}
                textSuccess="Ссылка скопирована"
                icon={CopyBufferIconDefault}
              />
            </Flex>
          </Flex>
          <ITabs tabs={items} />
        </Flex>
      </Flex>
      <Flex
        gap={8}
        justify="space-between"
        style={{ margin: '32px 32px 32px auto', maxWidth: '500px', }}
      >
        <Button
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
          type={'text'}
          style={{ width: '60px', color: '#E14453' }}
        >
          Закрыть
        </Button>
      </Flex>
    </div>
  );
};

export default ViewDeal;
