import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Rest_deal } from '../services/rest_deal';
import { FormInstance } from "antd";

export interface ITabView {
  deal: IDeal;
}
export interface IValues {
  [key: string]: any;
}

export interface IViewDeal {
  onClose: () => void;
  onEdit: () => void;
  isOpen?: boolean;
  isDisabled?: boolean;
  width?: string;
}

export interface ITabForm {
  isDisabled?: boolean;
  form: FormInstance<any>;
  isOpen?: boolean;
  setChangeSaveFiles?: (fuc: any) => void
}

export interface IContainer {
  id?: number;
  num_container: string;
  container_id?: number;
  status: string | null;
  status_id?: number | null;
  departure_date?: string | null;
  bet?: number | string | null;
  price?: number | string | null;
  type?: string | number;
  type_container?: string | number;
  deal?: {id: number, id_deal: string, status: string, status_id: number} | null;
  delete?: boolean;
  edit?: boolean;
}

export interface IFormDeal {
  isDisabled?: boolean;
  onClose?: () => void;
  title?: string;
  initialValue?: any;
  isOpen?: boolean;
}

interface IResponsible {
  avatar: string;
  name: string;
  id: number;
  isResponsible: boolean
}


export interface IComments {
  comment: string;
  id_deal: string;
  user_id: number;
  user?: {avatar: string, fio: string, id: number, name: string}
  edit?: boolean;
  createdAt?: string;
}

export interface IDeal {
  id?: number;
  id_deal?: string;
  account_num: string;
  type_deal?: string;
  type_deal_id: number;
  type_deal_char: string;
  name_deal?: string;
  company?: string;
  company_id?: number;
  containers_ids: number[];
  phone?: string;
  my_company?: string;
  my_company_id?: number;
  contact_id?: number;
  contact?: any;
  bet?: string;
  currency?: string;
  currency_id?: number;
  nds?: string;
  containers: IContainer[];
  comments: IComments[];
  comment?: string;
  comment_last?: string;
  responsible: IResponsible[];
  responsible_id?: number;
  responsible_name?: string;
  docs: number[];
  route: string[];
  delete?: boolean;
}
interface IDealState {
  saveFiles?: any[];
  count: number;
  currentPage: number;
  page: number;
  loading: boolean;
  loadingOneDeal: boolean;
  locations: any[];
  idDeal: string;
  isEditDeal: boolean;
  containerDealStatuses: {id: number, value: string}[];
  dealType: {id: number, value: string, char: string}[];
  isEditDealContainers: boolean;
  responsible: IResponsible[];
  docs: number[];
  dealData: IDeal[];
  dealOneData: IDeal | {};
  containers: IContainer[];
  comments: IComments[];
}

const initialState: IDealState = {
  saveFiles: [],
  containerDealStatuses: [],
  dealType: [],
  loading: false,
  loadingOneDeal: false,
  count: 0,
  currentPage: 0,
  page: 20,
  locations: [],
  idDeal: '',
  isEditDeal: false,
  isEditDealContainers: false,
  responsible: [],
  docs: [],
  dealData: [],
  dealOneData: {},
  containers: [],
  comments: [],
};
export const generateIdDeal = createAsyncThunk(
  'generateIdDeal',
  async (payload: string) => {
    return await Rest_deal.generateIdDeal(payload);
  },
);


export const getFiltersDeal = createAsyncThunk(
  'getFiltersDeal',
  async (param: any) => {
    return await Rest_deal.getFiltersDeal(param);
  },
);
export const getDeals = createAsyncThunk('getDeals', async (param: any) => {
  return await Rest_deal.getDeals(param);
});
export const getContainerDealStatuses = createAsyncThunk('getContainerDealStatuses', async () => {
  return await Rest_deal.getContainerDealStatuses();
});
export const getTypeDeal = createAsyncThunk('getTypeDeal', async () => {
  return await Rest_deal.getTypeDeal();
});
export const setDeals = createAsyncThunk(
  'setDeals',
  async (payload: {
    deal: IDeal;
    containers: IContainer[];
    comments: IComments[];
  }) => {
    return await Rest_deal.setDeals(payload);
  },
);
export const findOneDeal = createAsyncThunk(
  'findOneDeal',
  async (id: number) => {
    return await Rest_deal.findOneDeal(id);
  },
);
export const deleteDeal = createAsyncThunk(
  'deleteDeal',
  async (payload: any) => {
    return await Rest_deal.deleteDeal(payload);
  },
);
export const findAllCommentsDeal = createAsyncThunk(
  'findAllCommentsDeal',
  async (id: number) => {
    return await Rest_deal.findAllCommentsDeal(id);
  },
);

const dealSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setLocations(state, action) {
      state.locations = action.payload;
    },
    setResponsible(state, action) {
      state.responsible = action.payload;
    },
    setEditDeal(state, action) {
      state.isEditDeal = action.payload;
    },
    setOneDeal(state, action) {
      state.dealOneData = action.payload;
    },
    setTableDeals(state, action) {
      state.dealData = action.payload;
    },
    setFiltersDeals(state, action) {
      state.dealData = action.payload || [];
    },
    setDocsDeal(state, action) {
      state.docs = action.payload;
    },
    setIsEditDealContainers(state, action) {
      state.isEditDealContainers = action.payload;
    },
    editDealContainerList(state, action) {
      state.containers = action.payload;
    },
    setSaveFiles(state, action) {
      state.saveFiles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateIdDeal.fulfilled, (state, action) => {
      state.idDeal = action.payload;
    });

    builder.addCase(getFiltersDeal.fulfilled, (state, action) => {
      state.count = action.payload?.count || 0;
      state.dealData = action.payload?.rows || [];
    });

    builder.addCase(getTypeDeal.fulfilled, (state, action) => {
      state.dealType = action.payload;
    });

    builder.addCase(getDeals.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action.payload?.count || 0;
      state.dealData = action.payload?.rows || [];
    });
    builder.addCase(getDeals.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDeals.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(setDeals.fulfilled, (state, action) => {
      const {deal, containers, comments} = action.payload;
      state.dealOneData = {...deal, comments, containers};
      state.comments = comments;
      state.containers = containers;
    });

    builder.addCase(deleteDeal.fulfilled, (state, action) => {
      state.dealData = action.payload;
    });


    builder.addCase(findOneDeal.fulfilled, (state, action) => {
     const {deal, containers, comments} = action.payload;
      state.dealOneData = {...deal, comments, containers};
      state.comments = comments;
      state.containers = containers;
      state.loadingOneDeal = false;
    });
    builder.addCase(findOneDeal.pending, (state, action) => {
      state.loadingOneDeal = true;
    });
    builder.addCase(findOneDeal.rejected, (state, action) => {
      state.loadingOneDeal = false;
    });

    builder.addCase(getContainerDealStatuses.fulfilled, (state, action) => {
      state.containerDealStatuses = action.payload;
    });

    builder.addCase(findAllCommentsDeal.fulfilled, (state, action) => {
      state.comments = action.payload?.rows;
    });

  },
});
export default dealSlice.reducer;
export const {
  setResponsible,
  setEditDeal,
  setDocsDeal,
  setIsEditDealContainers,
  setOneDeal,
  editDealContainerList,
  setLocations,
  setCount,
  setPage,
  setCurrentPage,
  setFiltersDeals,
  setLoading,
  setTableDeals,
  setSaveFiles,
} = dealSlice.actions;
