import {createRoot} from 'react-dom/client';
import App from './App';
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {store} from "./store";
import {ConfigProvider} from "antd";
import ru_RU from 'antd/es/locale/ru_RU';
import "./i18n/i18n";

const container: any = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <ConfigProvider locale={ru_RU}>
                <App />
            </ConfigProvider>
        </BrowserRouter>
    </Provider>
);
