import React from 'react';
import { Form, Input, Table, Upload, Button } from 'antd';
// import { purchaseColumns, subleaseColumns, ktkColumns } from './columns';

interface Props {
    initialValue: any;
  }

const ReleaseTab: React.FC<Props> = ({ initialValue }) => {
  return (
    <div>
      hello 4
    </div>
  );
};

export default ReleaseTab;
