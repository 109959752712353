import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { App, AutoComplete, Button, Flex, Input, Space } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { RouteNames } from '../../../router/names';
import Container from '../../../components/Container';
import { filtersDealRent } from '../../../store/filters';
import { RootState, useStoreDispatch } from '../../../store';
import { useBreadcrumb, useCsvExport, useMenu, useParamsHistory } from '../../../hooks';
import {
  IColumnType,
  IPagination,
  Table,
} from '../../../components/ui/Table/Table';
import {
  deleteDeal,
  getFiltersDeal,
  IDeal,
  setCount,
  setCurrentPage,
  setDeals,
  setFiltersDeals,
  setLoading,
  setPage,
  setResetState,
  setTableDeals,
} from '../../../store/rent';
import { columns } from './columns';
import socket from '../../../socket';
import { DELETE_DEAL_RENT, GET_DEALS_RENT, Rest_rent } from '../../../services/rest_rent';
import { SEARCH_FILTERS_DEAL_RENT } from '../../../services/rest_filters';
import { COUNT_RENT_DEAL, ROW_RENT_DEAL, TABLE_RENT_DEAL } from './constants';

const Deals = () => {
  const {message} = App.useApp();
  const { t } = useTranslation();
  const [activeButtons, setActiveButtons] = useState<any>({});
  const [searchColumn, setSearchColumn] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const dealData = useSelector<RootState>(
    (state) => state.rent.dealData,
  ) as IDeal[];
  const loading = useSelector<RootState>(
    (state) => state.rent.loading,
  ) as boolean;
  const page = useSelector<RootState>((state) => state.rent.page) as number;
  const count = useSelector<RootState>((state) => state.rent.count) as number;
  const current = useSelector<RootState>(
    (state) => state.rent.currentPage,
  ) as number;
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const { generateCSV, copyToClipboard } = useCsvExport({
    fields: columns
      .filter((item: any) => item.dataIndex !== 'hidden') // Исключаем элементы с dataIndex равным 'hidden'
      .map((item: any) => item.dataIndex), // Создаем массив из dataIndex
  });

  useBreadcrumb(['Аренда', 'Сделки']);
  useMenu({openKey: RouteNames.RENT, activeKeys: [RouteNames.RENT, RouteNames.RENT_DEAL]});


  useEffect(() => {
    dispatch(setResetState());
  }, [pathname]);

  const handleButtonClickSearch = (option: any) => {
    setActiveButtons((prevActiveButtons: any) => {
      setSearchColumn((prev) =>
        prevActiveButtons[option.key]
          ? prev.filter((item: string) => item !== option.dataIndex)
          : [...prev, option.dataIndex],
      );
      return {
        ...prevActiveButtons,
        [option.key]: !prevActiveButtons[option.key],
      };
    });
  };

  const handleSearch = (val: string) => {
    setSearchValue(val);
    // dispatch(filtersDealRent({ text: val, data: searchColumn }))
    //   .then((res) => {
    //     dispatch(setFiltersDeals(res.payload?.rows));
    //   })
    //   .finally(() => socket.off(SEARCH_FILTERS_DEAL_RENT));
  };

  const handleShowDrawerDeal = () => {
    history.push(RouteNames.RENT_DEAL_CREATE, { [TABLE_RENT_DEAL]: dealData, [COUNT_RENT_DEAL]: count });
  };

  const onClickLink = (data: any) => {
    const { id } = data?.[0];
    if (id) {
      history.push(`${RouteNames.RENT_DEAL_VIEW}${id}`, {
        [ROW_RENT_DEAL]: data?.[0],
        [TABLE_RENT_DEAL]: dealData,
        [COUNT_RENT_DEAL]: count,
      });
    }
  };

  const showCardContainer = (data: any) => {
    const { id } = data;
    if (id) {
      history.push(`${RouteNames.RENT_DEAL_VIEW}${id}`, {
        [ROW_RENT_DEAL]: data,
        [TABLE_RENT_DEAL]: dealData,
        [COUNT_RENT_DEAL]: count,
      });
    }
  };

  const handleShowEditForm = (payload: any) => {
    const { id, edit } = payload;
    if (id) {
      history.push(
        `${edit ? RouteNames.RENT_DEAL_EDIT : RouteNames.RENT_DEAL_VIEW}${id}`,
        {
          [TABLE_RENT_DEAL]: dealData,
          [COUNT_RENT_DEAL]: count,
          [ROW_RENT_DEAL]: payload,
        },
      );
    }
  };

  const handleCopyAll = (selectedRows: any) => {
    const arSelectedRows = Array.from(selectedRows);
    const rowsData = generateCSV(arSelectedRows, dealData);
    copyToClipboard(rowsData);
  };

  const fetchData = ({
    current,
    clear,
    page,
    order,
  }: {
    current: number;
    page: number;
    clear: string;
    order: string;
  }) => {
    dispatch(setLoading(true));
    Rest_rent.getDeals({
        current: current >= 1 ? current - 1 : current * page,
        page,
        order,
      }).then((res) => {
        console.log(res);

        dispatch(setTableDeals(res?.data?.rows || []));
        dispatch(setCount(res?.data?.count || 1));
      }).catch((err) => {
        message.error('Произошла ошибка')
      })
      .finally(() =>  dispatch(setLoading(false)));
  };

  const handleOrder = (order: string) => {
    fetchData({ current, page, clear: '', order });
  };

  const filtersFetch = (text: string | number, column: IColumnType<any>) => {
    // dispatch(filtersDealRent({ text: text, column: column })).finally(() => {
    //   socket.off(SEARCH_FILTERS_DEAL_RENT);
    // });
  };

  const filtersColumn = (value: []) => {
    if (value.length > 0) {
      // dispatch(
      //   getFiltersDeal({
      //     data: value,
      //     current: current >= 1 ? current - 1 : current * page,
      //     page,
      //   }),
      // );
    } else {
      fetchData({ current, page, clear: '', order: 'DESC' });
    }
  };

  const onChangePagination = (page: IPagination<any>) => {
    if (page?.current !== null && page?.pageSize !== null) {
      const current = page.current || 0;
      const size = page.pageSize || 20;
      dispatch(setCurrentPage((current - 1) * size));
      dispatch(setPage(page.pageSize));
    }
  };

  const handleDeleteRow = (row: any) => {
    // dispatch(deleteDeal(row)).finally(() => {
    //   fetchData({ current, page, clear: '', order: 'DESC' });
    //   socket.off(DELETE_DEAL_RENT);
    // });
  };

  const handleDeleteCopyAll = async (selectedRows: Set<number | undefined>) => {
    const deleteData = dealData?.filter(
      (el) => selectedRows.has(el.id) && !el.delete,
    );

    if (deleteData?.length) {
      // dispatch(deleteDeal(deleteData)).finally(() => {
      //   fetchData({ current, page, clear: '', order: 'DESC' });
      //   socket.off(DELETE_DEAL_RENT);
      // });
    }
  };

  useEffect(() => {
    if (location?.state?.[TABLE_RENT_DEAL]) {
      console.log(location.state);

      dispatch(setTableDeals(location.state[TABLE_RENT_DEAL]));
      dispatch(setCount(location.state?.[COUNT_RENT_DEAL]));
    }
    if (!location?.state?.[TABLE_RENT_DEAL]) {
      fetchData({current, clear: '', page, order: 'DESC'})
    }
  }, [dispatch, page, current, pathname]);

  return (
    <Container>
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('Сделки')}</div>
        </Flex>
        <Flex gap={16}>
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
            onClick={handleShowDrawerDeal}
          />
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            options={columns}
            defaultValue={searchValue}
            popupMatchSelectWidth={false}
            onSearch={handleSearch}
            dropdownRender={() => (
              <>
                <div
                  className={'text-card-12'}
                  style={{ padding: '10px 10px' }}
                >
                  {t('Выбор колонок по которым будет производиться поиск')}
                </div>
                <Flex
                  gap={5}
                  style={{ width: 300, flexWrap: 'wrap', padding: 10 }}
                >
                  {columns.map(
                    (option) =>
                      option.title !== '' &&
                      option.dataIndex !== 'date_entry' &&
                      option.dataIndex !== 'arrival_date' &&
                      option.dataIndex !== 'departure_date' &&
                      option.dataIndex !== 'booking_before' && (
                        <Button
                          size={'small'}
                          key={option.key}
                          type="primary"
                          className={
                            activeButtons[Number(option.key)]
                              ? 'active-search-btn'
                              : ''
                          }
                          onClick={() => handleButtonClickSearch(option)}
                        >
                          {option.title}
                        </Button>
                      ),
                  )}
                </Flex>
              </>
            )}
          >
            <Input placeholder={t('Введите текст')} />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onClickLink={onClickLink}
          editRow={handleShowEditForm}
          deleteRow={handleDeleteRow}
          handleCopyAll={handleCopyAll}
          filtersFetch={filtersFetch}
          filters={filtersColumn}
          handleDeleteCopyAll={handleDeleteCopyAll}
          onRow={(record, rowIndex) => {
            showCardContainer(record);
          }}
          onChangePagination={onChangePagination}
          columns={columns}
          order={handleOrder}
          dataSource={dealData}
          loading={loading}
          height={'calc(-162px + 100vh)'}
          pagination={{
            pageSize: page,
            total: count,
            current: current,
            showSizeChanger: true,
          }}
        />
      </Space>
    </Container>
  );
};

export default Deals;
